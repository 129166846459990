import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HOME } from 'routes'
import Button from 'components/button'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import useAuth from 'hooks/auth'

const NoPermission: React.FC<{ inProcess?: boolean; type?: string; id?: string; project?: string }> = ({
  inProcess,
  type,
  id,
  project
}) => {
  const { user } = useAuth()
  const query = new URLSearchParams(useLocation().search)
  const permissionType = type === undefined ? query.get('type') : type
  const permissionId = id === undefined ? query.get('id') : id
  const permissionProject = project === undefined ? query.get('project') : project
  const { setSnackbarMessage } = useApplicationStore()

  const [requestedPermission, setRequestedPermission] = useState<boolean>(false)

  const requestPermission = () => {
    if (permissionType && permissionId && permissionProject) {
      setRequestedPermission(true)
      api
        .requestPermission(permissionProject, permissionType, permissionId)
        .then((response) => {
          if (response.data) {
            setSnackbarMessage({
              status: 'success',
              message: 'Permission has been requested from the workspace builders.'
            })
          } else {
            setSnackbarMessage({ status: 'error' })
          }
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error' })
        })
    }
  }

  return (
    <div className="bg-white flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-20">
      <div className="flex flex-column w-full justify-center items-center">
        <div style={{ fontSize: inProcess ? '1.5rem' : '2rem', marginTop: inProcess ? '250px' : 'none' }}>
          Uh oh, you do not have permission to view that content.
        </div>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          {process.env.REACT_APP_ENVIRONMENT === 'production' &&
            permissionType &&
            permissionId &&
            permissionProject &&
            !requestedPermission && (
              <Button internalType="secondary" style={{ marginRight: '20px' }} onClick={() => requestPermission()}>
                Request Permission
              </Button>
            )}
          {!inProcess && user && <Button to={HOME}>Return To Home</Button>}
        </div>
      </div>
    </div>
  )
}

export default NoPermission
