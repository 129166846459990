import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Toolbar from 'components/spreadsheet/components/toolbar'
import { rowHeights, IViewPortColumn } from 'components/spreadsheet/types'
import constants from 'style/constants.module.scss'
import Header from 'components/spreadsheet/components/header'
import Aggregate from 'components/spreadsheet/components/aggregate'
import HeaderAddColumn from 'components/spreadsheet/components/header/add-column'
import Rows from 'components/spreadsheet/components/rows'
import { useDataContext, DataContextProvider } from 'components/spreadsheet/contexts/data'
import ColumnMenu from 'components/spreadsheet/components/menu/views/column'
import AggregateMenu from 'components/spreadsheet/components/menu/views/aggregate'
import CellMenu from 'components/spreadsheet/components/menu/views/cell'
import Form from 'components/spreadsheet/components/form'
import Chart from 'components/spreadsheet/components/chart'
import { INITIAL_SELECTED_CELL, INITIAL_SELECTED_CELL_RANGE, ViewTypes } from 'components/spreadsheet/constants/const'
import ErrorBoundary from 'components/error'
import GroupContextMenu from './components/grouping/GroupContextMenu'
import { IContextMenuState, IProcessObject, ITableViewFilter, ITableViewSort } from 'types'
import NoPermission from 'views/auth/views/nopermission'
import ResourceDeleted from 'views/auth/views/resourceDeleted'
import ExpandedRow from 'components/spreadsheet/components/modal/views/expanded'
import { useProject } from 'hooks/project'
import DeleteColumnModal from 'components/spreadsheet/components/modal/views/deletecolumn'
import EditColumnModal from 'components/spreadsheet/components/modal/views/editcolumn'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { Prompt } from 'react-router-dom'
import ArchivedWarning from 'components/archived'
import { getAllGroupRows } from 'components/spreadsheet/helpers/grouping'
import DeleteResource from 'components/delete'
import DeleteRowModal from 'components/spreadsheet/components/modal/views/deleterow'
import { deleteTableRowAction } from 'components/spreadsheet/contexts/data/actions'
import useApplicationStore from 'hooks/application'
import AuditLogModal from 'components/spreadsheet/components/modal/views/audit'

interface SpreadsheetProps {
  tableId: string
  tableViewId: string
  permissionCap?: number
  processId?: string
  processSectionId?: string
  processResponseId?: string
  processTempVariables?: string[]
  process?: IProcessObject
  setStatusCode?: (statusCode: number) => void
}

const Spreadsheet: React.FC<SpreadsheetProps> = ({
  tableId,
  tableViewId,
  permissionCap,
  processId,
  processSectionId,
  processResponseId,
  processTempVariables,
  process,
  setStatusCode
}) => {
  return (
    <DataContextProvider
      tableId={tableId}
      tableViewId={tableViewId}
      permissionCap={permissionCap}
      processId={processId}
      processSectionId={processSectionId}
      processResponseId={processResponseId}
      processTempVariables={processTempVariables}
      process={process}
      setStatusCode={setStatusCode}
    >
      <SpreadsheetComponent />
    </DataContextProvider>
  )
}

interface IExpandedRow {
  rowId: string
  rowNumber: number
}

const SpreadsheetComponent: React.FC = () => {
  const {
    spreadsheetData,
    setSpreadsheetData,
    setScrollLeft,
    setScrollTop,
    gridRef,
    maxHeight,
    uniqueNumber,
    columns,
    handleToggleCollapseAll,
    handleCreateRow,
    handleCreateBlankRows,
    setCellValue,
    selectedCell,
    setSelectedCell,
    selectedCellRange,
    setSelectedCellRange,
    groupedRows
  } = useDataContext()

  const [deleteColumnModal, setDeleteColumnModal] = useState<boolean>(false)
  const [editColumnModal, setEditColumnModal] = useState<boolean>(false)
  const [editColumnMenu, setEditColumnMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [editCellMenu, setEditCellMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [editAggregateMenu, setEditAggregateMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [groupMenu, setGroupMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [dragOver, setDragOver] = useState<string>('')
  const [fullScreen, setFullScreen] = useState<boolean>(false)
  const [expandModal, updateExpandModal] = useState<boolean>(false)
  const [draggedColumnId, setDraggedColumnId] = useState<string>('')
  const { project } = useProject()
  const { setSnackbarMessage } = useApplicationStore()
  const [expandedRow, setExpandedRow] = useState<IExpandedRow>()
  const [deleteRowModal, setDeleteRowModal] = useState<boolean>(false)
  const [deleteRowsModal, setDeleteRowsModal] = useState<boolean>(false)
  const [rowAuditModal, setRowAuditModal] = useState<{
    open: boolean
    rowId: string
  }>({ open: false, rowId: '' })

  const [focusInput, setFocusInput] = useState<boolean>(false)

  const openRowAuditModal = (open: boolean, rowId: string) => {
    setRowAuditModal({ open, rowId })
  }

  const modifyExpandModalState = useCallback(
    (expandModal: boolean, focusInput: boolean, rowId: string, rowNumber: number) => {
      updateExpandModal(expandModal)
      setFocusInput(focusInput)
      setExpandedRow({ rowId, rowNumber })
    },
    []
  )

  const onSuccess = () => {
    setEditCellMenu(INITIAL_CONTEXT_MENU_STATE)
    setSnackbarMessage({
      status: 'success',
      message: 'Rows successfully deleted.'
    })
  }

  const onFailure = () => {
    setSnackbarMessage({
      status: 'error',
      message: 'Something went wrong deleting the rows. Please refresh and try again.'
    })
  }

  const getSelectedRange = () => {
    if (selectedCell !== INITIAL_SELECTED_CELL && selectedCellRange === INITIAL_SELECTED_CELL_RANGE) {
      const cellRange = { ...INITIAL_SELECTED_CELL_RANGE }
      cellRange['endRowIndex'] = selectedCell.rowNumber
      cellRange['endColumnIndex'] = selectedCell.columnNumber
      return cellRange
    } else {
      return selectedCellRange
    }
  }

  const handleDeleteRows = () => {
    const rowIds: string[] = []

    const rows = spreadsheetData.rows
    const cellRange = getSelectedRange()
    const startRowIndex = Math.min(selectedCell.rowNumber, cellRange.endRowIndex)
    const endRowIndex = Math.max(selectedCell.rowNumber, cellRange.endRowIndex)

    if (startRowIndex >= 0 && endRowIndex >= 0) {
      for (let i = startRowIndex; i < endRowIndex + 1; i++) {
        const row = rows[i]
        if (row) {
          rowIds.push(row.publicId)
        }
      }
      deleteTableRowAction(rowIds, project.publicId, spreadsheetData, setSpreadsheetData, onSuccess, onFailure)
    }
  }

  const handleClick = (event: any) => {
    const element = document.getElementById(`spreadsheet-${uniqueNumber}`)
    const selectCellElement = document.getElementById('select-cell-menu')
    const rowContextElement = document.getElementById(`row-context-menu-${uniqueNumber}`)
    const expandedRowModal = document.getElementById('expanded-row-modal')
    const deleteRowsModal = document.getElementById('delete-rows-modal')
    const rowCommentsModal = document.getElementById('row-comments')

    if (
      (!rowCommentsModal || (rowCommentsModal && !rowCommentsModal.contains(event.target))) &&
      ((element && element.contains(event.target)) ||
        event.target.id === 'calendar-today-icon' ||
        event.target.id === 'calendar-today-icon-container' ||
        event.target.id === 'select-value-list' ||
        (selectCellElement && selectCellElement.contains(event.target)) ||
        (rowContextElement && rowContextElement.contains(event.target)) ||
        (expandedRowModal && expandedRowModal.contains(event.target)) ||
        (deleteRowsModal && deleteRowsModal.contains(event.target)) ||
        event.target.classList.contains('option-text'))
    ) {
      // Clicked inside spreadsheet
    } else {
      // Click outside of spreadsheet
      setSelectedCell(INITIAL_SELECTED_CELL)
      setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [uniqueNumber])

  const unsavedChangesMessage = 'There are unsaved changes - are you sure you wish to navigate away from this page?'

  const handleBeforeUnload = (event: any) => {
    event.preventDefault()
    return (event.returnValue = '')
  }

  useEffect(() => {
    if (spreadsheetData.userConfiguration.unsavedChanges && spreadsheetData.isAdmin) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      if (spreadsheetData.userConfiguration.unsavedChanges && spreadsheetData.isAdmin) {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [spreadsheetData.userConfiguration.unsavedChanges, spreadsheetData.isAdmin])

  const handleOnCloseExpandModal = () => {
    setSelectedCell(INITIAL_SELECTED_CELL)
    setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
    const currentUrlParams = new URLSearchParams(window.location.search)
    currentUrlParams.delete('row')
    const newUrl = window.location.pathname + '?' + currentUrlParams.toString()
    window.history.replaceState({}, '', newUrl)
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setScrollLeft(event.currentTarget.scrollLeft)
    setScrollTop(event.currentTarget.scrollTop)
  }

  const gridWidth = useMemo(
    () =>
      spreadsheetData.columnWidths.frozenWidth +
      spreadsheetData.columnWidths.unfrozenWidth +
      (spreadsheetData.isAdmin ? Number(constants.columnMinWidth) : 0), // need to increase the width so the add new column button fits
    [spreadsheetData.columnWidths.frozenWidth, spreadsheetData.columnWidths.unfrozenWidth]
  )

  const cumulativeWidth = 0

  const placeholderClassname = () => {
    switch (spreadsheetData.viewDetails.rowHeight) {
      case rowHeights.Small:
        return 'small-placeholder'
      case rowHeights.Medium:
        return 'medium-placeholder'
      case rowHeights.Tall:
        return 'tall-placeholder'
      case rowHeights['Extra Tall']:
        return 'extra-tall-placeholder'
      default:
        return 'small-placeholder'
    }
  }

  const inProcess = !!(
    spreadsheetData.processId &&
    spreadsheetData.processSectionId &&
    spreadsheetData.processResponseId
  )

  if (spreadsheetData.errorStatusCode === 403) {
    return (
      <NoPermission
        inProcess={inProcess}
        type={'view'}
        id={spreadsheetData.activeTableView}
        project={project.publicId}
      />
    )
  } else if (spreadsheetData.errorStatusCode === 404) {
    return <ResourceDeleted inProcess={inProcess} />
  } else {
    return (
      <div
        id={`spreadsheet-${uniqueNumber}`}
        className={`h-full w-full text-primary text-sm bg-white block select-none overflow-visible border-inherit ${
          fullScreen ? 'fixed inset-0' : 'relative'
        }`}
        style={{ zIndex: fullScreen ? 5 : 1, transform: 'none' }}
        onMouseDown={(event: any) => {
          const element = document.getElementById(`spreadsheet-${uniqueNumber}`)
          if (event.button === 0 && element && element.contains(event.target)) {
            setSelectedCell(INITIAL_SELECTED_CELL)
            setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
          }
        }}
      >
        {spreadsheetData.tableDetails.isDeleted && <ArchivedWarning message={'This resource has been archived'} />}
        <Prompt
          when={spreadsheetData.userConfiguration.unsavedChanges && spreadsheetData.isAdmin}
          message={() => unsavedChangesMessage}
        />
        <ErrorBoundary>
          <Toolbar
            uniqueNumber={uniqueNumber}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
            handleToggleCollapseAll={handleToggleCollapseAll}
            columns={columns}
          />
        </ErrorBoundary>

        {spreadsheetData.loading && (
          <div
            className="w-full bg-light-grey"
            style={{ zIndex: 2, height: `calc(100% - ${Number.parseInt(constants.toolbarHeight)}px)` }}
          />
        )}
        {!spreadsheetData.loading && spreadsheetData.viewDetails.type === ViewTypes.SPREADSHEET && (
          <ErrorBoundary>
            <div
              id={`data-view-${uniqueNumber}`}
              className="relative bg-light-grey w-full overflow-scroll"
              ref={gridRef}
              onScroll={handleScroll}
              style={{
                height: `calc(100% - ${constants.toolbarHeight}px)`
              }}
            >
              <div
                className="sticky bg-light-grey border-b-1px border-solid border-grey"
                style={{
                  width: gridWidth,
                  height: `${Number(constants.headerHeight)}px`,
                  top: '-1px',
                  zIndex: 7
                }}
              >
                <Header
                  width={Number(constants.rowNumberColumnWidth)}
                  cumulativeWidth={cumulativeWidth}
                  frozen={true}
                  isLastFrozenColumn={false}
                />
                {columns.map((viewportColumn: IViewPortColumn) => {
                  const isSorted =
                    spreadsheetData.userConfiguration.sortSettings.find(
                      (sortedColumn: ITableViewSort) => sortedColumn.columnId === viewportColumn.column.publicId
                    ) !== undefined
                  const isFiltered =
                    spreadsheetData.userConfiguration.filterSettings.find(
                      (filteredColumn: ITableViewFilter) => filteredColumn.columnId === viewportColumn.column.publicId
                    ) !== undefined
                  return (
                    <Header
                      key={viewportColumn.column.publicId}
                      columnNumber={viewportColumn.originalPosition}
                      width={viewportColumn.column.width}
                      cumulativeWidth={viewportColumn.left}
                      value={viewportColumn.column.name}
                      locked={viewportColumn.column.locked && !viewportColumn.isGrouped}
                      kind={viewportColumn.column.kind}
                      optionClick={setEditColumnMenu}
                      frozen={viewportColumn.isFrozen}
                      filtered={isFiltered}
                      sorted={isSorted}
                      dragOver={dragOver}
                      setDragOver={setDragOver}
                      isAdmin={spreadsheetData.isAdmin}
                      isLastFrozenColumn={viewportColumn.isLastFrozenColumn}
                      columnId={viewportColumn.column.publicId}
                      viewId={spreadsheetData.viewDetails.publicId}
                      draggedColumnId={draggedColumnId}
                      setDraggedColumnId={setDraggedColumnId}
                    />
                  )
                })}
                {spreadsheetData.isAdmin && (
                  <HeaderAddColumn spreadsheetData={spreadsheetData} setSpreadsheetData={setSpreadsheetData} />
                )}
              </div>

              <div
                id="row-footer-container"
                style={{
                  minHeight: `calc(100% - ${
                    Number.parseInt(constants.statsHeight) + Number.parseInt(constants.headerHeight) + 6
                  }px)`
                }}
              >
                <div
                  className={spreadsheetData.refreshing ? 'bg-grey relative opacity-40 z-100' : placeholderClassname()}
                  style={{ height: maxHeight, width: gridWidth }}
                />

                <Rows
                  contextClick={setEditCellMenu}
                  width={gridWidth}
                  onGroupContext={setGroupMenu}
                  setExpandModal={modifyExpandModalState}
                />
              </div>

              <div
                id="footer"
                className="bg-light-grey sticky border-solid border-grey"
                style={{
                  borderTopWidth: '1px',
                  borderBottomWidth: '1px',
                  width: gridWidth,
                  bottom: 0,
                  zIndex: 7
                }}
              >
                <Aggregate
                  cumulativeWidth={cumulativeWidth}
                  width={Number(constants.rowNumberColumnWidth)}
                  frozen={true}
                  isLastFrozenColumn={false}
                  borderTop={'none'}
                  backgroundColor={constants.lightGrey}
                  height={Number(constants.statsHeight) - 1}
                  streaming={spreadsheetData.streaming}
                />
                {columns.map((viewportColumn, columnNumber) => {
                  return (
                    <Aggregate
                      key={viewportColumn.column.publicId}
                      cumulativeWidth={viewportColumn.left}
                      width={viewportColumn.column.width}
                      frozen={viewportColumn.isFrozen}
                      isLastFrozenColumn={viewportColumn.isLastFrozenColumn}
                      rows={groupedRows ? getAllGroupRows(groupedRows) : spreadsheetData.rows}
                      optionClick={setEditAggregateMenu}
                      column={viewportColumn}
                      columnNumber={columnNumber}
                      borderTop={'none'}
                      backgroundColor={constants.lightGrey}
                      height={Number(constants.statsHeight) - 1}
                      streaming={spreadsheetData.streaming}
                    />
                  )
                })}
              </div>

              <ColumnMenu
                id={`column-context-menu-${uniqueNumber}`}
                menuState={editColumnMenu}
                setMenuState={setEditColumnMenu}
                width={175}
                setEditColumnModal={setEditColumnModal}
                setDeleteColumnModal={setDeleteColumnModal}
              />
              <CellMenu
                id={`row-context-menu-${uniqueNumber}`}
                menuState={editCellMenu}
                setMenuState={setEditCellMenu}
                width={250}
                onCreateRow={handleCreateRow}
                onCreateBlankRows={handleCreateBlankRows}
                uniqueNumber={uniqueNumber}
                setExpandModal={modifyExpandModalState}
                setRowAuditModal={openRowAuditModal}
                selectedCellRange={selectedCellRange}
                setDeleteRowModal={setDeleteRowModal}
                setDeleteRowsModal={setDeleteRowsModal}
              />

              {editColumnModal && (
                <EditColumnModal
                  id="expanded-row-modal"
                  open={editColumnModal}
                  setOpen={setEditColumnModal}
                  initialColumnNumber={editColumnMenu.columnNumber}
                  initialColumnId={editColumnMenu.columnId}
                />
              )}
              {deleteColumnModal && (
                <DeleteColumnModal
                  id="delete-column-modal"
                  open={deleteColumnModal}
                  setOpen={setDeleteColumnModal}
                  initialColumnId={editColumnMenu.columnId}
                />
              )}

              {expandModal && (
                <ExpandedRow
                  id="expanded-row-modal"
                  open={expandModal}
                  setOpen={updateExpandModal}
                  focusInput={focusInput}
                  setCellValue={setCellValue}
                  setSelectedCell={setSelectedCell}
                  selectedCell={selectedCell}
                  onClose={handleOnCloseExpandModal}
                  initialRowId={expandedRow?.rowId}
                  initialRowNumber={expandedRow?.rowNumber}
                />
              )}

              {rowAuditModal.open && rowAuditModal.rowId && rowAuditModal.rowId !== '' && (
                <AuditLogModal
                  id="row-audit-modal"
                  open={rowAuditModal.open}
                  setOpen={(open: boolean) => openRowAuditModal(open, '')}
                  rowId={rowAuditModal.rowId}
                  verbs={['table.cell_updated']}
                />
              )}

              {deleteRowModal && (
                <DeleteRowModal
                  id="delete-row-modal"
                  open={deleteRowModal}
                  setOpen={setDeleteRowModal}
                  initialRowId={editCellMenu.rowId}
                />
              )}

              {deleteRowsModal && (
                <DeleteResource
                  id="delete-rows-modal"
                  open={deleteRowsModal}
                  setOpen={setDeleteRowsModal}
                  resourceName={spreadsheetData.tableDetails.name}
                  onClose={() => {
                    setEditCellMenu(INITIAL_CONTEXT_MENU_STATE)
                  }}
                  deleteResource={() => handleDeleteRows()}
                  notUndo={true}
                />
              )}

              {groupMenu.open && (
                <GroupContextMenu
                  id={`grouping-context-menu-${uniqueNumber}`}
                  menuState={groupMenu}
                  setMenuState={setGroupMenu}
                  width={175}
                />
              )}

              {editAggregateMenu.open && (
                <AggregateMenu
                  id={`column-aggregate-menu-${uniqueNumber}`}
                  menuState={editAggregateMenu}
                  setMenuState={setEditAggregateMenu}
                  width={175}
                />
              )}
            </div>
          </ErrorBoundary>
        )}
        {!spreadsheetData.loading && spreadsheetData.viewDetails.type === ViewTypes.FORM && (
          <ErrorBoundary>
            <div
              id={`data-view-${uniqueNumber}`}
              className="relative bg-light-grey w-full overflow-y-auto overflow-x-hidden text-base"
              style={{ height: `calc(100% - ${constants.toolbarHeight}px)` }}
            >
              <Form />
            </div>
          </ErrorBoundary>
        )}
        {!spreadsheetData.loading && spreadsheetData.viewDetails.type === ViewTypes.CHART && (
          <ErrorBoundary>
            <div
              id={`data-view-${uniqueNumber}`}
              className="relative bg-light-grey w-full overflow-y-auto overflow-x-hidden text-base"
              style={{ height: `calc(100% - ${constants.toolbarHeight}px)` }}
            >
              <Chart />
            </div>
          </ErrorBoundary>
        )}
      </div>
    )
  }
}

export default Spreadsheet
