import React, { useState, useEffect } from 'react'
import Select from 'components/select'
import useProject from 'hooks/project'
import api, { NewPolicyRequest } from 'helpers/api'
import TagPermissions from 'components/permissions/views/tag'
import UserPermissions from 'components/permissions/views/user'
import { IAccessPolicy } from 'types'
import constants from 'style/constants.module.scss'
import { AccessResourceKinds, UserRoles, AccessAttributeKinds } from 'app-constants'
import { uuidv4 } from 'helpers/utils'
import Button from 'components/button'
import useApplicationStore from 'hooks/application'

interface IResource {
  label: string
  value: string
  group?: string
}

const BulkPermissionsUpdate: React.FC = () => {
  const { project } = useProject()
  const { setSnackbarMessage } = useApplicationStore()

  const [resourceType, setResourceType] = useState<AccessResourceKinds>()
  const [resources, setResources] = useState<IResource[]>()
  const [selectedResources, setSelectedResources] = useState<string[]>([])
  const [loadingResources, setLoadingResources] = useState<boolean>(false)
  const [permissionTab, setPermissionTab] = useState<string>('user')
  const [policies, setPolicies] = useState<Array<IAccessPolicy>>([])
  const [savingPermissions, setSavingPermissions] = useState<boolean>(false)

  const tabs = [
    { id: 'user', name: 'General Permissions', color: constants.green, icon: 'type-person' },
    { id: 'tags', name: 'Permission Using Tags', color: constants.blue, icon: 'type-tag' }
  ]

  const excludedPermissionCategories =
    resourceType === AccessResourceKinds.PROCESS
      ? []
      : resourceType === AccessResourceKinds.TABLE
      ? [UserRoles.VIEWER, UserRoles.CONTRIBUTER]
      : [UserRoles.OWNER]

  const getProjectViews = async () => {
    const projectTables = await api.getProjectTables(project.publicId)
    if (projectTables.data) {
      for (const table of projectTables.data) {
        const projectViews = await api.getTableViews(table.publicId, { ignoreColumns: true })
        if (projectViews.data) {
          const projectViewsOptions = projectViews.data.map((view) => {
            return {
              value: view.publicId,
              label: view.name,
              group: 'Table: ' + table.name
            }
          })
          setResources((resources) => (resources ? [...resources, ...projectViewsOptions] : projectViewsOptions))
        }
      }
    }
  }

  useEffect(() => {
    setResources(undefined)
    setPolicies([])
    setSelectedResources([])
    setPermissionTab('user')
    if (project && project.publicId !== '' && resourceType) {
      setLoadingResources(true)
      if (resourceType === AccessResourceKinds.PROCESS) {
        api.getProjectProcesses(project.publicId).then((response) => {
          if (response.data) {
            const projectProcesses = response.data.map((process) => {
              return {
                value: process.publicId,
                label: process.name
              }
            })
            setResources(projectProcesses)
            setLoadingResources(false)
          }
        })
      } else if (resourceType === AccessResourceKinds.TABLE) {
        api
          .getProjectTables(project.publicId)
          .then((response) => {
            if (response.data) {
              const projectTables = response.data.map((table) => {
                return {
                  value: table.publicId,
                  label: table.name
                }
              })
              setResources(projectTables)
              setLoadingResources(false)
            }
          })
          .catch((e) => setSnackbarMessage({ status: 'error', message: e }))
      } else if (resourceType === AccessResourceKinds.VIEW) {
        getProjectViews().then(() => {
          setLoadingResources(false)
        })
      }
    }
  }, [project.publicId, resourceType])

  const handleCreateAccessPolicy = async (
    role: UserRoles,
    attributeKind: AccessAttributeKinds,
    attributeId?: string,
    tagReferenceId?: string
  ) => {
    if (resourceType) {
      const policy: IAccessPolicy = {
        accessAttribute: {
          kind: attributeKind,
          publicId: attributeId ? attributeId : 'AAA'
        },
        accessResource: {
          kind: resourceType,
          publicId: uuidv4()
        },
        publicId: uuidv4(),
        role,
        roleLabel: role === UserRoles.OWNER ? 'Owner' : role === UserRoles.CONTRIBUTER ? 'Contributer' : 'Viewer'
      }

      if (attributeKind === AccessAttributeKinds.PROJECT && attributeId) {
        policy.accessAttribute['project'] = {
          publicId: project.publicId,
          name: project.name
        }
      } else if (attributeKind === AccessAttributeKinds.TAG && tagReferenceId) {
        const tag = await api.getTag(tagReferenceId)
        if (tag) {
          policy.accessAttribute['tag'] = {
            publicId: tag.data.publicId,
            value: tag.data.value,
            projectPublicId: tag.data.projectPublicId,
            referencePublicId: tagReferenceId
          }
        } else {
          return
        }
      } else if (attributeKind === AccessAttributeKinds.ALL_TABLE_TAGS && attributeId) {
        const table = await api.getTable(attributeId)

        if (table) {
          policy.accessAttribute['documentTable'] = {
            name: table.data.name,
            publicId: attributeId
          }
        } else {
          return
        }
      } else if (attributeKind === AccessAttributeKinds.USER && attributeId) {
        const user = await api.getUserByPublicId(attributeId)
        if (user) {
          policy.accessAttribute['user'] = {
            publicId: attributeId,
            name: user.data.name,
            firebaseUserId: user.data.firebaseUserId
          }
        } else {
          return
        }
      }
      setPolicies((policies) => [...policies, policy])
    }
  }

  const handleUpdateAccessPolicy = async (policy: IAccessPolicy, role: UserRoles) => {
    if (role == UserRoles.NO_ACCEESS) {
      setPolicies(policies.filter((p) => p.publicId !== policy.publicId))
    } else {
      setPolicies(
        policies.map((p) =>
          p.publicId === policy.publicId
            ? {
                ...policy,
                role,
                roleLabel:
                  role === UserRoles.OWNER ? 'Owner' : role === UserRoles.CONTRIBUTER ? 'Contributer' : 'Viewer'
              }
            : p
        )
      )
    }
  }

  const handleDeleteAccessPolicy = async (policy: IAccessPolicy) => {
    setPolicies(policies?.filter((p) => p.publicId !== policy.publicId))
  }

  const bulkUpdatePermissions = async () => {
    if (resources && resourceType) {
      if (window.confirm('Are you sure you want to bulk update permissions for these resources?')) {
        setSavingPermissions(true)
        let updateNumber = 1

        for (let i = 0; i < selectedResources.length; i++) {
          const selectedResourceId = selectedResources[i]
          const existingPolicies = await api.getResourcePermissions(resourceType, selectedResourceId)

          // First create the new policies
          try {
            for (let j = 0; j < policies.length; j++) {
              const policy = policies[j]
              const policyObject: NewPolicyRequest = {
                attributeKind: policy.accessAttribute.kind,
                attributeId: policy.accessAttribute.publicId === 'AAA' ? undefined : policy.accessAttribute.publicId,
                role: policy.role,
                resourceKind: resourceType,
                resourceId: selectedResourceId,
                tagReferenceId: policy.accessAttribute.tag?.referencePublicId,
                context: { projectId: project.publicId }
              }

              await api.createAccessPolicy(policyObject)
            }

            // Then delete the old policies
            if (existingPolicies.data) {
              for (let k = 0; k < existingPolicies.data.length; k++) {
                const policy = existingPolicies.data[k]
                await api.deleteAccessPolicy(policy.publicId, policy)
              }
            }

            setSnackbarMessage({
              status: 'success',
              message: `Successfully updated resource ${updateNumber} of ${selectedResources.length})`
            })
          } catch {
            setSnackbarMessage({
              status: 'error',
              message: `Unsuccessfully updated resource ${updateNumber} of ${selectedResources.length})`
            })
          }

          // Increment the update number
          updateNumber++
        }

        setResourceType(undefined)
        setSavingPermissions(false)
        setResources(undefined)
        setPolicies([])
        setSelectedResources([])
        setPermissionTab('user')
      }
    }
  }

  return (
    <div className="w-full" style={{ padding: '10px 30px' }}>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Resource Type
        </div>
        <p style={{ marginBottom: '20px' }}>Which type of resource do you want to bulk apply permissions?</p>
        <select
          value={resourceType ? resourceType : ''}
          onChange={(event) => {
            if (event.target.value === '') {
              setResourceType(undefined)
            } else {
              const type: AccessResourceKinds = event.target.value as AccessResourceKinds
              setResourceType(type)
            }
          }}
        >
          <option value="">Select a resource type</option>
          <option value={AccessResourceKinds.PROCESS}>Document</option>
          <option value={AccessResourceKinds.TABLE}>Table</option>
          <option value={AccessResourceKinds.VIEW}>View</option>
        </select>
      </div>

      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Select Resources
        </div>
        <Select
          options={resources ? resources : []}
          optionsSelected={selectedResources}
          setOptionsSelected={setSelectedResources}
          multiselect={true}
          onOptionClick={(option) => {
            // If the option is in the array remove it
            if (selectedResources.includes(option)) {
              setSelectedResources(selectedResources.filter((value) => value !== option))
            }
            // If the option is not in the array add it
            else {
              setSelectedResources([...selectedResources, option])
            }
          }}
          loading={loadingResources}
          disabled={resources === undefined}
          groupBy={resourceType === AccessResourceKinds.VIEW}
        />
      </div>

      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          New Permissions
        </div>

        {resourceType && resources && selectedResources.length > 0 && (
          <div
            className="flex flex-column h-full overflow-hidden select-none"
            style={{ minWidth: '300px', paddingTop: '20px', paddingBottom: '20px' }}
          >
            <div
              className="static flex text-primary justify-start overflow-x-auto overflow-y-hidden"
              style={{ marginBottom: '10px' }}
            >
              <div
                className="flex justify-center items-center text-center cursor-pointer"
                style={{
                  boxShadow:
                    permissionTab === tabs[0].id ? `inset 0 -5px 0 0 ${tabs[0].color}` : 'inset 0 -5px 0 0 grey',
                  minWidth: '200px',
                  height: '40px',
                  paddingBottom: '0.5rem'
                }}
                onClick={() => setPermissionTab(tabs[0].id)}
              >
                <div style={{ fontWeight: 500, color: 'inherit' }}>{tabs[0].name}</div>
              </div>

              {project && (
                <div
                  className="flex justify-center items-center text-center cursor-pointer"
                  style={{
                    boxShadow:
                      permissionTab === tabs[1].id ? `inset 0 -5px 0 0 ${tabs[1].color}` : 'inset 0 -5px 0 0 grey',
                    minWidth: '200px',
                    height: '40px',
                    paddingBottom: '0.5rem'
                  }}
                  onClick={() => setPermissionTab(tabs[1].id)}
                >
                  <div style={{ fontWeight: 500, color: 'inherit' }}>{tabs[1].name}</div>
                </div>
              )}
            </div>
            <UserPermissions
              policies={policies}
              display={permissionTab === 'user'}
              onCreatePolicy={handleCreateAccessPolicy}
              onUpdatePolicy={handleUpdateAccessPolicy}
              excludedPermissionCategories={excludedPermissionCategories}
            />
            <TagPermissions
              policies={policies}
              display={permissionTab === 'tags'}
              onCreatePolicy={handleCreateAccessPolicy}
              onDeletePolicy={handleDeleteAccessPolicy}
              excludedPermissionCategories={excludedPermissionCategories}
            />
          </div>
        )}
      </div>

      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Apply Changes
        </div>
        <Button
          internalType="danger"
          onClick={() => bulkUpdatePermissions()}
          disabled={!resourceType || !resources || selectedResources.length === 0 || policies.length === 0}
          isLoading={savingPermissions}
        >
          Bulk Save Changes
        </Button>
      </div>
    </div>
  )
}

export default BulkPermissionsUpdate
