import React, { useState } from 'react'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import Row from 'components/spreadsheet/components/row'
import { IContextMenuState } from 'types'
import { isGroupRowCreate, isCollapsedGroupRow } from 'components/spreadsheet/helpers/functions'
import AddRow from 'components/spreadsheet/components/addRow'
import CollapsedRow from 'components/spreadsheet/components/grouping/CollapsedRow'
import { getGroupRowsFromKeys } from 'components/spreadsheet/helpers/grouping'

interface RowsProps {
  contextClick: (menuState: IContextMenuState) => void
  onGroupContext: (menuState: IContextMenuState) => void
  width: number
  setExpandModal: (expandModal: boolean, focusInput: boolean, rowId: string, rowNumber: number) => void
}

const Rows: React.FC<RowsProps> = ({ contextClick, width, onGroupContext, setExpandModal }) => {
  const {
    spreadsheetData,
    setSpreadsheetData,
    rows,
    groupedRows,
    selectedCell,
    setSelectedCell,
    selectedCellRange,
    setSelectedCellRange,
    handleKeyDown,
    uniqueNumber,
    setCellValue,
    startRowIndex,
    endRowIndex,
    columns,
    handleToggleGroup,
    visibleColumns,
    handleCreateRow,
    setIsFocused
  } = useDataContext()
  const [dragOver, setDragOver] = useState<string>('')
  const rowElements = []
  const isGroupingEnabled = spreadsheetData.userConfiguration.groupSettings.length > 0
  let isFirstRow = true

  // Separate into a list of grouped, and non-grouped columns
  const columnsToRender = columns.filter((vColumn) => visibleColumns.includes(vColumn.column.publicId))
  const groupedColumns = columnsToRender.filter((vColumn) => vColumn.isGrouped)
  const nonGroupedColumns = columnsToRender.filter((vColumn) => !vColumn.isGrouped)

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex]
    const previousRow = rows[rowIndex - 1]
    const nextRow = rows[rowIndex + 1]
    const rowNumber = startRowIndex + rowIndex
    let element = null

    if (row !== undefined && isGroupRowCreate(row)) {
      element = (
        <AddRow
          key={`add-row-group-${rowNumber}`}
          width={width}
          onClick={handleCreateRow}
          rowNumber={rowNumber}
          rowHeight={spreadsheetData.viewDetails.rowHeight}
          uniqueNumber={uniqueNumber}
          columns={columns}
          lastGroupRow={row.lastGroupRow}
          colourSettings={spreadsheetData.userConfiguration.colourSettings}
        />
      )
    } else if (row !== undefined && isCollapsedGroupRow(row)) {
      const childRows = groupedRows ? getGroupRowsFromKeys(row.groupKeys, groupedRows) : []
      element = (
        <CollapsedRow
          key={`collapsed-row-${rowNumber}`}
          width={width}
          rowNumber={rowNumber}
          rowHeight={spreadsheetData.viewDetails.rowHeight}
          colourSettings={spreadsheetData.userConfiguration.colourSettings}
          collapsedRow={row}
          onGroupToggle={handleToggleGroup}
          onGroupContext={onGroupContext}
          rows={childRows}
          streaming={spreadsheetData.streaming}
          groupedColumns={groupedColumns}
          nonGroupedColumns={nonGroupedColumns}
        />
      )
    } else {
      element = (
        <Row
          key={`${rowNumber}`}
          rowNumber={rowNumber}
          rowHeight={spreadsheetData.viewDetails.rowHeight}
          row={row}
          prevRowId={previousRow && 'publicId' in previousRow ? previousRow.publicId : ''}
          nextRowId={nextRow && 'publicId' in nextRow ? nextRow.publicId : ''}
          columns={columns}
          contextClick={contextClick}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          selectedCellRange={selectedCellRange}
          setSelectedCellRange={setSelectedCellRange}
          width={width}
          dragOver={dragOver}
          setDragOver={setDragOver}
          isAdmin={spreadsheetData.isAdmin}
          isContributor={spreadsheetData.isContributor}
          setSpreadsheetData={setSpreadsheetData}
          handleKeyDown={handleKeyDown}
          uniqueNumber={uniqueNumber}
          filteredColumns={spreadsheetData.userConfiguration.filterSettings}
          sortedColumns={spreadsheetData.userConfiguration.sortSettings}
          setCellValue={setCellValue}
          groupingEnabled={isGroupingEnabled}
          spreadsheetData={spreadsheetData}
          onGroupToggle={handleToggleGroup}
          onGroupContext={onGroupContext}
          isFirstRow={isFirstRow}
          groupedColumns={groupedColumns}
          nonGroupedColumns={nonGroupedColumns}
          setExpandModal={setExpandModal}
          setIsFocused={setIsFocused}
        />
      )
    }
    rowElements.push(element)
    isFirstRow = false
  }

  // The end row is visible, so display the add row component
  if (
    !spreadsheetData.viewDetails.disableNewRow &&
    !spreadsheetData.tableDetails.isSynced &&
    endRowIndex === spreadsheetData.totalRows &&
    !isGroupingEnabled &&
    spreadsheetData.isContributor
  ) {
    rowElements.push(
      <AddRow
        key="add-row"
        width={width}
        onClick={handleCreateRow}
        rowNumber={endRowIndex}
        rowHeight={spreadsheetData.viewDetails.rowHeight}
        uniqueNumber={uniqueNumber}
        columns={columns}
      />
    )
  }

  return <>{rowElements}</>
}

export default React.memo(Rows)
