import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import api from 'helpers/api'
import { IProject } from 'types'
import Select from 'components/select'
import Checkbox from 'components/checkbox'
import Button from 'components/button'
import useApplicationStore from 'hooks/application'
import useProject from 'hooks/project'

interface DuplicateProps extends ModalProps {
  type: 'table' | 'workspace' | 'process'
  publicId: string
}

const Duplicate: React.FC<DuplicateProps> = ({ id, open, setOpen, type, publicId }) => {
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const [projects, setProjects] = useState<IProject[]>()
  const [targetProjectId, setTargetProjectId] = useState<string>()
  const [duplicatePermissions, setDuplicatePermissions] = useState<boolean>(true)

  useEffect(() => {
    if (project && project.publicId !== '') {
      setTargetProjectId(project.publicId)
    }
  }, [project])

  useEffect(() => {
    api
      .getOwnerProjects()
      .then((response) => {
        if (response && response.data) {
          setProjects(
            response.data
              .filter((project: IProject) => !project.isDeleted)
              .sort((a: IProject, b: IProject) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
          )
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const duplicate = () => {
    if (type === 'workspace') {
      api
        .duplicateProject({ publicId, duplicatePermissions })
        .then(() => {
          setSnackbarMessage({
            status: 'success',
            message: 'Duplication has started. You will receive an email once it has been complete.'
          })
          setOpen(false)
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({ status: 'error' })
        })
      return
    }

    if (targetProjectId) {
      if (type === 'table') {
        api
          .duplicateTable({
            publicId,
            targetProjectId,
            duplicatePermissions
          })
          .then(() => {
            setSnackbarMessage({
              status: 'success',
              message: 'Duplication has started. You will receive an email once it has been complete.'
            })
            setOpen(false)
          })
          .catch((error) => {
            console.error(error)
            setSnackbarMessage({ status: 'error' })
          })
      } else if (type === 'process') {
        api
          .duplicateProcess({
            publicId,
            targetProjectId,
            duplicatePermissions
          })
          .then(() => {
            setSnackbarMessage({
              status: 'success',
              message: 'Duplication has started. You will receive an email once it has been complete.'
            })
            setOpen(false)
          })
          .catch((error) => {
            console.error(error)
            setSnackbarMessage({ status: 'error' })
          })
      }
    }
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={id === 'use-template' ? 'Use Template' : `Duplicate ${type.charAt(0).toUpperCase()}${type.substring(1)}`}
    >
      {type === 'table' || type === 'process' ? (
        <>
          <div style={{ marginBottom: '20px' }}>
            Please select the project you want to duplicate this {type} to. Only projects in which you are
            &apos;administrator&apos; or &apos;owner&apos; will be shown.
          </div>
          <Select
            options={
              projects
                ? projects.map((project) => {
                    return { value: project.publicId, label: project.name }
                  })
                : []
            }
            optionsSelected={targetProjectId ? [targetProjectId] : []}
            onOptionClick={(option: string) => setTargetProjectId(option)}
            loading={projects === undefined}
          />
        </>
      ) : (
        <>
          <div style={{ marginBottom: '20px' }}>
            All tables, processes, workspace settings and webhooks will be duplicated into a new workspace.
          </div>
        </>
      )}
      <>
        <div style={{ marginTop: '20px' }}>
          Please select whether you would like the duplicated resource to have the same permissions.
        </div>
        <Checkbox checked={duplicatePermissions} onChange={(event) => setDuplicatePermissions(event.target.checked)}>
          Duplicate Permissions
        </Checkbox>
      </>

      <div className="flex justify-end">
        <Button internalType="danger" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          internalType="accept"
          onClick={() => duplicate()}
          style={{ marginLeft: '10px' }}
          disabled={targetProjectId === undefined && type in ['process', 'table']}
        >
          Duplicate
        </Button>
      </div>
    </Modal>
  )
}

export default Duplicate
