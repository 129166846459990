import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import TableJoinEdit from 'components/spreadsheet/components/modal/views/tablejoins/edit'
import { ITable, ITableJoin } from 'types'
import { useProject } from 'hooks/project'
import { getProjectTables } from 'helpers/utils'
import { getTableJoins } from 'components/spreadsheet/contexts/data/actions'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { Triangle } from 'components/icons'
import useApplicationStore from 'hooks/application'

const TableJoinsModal: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const [showAddNewJoin, setShowAddNewJoin] = useState(false)
  const [expanded, setExpanded] = useState<number>(-1)
  const [tables, setTables] = useState<ITable[]>([])
  const [joins, setJoins] = useState<ITableJoin[]>([])
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()
  useEffect(() => {
    if (project.publicId !== '') {
      getProjectTables(project.publicId)
        .then((tables) => {
          getTableJoins(spreadsheetData.tableDetails.publicId)
            .then((response) => setJoins(response.data as ITableJoin[]))
            .catch((e) => console.error(e))
          setTables(tables)
        })
        .catch((e) => setSnackbarMessage({ status: 'error', message: e }))
    }
  }, [project.publicId])

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Table Look Ups">
      <Button
        internalType="accept"
        onClick={() => {
          setShowAddNewJoin(true)
          setExpanded(-1)
        }}
        style={{ marginBottom: 5, width: '200px' }}
      >
        Add New Table Look Up
      </Button>
      {showAddNewJoin && (
        <TableJoinEdit
          key={`table-join-new`}
          spreadsheetData={spreadsheetData}
          setSpreadsheetData={setSpreadsheetData}
          setOpen={setOpen}
          selected={false}
          tables={tables}
        />
      )}
      {joins.map((join: ITableJoin, index: number) => (
        <div key={`table-join-${index}`} className="shadow rounded" style={{ padding: '10px' }}>
          <div
            className="flex items-center cursor-pointer"
            style={{ minHeight: '50px' }}
            onClick={() => (expanded === -1 ? setExpanded(index) : setExpanded(-1))}
          >
            <div>
              Table Look Up - {join.joinTableName} / {join.joinViewName}
            </div>
            <div className="ml-auto ">
              <Triangle type="right" />
            </div>
          </div>
          {expanded === index && (
            <TableJoinEdit
              spreadsheetData={spreadsheetData}
              setSpreadsheetData={setSpreadsheetData}
              joinTable={join}
              setOpen={setOpen}
              selected={expanded === index}
              tables={tables}
            />
          )}
        </div>
      ))}
    </Modal>
  )
}

export default React.memo(TableJoinsModal)
