import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SendPasswordResetForm, { IFormValues } from 'views/auth/components/SendPasswordResetForm'
import { useApplicationStore } from 'hooks/application'
import api, { APIError } from 'helpers/api'
import { SIGN_IN } from 'routes'

const SendForgotPasswordLink: React.FC = () => {
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const { setSnackbarMessage } = useApplicationStore()
  document.title = 'Morta | Forgot Password'

  const handlePasswordReset = async (values: IFormValues) => {
    try {
      await api.sendPasswordReset(values.email)
      setEmailSubmitted(true)
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({ message: e.message, status: 'error' })
      }
    }
  }

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        <h1 className="mb-12 text-2xl">Forgot password?</h1>
        {emailSubmitted ? (
          <>
            <h2>Please check your email</h2>
            <p>We have sent an email to your account with details of how to reset your password</p>
            <p className="border-t-1px border-solid border-grey mt-20px pt-20px">
              Return to <Link to={SIGN_IN}>Sign In</Link>
            </p>
          </>
        ) : (
          <div>
            <SendPasswordResetForm onSubmit={handlePasswordReset} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SendForgotPasswordLink
