import React from 'react'
import ErrorBoundary from 'components/error'
import useAuth from 'hooks/auth'
import AdminProcessStats from 'views/admin/components/process'
import AdminTableStats from 'views/admin/components/table'
import AdminUsers from 'views/admin/components/users'
import AdminUserStats from 'views/admin/components/userStats'
import AdminProjectStats from 'views/admin/components/projectStats'
import AdminCelery from 'views/admin/components/celery'
import AdminRedis from 'views/admin/components/redis'
import Nav from 'components/nav'
import constants from 'style/constants.module.scss'
import { useParams } from 'react-router'
import { history } from 'helpers/history'
import Audit from 'components/audit'

const sections = ['Today', 'Logs', 'Users', 'Projects', 'Processes', 'Tables', 'Celery', 'Redis']

const Admin: React.FC = () => {
  const params: { tab?: string } = useParams()

  const { user } = useAuth()

  return (
    <div className="flex flex-column w-full h-full overflow-auto">
      <Nav
        sections={sections}
        selectedSection={params.tab ? params.tab.charAt(0).toUpperCase() + params.tab.slice(1) : 'Users'}
        onClick={(section) => {
          // Navigate to new path with section as param
          history.push(`/admin/${section.toLowerCase()}`)
        }}
        primaryColor={constants.blue}
      />
      <div className="flex flex-column w-full h-full overflow-y-scroll" style={{ padding: '10px 30px' }}>
        <ErrorBoundary>
          {user?.isSuperAdmin && params.tab === 'today' && <AdminUsers />}
          {user?.isSuperAdmin && params.tab === 'logs' && (
            <>
              <Audit />
            </>
          )}
          {user?.isSuperAdmin && params.tab === 'users' && <AdminUserStats />}
          {user?.isSuperAdmin && params.tab === 'projects' && <AdminProjectStats />}
          {user?.isSuperAdmin && params.tab === 'processes' && <AdminProcessStats />}
          {user?.isSuperAdmin && params.tab === 'tables' && <AdminTableStats />}
          {user?.isSuperAdmin && params.tab === 'celery' && <AdminCelery />}
          {user?.isSuperAdmin && params.tab === 'redis' && <AdminRedis />}
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default Admin
