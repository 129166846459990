import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import useAuth from 'hooks/auth'
import api, { APIError } from 'helpers/api'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import useApplicationStore from 'hooks/application'
import Integration from 'components/integrations'
import { IntegrationProviders } from 'app-constants'
import { IIntegrationSyncResourceTypes } from 'types'
import {
  getIsProviderSynced,
  getProviderLogo,
  syncIntegration
} from 'components/spreadsheet/components/modal/views/integration/helpers/sync'
import useProject from 'hooks/project'

interface IntegrationModalProps extends ModalProps {
  provider: IntegrationProviders
  integrationName: string
  dataType: IIntegrationSyncResourceTypes
}

const IntegrationModal: React.FC<IntegrationModalProps> = ({
  id,
  open,
  setOpen,
  provider,
  integrationName,
  dataType
}) => {
  const { user } = useAuth()
  const { project } = useProject()
  const { spreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()

  const [syncingIntegration, setSyncingIntegration] = useState<boolean>(false)
  const [deletingIntegration, setDeletingIntegration] = useState<boolean>(false)

  const providerLogo = getProviderLogo(provider)
  const isIntegrationSynced = getIsProviderSynced(provider, spreadsheetData.tableDetails)

  const onSuccess = () => {
    setInterval(() => {
      api.getTable(spreadsheetData.tableDetails.publicId).then((table) => {
        if (table.data.lastSync) {
          setSyncingIntegration(false)
          window.location.reload()
        }
      })
    }, 3000)
  }

  const onFailure = (error: any) => {
    setSyncingIntegration(false)
    if (error instanceof APIError) {
      console.error(error.message)
      setSnackbarMessage({ status: 'error', message: error.message })
    } else {
      setSnackbarMessage({ status: 'error', message: `Something went wrong syncing ${integrationName}.` })
    }
  }

  const handleFailureMessage = (error: string, tableId?: string, tableViewId?: string) => {
    setSnackbarMessage({
      status: 'error',
      message: error,
      action:
        tableId && tableViewId ? (
          <a
            style={{ color: 'inherit !important', border: '1px solid white', padding: '5px', borderRadius: '2px' }}
            href={`/project/${project.publicId}/table/${tableId}/view/${tableViewId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Table
          </a>
        ) : (
          <></>
        )
    })
  }

  const deleteIntegration = () => {
    const confirmation = window.confirm('Are you sure you want to delete this integration?')
    if (confirmation) {
      setDeletingIntegration(true)
      api
        .deleteIntegration(spreadsheetData.tableDetails.publicId, provider)
        .then(() => {
          window.location.reload()
        })
        .catch((e) => {
          setDeletingIntegration(false)
          setSnackbarMessage({
            status: 'error',
            message: `There was an error deleting the ${integrationName} integration`
          })
          const error = e as APIError
          if (error.errors) {
            const errorEntry = error.errors[0]
            const attributes = errorEntry.attribute
            if (attributes && typeof attributes !== 'string') {
              const tableId = attributes.tableId
              const tableViewId = attributes.tableViewId
              handleFailureMessage(errorEntry.messages[0], tableId, tableViewId)
            } else {
              handleFailureMessage(errorEntry.messages[0])
            }
          } else {
            setSnackbarMessage({ status: 'error', message: 'There was an error deleteing the integration.' })
          }
        })
    }
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={
        isIntegrationSynced ? `Delete Sync From ${integrationName}` : `Sync ${dataType} Data From ${integrationName}`
      }
      warningBanner={true}
      warningBannerText={
        isIntegrationSynced
          ? 'This will delete all data currently in the table.'
          : syncingIntegration
          ? 'Your integration is currently being synced. This may take some time and you will be emailed when it is complete.'
          : 'You need to delete all rows in the table before setting up the sync.'
      }
    >
      {!syncingIntegration && (
        <img src={providerLogo} alt={`${provider} logo`} style={{ width: '300px', marginBottom: '30px' }} />
      )}
      {syncingIntegration && (
        <div className="flex items-center justify-center">
          <img src="/assets/images/connecting.gif" alt="loading" style={{ width: '400px' }} />
        </div>
      )}
      {!syncingIntegration && !isIntegrationSynced && (
        <>
          <div>
            You are setting up a sync with {integrationName}. This will copy data from your {integrationName} account.
          </div>

          <Integration
            provider={provider}
            type={dataType}
            buttonLabel={`Sync ${integrationName} ${dataType} Data`}
            buttonLoading={syncingIntegration}
            buttonFunction={(props) =>
              syncIntegration(
                user,
                provider,
                props,
                dataType,
                syncingIntegration,
                setSyncingIntegration,
                spreadsheetData.tableDetails.publicId,
                onSuccess,
                onFailure
              )
            }
          />
        </>
      )}

      {isIntegrationSynced && (
        <>
          <div style={{ marginBottom: '20px' }}>
            The data in this table is being synced from {integrationName} <b>once per day</b>.
          </div>
          <div>
            Are you sure you would like to delete the sync with {integrationName}? This will remove all data from this
            table and delete all columns related to your {integrationName} account.
          </div>
          <div className="flex" style={{ marginTop: '50px' }}>
            <Button
              className="ml-auto text-base"
              internalType="danger"
              onClick={() => deleteIntegration()}
              isLoading={deletingIntegration}
            >
              I understand the consequences, delete the {integrationName} integration
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}
export default IntegrationModal
