import React, { useRef, useState, useEffect } from 'react'
import FieldValueMultiPickerInput from './FieldValueMultiPicker'
import { IFilterType } from 'components/spreadsheet/types'
import { ITableColumn } from 'types'
import constants from 'style/constants.module.scss'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
interface Props {
  value: string
  multipleValues: string[]
  columnId: string
  filterType: string
  columns: ITableColumn[]
  onChange: (value: string) => void
  onChangeMultipleValues: (options: string[]) => void
  optionsOpen?: boolean
}

// Based on the column type + filter kind this will return the correct
// input component to use
const FilterInput: React.FC<Props> = ({
  value,
  multipleValues,
  columnId,
  filterType,
  columns,
  onChange,
  onChangeMultipleValues,
  optionsOpen
}) => {
  const column = columns.find((c) => c.publicId === columnId)!
  const filterRef = useRef<HTMLInputElement>(null)
  const filterTimeout = useRef<TimeoutID | null>(null)
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (filterTimeout.current !== null) cancelTimeout(filterTimeout.current)
    filterTimeout.current = requestTimeout(() => {
      onChange(currentValue)
      filterRef.current?.focus()
    }, 500)
  }, [currentValue])

  const handleDateTimeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.value)
  }

  const handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.checked.toString())
  }

  // For one_of filter type
  if (filterType === IFilterType.one_of || filterType === IFilterType.not_one_of) {
    return (
      <FieldValueMultiPickerInput
        columnId={columnId}
        multipleValues={multipleValues}
        onChange={onChangeMultipleValues}
        optionsOpen={optionsOpen}
      />
    )
  }

  // If an is/is not null filter, we don't need a user defined value
  if (filterType == IFilterType.is_null || filterType == IFilterType.is_not_null) {
    return <div></div>
  }

  // Date time picker
  if (column && (column.kind === 'datetime' || column.kind === 'created' || column.kind === 'modified')) {
    return <input value={value} onChange={handleDateTimeChange} type="datetime-local" />
  }

  // Date picker
  if (column && column.kind === 'date') {
    return <input value={value} onChange={handleDateTimeChange} type="date" />
  }

  // Checkbox picker
  if (column && column.kind === 'checkbox') {
    return <input checked={value === 'true' ? true : false} onChange={handleCheckboxChange} type="checkbox" />
  }

  const handleChangeDefaultValue = (value: any) => {
    setCurrentValue(value)
  }
  // default text input
  return (
    <input
      style={{
        color: value && value.includes('{{') && value.includes('}}') ? constants.orange : 'inherit'
      }}
      type="text"
      value={currentValue}
      ref={filterRef}
      onChange={(evt) => handleChangeDefaultValue(evt.target.value)}
      placeholder="Type a value here..."
    />
  )
}

export default React.memo(FilterInput)
