import {
  IColumnTypes,
  ISpreadsheetContextMenuState,
  ISpreadsheetData,
  rowHeights,
  IFilterType,
  ISelectedCellRange,
  ISelectedCellState,
  ISelectedSearchCell
} from 'components/spreadsheet/types'

/***

  Spreadsheet Constants
  ---------------------------

  - KEY_PRESS - code to identify which key is being pressed
  - KEY_PRESSES - the list of key presses which should be activated in the component

***/

export const KEY_PRESS = {
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown',
  esc: 'Escape'
}

export const KEY_PRESSES = [KEY_PRESS.left, KEY_PRESS.up, KEY_PRESS.right, KEY_PRESS.down]

export enum ViewTypes {
  SPREADSHEET = 0,
  FORM = 1,
  CHART = 2
}

export enum IntegrationSyncTypes {
  NOT_SYNCED = 0,
  READ_ONLY_SYNCED = 1,
  READ_AND_WRITE_SYNCED = 2
}

export enum ValidationDisplayTypes {
  SHOW_VALIDATION = 0,
  HIDE_VALIDATION = 1,
  ONLY_VALIDATION = 2
}

export enum CommentDisplayTypes {
  SHOW_ALL = 0,
  SHOW_WITH_COMMENTS = 1,
  SHOW_UNRESOLVED = 2,
  SHOW_RESOLVED = 3
}

export const ViewTypesNames = ['Spreadsheet', 'Form', 'Chart']

export const ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS = [ViewTypes.SPREADSHEET]

export const getInitialSpreadsheetState = (): ISpreadsheetData => {
  return {
    tableDetails: {
      publicId: '',
      name: '',
      permissionLevel: -1,
      logo: null,
      permissions: [[], [], [], [], []],
      columns: [],
      variables: [],
      defaultViewId: '',
      type: '',
      joins: [],
      keepValidationsInSync: false,
      keepColoursInSync: false,
      allowDuplication: false,
      deletedAt: null,
      isDeleted: false,
      isSynced: false,
      lastSync: undefined,
      syncHourlyFrequency: 24,
      isViewpointSynced: false,
      isViewpointRfisSynced: false,
      isAutodeskBim360Synced: false,
      isProcoreSynced: false,
      isAconexSynced: false,
      isAconexWorkflowsSynced: false,
      isAsiteDocumentsSynced: false,
      isMortaProjectsSynced: false,
      isMortaUsersSynced: false,
      isMortaResourcesSynced: false,
      isMortaCommentsSynced: false,
      isReviztoIssuesSynced: false
    },
    viewDetails: {
      columns: [],
      publicId: '',
      name: '',
      default: false,
      filterSettings: [],
      groupSettings: [],
      colourSettings: [],
      hiddenColumns: [],
      sortSettings: [],
      chartSettings: null,
      permissionLevel: -1,
      frozenIndex: -1,
      rowHeight: rowHeights.Small,
      type: ViewTypes.SPREADSHEET,
      description: null,
      isDefault: false,
      disableNewRow: false,
      allowContributorDelete: false,
      displayValidationErrorRows: ValidationDisplayTypes.SHOW_VALIDATION,
      displayCommentRows: CommentDisplayTypes.SHOW_ALL,
      collapsedGroupView: false,
      unpackMultiselectGroupView: false
    },
    userConfiguration: {
      filterSettings: [],
      groupSettings: [],
      colourSettings: [],
      hiddenColumns: [],
      sortSettings: [],
      chartSettings: null,
      unsavedChanges: false
    },
    processId: undefined,
    processSectionId: undefined,
    processResponseId: undefined,
    tableView: [],
    activeTableView: '',
    comments: [],
    rows: [],
    filteredRows: [],
    totalRows: 0,
    totalRowsOnServer: 0,
    loading: true,
    refreshing: false,
    error: false,
    errorMessage: '',
    columnWidths: {
      frozenWidth: 100,
      unfrozenWidth: 100
    },
    isAdmin: false,
    isContributor: false,
    collapsedGroupIds: new Set<string>(),
    collapsedColumnIds: new Set<string>(),
    streaming: true,
    originalViewColumns: [],
    processTags: [],
    processVariables: [],
    processVariableValues: [],
    searching: false,
    searchTerm: '',
    searchFound: 0,
    selectedSearchCell: { rowId: null, columnId: null, rowNumber: -1, columnNumber: -1 },
    columnValuesCount: {},
    history: [],
    isPollingEnabled: false,
    historyPoint: -1
  }
}

export const INITIAL_SPREADSHEET_CONTEXT_MENU_STATE: ISpreadsheetContextMenuState = {
  open: false,
  top: '0px',
  right: '0px',
  left: '0px',
  bottom: '0px',
  columnNumber: -1,
  columnId: '',
  rowNumber: -1,
  rowId: ''
}

export const COLUMN_TYPE_NAMES = {
  text: 'Text',
  datetime: 'Date + Time',
  date: 'Date',
  link: 'URL',
  multilink: 'Multiple URL',
  select: 'Select',
  multiselect: 'Multiple Select',
  integer: 'Number',
  float: 'Decimal',
  created: 'Created Date',
  modified: 'Modified Date',
  createdby: 'Created By',
  modifiedby: 'Last Modified By',
  percentage: 'Percentage',
  tag: 'Tag',
  variable: 'Variable',
  attachment: 'Attachment',
  phone: 'Phone',
  email: 'Email',
  vote: 'Vote',
  checkbox: 'Checkbox',
  duration: 'Duration',
  autonumber: 'Autonumber'
}

export const TEXT_COLUMN_TYPES: IColumnTypes[] = ['text', 'tag', 'variable', 'email', 'phone']
export const NUMBER_COLUMN_TYPES: IColumnTypes[] = ['integer', 'float', 'percentage', 'duration', 'autonumber']
export const DATE_COLUMN_TYPES: IColumnTypes[] = ['date', 'datetime', 'created', 'modified']
export const USER_COLUMN_TYPES: IColumnTypes[] = ['modifiedby', 'createdby']
export const ATTACHMENT_COLUMN_TYPES: IColumnTypes[] = ['attachment']
export const SELECT_COLUMN_TYPES: IColumnTypes[] = ['select', 'multiselect']
export const LINK_COLUMN_TYPES: IColumnTypes[] = ['link', 'multilink']
export const VOTE_COLUMN_TYPES: IColumnTypes[] = ['vote']
export const BOOLEAN_COLUMN_TYPES: IColumnTypes[] = ['checkbox']
export const PREVIEW_COLUMN_TYPES: IColumnTypes[] = ['created', 'createdby']
export const WARNING_COLUMN_TYPES: IColumnTypes[] = [
  ...DATE_COLUMN_TYPES,
  ...USER_COLUMN_TYPES,
  ...ATTACHMENT_COLUMN_TYPES,
  ...SELECT_COLUMN_TYPES,
  ...NUMBER_COLUMN_TYPES,
  ...LINK_COLUMN_TYPES,
  ...VOTE_COLUMN_TYPES,
  ...BOOLEAN_COLUMN_TYPES
]

export const BLOCK_SELECT_OPTION_TYPES: IColumnTypes[] = [
  'multilink',
  'created',
  'modified',
  'vote',
  ...ATTACHMENT_COLUMN_TYPES,
  ...USER_COLUMN_TYPES
]
export const EDITABLE_DATETIME_OPTION_TYPES: IColumnTypes[] = ['date', 'datetime']
export const INPUT_COLUMN_OPTION_TYPES = [...TEXT_COLUMN_TYPES, 'link', ...NUMBER_COLUMN_TYPES, 'date', 'datetime']
export const NON_EDITABLE_COLUMN_OPTION_TYPES: IColumnTypes[] = [
  'created',
  'modified',
  'autonumber',
  ...USER_COLUMN_TYPES
]
export const BLOCK_COPY_PASTE_CUT_COLUMN_TYPES: IColumnTypes[] = [
  ...ATTACHMENT_COLUMN_TYPES,
  ...USER_COLUMN_TYPES,
  ...VOTE_COLUMN_TYPES,
  ...BOOLEAN_COLUMN_TYPES
]

export const ALL_TYPES = [
  ...TEXT_COLUMN_TYPES,
  ...NUMBER_COLUMN_TYPES,
  ...DATE_COLUMN_TYPES,
  ...USER_COLUMN_TYPES,
  ...ATTACHMENT_COLUMN_TYPES,
  ...SELECT_COLUMN_TYPES,
  ...LINK_COLUMN_TYPES,
  ...VOTE_COLUMN_TYPES,
  ...BOOLEAN_COLUMN_TYPES
]

export const BLOCK_GROUP_BY: IColumnTypes[] = ['multilink', 'vote']
export const BLOCK_SORT_BY: IColumnTypes[] = ['multilink', 'attachment']
export const BLOCK_FILTER_BY: IColumnTypes[] = ['vote']
export const BLOCK_COLOUR_BY: IColumnTypes[] = []
export const BLOCK_SEARCH_BY: IColumnTypes[] = [
  'multilink',
  'created',
  'modified',
  ...VOTE_COLUMN_TYPES,
  ...ATTACHMENT_COLUMN_TYPES,
  ...USER_COLUMN_TYPES,
  ...BOOLEAN_COLUMN_TYPES
]

export const STARTING_PAGE = 1

export const FilterTypesToDescription = {
  [IFilterType.neq]: 'is not',
  [IFilterType.eq]: 'is',
  [IFilterType.contains]: 'contains',
  [IFilterType.in]: 'is in',
  [IFilterType.gte]: '>=',
  [IFilterType.lte]: '<=',
  [IFilterType.gt]: '>',
  [IFilterType.lt]: '<',
  [IFilterType.one_of]: 'one of',
  [IFilterType.not_one_of]: 'not one of',
  [IFilterType.not_contains]: 'does not contain',
  [IFilterType.is_null]: 'is empty',
  [IFilterType.is_not_null]: 'is not empty',
  [IFilterType.starts_with]: 'starts with',
  [IFilterType.ends_with]: 'ends with'
}

export const FilterVariables = [
  '{{user.email_domain}}',
  '{{user.email}}',
  '{{user.id}}',
  '{{user.tags}}',
  '{{user.tag_values}}',
  '{{process.tags}}',
  '{{process.variables}}',
  '{{process.variable_values}}',
  '{{process.public_variables}}'
]

export const COLUMN_NAME = 'column'

export const GROUP_KEY_SPLIT = '<$!?>'

export const INITIAL_SELECTED_CELL_RANGE: ISelectedCellRange = {
  endRowIndex: -1,
  endColumnIndex: -1
}

export const INITIAL_SELECTED_CELL: ISelectedCellState = {
  rowId: '',
  columnId: '',
  rowNumber: -1,
  columnNumber: -1,
  editing: false,
  value: ''
}

export const INITIAL_SELECTED_SEARCH_CELL: ISelectedSearchCell = {
  rowId: null,
  columnId: null,
  rowNumber: -1,
  columnNumber: -1
}
