import React, { useRef } from 'react'
import Menu, { MenuProps } from 'components/menu'
import Variables from 'components/variables'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { updateTable } from 'components/spreadsheet/contexts/data/actions'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import useProject from 'hooks/project'
import useApplicationStore from 'hooks/application'

const VariablesMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()

  const changeTimeout = useRef<TimeoutID | null>(null)

  const handleVariableChange = (value: string[]) => {
    if (changeTimeout.current !== null) cancelTimeout(changeTimeout.current)

    changeTimeout.current = requestTimeout(() => {
      updateTable(
        'variables',
        spreadsheetData,
        setSpreadsheetData,
        value,
        project.publicId,
        () => {
          setSnackbarMessage({ status: 'success', message: 'The table was updated successfully!' })
        },
        (error) => {
          setSnackbarMessage({
            status: 'error',
            message: error
          })
        }
      )
    }, 1000)
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div
        className="overflow-x-hidden overflow-y-auto"
        style={{ listStyle: 'none', padding: '10px', height: '500px', margin: '10px' }}
      >
        <Variables
          initialVariables={spreadsheetData.tableDetails.variables}
          onChange={(variableIds: string[]) => handleVariableChange(variableIds)}
        />
      </div>
    </Menu>
  )
}

export default VariablesMenu
