import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { IUserObject, IVote } from 'types'
import constants from 'style/constants.module.scss'
import api from 'helpers/api'

interface VoteCellModalProps extends ModalProps {
  rowNumber: number
  votes: IVote[]
}

const VoteCellModal: React.FC<VoteCellModalProps> = ({ id, open, setOpen, rowNumber, votes }) => {
  const [users, setUsers] = useState<Partial<IUserObject>[]>([])
  const [tab, setTab] = useState<string>('positive')

  const anonymousName = 'Anonymous User'

  const getAllUsers = async (userIds: string[]) => {
    const allUsers: Partial<IUserObject>[] = []
    for (let i = 0; i < userIds.length; i++) {
      const userId = userIds[i]
      let userDetails = {
        data: {
          name: anonymousName,
          publicId: userId,
          firebaseUserId: userId,
          email: 'anonymous@anonymous.com',
          profilePicture: ''
        }
      }

      try {
        userDetails = await api.getUser(userId)
      } catch {}

      allUsers.push(userDetails.data)
    }
    setUsers(allUsers)
  }

  useEffect(() => {
    const userIds: string[] = []
    for (let i = 0; i < votes.length; i++) {
      const vote: IVote = votes[i]
      if (!userIds.includes(vote.firebaseUserId)) userIds.push(vote.firebaseUserId)
    }
    getAllUsers(userIds)
  }, [])

  const positiveVotes = votes.filter((vote: IVote) => vote.type).length
  const negativeVotes = votes.filter((vote: IVote) => !vote.type).length

  return (
    <Modal id={id} open={open} setOpen={setOpen} title={`View Votes on Row #${rowNumber + 1}`}>
      <div className="flex items-center">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setTab('positive')}
          style={{
            padding: '10px',
            borderBottom: tab === 'positive' ? `2px solid ${constants.blue}` : `2px solid ${constants.grey}`
          }}
        >
          <div>👍</div>
          <div style={{ marginLeft: '5px' }}>{positiveVotes}</div>
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setTab('negative')}
          style={{
            padding: '10px',
            borderBottom: tab === 'negative' ? `2px solid ${constants.blue}` : `2px solid ${constants.grey}`
          }}
        >
          <div>👎</div>
          <div style={{ marginLeft: '5px' }}>{negativeVotes}</div>
        </div>
      </div>

      <div style={{ marginTop: '20px' }}>
        {tab === 'positive' && positiveVotes === 0 && <p>There have been no positive votes so far.</p>}
        {tab === 'negative' && negativeVotes === 0 && <p>There have been no negative votes so far.</p>}
        {((tab === 'positive' && positiveVotes > 0) || (tab === 'negative' && negativeVotes > 0)) && (
          <>
            {users.length !== 0 &&
              votes
                .filter((vote: IVote) => (tab === 'positive' && vote.type) || (tab === 'negative' && !vote.type))
                .sort((voteA: IVote, voteB: IVote) =>
                  voteA.timestamp > voteB.timestamp ? 1 : voteA.timestamp < voteB.timestamp ? -1 : 0
                )
                .map((vote: IVote, voteNumber: number) => {
                  const user: Partial<IUserObject> | undefined = users.find(
                    (user: Partial<IUserObject>) => user.firebaseUserId === vote.firebaseUserId
                  )
                  return (
                    <div className="flex items-center" style={{ marginBottom: '10px' }} key={voteNumber}>
                      <img
                        style={{
                          borderRadius: '50%',
                          width: '60px',
                          height: '60px',
                          objectFit: 'cover',
                          marginRight: '10px'
                        }}
                        src={user && user.profilePicture ? user.profilePicture : '/assets/images/user.png'}
                        alt={`user profile picture`}
                      />

                      <div className="flex flex-column">
                        {user && user.name && user.name !== anonymousName ? (
                          <a
                            className="truncate font-bold"
                            href={`/profile/${user.firebaseUserId}/information`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {user.name}
                          </a>
                        ) : (
                          <div className="truncate font-bold">{anonymousName}</div>
                        )}
                        <div className="text-sm" style={{ marginTop: '5px' }}>
                          Voted on: {vote.timestamp.split('T')[0]}
                        </div>
                      </div>
                      <div className="text-lg ml-auto">{vote.type ? <div>👍</div> : <div>👎</div>}</div>
                    </div>
                  )
                })}
          </>
        )}
      </div>
    </Modal>
  )
}

export default VoteCellModal
