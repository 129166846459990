import React, { useState, useRef, useEffect } from 'react'
import { EditorContent } from 'types'
import { useApplicationStore } from 'hooks/application'
import {
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  AtomicBlockUtils,
  ContentBlock,
  Modifier
} from 'draft-js'
import Editor, { composeDecorators } from '@draft-js-plugins/editor'
import createImagePlugin from '@draft-js-plugins/image'
import createVideoPlugin from '@draft-js-plugins/video'
import createFocusPlugin from '@draft-js-plugins/focus'
import createResizeablePlugin from '@draft-js-plugins/resizeable'
import createDnDUploadPlugin from './plugins/DnDUploadPlugin'
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import createAnchorPlugin from './plugins/AnchorPlugin'
import ControlPanel from './components/ControlPanel'
import { colorStyleFn } from './components/Highlighter'

// Custom block components
import EmbeddedFile from './components/EmbeddedFile'
import EmbeddedImage from './components/EmbeddedImage'
import EmbeddedVideo from './components/EmbeddedVideo'
import EmbeddedIframe from './components/EmbeddedIframe'

import { IFileParentResource } from 'types'
import ErrorBoundary from 'components/error'

// Initiate editor plugins
const focusPlugin = createFocusPlugin()
const resizeablePlugin = createResizeablePlugin()
const blockDndPlugin = createBlockDndPlugin()
const anchorPlugin = createAnchorPlugin()
const linkifyPlugin = createLinkifyPlugin({
  target: '_blank'
})

const decorator = composeDecorators(resizeablePlugin.decorator, focusPlugin.decorator, blockDndPlugin.decorator)
const imagePlugin = createImagePlugin({ decorator, imageComponent: EmbeddedImage })
const videoPlugin = createVideoPlugin({ decorator, videoComponent: EmbeddedVideo })
const { types } = videoPlugin

export interface RichEditorProps {
  databaseDoc: any // TODO
  onBlur?: () => void
  onChange?: (newContent: EditorContent) => void
  readOnly: boolean
  editorId: string
  resources?: IFileParentResource[]
  border?: boolean
}

function myBlockRenderer(contentBlock: ContentBlock, editorState: EditorState) {
  const type = contentBlock.getType()
  if (type === 'atomic') {
    const contentState = editorState.getCurrentContent()
    const block = contentBlock.getEntityAt(0)
    if (block) {
      const entity = contentState.getEntity(block)
      if (entity) {
        const entityType = entity.getType()
        if (entityType === 'FILE') {
          const data = entity.getData()
          const src = data.src
          const filename = data.filename

          return {
            component: EmbeddedFile,
            editable: false,
            props: {
              src: src,
              filename: filename
            }
          }
        }

        if (entityType === 'IFRAME') {
          const data = entity.getData()
          const src = data.src
          const height = data.height

          return {
            component: EmbeddedIframe,
            editable: false,
            props: {
              src: src,
              height: height
            }
          }
        }
      }
    }
  }
}

export const RichEditor: React.FC<RichEditorProps> = ({
  databaseDoc,
  onBlur,
  onChange,
  readOnly,
  editorId,
  resources,
  border
}: RichEditorProps) => {
  const plugins = [
    focusPlugin,
    resizeablePlugin,
    imagePlugin,
    videoPlugin,
    blockDndPlugin,
    anchorPlugin,
    linkifyPlugin,
    createDnDUploadPlugin(resources)
  ]
  const { setSnackbarMessage } = useApplicationStore()
  const [editorState, setEditorState] = useState<EditorState>()
  const [editorFocused, setEditorFocused] = useState<boolean>(false)
  const [showDropArea, setShowDropArea] = useState<boolean>(false)
  const editorContainer = useRef<HTMLDivElement>(null)
  const editor = useRef<Editor>(null)

  function insertFile(editorState: EditorState, url: string, filename: string) {
    if (RichUtils.getCurrentBlockType(editorState) === types.ATOMIC) {
      return editorState
    }
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('FILE', 'IMMUTABLE', { src: url, filename: filename })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')
  }

  function insertIframe(editorState: EditorState, url: string, height: number) {
    if (RichUtils.getCurrentBlockType(editorState) === types.ATOMIC) {
      return editorState
    }
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('IFRAME', 'IMMUTABLE', { src: url, height })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')
  }

  useEffect(() => {
    if (databaseDoc && databaseDoc.content) {
      const content = convertFromRaw(databaseDoc.content)
      setEditorState(EditorState.createWithContent(content))
    } else setEditorState(EditorState.createEmpty())
  }, [editorId])

  function focus() {
    editor.current && editor.current.focus()
  }

  function handleOnChange(newEditorState: EditorState) {
    // Trigger update when actual content updates https://github.com/facebook/draft-js/issues/2804
    const currentContent = editorState?.getCurrentContent()
    const newContent = newEditorState?.getCurrentContent()

    // If only content selection changes don't trigger update
    if (editorState?.getSelection() !== newEditorState?.getSelection() && currentContent === newContent) {
      setEditorState(newEditorState)
      return
    }

    if (onChange) onChange({ content: convertToRaw(newContent) })
    setEditorState(newEditorState)
  }

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      handleOnChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  function toggleStyle(controlType: string, styleType: string) {
    if (editorState) {
      if (controlType === 'INLINE') return handleOnChange(RichUtils.toggleInlineStyle(editorState, styleType))
      return handleOnChange(RichUtils.toggleBlockType(editorState, styleType))
    }
  }

  function handleOnBlur(event: any) {
    if (editorContainer.current && !editorContainer.current.contains(event.relatedTarget)) {
      setEditorFocused(false)
    }
    if (editorState && onBlur) {
      databaseDoc.content = convertToRaw(editorState.getCurrentContent())
      onBlur()
    }
  }

  if (editorState)
    return (
      <ErrorBoundary>
        <div
          className="w-full rounded"
          ref={editorContainer}
          style={{
            padding: '0rem 0rem 0rem 0rem',
            userSelect: readOnly ? 'none' : 'all',
            border: showDropArea ? '2px dashed green' : 'none'
          }}
        >
          <div className="RichEditor-root editor">
            <div
              className="RichEditor-editor rounded"
              style={{ border: border ? '1px solid black' : 'none', padding: '11px 5px' }}
              onClick={() => focus()}
              onDragEnter={() => setShowDropArea(true)}
              onDragOver={() => setShowDropArea(true)}
              onDragLeave={() => setShowDropArea(false)}
              onDrop={() => setShowDropArea(false)}
            >
              <Editor
                data-cy="editor"
                customStyleMap={colorStyleFn()}
                editorState={editorState}
                ref={editor}
                spellCheck={true}
                plugins={plugins}
                handleKeyCommand={handleKeyCommand}
                onChange={handleOnChange}
                onFocus={() => setEditorFocused(true)}
                onBlur={(event) => handleOnBlur(event)}
                readOnly={readOnly}
                blockRendererFn={(block) => myBlockRenderer(block, editorState)}
                handlePastedText={(text, html, editorState) => {
                  try {
                    const newState = Modifier.replaceText(
                      editorState.getCurrentContent(),
                      editorState.getSelection(),
                      text.trim()
                    )
                    handleOnChange(EditorState.push(editorState, newState, 'insert-fragment'))
                    return 'handled'
                  } catch (e) {
                    setSnackbarMessage({ status: 'error', message: 'Failed to paste text' })
                    return 'not-handled'
                  }
                }}
              />
            </div>
            {editorFocused && (
              <ControlPanel
                editorState={editorState}
                onToggle={toggleStyle}
                onChange={handleOnChange}
                addImageModifier={imagePlugin.addImage}
                addVideoModifier={videoPlugin.addVideo}
                addFileModifier={insertFile}
                addIframeModifier={insertIframe}
                resources={resources}
              />
            )}
          </div>
        </div>
      </ErrorBoundary>
    )
  else return <></>
}

export default RichEditor
