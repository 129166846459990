import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import api, { APIError } from 'helpers/api'
import { IUserObject } from 'types'
import useAuth from 'hooks/auth'
import { HOME } from 'routes'
import { mapUsersToTableData } from 'views/admin/components/users/utils'
import Modal from 'components/modal'
import Button from 'components/button'
import Audit from 'components/audit'
import { Table } from 'components/table'
import { dateTime } from 'helpers/date'
import Select from 'components/select'
import { USER_SUBSCRIPTION_LEVELS } from 'app-constants'
import useApplicationStore from 'hooks/application'

interface IAdminUserObject extends IUserObject {
  password: string | null
  subscriptionLevel: number
}

const AdminUserList: React.FC = () => {
  const { setSnackbarMessage } = useApplicationStore()

  const [users, setUsers] = useState<IUserObject[]>()
  const [editUserModal, setEditUserModal] = useState<IAdminUserObject>()
  const [auditModal, setAuditModal] = useState<{ open: boolean; user?: IUserObject }>({ open: false })
  const { startMasquerade } = useAuth()
  const { push } = useHistory()

  const fetchUsers = async () => {
    try {
      const response = await api.getAllUsers()
      setUsers(response.data)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchUsers()
    document.title = 'Morta | Users'
  }, [])

  const createLoginHandler = async (user: IUserObject) => {
    try {
      const response = await api.getMasqueradeCredentials(user)
      startMasquerade(response.data.token)
      push(HOME)
    } catch (e) {
      console.error(e)
    }
  }

  const verifyUserEmail = async (user: IUserObject) => {
    try {
      const response = await api.verifyUserEmail(user.publicId)
      if (response.data && users) {
        const newUsers = [...users].map((userObject: IUserObject) => {
          if (userObject.publicId === user.publicId) {
            return { ...userObject, isEmailVerified: true }
          } else {
            return userObject
          }
        })

        setUsers(newUsers)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const remove2fa = async (user: IUserObject) => {
    try {
      const response = await api.adminRemove2fa(user.publicId)
      if (response.data && users) {
        const newUsers = [...users].map((userObject: IUserObject) => {
          if (userObject.publicId === user.publicId) {
            return { ...userObject, is2FaEnabled: false }
          } else {
            return userObject
          }
        })

        setUsers(newUsers)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleEditUser = (user: IUserObject) => {
    setEditUserModal({ ...user, password: null })
  }

  const password8CharactersError = !(editUserModal && editUserModal.password && editUserModal.password.match(/.{8,}/))
  const passwordLowercaseError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[a-z])/)
  )
  const passwordUppercaseError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[A-Z])/)
  )
  const passwordNumericError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[0-9])/)
  )
  const passwordSpecialError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[!@#$%^&*])/)
  )

  const passwordError =
    password8CharactersError ||
    passwordLowercaseError ||
    passwordUppercaseError ||
    passwordNumericError ||
    passwordSpecialError

  const handleUserSave = async () => {
    try {
      if (
        editUserModal &&
        (editUserModal.password === undefined ||
          editUserModal.password === null ||
          editUserModal.password === '' ||
          (editUserModal.password && !passwordError))
      ) {
        const response = await api.adminUpdateUser(
          editUserModal.publicId,
          editUserModal.name,
          editUserModal.email,
          editUserModal.password,
          editUserModal.subscriptionLevel
        )
        if (response.data && users) {
          const newUsers = [...users].map((userObject: IUserObject) => {
            if (userObject.publicId === editUserModal.publicId) {
              return { ...userObject, name: editUserModal.name, email: editUserModal.email }
            } else {
              return userObject
            }
          })

          setUsers(newUsers)
          setSnackbarMessage({
            status: 'success',
            message: 'User updated successfully! Refresh the page to see any updates to subscription level.'
          })
          setEditUserModal(undefined)
        }
      } else {
        console.error('There is something wrong with the password.')
      }
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({
          status: 'error',
          message: e.message
        })
      } else if (typeof e === 'string') {
        setSnackbarMessage({
          status: 'error',
          message: e
        })
      } else {
        setSnackbarMessage({
          status: 'error'
        })
      }
    }
  }

  const openAuditModal = (user: IUserObject) => {
    setAuditModal({ open: true, user })
  }

  const tableData = mapUsersToTableData(
    users ? users : [],
    createLoginHandler,
    verifyUserEmail,
    handleEditUser,
    remove2fa,
    openAuditModal
  )

  const todayIsoDate = new Date().toLocaleDateString()

  return (
    <>
      <div className="select-none" style={{ marginBottom: '10px' }}>
        {users !== undefined
          ? `🚀 ${
              users.filter((user) => {
                const lastLogin = user.lastLoginAt
                  ? dateTime(new Date(Date.parse(user.lastLoginAt))).toLocaleString()
                  : ''
                return lastLogin.includes(todayIsoDate)
              }).length
            } Users Today 🚀`
          : ''}
      </div>
      <Table
        data={tableData}
        include={[
          { header: 'User', id: 'user' },
          { header: 'Email', id: 'email' },
          { header: 'Signed Up', id: 'createdAt' },
          { header: 'Last Active', id: 'lastLoginAt' },
          { header: 'Subscription Level', id: 'subscriptionLevel' },
          // { header: 'Invited By', id: 'invitedBy' },
          // { header: 'Invites Sent', id: 'invitesSent' },
          // { header: 'Projects On', id: 'projectsOn' },
          { header: '2fa Enabled?', id: 'is2faEnabled' },
          { header: 'Super Admin', id: 'isSuperAdmin' },
          { header: 'Email Verified?', id: 'verified' },
          { header: 'Edit', id: 'edit' },
          { header: 'Login', id: 'login' }
        ]}
        defaultSort={'lastLoginAt'}
        defaultSortAscending={true}
        loading={users === undefined}
      />

      {editUserModal && (
        <Modal
          id="edit-user-modal"
          open={editUserModal !== undefined}
          setOpen={() => setEditUserModal(undefined)}
          title={`Edit User`}
        >
          <div className="mb-8">
            <div className="font-bold mb-4">User Name</div>
            <input
              type="text"
              defaultValue={editUserModal.name}
              onChange={(event) => setEditUserModal({ ...editUserModal, name: event.target.value })}
            />
          </div>
          <div className="mb-8">
            <div className="font-bold mb-4">User Email</div>
            <input
              type="text"
              defaultValue={editUserModal.email}
              onChange={(event) => setEditUserModal({ ...editUserModal, email: event.target.value })}
            />
          </div>
          <div className="mb-8">
            <div className="font-bold mb-4">New User Password</div>
            <input
              type="password"
              onChange={(event) => setEditUserModal({ ...editUserModal, password: event.target.value })}
              autoComplete="new-password"
            />
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${password8CharactersError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${password8CharactersError ? '⤫' : '✓'} Password must be at least 8 characters long`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordLowercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordLowercaseError ? '⤫' : '✓'} Password must contain at least one lowercase letter`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordUppercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordUppercaseError ? '⤫' : '✓'} Password must contain at least one uppercase letter`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordNumericError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordNumericError ? '⤫' : '✓'} Password must contain at least one numeric character`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordSpecialError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordSpecialError ? '⤫' : '✓'} Password must contain at least one special character: !@#$%^&*`}
              </p>
            )}
          </div>

          <div className="mb-8">
            <div className="font-bold mb-4">User Subscription Level</div>
            <Select
              options={USER_SUBSCRIPTION_LEVELS.map((level, levelIndex) => {
                return { label: level, value: levelIndex.toString() }
              })}
              onOptionClick={(option) => {
                setEditUserModal({ ...editUserModal, subscriptionLevel: Number.parseInt(option) })
              }}
              optionsSelected={[editUserModal.subscriptionLevel.toString()]}
            />
          </div>
          <div className="mb-8 flex items-center justify-end">
            <Button internalType="danger" onClick={() => setEditUserModal(undefined)}>
              Cancel
            </Button>
            <Button style={{ marginLeft: '15px' }} internalType="accept" onClick={() => handleUserSave()}>
              Save
            </Button>
          </div>
        </Modal>
      )}

      {auditModal.open && auditModal.user && (
        <Modal
          id={'user-audit'}
          open={auditModal.open}
          setOpen={(open) => setAuditModal({ ...auditModal, open })}
          title="Audit Log"
        >
          <Audit type={'user'} resourceId={auditModal.user.publicId} />
        </Modal>
      )}
    </>
  )
}

export default AdminUserList
