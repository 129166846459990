import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import Permissions from 'components/permissions'
import { AccessResourceKinds } from 'app-constants'
import { useDataContext } from 'components/process/contexts/data'

const PermissionsMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { process } = useDataContext()
  if (process) {
    return (
      <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
        <div
          className="overflow-hidden"
          style={{ listStyle: 'none', height: '500px', padding: '10px', margin: '10px' }}
        >
          <Permissions resource={AccessResourceKinds.PROCESS} resourceId={process.publicId} />
        </div>
      </Menu>
    )
  } else {
    return <></>
  }
}

export default PermissionsMenu
