import React, { useState, useEffect, useRef } from 'react'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'

interface ISnackbarObject {
  message?: string
  status?: string
  action?: any
  duration?: number
}

const _useApplicationProvider = () => {
  const [snackbarMessage, updateSnackbarMessage] = useState<ISnackbarObject>({
    message: '',
    status: 'noShow',
    duration: 5000
  })
  const [sidebarWidth, updateSidebarWidth] = useState<number>(0)
  const [templatesModal, setTemplatesModal] = useState<boolean>(false)

  const timeoutRef = useRef<TimeoutID | null>(null)

  const setSidebarWidth = (newSidebarWidth: number) => {
    localStorage.setItem('mortaSidebarWidth', newSidebarWidth.toString())
    updateSidebarWidth(newSidebarWidth)
  }

  const setSnackbarMessage = (newSnackbarObject: ISnackbarObject) => {
    updateSnackbarMessage(newSnackbarObject)
    if (timeoutRef.current) {
      cancelTimeout(timeoutRef.current)
    }
    timeoutRef.current = requestTimeout(
      () => {
        updateSnackbarMessage({ status: undefined })
      },
      newSnackbarObject.duration === undefined ? 5000 : newSnackbarObject.duration
    )
  }

  useEffect(() => {
    const savedSidebarWidth = localStorage.getItem('mortaSidebarWidth')
    if (savedSidebarWidth) {
      const newSidebarWidth = Number.parseInt(savedSidebarWidth)
      updateSidebarWidth(newSidebarWidth)
    }
  }, [])

  const minSidebarWidth = 60
  const maxSidebarWidth = 600
  const minSidebarWidthDisplay = 170

  return {
    snackbarMessage,
    setSnackbarMessage,
    sidebarWidth,
    setSidebarWidth,
    minSidebarWidth,
    maxSidebarWidth,
    minSidebarWidthDisplay,
    templatesModal,
    setTemplatesModal
  }
}

type ApplicationContextType = ReturnType<typeof _useApplicationProvider>

const ApplicationContext = React.createContext<ApplicationContextType | undefined>(undefined)

export const ApplicationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = _useApplicationProvider()
  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
}

export const useApplicationStore = () => {
  const context = React.useContext(ApplicationContext)

  if (context === undefined) {
    throw new Error('useApplication can only be used within an Application context')
  }

  return context
}

export default useApplicationStore
