import React, { useEffect, useRef, useState } from 'react'
import { useDataContext } from 'components/process/contexts/data'
import { getSectionDisplayNumber } from 'components/process/helpers/formatting'
import useProject from 'hooks/project'
import { IHeadingStyle, IProcessSectionObject } from 'types'
import { CollapseAll, Cross, ExpandAll, Move, Restore, Triangle } from 'components/icons'
import { scrollToSection, sectionSearchMatch } from 'components/process/helpers/functions'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'

interface ProcessSectionTitleProps {
  section: IProcessSectionObject
  sectionNumber: string
  level: number
  formatting: IHeadingStyle
}

const ProcessSectionTitle: React.FC<ProcessSectionTitleProps> = ({ section, sectionNumber, level, formatting }) => {
  const { project } = useProject()
  const { setSection, searchTerm } = useDataContext()

  useEffect(() => {
    if (searchTerm !== '' && !section.expanded && sectionSearchMatch(section, searchTerm)) {
      setSection({ ...section, expanded: true }, true)
    }
  }, [searchTerm])

  return (
    <div className="w-full cursor-pointer select-none">
      <div
        className={`flex w-full ${section.visible && 'bg-light-blue'} transition-all`}
        style={{ paddingLeft: `${level * 10 + 10}px`, paddingRight: '20px' }}
      >
        <span
          className="transition-all"
          style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft: '5px', paddingRight: '5px' }}
          title={section.expanded ? 'Collapse Section' : 'Expand Section'}
          onClick={() => setSection({ ...section, expanded: section.expanded ? false : true }, true)}
        >
          <Triangle type={section.expanded ? 'down' : 'right'} style={{ height: '10px', width: '10px' }} />
        </span>
        <span
          className="transition-all w-full truncate"
          style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft: '5px' }}
          title={`Scroll To ${section.name}`}
          onClick={() => scrollToSection(section.publicId)}
        >
          {sectionNumber}&nbsp;
          {section.name}
        </span>
      </div>
      {section.expanded &&
        section.children.map((subsection: IProcessSectionObject, subsectionNumber: number) => {
          const subsectionFormatting = project.headingStyles[level] ? project.headingStyles[level] : formatting
          const subsectionDisplayNumber = getSectionDisplayNumber(sectionNumber, subsectionNumber, subsectionFormatting)
          const display = sectionSearchMatch(subsection, searchTerm)
          if (display)
            return (
              <ProcessSectionTitle
                key={subsection.publicId}
                section={subsection}
                sectionNumber={subsectionDisplayNumber}
                level={level + 1}
                formatting={subsectionFormatting}
              />
            )
        })}
    </div>
  )
}

const Navigation: React.FC = () => {
  const { project } = useProject()
  const { process, expandAllSections, searchTerm, setSearchTerm } = useDataContext()

  const [y, setY] = useState<number>()
  const [x, setX] = useState<number>()
  const [height, setHeight] = useState<number>()
  const [width, setWidth] = useState<number>()

  const DEFAULT_X = 10
  const DEFAULT_Y = 55
  const DEFAULT_HEIGHT = 400
  const DEFAULT_WIDTH = 362

  const formatting = project.headingStyles[0]
    ? project.headingStyles[0]
    : {
        fontSize: 1.4,
        colour: project.processTitleColour,
        bold: project.processTitleBold,
        italic: project.processTitleItalic,
        underline: project.processTitleUnderline
      }

  const dragging = useRef<boolean>(false)
  const mouseStartX = useRef<number>()
  const mouseStartY = useRef<number>()
  const originalX = useRef<number>()
  const originalY = useRef<number>()

  useEffect(() => {
    setX(DEFAULT_X)
    setY(DEFAULT_Y)
    setHeight(DEFAULT_HEIGHT)
    setWidth(DEFAULT_WIDTH)

    originalX.current = x
    originalY.current = y
    mouseStartX.current = x
    mouseStartY.current = y

    return () => {
      setSearchTerm('')
    }
  }, [])

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault()
    dragging.current = true
    mouseStartX.current = event.clientX
    mouseStartY.current = event.clientY
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (
      dragging.current === true &&
      mouseStartX.current &&
      mouseStartY.current &&
      originalX.current &&
      originalY.current
    ) {
      const deltaX = event.clientX - mouseStartX.current
      const deltaY = event.clientY - mouseStartY.current
      const newX = originalX.current - deltaX
      const newY = originalY.current + deltaY
      setX(newX)
      setY(newY)
    } else if (verticalDragging.current === true && verticalMouseStart.current && originalHeight.current) {
      const deltaY = event.clientY - verticalMouseStart.current
      const newHeight = originalHeight.current + deltaY
      setHeight(newHeight)
    } else if (horizontalDragging.current === true && horizontalMouseStart.current && originalWidth.current) {
      const deltaX = event.clientX - horizontalMouseStart.current
      const newWidth = originalWidth.current - deltaX
      setWidth(newWidth)
    }
  }

  const handleMouseUp = () => {
    if (dragging.current === true) {
      originalX.current = x
      originalY.current = y
      dragging.current = false
      mouseStartX.current = 0
      mouseStartY.current = 0
    } else if (verticalDragging.current === true) {
      verticalDragging.current = false
    } else if (horizontalDragging.current === true) {
      horizontalDragging.current = false
    }
  }

  // Vertical resize logic
  const verticalDragging = useRef<boolean>(false)
  const verticalMouseStart = useRef<number>()
  const originalHeight = useRef<number>()

  const handleVerticalMouseDown = (event: React.MouseEvent) => {
    verticalDragging.current = true
    originalHeight.current = height ? height : DEFAULT_HEIGHT
    verticalMouseStart.current = event.clientY
  }

  // Horizontal resize logic
  const horizontalDragging = useRef<boolean>(false)
  const horizontalMouseStart = useRef<number>()
  const originalWidth = useRef<number>()

  const handleHorizontalMouseDown = (event: React.MouseEvent) => {
    horizontalDragging.current = true
    originalWidth.current = width ? width : DEFAULT_WIDTH
    horizontalMouseStart.current = event.clientX
  }

  const searchRef = useRef<HTMLInputElement>(null)
  const searchTimeout = useRef<TimeoutID | null>(null)

  const updateSearch = (search: string) => {
    if (searchTimeout.current !== null) cancelTimeout(searchTimeout.current)
    searchTimeout.current = requestTimeout(() => {
      setSearchTerm(search)
    }, 500)
  }

  const resetNavPosition = () => {
    const x = DEFAULT_X
    const y = DEFAULT_Y

    dragging.current = false
    mouseStartX.current = 0
    mouseStartY.current = 0
    originalX.current = x
    originalY.current = y
    setX(x)
    setY(y)
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [x, y, height, width])

  if (x !== undefined && y !== undefined && height !== undefined && width !== undefined) {
    return (
      <div
        className="flex flex-column rounded shadow bg-white select-none rounded"
        style={{
          position: 'fixed',
          inset: `${y}px ${x}px auto auto`,
          zIndex: 5,
          width: `${width}px`,
          height: `${height}px`,
          borderBottom: 'none'
        }}
      >
        <div className="flex items-center border-b-1 border-solid border-grey font-bold">
          <input
            ref={searchRef}
            className="w-full"
            placeholder="Search for section"
            defaultValue={''}
            onDragStart={(event) => event.preventDefault()}
            onChange={(event) => updateSearch(event.target.value)}
            style={{ margin: '10px' }}
          />
          <div
            className="flex items-center justify-center hover-bg-light-grey transition-all cursor-pointer"
            style={{ width: '30px', padding: '7px' }}
            title="Clear Search"
            onClick={() => {
              if (searchRef.current) {
                searchRef.current.value = ''
                setSearchTerm('')
              }
            }}
          >
            <Cross />
          </div>

          <div
            className="flex items-center justify-center hover-bg-light-grey transition-all cursor-pointer"
            style={{ width: '30px', padding: '7px' }}
            title="Expand All Sections"
            onClick={() => {
              if (process) {
                expandAllSections(process, true)
              }
            }}
          >
            <ExpandAll />
          </div>
          <div
            className="flex items-center justify-center hover-bg-light-grey transition-all cursor-pointer"
            style={{ width: '30px', padding: '7px' }}
            title="Collapse All Sections"
            onClick={() => {
              if (process) {
                expandAllSections(process, false)
              }
            }}
          >
            <CollapseAll />
          </div>
          <div
            className="flex items-center justify-center hover-bg-light-grey transition-all cursor-pointer"
            style={{ width: '30px', padding: '7px' }}
            title="Reset Navigation Position"
            onClick={() => {
              resetNavPosition()
            }}
          >
            <Restore />
          </div>
          <div
            className="flex items-center justify-center cursor-grab hover-bg-light-grey transition-all"
            title="Move Navigation Panel"
            style={{ width: '30px', padding: '7px', marginRight: '10px' }}
            onMouseDown={handleMouseDown}
          >
            <Move />
          </div>
        </div>
        <div className="overflow-y-auto ">
          {process &&
            process.children.map((section: IProcessSectionObject, sectionNumber: number) => {
              const sectionDisplayNumber = getSectionDisplayNumber('', sectionNumber, formatting)
              const display = sectionSearchMatch(section, searchTerm)
              if (display)
                return (
                  <ProcessSectionTitle
                    key={sectionNumber}
                    level={1}
                    sectionNumber={sectionDisplayNumber}
                    section={section}
                    formatting={formatting}
                  />
                )
            })}
        </div>
        <div
          className="absolute w-full cursor-vertical-resize block rounded h-full bg-blue opacity-0 hover-opacity-1"
          style={{ height: '6px', bottom: 0 }}
          onMouseDown={handleVerticalMouseDown}
        />
        <div
          className="absolute w-full cursor-resize block rounded h-full bg-blue opacity-0 hover-opacity-1"
          style={{ height: `${height}px`, left: 0, width: '6px' }}
          onMouseDown={handleHorizontalMouseDown}
        />
      </div>
    )
  } else return <></>
}

export default Navigation
