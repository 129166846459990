import React, { useState } from 'react'
import { BarChartProps } from 'types'

const BarChart: React.FC<BarChartProps> = ({ data, showLegend, showValues, axisNames }) => {
  const total = data.reduce((sum, { value }) => sum + value, 0)
  const [hoveredLabel, setHoveredLabel] = useState<string | null>(null)

  const handleMouseOver = (label: string): void => {
    setHoveredLabel(label)
  }

  const handleMouseOut = (): void => {
    setHoveredLabel(null)
  }

  const maxValue = Math.max(...data.map((item) => item.value))
  const yAxisOffset = 40 // This provides spacing for the y-axis
  const barSpacing = 20 // This provides spacing between y-axis and the first bar
  const chartWidth = data.length * 80 + yAxisOffset + barSpacing
  const chartHeight = 400 - yAxisOffset
  if (data.length === 0) return null
  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${chartWidth} ${chartHeight}`}>
      {/* X-axis */}
      <line
        x1={yAxisOffset}
        y1={chartHeight - 20}
        x2={chartWidth}
        y2={chartHeight - 20}
        stroke="black"
        strokeWidth="1"
      />
      <text x={yAxisOffset} dy={chartHeight + 10} dx={chartWidth / 2} textAnchor="middle" fontSize="12" fill="black">
        {axisNames.x}
      </text>

      {data.map((bar, index) => {
        const barHeight = (bar.value / maxValue) * (chartHeight - yAxisOffset)
        const barWidth = 60
        const x = index * 80 + yAxisOffset + barSpacing
        const y = chartHeight - barHeight - 20

        const isHovered = !showLegend && hoveredLabel === bar.label
        const percentage = ((bar.value / total) * 100).toFixed(2)

        return (
          <g key={index}>
            <rect
              x={x}
              y={y}
              width={barWidth}
              height={barHeight}
              fill={bar.color}
              onMouseOver={() => handleMouseOver(bar.label)}
              onMouseOut={handleMouseOut}
            />
            {(isHovered || showLegend) && (
              <>
                <text x={x + barWidth / 2} dy={-barHeight} textAnchor="middle" fontSize="6" fill="black">
                  <tspan x={x + barWidth / 2} dy={y - 5}>
                    {percentage}% {showValues ? `,${bar.value.toFixed(2)}` : ''}
                  </tspan>
                </text>
                <text x={x + barWidth / 2} textAnchor="middle" fontSize="6" fill="black">
                  <tspan x={x + barWidth / 2} dy={chartHeight - 10}>
                    {bar.label}
                  </tspan>
                </text>
              </>
            )}
          </g>
        )
      })}

      {/* Y-axis */}
      <line x1={yAxisOffset} y1={chartHeight} x2={yAxisOffset} y2={-30} stroke="black" strokeWidth="1" />
      <text
        textAnchor="middle"
        fontSize="12"
        fill="black"
        direction="ltr"
        transform={`translate(${yAxisOffset - 15}, ${chartHeight / 2}) rotate(-90)`}
      >
        {axisNames.y}
      </text>
    </svg>
  )
}

export default BarChart
