import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { useDataContext } from 'components/process/contexts/data'
import Variables from 'components/variables'

const DefaultVariablesMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { process, updateProcess } = useDataContext()
  if (process) {
    return (
      <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
        <div
          className="overflow-x-hidden overflow-y-auto"
          style={{ listStyle: 'none', margin: '10px', padding: '10px', height: '500px' }}
        >
          <div style={{ marginTop: '20px', marginBottom: '40px' }}>
            Select the default variables that will be used for filtering tables in this process.
          </div>

          <Variables
            initialVariables={process ? process.variables : []}
            onChange={(variableIds: string[]) => updateProcess({ ...process, variables: variableIds })}
          />
        </div>
      </Menu>
    )
  } else {
    return <></>
  }
}

export default DefaultVariablesMenu
