import { decodeMultiStream } from '@msgpack/msgpack'

import { get, post } from 'helpers/http-service'
import { getAuthToken } from 'helpers/auth'

import {
  ResponsePayload,
  ITable,
  ITableViewWithColumns,
  ITableRowCompressed,
  ITableRow,
  ICommentThreadStats,
  ResponseError,
  ICellValue,
  IUsed
} from 'types'
import { SpreadsheetInProcessContext } from '../types'
import { buildQueryString } from 'components/spreadsheet/helpers/functions'
import api from 'helpers/api'

const apiUrl = process.env.REACT_APP_API_HOST
const apiVersion = process.env.REACT_APP_API_VERSION

export const getTable = async (tableId: string): Promise<ResponsePayload<ITable>> => {
  return get(`/table/${tableId}`)
}

export interface IGetTableViewQueryParams {
  ignoreCachedOptions?: boolean
}
export const getTableView = async (
  tableViewId: string,
  params?: IGetTableViewQueryParams
): Promise<ResponsePayload<ITableViewWithColumns>> => {
  const queryString = params ? buildQueryString(params!) : ''
  return get(`/table/views/${tableViewId}${queryString}`)
}

export interface IGetTableViewsQueryParams {
  ignoreColumns?: boolean
}

export const getTableViews = async (
  tableId: string,
  params?: IGetTableViewsQueryParams
): Promise<ResponsePayload<ITableViewWithColumns[]>> => {
  const queryString = params ? buildQueryString(params!) : ''
  return get(`/table/${tableId}/views${queryString}`)
}

export const getWhereViewUsed = async (viewId: string): Promise<ResponsePayload<IUsed[]>> => {
  return get(`/table/views/${viewId}/used`)
}

export const getColumnUsedViews = async (
  tableId: string,
  columnId: string
): Promise<ResponsePayload<ITableViewWithColumns[]>> => {
  return get(`/table/${tableId}/column/${columnId}/views`)
}

export const getTableComments = async (tableId: string): Promise<ResponsePayload<ICommentThreadStats[]>> => {
  return get(`/comment_thread/stats?referenceType=table&mainReferenceId=${tableId}`)
}

interface IAsyncRowsReturn {
  type: 'header' | 'rows'
  rows?: ITableRowCompressed[]
  header?: Array<string>
}

export async function* getTableViewRowsAsync(
  tableId: string,
  tableViewId: string,
  isAdmin: boolean,
  processId?: string
): AsyncGenerator<IAsyncRowsReturn> {
  let uri = isAdmin ? `/table/${tableId}/rows-stream` : `/table/views/${tableViewId}/rows-stream`
  if (processId !== undefined) {
    uri = uri + `?processId=${processId}`
  }
  const response = await fetch(`${apiUrl}${apiVersion}${uri}`, {
    method: 'GET',
    headers: {
      Authorization: getAuthToken()!
    }
  })

  if (response.body === null) {
    return
  }

  let gotHeader = false
  for await (const data of decodeMultiStream(response.body)) {
    if (!gotHeader) {
      gotHeader = true
      yield { type: 'header', header: data as string[] }
    } else {
      const rows = data as ITableRowCompressed[]
      yield { type: 'rows', rows: rows }
    }
  }
}

export const createNewRow = (
  projectId: string,
  tableViewId: string,
  rowData: Record<string, unknown> = {},
  sortOrder?: number,
  context?: SpreadsheetInProcessContext
): Promise<ResponsePayload<ITableRow[]>> => {
  const payload: { rowData: Record<string, unknown>; sortOrder?: number } = {
    rowData
  }
  if (sortOrder !== undefined) {
    payload.sortOrder = sortOrder
  }

  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }

  return api({
    method: 'POST',
    endpoint: `/table/views/${tableViewId}/rows`,
    data: { rows: [payload], context: processContext }
  })
}

export const createNewRows = (
  projectId: string,
  tableViewId: string,
  rows: Array<{ rowData: Record<string, unknown>; sortOrder?: number }>,
  context?: SpreadsheetInProcessContext
): Promise<ResponsePayload<ITableRow[]>> => {
  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }
  return api({ method: 'POST', endpoint: `/table/views/${tableViewId}/rows`, data: { rows, context: processContext } })
}

export const createBlankRows = (
  projectId: string,
  tableViewId: string,
  numberRows: number,
  context?: SpreadsheetInProcessContext,
  values?: Record<string, ICellValue>
): Promise<ResponsePayload<ITableRow[]>> => {
  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }

  const rows = []
  for (let i = 0; i < numberRows; i++) {
    if (values) {
      rows.push({ rowData: { ...values } })
    } else {
      rows.push({ rowData: {} })
    }
  }

  return api({ method: 'POST', endpoint: `/table/views/${tableViewId}/rows`, data: { rows, context: processContext } })
}

export const previewRow = (
  projectId: string,
  tableViewId: string,
  rowData: Record<string, unknown> = {},
  signal: AbortSignal,
  context?: SpreadsheetInProcessContext
): Promise<ResponsePayload<Record<string, ICellValue>>> => {
  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }

  return post(`/table/views/${tableViewId}/preview-row`, { rowData, context: processContext }, signal)
}

export const processErrorMessage = (
  error: any,
  onError: (error: string, tableId?: string, tableViewI?: string) => void
) => {
  try {
    error.json().then((errorBody: ResponseError) => {
      let message = ''

      if (errorBody.detail.message) {
        message = errorBody.detail.message
        if (errorBody.detail.errors) {
          const errorEntry = errorBody.detail.errors![0]
          const attributes = errorEntry.attribute

          if (attributes && typeof attributes !== 'string') {
            const tableId = attributes.tableId
            const tableViewId = attributes.tableViewId
            onError(message, tableId, tableViewId)
            return
          } else if (errorEntry.messages && !Array.isArray(errorEntry.messages)) {
            message += ' ' + JSON.stringify(errorBody.detail.errors[0].messages)
          }
        }
      } else if (errorBody.detail.errors) {
        message = errorBody.detail.errors[0].messages[0]
        if (!Array.isArray(message)) {
          message = JSON.stringify(message)
        }
      } else {
        message = JSON.stringify(errorBody)
      }
      onError(message)
    })
  } catch {
    onError('Something went wrong.')
  }
}

export const getChartData = async (
  tableViewId: string,
  params?: any
): Promise<ResponsePayload<Record<string, number>>> => {
  return api({
    method: 'GET',
    endpoint: `/table/views/${tableViewId}/chart${params ? '?' + new URLSearchParams(params) : ''}`
  })
}
