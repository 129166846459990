import React, { useState, useEffect } from 'react'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { getTableView, getTableViews } from 'components/spreadsheet/helpers/api'
import { ISpreadsheetData, SpreadsheetReducerActions } from 'components/spreadsheet/types'
import { ITableViewColumn, ITableJoin, ITable, ITableView, ITableViewWithColumns, IMapJoinColumn } from 'types'
import { ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS, BLOCK_SELECT_OPTION_TYPES } from 'components/spreadsheet/constants/const'
import {
  saveCurrentView,
  updateTableJoin,
  deleteTableJoin,
  createTableJoin,
  ICreateTableJoin
} from 'components/spreadsheet/contexts/data/actions'
import Button from 'components/button'
import { Delete } from 'components/icons'
import Select from 'components/select'
import Checkbox from 'components/checkbox'

export interface TableJoinEditProps {
  spreadsheetData: ISpreadsheetData
  selected: boolean
  joinTable?: ITableJoin
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>
  setOpen: (open: boolean) => void
  tables: ITable[]
}

const TableJoinEdit: React.FC<TableJoinEditProps> = ({
  spreadsheetData,
  joinTable,
  selected,
  setSpreadsheetData,
  setOpen,
  tables
}) => {
  const { project } = useProject()
  const { setSnackbarMessage } = useApplicationStore()

  // Join state properties
  const [joinOptions, setJoinOptions] = useState<ICreateTableJoin>(
    joinTable
      ? {
          joinTableId: joinTable.joinTableId,
          joinViewId: joinTable.joinViewId,
          joinColumns: joinTable.joinColumns,
          dataColumns: joinTable.dataColumns.map((entry) => entry.sourceColumnId),
          isOneToMany: joinTable.isOneToMany
        }
      : {
          joinTableId: '',
          joinViewId: '',
          joinColumns: [],
          dataColumns: [],
          isOneToMany: false
        }
  )

  const [joinSelected, setJoinSelected] = useState<IMapJoinColumn>({ sourceColumnId: '', targetColumnId: '' })

  const [showDataSourceJoin, setShowDataSourceJoin] = useState<boolean>(false)
  const [addJoin, setAddJoin] = useState<boolean>(false)

  const [views, setViews] = useState<ITableView[]>()
  const [viewColumns, setViewColumns] = useState<ITableViewColumn[]>([])

  // other states
  const [processing, setProcessing] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(true)
  const [userCheckboxSelection, setUserCheckboxSelection] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  // effects
  useEffect(() => {
    if (joinTable && selected) {
      getTableViews(joinTable.joinTableId!, { ignoreColumns: true })
        .then((response) => {
          setViews(getAllowedViews(response.data as ITableViewWithColumns[]))
        })
        .catch((error) => {
          setCanEdit(false)
          handleErrorMessage(error)
        })
      getTableView(joinTable.joinViewId, { ignoreCachedOptions: true })
        .then((response) => {
          const view = response.data as ITableViewWithColumns
          setViewColumns(view.columns)
        })
        .catch((error) => {
          setCanEdit(false)
          handleErrorMessage(error)
        })
      setUserCheckboxSelection(joinTable.isOneToMany)
      setJoinOptions({
        joinTableId: joinTable.joinTableId,
        joinViewId: joinTable.joinViewId,
        joinColumns: joinTable.joinColumns,
        dataColumns: joinTable.dataColumns.map((entry) => entry.sourceColumnId),
        isOneToMany: joinTable.isOneToMany
      })
    }
  }, [joinTable, selected])

  useEffect(() => {
    if (success) {
      saveCurrentView(project.publicId, spreadsheetData, setSpreadsheetData, handleOnUpdateSuccess, handleOnError)
    }
  }, [success])

  const handleErrorMessage = (error: any) => {
    if (error.status == 403) setErrorMessage('You do not have owner access to the underlying table')
  }
  const handleOnTableChange = (tableId: string) => {
    setViews(undefined)
    setJoinOptions({ joinTableId: tableId, joinViewId: '', joinColumns: [], dataColumns: [], isOneToMany: false })
    getTableViews(tableId, { ignoreColumns: true })
      .then((response) => {
        setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
        setAddJoin(false)
        setShowDataSourceJoin(false)
        setViews(getAllowedViews(response.data as ITableViewWithColumns[]))
      })
      .catch(() => {
        setJoinOptions({ joinTableId: '', joinViewId: '', joinColumns: [], dataColumns: [], isOneToMany: false })
      })
  }

  const getAllowedViews = (views: ITableViewWithColumns[]) => {
    return views.filter((view) => ALLOWED_VIEW_TYPES_ON_SELECT_OPTIONS.includes(view.type))
  }

  const handleOnViewChange = (viewId: string) => {
    getTableView(viewId, { ignoreCachedOptions: true })
      .then((response) => {
        const view = response.data as ITableViewWithColumns
        setViewColumns(view.columns)
        setJoinOptions({
          ...joinOptions,
          joinViewId: viewId,
          joinColumns: [],
          dataColumns: []
        })
        setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
        setAddJoin(false)
        setShowDataSourceJoin(false)
      })
      .catch(() => {
        setJoinOptions({
          joinTableId: '',
          joinViewId: '',
          joinColumns: [],
          dataColumns: [],
          isOneToMany: false
        })
        setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
        setViewColumns([])
      })
  }

  const handleOnDataTargetJoinColumnChange = (columnId: string) => {
    if (!canEdit) return

    const foundColumn = spreadsheetData.viewDetails.columns.find((col) => col.publicId === columnId)
    if (foundColumn?.isJoined) {
      setSnackbarMessage({
        status: 'error',
        message: `Column ${foundColumn.name} is a joined column and cannot be selected.`
      })
      return
    }

    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
      setAddJoin(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable for joins`
      })
      return
    }

    let sourceColumnId = ''
    if (foundColumn && foundColumn.kind == 'select') {
      const needsJoin =
        foundColumn.kindOptions?.tableOptions?.tableId !== joinOptions.joinTableId ||
        foundColumn.kindOptions?.tableOptions?.viewId !== joinOptions.joinViewId
      setShowDataSourceJoin(needsJoin)
      setAddJoin(!needsJoin)
      if (!needsJoin) {
        sourceColumnId = foundColumn.kindOptions?.tableOptions?.columnId || ''
      }
    } else {
      setShowDataSourceJoin(true)
      setAddJoin(false)
    }

    setJoinSelected({ targetColumnId: columnId, sourceColumnId })
  }

  const handleOnDataSourceJoinColumnChange = (columnId: string) => {
    const foundColumn = viewColumns.find((column) => column.publicId === columnId)
    if (foundColumn?.isJoined) {
      setSnackbarMessage({
        status: 'error',
        message: `Column ${foundColumn.name} is a joined column and cannot be selected.`
      })
      return
    }
    if (foundColumn && BLOCK_SELECT_OPTION_TYPES.includes(foundColumn.kind)) {
      setAddJoin(false)
      setSnackbarMessage({
        status: 'error',
        message: `${foundColumn.kind} options are not applicable for joins`
      })
      return
    }

    setJoinSelected({ ...joinSelected, sourceColumnId: columnId })
    setAddJoin(true)
  }

  const handleAddJoinColumn = () => {
    if (!canEdit) return

    const newJoins = [...joinOptions.joinColumns]
    newJoins.push(joinSelected)
    const newJoinOptions = {
      ...joinOptions,
      joinColumns: newJoins
    }

    const blockCheck = newJoins.map((joinCondition) => allowOnlyIsOneToMany(joinCondition)).some((value) => value)

    if (!blockCheck) {
      newJoinOptions.isOneToMany = userCheckboxSelection
    } else {
      newJoinOptions.isOneToMany = true
    }
    setJoinOptions(newJoinOptions)
    setAddJoin(false)
    setShowDataSourceJoin(false)
    setJoinSelected({ targetColumnId: '', sourceColumnId: '' })
  }

  const handleDeleteJoinColumn = (index: number) => {
    const newJoins = [...joinOptions.joinColumns]
    newJoins.splice(index, 1)

    const blockCheck = joinOptions.joinColumns
      .map((joinCondition) => allowOnlyIsOneToMany(joinCondition))
      .some((value) => value)

    const isOneToMany = blockCheck ? true : userCheckboxSelection

    const newJoinOptions = {
      ...joinOptions,
      joinColumns: newJoins,
      isOneToMany
    }
    setJoinOptions(newJoinOptions)
  }

  const handleOnClickColumn = (columnId: string) => {
    const column = viewColumns.find((column) => column.publicId === columnId)
    if (!column || processing || !canEdit) return

    if (column.isJoined) {
      setSnackbarMessage({
        status: 'error',
        message: `Column ${column.name} is a joined column and cannot be selected.`
      })
      return
    }

    let newImportedColumns = [...joinOptions.dataColumns]
    if (newImportedColumns.includes(columnId)) {
      newImportedColumns = joinOptions.dataColumns.filter((colId) => colId !== columnId)
    } else {
      newImportedColumns.push(columnId)
    }
    setJoinOptions({ ...joinOptions, dataColumns: newImportedColumns })
  }

  const handleAddTableJoin = () => {
    if (processing || !canEdit) return

    setProcessing(true)
    const newJoinOptions = { ...joinOptions }
    delete newJoinOptions['joinTableId']

    createTableJoin(
      spreadsheetData,
      setSpreadsheetData,
      newJoinOptions,
      project.publicId,
      () => setSuccess(true),
      handleOnError
    )
  }

  const handleUpdateTableJoin = () => {
    if (processing || !canEdit) return
    setProcessing(true)
    const newJoinOptions = { ...joinOptions }
    delete newJoinOptions['joinTableId']
    updateTableJoin(
      spreadsheetData,
      setSpreadsheetData,
      joinTable!.publicId,
      newJoinOptions,
      project.publicId,
      () => setSuccess(true),
      handleOnError
    )
  }

  const handleOnUpdateSuccess = async () => {
    setSuccess(false)
    setProcessing(false)
    setSnackbarMessage({ status: 'success', message: joinTable ? 'Updated table look up' : 'Created table look up' })
    setOpen(false)
  }

  const handleDeleteTableJoin = () => {
    if (window.confirm('Are you sure you want to delete this table look up?')) {
      if (processing || !canEdit) return
      const newJoinOptions = { ...joinOptions, joinViewId: '' }
      delete newJoinOptions['joinTableId']
      setProcessing(true)
      deleteTableJoin(
        spreadsheetData,
        setSpreadsheetData,
        joinTable!.publicId,
        project.publicId,
        () => {
          setProcessing(false)
          setSuccess(true)
        },
        handleOnError
      )
    } else {
      return
    }
  }

  const handleOnError = (error: string, tableId?: string, tableViewId?: string) => {
    setSuccess(false)
    setProcessing(false)
    setSnackbarMessage({
      status: 'error',
      message: error,
      action:
        tableId && tableViewId ? (
          <a
            style={{ color: 'inherit !important', border: '1px solid white', padding: '5px', borderRadius: '2px' }}
            href={`/project/${project.publicId}/table/${tableId}/view/${tableViewId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Table
          </a>
        ) : (
          <></>
        )
    })
  }

  const handleCheckOneToMany = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!canEdit || viewColumns.length === 0) return

    const blockCheck = joinOptions.joinColumns
      .map((joinCondition) => allowOnlyIsOneToMany(joinCondition))
      .some((value) => value)
    let isOneToMany = event.target.checked
    if (blockCheck) {
      isOneToMany = true
      setSnackbarMessage({
        status: 'warning',
        message: `You are not allowed uncheck the 'Is one to many' box while you have a plain target column joined with a multi-select source column.`
      })
    } else {
      setUserCheckboxSelection(event.target.checked)
    }
    const newJoinOptions = {
      ...joinOptions,
      isOneToMany: isOneToMany
    }
    setJoinOptions(newJoinOptions)
  }

  const allowOnlyIsOneToMany = (joinCondition: { targetColumnId: string; sourceColumnId: string }) => {
    const targetColumn = spreadsheetData.viewDetails.columns.find(
      (col) => col.publicId === joinCondition.targetColumnId
    )
    const sourceColumn = viewColumns.find((col) => col.publicId === joinCondition.sourceColumnId)
    const multiselect_options = ['multiselect', 'multilink']
    return !multiselect_options.includes(targetColumn!.kind) && multiselect_options.includes(sourceColumn!.kind)
  }
  const joinTableName = tables.find((table: ITable) => table.publicId === joinOptions.joinTableId)?.name
  const joinViewName = views
    ? views.find((view: ITableView) => view.publicId === joinOptions.joinViewId)?.name
    : undefined
  const updateTableJoinButton =
    joinTable &&
    (joinTable.joinViewId !== joinOptions.joinViewId ||
      joinTable.isOneToMany !== joinOptions.isOneToMany ||
      JSON.stringify(joinTable.joinColumns) !== JSON.stringify(joinOptions.joinColumns) ||
      JSON.stringify(joinTable.dataColumns.map((entry) => entry.sourceColumnId)) !==
        JSON.stringify(joinOptions.dataColumns))
  const addNewTableJoin =
    joinOptions.joinViewId && joinOptions.joinColumns.length > 0 && joinOptions.dataColumns.length > 0

  const enableButton = canEdit ? (joinTable ? updateTableJoinButton : addNewTableJoin) : false

  return (
    <div style={{ display: 'block' }}>
      {!canEdit && errorMessage && (
        <div className="flex items-center flex-column text-red font-medium text-center p-16px">{errorMessage}</div>
      )}
      <div className="flex items-center">
        <div style={{ minWidth: '200px' }}>Look Up Table:</div>
        <div className="w-full">
          <Select
            options={
              tables
                ? tables.map((table: ITable) => {
                    return { label: table.name, value: table.publicId }
                  })
                : []
            }
            optionsSelected={joinOptions.joinTableId ? [joinOptions.joinTableId] : []}
            onOptionClick={(option) => handleOnTableChange(option)}
            disabled={!canEdit}
            info={'This is the table containing the data you wish to bring in.'}
          />
        </div>
      </div>
      <div className="flex items-center" style={{ marginTop: '20px' }}>
        <div style={{ minWidth: '200px' }}>Look Up Table View:</div>
        <div className="w-full">
          <Select
            options={
              views
                ? views.map((view: ITableView) => {
                    return { label: view.name, value: view.publicId }
                  })
                : []
            }
            loading={canEdit && joinOptions.joinTableId && views === undefined ? true : false}
            disabled={!canEdit || views === undefined || !joinOptions.joinTableId}
            onOptionClick={(option) => handleOnViewChange(option)}
            optionsSelected={joinOptions.joinViewId ? [joinOptions.joinViewId] : []}
            info="You must select a source table before a view can be selected."
          />
        </div>
      </div>

      {joinTable && (
        <Button
          internalType="danger"
          style={{ maxWidth: 100 }}
          onClick={() => handleDeleteTableJoin()}
          isLoading={processing}
          disabled={!canEdit}
        >
          Delete
        </Button>
      )}

      {joinOptions.joinTableId && joinOptions.joinViewId && (
        <React.Fragment>
          <div
            className="w-full inline-flex space-between items-center font-semibold"
            style={{ height: '20px', margin: '15px 0 5px 0' }}
          >
            Join Columns:
          </div>
          <div style={{ display: 'grid' }}>
            {joinOptions.joinColumns.length > 0 &&
              joinOptions.joinColumns.map((join, index) => {
                const foundDataTargetJoinColumn = spreadsheetData.tableDetails.columns.find(
                  (col) => col.publicId === join.targetColumnId
                )
                const columnJoinId =
                  join.sourceColumnId || foundDataTargetJoinColumn?.kindOptions?.tableOptions?.columnId
                const columnMatchName = viewColumns.find((col) => col.publicId === columnJoinId)?.name
                const remoteMatch = `${joinTableName}/${joinViewName}/${columnMatchName}`
                if (!canEdit) return <></>
                return (
                  <div key={`column-select-${index}`} className="inline-flex items-center space-between">
                    {!foundDataTargetJoinColumn?.name || !joinViewName || !columnMatchName ? (
                      <div className="spin" style={{ height: '16px', width: '16px' }} />
                    ) : (
                      <>
                        <div className="inline-flex items-center w-full space-evenly" style={{ marginRight: '10px' }}>
                          <span>
                            {spreadsheetData.tableDetails.name}/{foundDataTargetJoinColumn?.name}
                          </span>
                          <span>Match to</span>
                          <span>{remoteMatch}</span>
                        </div>
                        <div className="cursor-pointer" onClick={() => handleDeleteJoinColumn(index)}>
                          <Delete />
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
          </div>

          <div className="inline-flex items-center" style={{ marginTop: '20px' }}>
            <div className="block">
              <span className="font-bold">
                {spreadsheetData.tableDetails.name}/{spreadsheetData.viewDetails.name}
              </span>
              <div style={{ width: '200px' }}>
                <Select
                  options={spreadsheetData.viewDetails.columns.map((column) => {
                    return { label: column.name, value: column.publicId }
                  })}
                  onOptionClick={(option) => handleOnDataTargetJoinColumnChange(option)}
                  optionsSelected={joinSelected.targetColumnId ? [joinSelected.targetColumnId] : []}
                  disabled={!canEdit}
                />
              </div>
            </div>
            {showDataSourceJoin && (
              <>
                <div className="flex justify-center" style={{ minWidth: '150px' }}>
                  Match to
                </div>
                <div className="block">
                  <span className="font-bold">
                    {joinTableName}/{joinViewName}
                  </span>
                  <div style={{ width: '200px' }}>
                    <Select
                      options={viewColumns.map((column) => {
                        return { label: column.name, value: column.publicId }
                      })}
                      onOptionClick={(option) => handleOnDataSourceJoinColumnChange(option)}
                      optionsSelected={joinSelected.sourceColumnId ? [joinSelected.sourceColumnId] : []}
                    />
                  </div>
                </div>
              </>
            )}
            <Button
              internalType="accept"
              style={{ width: 50, margin: '5px 10px' }}
              onClick={() => handleAddJoinColumn()}
              disabled={!addJoin || !canEdit}
            >
              <span>Add</span>
            </Button>
            {addJoin && (
              <div className="relative inline-block" style={{ margin: `5px 5px` }}>
                {
                  spreadsheetData.viewDetails.columns.find((column) => column.publicId === joinSelected.targetColumnId)
                    ?.name
                }{' '}
                {joinSelected?.sourceColumnId ? (
                  <span>
                    needs to be linked to a column in {joinTableName}/{joinViewName}
                  </span>
                ) : (
                  <span>
                    has already a link to {joinTableName}/{joinViewName}.
                  </span>
                )}
              </div>
            )}
          </div>
          <div
            className="w-full inline-flex space-between items-center font-semibold"
            style={{ height: '20px', margin: '15px 0 5px 0' }}
          >
            Add columns from view {joinTableName}/{joinViewName}:
            <Checkbox checked={joinOptions.isOneToMany} onChange={handleCheckOneToMany}>
              Is one to many relationship?
            </Checkbox>
          </div>

          <Select
            options={viewColumns.map((column) => {
              const isSelected = joinOptions.dataColumns.includes(column.publicId)
              const mapsTo =
                isSelected &&
                joinTable &&
                joinTable.dataColumns.find((entry) => entry.sourceColumnId === column.publicId)?.targetColumnId
              const columnMapsTo = mapsTo && spreadsheetData.viewDetails.columns.find((col) => col.publicId === mapsTo)
              return {
                value: column.publicId,
                label: selected && columnMapsTo ? `${column.name} maps to ${columnMapsTo.name}` : column.name
              }
            })}
            optionsSelected={viewColumns
              .filter((column) => joinOptions.dataColumns.includes(column.publicId))
              .map((column) => column.publicId)}
            onOptionClick={(option) => handleOnClickColumn(option)}
            multiselect={true}
            disabled={!canEdit}
          />

          <Button
            internalType="accept"
            style={{ marginTop: '15px' }}
            onClick={() => (joinTable ? handleUpdateTableJoin() : handleAddTableJoin())}
            disabled={!enableButton}
          >
            {joinTable ? <span>Update</span> : <span>Add</span>}
          </Button>
        </React.Fragment>
      )}
    </div>
  )
}

export default React.memo(TableJoinEdit)
