import React, { useState, useRef, useEffect } from 'react'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import { IProcessResponseObject, IProcessSectionObject, ISummaryUser, IUserObject } from 'types'
import Select from 'components/select'

interface RespondersProps {
  setSection: (section: IProcessSectionObject) => void
  processId: string
  processSectionId: string
  response: IProcessResponseObject
}

const Responders: React.FC<RespondersProps> = ({
  setSection,
  processId,
  processSectionId,
  response
}: RespondersProps) => {
  const [permissionedUsers, setPermissionedUsers] = useState<Array<IUserObject | ISummaryUser>>()
  const [userResponders, setUserResponders] = useState<Array<IUserObject | ISummaryUser>>(response.userResponders)
  const respondersTimeout: any = useRef(null)
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  useEffect(() => {
    api
      .searchUsers({
        processId,
        tableViewId:
          response &&
          response.type &&
          response.type === 'Table' &&
          response.typeOptions &&
          response.typeOptions.table &&
          response.typeOptions.tableView
            ? response.typeOptions.tableView
            : undefined
      })
      .then((apiResponse) => {
        const list = [
          ...apiResponse.data.filter((user: any) => !response.responders.includes(user.firebaseUserId)),
          ...userResponders
        ]
        setPermissionedUsers(list)
      })
      .catch((error) => {
        console.error(error)
        setSnackbarMessage({ status: 'error' })
      })
  }, [])

  const onAllRespondersChange = (options: string[]) => {
    if (permissionedUsers) {
      const newResponders: Array<IUserObject | ISummaryUser> = []
      for (let i = 0; i < options.length; i++) {
        const user = permissionedUsers.find((user) => user.firebaseUserId === options[i])
        if (user) {
          newResponders.push(user)
        }
      }

      setUserResponders(newResponders)

      if (respondersTimeout.current !== null) {
        clearTimeout(respondersTimeout.current)
      }

      respondersTimeout.current = setTimeout(() => {
        respondersTimeout.current = null

        api
          .updateProcessResponse({
            responders: newResponders.map((responder) => responder.firebaseUserId),
            type: response.type,
            typeOptions: response.typeOptions,
            processId: processId,
            processSectionId: processSectionId,
            processResponseId: response.publicId,
            context: { projectId: project.publicId }
          })
          .then((apiResponse) => {
            setSection(apiResponse.data)
          })
          .catch((error) => {
            console.error(error)
            setSnackbarMessage({ status: 'error' })
          })
      }, 1000)
    }
  }

  function onRespondersChange(option: string): void {
    if (permissionedUsers) {
      const user = permissionedUsers.find((user) => user.firebaseUserId === option)
      if (user) {
        const newResponders = [...userResponders]
        const index = newResponders.findIndex((responder) => responder.firebaseUserId === option)
        if (index === -1) {
          newResponders.push(user)
        } else {
          newResponders.splice(index, 1)
        }

        setUserResponders(newResponders)

        if (respondersTimeout.current !== null) {
          clearTimeout(respondersTimeout.current)
        }

        respondersTimeout.current = setTimeout(() => {
          respondersTimeout.current = null

          api
            .updateProcessResponse({
              responders: newResponders.map((responder) => responder.firebaseUserId),
              type: response.type,
              typeOptions: response.typeOptions,
              processId: processId,
              processSectionId: processSectionId,
              processResponseId: response.publicId,
              context: { projectId: project.publicId }
            })
            .then((apiResponse) => {
              setSection(apiResponse.data)
            })
            .catch((error) => {
              console.error(error)
              setSnackbarMessage({ status: 'error' })
            })
        }, 1000)
      }
    }
  }

  return (
    <div className="flex items-center" style={{ marginTop: '10px', marginBottom: '10px' }}>
      <div style={{ flex: 1 }}>Responders:</div>
      <div style={{ flex: 3, overflow: 'hidden', padding: '5px' }}>
        <Select
          options={
            permissionedUsers
              ? permissionedUsers.map((user) => {
                  return { value: user.firebaseUserId, label: `${user.name}` }
                })
              : []
          }
          optionsSelected={userResponders.map((responder) => responder.firebaseUserId)}
          onOptionClick={(option) => onRespondersChange(option)}
          setOptionsSelected={(options) => onAllRespondersChange(options)}
          loading={permissionedUsers === undefined}
          multiselect={true}
          info={
            "Only users who have 'contributor' permissions to the process (and table selected in response) will show up here"
          }
        />
      </div>
    </div>
  )
}

export default Responders
