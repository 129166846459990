import React from 'react'
import { Link } from 'react-router-dom'
import { INotification } from 'types'
import { useProject } from 'hooks/project'

interface Props {
  notification: INotification
}

const NotificationResourceList: React.FC<Props> = ({ notification }) => {
  const { project } = useProject()

  return (
    <div>
      {notification.processes.length == 0 && notification.tables.length == 0 && (
        <div>
          <i>All resources</i>
        </div>
      )}
      {notification.processes.length > 0 && (
        <div className="mb-4">
          <div className="font-semibold">Processes</div>
          {notification.processes.map((process) => {
            return (
              <div key={process.publicId}>
                <Link to={`/project/${project.publicId}/process/${process.publicId}`}>{process.name}</Link>
              </div>
            )
          })}
        </div>
      )}
      {notification.tables.length > 0 && (
        <div className="mb-4">
          <div className="font-semibold">Tables</div>
          {notification.tables.map((table) => {
            return (
              <div key={table.publicId}>
                <Link to={`/project/${project.publicId}/table/${table.publicId}/view/${table.defaultViewId}`}>
                  {table.name}
                </Link>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default NotificationResourceList
