import React, { useRef, useState } from 'react'
import Button from 'components/button'
import Modal, { ModalProps } from 'components/modal'
import { useProject } from 'hooks/project'
import api, { APIError } from 'helpers/api'
import { history } from 'helpers/history'
import { PROJECT_BASE } from 'routes'
import { useApplicationStore } from 'hooks/application'
import ResourceType from 'components/resourceType'
import Checkbox from 'components/checkbox'

interface NewTableProps extends ModalProps {
  noRedirect?: boolean
  noRedirectFunction?: (id: string) => void
}

type newTableTypes = 'Upload CSV' | 'Blank Table'

const NewTable: React.FC<NewTableProps> = ({ id, open, setOpen, noRedirect, noRedirectFunction }) => {
  const [name, setName] = useState<string>('New Table')
  const tableSourceInit: newTableTypes = 'Blank Table'
  const [source, setSource] = useState<string>(tableSourceInit)
  const [type, setType] = useState<string>('')
  const inputFile: any = useRef(null)
  const [forHeadersChecked, setForHeadersChecked] = useState(true)
  const { project } = useProject()
  const [creatingTableLoading, setCreatingTableLoading] = useState(false)
  const { setSnackbarMessage } = useApplicationStore()

  const handleClose = () => {
    setOpen(false)
  }

  async function createTable() {
    if (name !== '' && type !== null && source !== '') {
      const file = inputFile.current && inputFile.current.files && inputFile.current.files[0]

      if (source === 'Blank Table') {
        let res
        try {
          res = await api.createNewTable({
            projectId: project.publicId,
            name,
            type,
            context: { projectId: project.publicId }
          })

          if (res && res.data) {
            if (!noRedirect || !noRedirectFunction) {
              history.push(
                PROJECT_BASE + project.publicId + `/table/` + res.data.publicId + '/view/' + res.data.defaultViewId
              )
              handleClose()
            } else {
              noRedirectFunction(res.data.publicId)
              handleClose()
            }
          }
        } catch (error) {
          console.error(error)
        }

        return
      }

      if (source === 'Upload CSV') {
        if (file && creatingTableLoading === false) {
          setCreatingTableLoading(true)
          const reader = new FileReader()
          reader.onload = function () {
            file.text().then(() => {
              api
                .postCSV(name, type, file, forHeadersChecked, project.publicId)
                .then((res: any) => {
                  let jsonResponse = null
                  let data = null
                  try {
                    jsonResponse = JSON.parse(res)
                    data = jsonResponse.data
                  } catch (error) {
                    setSnackbarMessage({
                      status: 'error',
                      message: 'Something went wrong creating the table! Please try again.'
                    })
                    setCreatingTableLoading(false)
                    return
                  }

                  if (data) {
                    setCreatingTableLoading(false)
                    if (!noRedirect || !noRedirectFunction) {
                      history.push(
                        PROJECT_BASE +
                          project.publicId +
                          `/table/` +
                          jsonResponse.data.publicId +
                          '/view/' +
                          jsonResponse.data.defaultViewId
                      )
                      handleClose()
                    } else {
                      noRedirectFunction(jsonResponse.data.publicId)
                      handleClose()
                    }
                  } else {
                    const detail = jsonResponse.detail
                    const errors = detail?.errors
                    if (errors) {
                      setSnackbarMessage({
                        status: 'error',
                        message: errors[0].messages[0]
                      })
                    } else {
                      setSnackbarMessage({
                        status: 'error',
                        message: 'Something went wrong creating the table! Please try again.'
                      })
                    }

                    setCreatingTableLoading(false)
                  }
                })
                .catch((e) => {
                  if (e instanceof APIError) {
                    setSnackbarMessage({
                      status: 'error',
                      message: e.message
                    })
                  } else if (typeof e === 'string') {
                    setSnackbarMessage({
                      status: 'error',
                      message: e
                    })
                  } else {
                    setSnackbarMessage({
                      status: 'error'
                    })
                  }
                  setCreatingTableLoading(false)
                  return
                })
            })
          }
          reader.readAsText(file)
        }
      }
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Create New Table">
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Table Name</div>
        <input
          className="w-full"
          data-cy="table-name"
          required
          defaultValue={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Table Type</div>
        <ResourceType
          data-cy={'table-type'}
          handleChange={(value: string | null) => setType(value ? value : '')}
          defaultValue={type}
          helperText="The type can be selected from your project variables (created by going into a table and changing the column type to 'variable') or custom text."
        />
      </div>

      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">From CSV?</div>

        <select
          required
          value={source}
          onChange={(event: any): void => {
            setSource(event.target.value)
          }}
        >
          <option value={'Upload CSV'}>Upload CSV</option>
          <option value={'Blank Table'}>Blank Table</option>
        </select>
      </div>
      <div className="mb-8">
        {source === 'Upload CSV' && <input type="file" id="file" ref={inputFile} accept=".csv" />}
        {source === 'Upload CSV' && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Checkbox
              checked={forHeadersChecked}
              onChange={(event) => {
                setForHeadersChecked(event.target.checked)
              }}
            >
              Use first row as column headers
            </Checkbox>
          </div>
        )}
      </div>
      <div className="flex mt-20px">
        <Button
          className="ml-auto"
          onClick={() => createTable()}
          data-cy="create-table-button"
          isLoading={creatingTableLoading}
        >
          Create Table
        </Button>
      </div>
    </Modal>
  )
}

export default NewTable
