import React, { useEffect, useState } from 'react'
import { IColumnTypes } from 'components/spreadsheet/types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import { ViewTypes } from 'components/spreadsheet/constants/const'
import { ISpreadsheetData } from 'components/spreadsheet/types'

interface LinkCellProps {
  value: string | null
  align: 'center' | 'left' | 'right'
  color: string
  kind: IColumnTypes
  readOnly: boolean
  editing: boolean
  width: number
  rowHeight: number
  selected: boolean
  spreadsheetData?: ISpreadsheetData
  validation: boolean
  handleKeyDown: (event: React.KeyboardEvent) => void
  setCellValue: (value: string | null) => void
  isExpanded?: boolean
}

const LinkCellComponent: React.FC<LinkCellProps> = ({
  value,
  align,
  color,
  kind,
  readOnly,
  editing,
  width,
  rowHeight,
  selected,
  spreadsheetData,
  validation,
  setCellValue,
  handleKeyDown,
  isExpanded
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value ? value : '')

  useEffect(() => {
    const newValue = value ? value : ''
    if (newValue !== currentValue) {
      setCurrentValue(newValue)
    }
  }, [value])

  const isForm = spreadsheetData?.viewDetails.type === ViewTypes.FORM
  if (kind === 'link' && editing && !readOnly) {
    return (
      <input
        className="cell-value-editing focus"
        value={currentValue ? currentValue : ''}
        style={{ height: `${getRowHeightVariable(rowHeight) - 1}px` }}
        onChange={(event) => {
          setCurrentValue(event.target.value)
          setCellValue(event.target.value)
        }}
        onKeyDown={handleKeyDown}
        autoFocus
      ></input>
    )
  } else {
    return (
      <div
        className={`cell-value ${selected ? 'focus' : ''}`}
        tabIndex={-1}
        style={{
          textAlign: align,
          color: color,
          width: width,
          maxWidth: width
        }}
      >
        {value !== '' && (
          <div
            className="truncate flex items-center"
            style={{
              width: validation ? 'calc(100% - 25px)' : '100%',
              maxWidth: isExpanded ? (isForm ? 1000 : 500) : width - 20
            }}
          >
            <a
              href={value ? value : '#'}
              target="_blank"
              rel="noopener noreferrer"
              className="truncate"
              style={{ marginLeft: '5px' }}
            >
              {value}
            </a>
          </div>
        )}
      </div>
    )
  }
}

export const LinkCell = React.memo(LinkCellComponent)
