import React, { useState, useEffect } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { ITableColumn, ITableViewColumn, ITableViewColour, IColourItem } from 'types'
import { IFilterType } from 'components/spreadsheet/types'
import { useApplicationStore } from 'hooks/application'
import { BLOCK_COLOUR_BY } from 'components/spreadsheet/constants/const'
import ColourItem from 'components/spreadsheet/components/menu/views/colour/ColourItem'
import { getColumnsPerUserRole } from 'components/spreadsheet/helpers/functions'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import Select from 'components/select'

const ColourMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { setSnackbarMessage } = useApplicationStore()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const [colourGroups, setColourGroups] = useState<Record<string, IColourItem[]>>({} as Record<string, IColourItem[]>)
  const allColumns = getColumnsPerUserRole(spreadsheetData)

  useEffect(() => {
    setColourGroups(
      spreadsheetData.userConfiguration.colourSettings.reduce((acc, colour: ITableViewColour, currentIndex: number) => {
        const key = `${colour.backgroundColour}-${colour.filterType}-${colour.value}`
        ;(acc[key] = acc[key] || []).push({ colour: colour, index: currentIndex })
        return acc
      }, {} as Record<string, IColourItem[]>)
    )
  }, [spreadsheetData.userConfiguration.colourSettings])

  const columnOptions = spreadsheetData.viewDetails.columns
    .filter((column: ITableColumn) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId))
    .map((column) => ({
      label: column.name,
      value: column.publicId
    }))

  const handleAddColour = (columnId: string) => {
    const column = spreadsheetData.viewDetails.columns.find((column: ITableViewColumn) => column.publicId === columnId)
    if (column) {
      if (BLOCK_COLOUR_BY.includes(column.kind)) {
        setSnackbarMessage({ status: 'error', message: `Colouring columns of type ${column.kind} is not allowed ` })
        return
      }
    }

    const newColumn = spreadsheetData.viewDetails.columns.find((c) => c.publicId === columnId)!
    const newColour: ITableViewColour = {
      columnId: newColumn.publicId,
      columnName: newColumn.name,
      filterType: IFilterType.eq,
      value: '',
      multipleValues: [],
      backgroundColour: '#ffffff',
      fontColour: '#000000'
    }

    const found = spreadsheetData.userConfiguration.colourSettings.find(
      (colour) =>
        colour.columnId === newColumn.publicId &&
        colour.columnName === newColumn.name &&
        colour.filterType === IFilterType.eq &&
        colour.value === '' &&
        colour.multipleValues &&
        colour.multipleValues.length === 0 &&
        colour.backgroundColour === '#ffffff' &&
        colour.fontColour === '#000000'
    )
    // if there is a column with same configuration, do not add it again
    if (found) return
    // otherwise add it
    setSpreadsheetData({
      type: 'UPDATE_COLOURS',
      colours: [...spreadsheetData.userConfiguration.colourSettings, newColour]
    })
  }

  const handleDeleteColour = (indexes: number[]) => {
    const newColours = [...spreadsheetData.userConfiguration.colourSettings]
    indexes.forEach((index) => newColours.splice(index, 1))
    setSpreadsheetData({
      type: 'UPDATE_COLOURS',
      colours: newColours
    })
  }

  const handleColourChange = (colourItems: IColourItem[]) => {
    const newColours = [...spreadsheetData.userConfiguration.colourSettings]
    colourItems.forEach((colourItem) => {
      if (colourItem.index != undefined) newColours[colourItem.index] = colourItem.colour
      else newColours.push(colourItem.colour)
    })

    const reapatedColours: number[] = []
    newColours.forEach((newColour, indexFind) => {
      newColours.forEach((newColour2, indexFind2) => {
        if (
          newColour.columnId === newColour2.columnId &&
          newColour.filterType === newColour2.filterType &&
          newColour.value === newColour2.value &&
          newColour.backgroundColour === newColour2.backgroundColour &&
          newColour.fontColour === newColour2.fontColour &&
          newColour.multipleValues === newColour2.multipleValues
        ) {
          if (indexFind < indexFind2) reapatedColours.push(indexFind2)
        }
      })
    })

    const deleteIndexes: number[] = Array.from(new Set(reapatedColours))
    deleteIndexes.sort((a, b) => a - b)
    deleteIndexes.reverse()
    deleteIndexes.forEach((deleteIndex) => newColours.splice(deleteIndex, 1))
    setSpreadsheetData({
      type: 'UPDATE_COLOURS',
      colours: newColours
    })
  }

  const getArrayByPosition = (position: number) => {
    return Object.keys(colourGroups).flatMap((key) => {
      const found = colourGroups[key].find((item) => item.index === position)
      if (found) return colourGroups[key]
      else return []
    })
  }

  const handleOrderChange = (from: number, to: number) => {
    const newColours: (ITableViewColour | undefined)[] = [...spreadsheetData.userConfiguration.colourSettings]
    const arrayPosition1 = getArrayByPosition(from)

    const indexesToDelete = arrayPosition1
      .filter((item) => item.index !== undefined)
      .map((item) => item.index)
      .sort((a, b) => a! - b!)
      .reverse()

    indexesToDelete.forEach((index) => (newColours[index!] = undefined))
    newColours.splice(to, 0, ...arrayPosition1.map((item) => item.colour))
    setSpreadsheetData({
      type: 'UPDATE_COLOURS',
      colours: newColours.filter((item) => item !== undefined) as ITableViewColour[]
    })
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {spreadsheetData.userConfiguration.colourSettings.length === 0 && (
          <div>
            <div className="font-bold truncate" style={{ padding: '3px 10px', marginBottom: '10px' }}>
              You have no active colour settings in this view.
            </div>
            <div className="truncate" style={{ padding: '5px 10px', marginBottom: '20px', marginTop: '20px' }}>
              Colouring allow you to show cells that match your set conditions.
            </div>
          </div>
        )}

        {spreadsheetData.userConfiguration.colourSettings.length > 0 && (
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {Object.keys(colourGroups).map((key: string) => {
              return (
                <ColourItem
                  key={key}
                  colours={colourGroups[key]}
                  columns={allColumns}
                  onDelete={handleDeleteColour}
                  onChange={handleColourChange}
                  handleOrderChange={handleOrderChange}
                />
              )
            })}
          </div>
        )}

        <div className="flex w-full items-center rounded" style={{ padding: '10px', height: '50px' }}>
          <span style={{ width: '200px', marginRight: '10px' }}>Column To Colour:</span>
          <div className="w-full">
            <Select options={columnOptions} onOptionClick={(option) => handleAddColour(option)} />
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(ColourMenu)
