import React, { useState } from 'react'
import Button from 'components/button'

export interface Setup2FaValues {
  code: string
}

interface Props {
  onSubmit: (code: string) => void | Promise<void>
}

const TwoFactorSetupForm: React.FC<Props> = ({ onSubmit }) => {
  const [code, setCode] = useState<string>('')

  const codeError = code === ''

  return (
    <>
      <input
        name="code"
        type="text"
        value={code}
        placeholder="Enter 2FA code"
        autoComplete="off"
        autoFocus
        onChange={(event) => setCode(event.target.value)}
      />
      {codeError && (
        <p className="text-red" style={{ marginTop: '10px' }}>
          You must enter a 2FA code.
        </p>
      )}

      <div className="flex items-center justify-end">
        <Button style={{ marginTop: '40px' }} disabled={codeError} onClick={() => onSubmit(code)}>
          Complete Setup
        </Button>
      </div>
    </>
  )
}

export default TwoFactorSetupForm
