import React from 'react'
import { post, put, del, get } from 'helpers/http-service'
import {
  ITableViewWithColumns,
  ITableRow,
  ITableView,
  ITableViewColumn,
  ITableColumn,
  SelectKindOptions,
  EditorContent,
  IContext,
  IColumnAlterOptions,
  ITable,
  ResponseError,
  IMapJoinColumn
} from 'types'
import {
  ISpreadsheetData,
  SpreadsheetReducerActions,
  IColumnTypes,
  ITableViewCell,
  ITableViewHistoryCell
} from 'components/spreadsheet/types'
import {
  convertToApiFilter,
  getSpreadsheetRowsStream,
  getSortIndexForPosition,
  convertToApiColour
} from 'components/spreadsheet/helpers/functions'
import { processErrorMessage } from 'components/spreadsheet/helpers/api'
import { SELECT_COLUMN_TYPES, ViewTypes, ViewTypesNames } from 'components/spreadsheet/constants/const'
import { history } from 'helpers/history'

export type ICreateTableColumnView = Omit<ITableViewColumn, 'publicId' | 'kindOptions'> & IContext
export const createTableColumnViewAction = (
  tableViewId: string,
  column: ICreateTableColumnView,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void
) => {
  setSpreadsheetData({ type: 'REFRESH', refreshing: true })
  post(`/table/views/${tableViewId}/columns`, column)
    .then((response) => {
      setSpreadsheetData({ type: 'ADD_COLUMN', column: response.data as ITableViewColumn })
      onSuccess()
    })
    .catch(() => {
      setSpreadsheetData({ type: 'REFRESH', refreshing: false })
    })
}

export const deleteTableColumnAction = (
  tableId: string,
  columnId: string,
  projectId: string,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: (error: string, tableId?: string, tableViewId?: string) => void
) => {
  setSpreadsheetData({ type: 'REFRESH', refreshing: true })
  del(`/table/${tableId}/column/${columnId}`, { context: { projectId: projectId } })
    .then(() => {
      setSpreadsheetData({ type: 'DELETE_COLUMN', columnId })
      onSuccess()
    })
    .catch((error) => {
      try {
        error.json().then((errorBody: ResponseError) => {
          let message = ''
          let tableId = undefined
          let tableViewId = undefined
          if (errorBody.detail.errors) {
            message = errorBody.detail.errors[0].messages[0]
            const attributes = errorBody.detail.errors[0].attribute
            if (attributes && typeof attributes !== 'string') {
              tableId = attributes.tableId
              tableViewId = attributes.tableViewId
            }
          } else {
            message = errorBody.detail.message
          }
          onFailure(message, tableId, tableViewId)
          setSpreadsheetData({ type: 'REFRESH', refreshing: false })
        })
      } catch {
        onFailure('Something went wrong.')
        setSpreadsheetData({ type: 'REFRESH', refreshing: false })
      }
    })
}

export const deleteTableRowAction = (
  rowIds: string[],
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: () => void
) => {
  del(`/table/views/${spreadsheetData.activeTableView}/rows`, {
    rowIds: rowIds,
    context: {
      projectId: projectId,
      processPublicId: spreadsheetData.processId,
      processSectionPublicId: spreadsheetData.processSectionId,
      processResponsePublicId: spreadsheetData.processResponseId
    }
  })
    .then(() => {
      setSpreadsheetData({ type: 'DELETE_ROWS', rowIds })
      onSuccess()
    })
    .catch(() => {
      onFailure()
    })
}

export interface IUpdateTableViewCell extends IContext {
  cells: ITableViewCell[]
}

export interface ITableViewHistory extends IContext {
  type: 'EDIT_CELL' | 'DELETE_ROWS' | 'ADD_ROW'
  cells?: ITableViewHistoryCell[]
}

export const updateTableViewCellsAction = (
  tableViewId: string,
  updateTableViewPayload: IUpdateTableViewCell,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onError: (message?: string) => void,
  ignoreHistory?: boolean
) => {
  // Remap columnId to columnName for the API
  const newHistory: ITableViewHistory = { type: 'EDIT_CELL', cells: [], context: updateTableViewPayload.context }
  const remappedCells = updateTableViewPayload.cells.map((cell) => {
    const previousValue = spreadsheetData.rows.find((row) => row.publicId === cell.rowId)?.rowData[cell.columnId]
    const column = spreadsheetData.viewDetails.columns.find((c) => c.publicId === cell.columnId)!
    const newCell = {
      ...cell,
      columnName: column.name
    }
    if (!ignoreHistory && previousValue !== undefined && newHistory.cells) {
      newHistory['cells'].push({
        rowId: cell.rowId,
        columnId: cell.columnId,
        oldValue: previousValue,
        newValue: cell.value
      })
    }
    return {
      columnName: newCell.columnName,
      rowId: newCell.rowId,
      value: newCell.value,
      processPublicId: newCell.processPublicId,
      processSectionPublicId: newCell.processSectionPublicId,
      processResponsePublicId: newCell.processResponsePublicId
    }
  })

  if (newHistory.cells && newHistory.cells.length > 0) {
    setSpreadsheetData({
      type: 'ADD_HISTORY',
      event: newHistory
    })
  }

  // Add in process context
  const processContext = spreadsheetData.processId
    ? {
        processPublicId: spreadsheetData.processId,
        processSectionPublicId: spreadsheetData.processSectionId!,
        processResponsePublicId: spreadsheetData.processResponseId!
      }
    : {}

  const payload = {
    ...updateTableViewPayload,
    cells: remappedCells,
    context: { ...updateTableViewPayload.context, ...processContext }
  }

  put(`/table/views/${tableViewId}/cells`, payload)
    .then(() => {
      onSuccess()
    })
    .catch(async (response) => {
      if (response.status >= 400 && response.status < 500) {
        const error = (await response.json()) as ResponseError
        const message = error.detail.message
        onError(message)
      } else {
        onError()
      }
    })
}

export const addNewView = (
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  type: number,
  onSuccess: () => void,
  onFailure: () => void
) => {
  post(`/table/${spreadsheetData.tableDetails.publicId}/views/duplicate-default`, {
    name: `New ${type === ViewTypes.SPREADSHEET ? 'Table' : ViewTypesNames[type]} View`,
    type: type,
    context: { projectId: projectId }
  })
    .then((response) => {
      const viewData = response.data as ITableViewWithColumns
      setSpreadsheetData({
        type: 'ADD_VIEW',
        view: { ...viewData, hiddenColumns: [] }
      })
      history.push(`/project/${projectId}/table/${spreadsheetData.tableDetails.publicId}/view/${viewData.publicId}`)
      onSuccess()
    })
    .catch(() => {
      onFailure()
    })
}

export const duplicateView = (
  viewId: string,
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: () => void
) => {
  post(`/table/${spreadsheetData.tableDetails.publicId}/views/${viewId}/duplicate`, {
    context: { projectId: projectId }
  })
    .then((response) => {
      setSpreadsheetData({ type: 'ADD_VIEW', view: response.data as ITableViewWithColumns })
      onSuccess()
    })
    .catch(() => {
      onFailure()
    })
}

interface INewViewColumn {
  columnName: string
  columnId: string
  locked: boolean
  required: boolean
}

interface INewView extends Partial<ITableView> {
  columns: INewViewColumn[]
}

export const saveNewView = (
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: (message: string) => void
) => {
  const newTableView: INewView & IContext = {
    name: 'New Saved View',
    type: spreadsheetData.viewDetails.type,
    description: spreadsheetData.viewDetails.description,
    filterSettings: convertToApiFilter(spreadsheetData.userConfiguration.filterSettings),
    groupSettings: spreadsheetData.userConfiguration.groupSettings,
    colourSettings: convertToApiColour(spreadsheetData.userConfiguration.colourSettings),
    sortSettings: spreadsheetData.userConfiguration.sortSettings,
    chartSettings: spreadsheetData.userConfiguration.chartSettings,
    frozenIndex: spreadsheetData.viewDetails.frozenIndex,
    disableNewRow: spreadsheetData.viewDetails.disableNewRow,
    allowContributorDelete: spreadsheetData.viewDetails.allowContributorDelete,
    displayValidationErrorRows: spreadsheetData.viewDetails.displayValidationErrorRows,
    displayCommentRows: spreadsheetData.viewDetails.displayCommentRows,
    collapsedGroupView: spreadsheetData.viewDetails.collapsedGroupView,
    unpackMultiselectGroupView: spreadsheetData.viewDetails.unpackMultiselectGroupView,
    rowHeight: spreadsheetData.viewDetails.rowHeight,
    columns: spreadsheetData.viewDetails.columns
      .map((column) => {
        const newColumn = {
          columnName: column.name,
          columnId: column.publicId,
          locked: column.locked,
          required: column.required
        }

        return newColumn
      })
      .filter((column: INewViewColumn) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.columnId)),
    context: { projectId }
  }

  post(`/table/${spreadsheetData.tableDetails.publicId}/views`, newTableView)
    .then((response) => {
      const viewData = response.data as ITableViewWithColumns
      viewData.hiddenColumns = spreadsheetData['tableDetails']['columns']
        .filter(
          (column: ITableColumn) =>
            !viewData.columns.map((column: ITableViewColumn) => column.publicId).includes(column.publicId)
        )
        .map((column: ITableColumn) => {
          return { columnId: column.publicId, columnName: column.name }
        })
      setSpreadsheetData({ type: 'ADD_VIEW', view: viewData })
      history.push(`/project/${projectId}/table/${spreadsheetData.tableDetails.publicId}/view/${viewData.publicId}`)
      onSuccess()
    })
    .catch((error) => {
      processErrorMessage(error, onFailure)
    })
}

export const saveCurrentView = (
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: (message: string) => void
) => {
  const newTableView: Partial<ITableViewWithColumns> & IContext = {
    name: spreadsheetData.viewDetails.name,
    type: spreadsheetData.viewDetails.type,
    description: spreadsheetData.viewDetails.description,
    filterSettings: convertToApiFilter(spreadsheetData.userConfiguration.filterSettings),
    groupSettings: spreadsheetData.userConfiguration.groupSettings,
    sortSettings: spreadsheetData.userConfiguration.sortSettings,
    colourSettings: convertToApiColour(spreadsheetData.userConfiguration.colourSettings),
    chartSettings: spreadsheetData.userConfiguration.chartSettings,
    frozenIndex: spreadsheetData.viewDetails.frozenIndex,
    displayValidationErrorRows: spreadsheetData.viewDetails.displayValidationErrorRows,
    displayCommentRows: spreadsheetData.viewDetails.displayCommentRows,
    collapsedGroupView: spreadsheetData.viewDetails.collapsedGroupView,
    unpackMultiselectGroupView: spreadsheetData.viewDetails.unpackMultiselectGroupView,
    rowHeight: spreadsheetData.viewDetails.rowHeight,
    columns: spreadsheetData.viewDetails.columns
      .map((column) => {
        const newColumn = JSON.parse(JSON.stringify(column))
        if (newColumn['required'] === undefined) newColumn['required'] = false
        if (SELECT_COLUMN_TYPES.includes(newColumn.kind) && newColumn.kindOptions?.tableOptions)
          delete newColumn.kindOptions?.tableOptions['cachedOptions']

        return newColumn
      })
      .filter((column: ITableViewColumn) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)),
    context: { projectId }
  }

  put(`/table/views/${spreadsheetData.activeTableView}`, newTableView)
    .then(() => {
      setSpreadsheetData({ type: 'SAVE_CURRENT_VIEW' })
      onSuccess()
    })
    .catch((error) => {
      processErrorMessage(error, onFailure)
    })
}

export const updateTable = (
  field:
    | 'name'
    | 'type'
    | 'logo'
    | 'variables'
    | 'keepValidationsInSync'
    | 'keepColoursInSync'
    | 'allowDuplication'
    | 'isDeleted'
    | 'syncHourlyFrequency',
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  value: string | string[] | boolean | number,
  projectId: string,
  onSuccess: (updatedTable: ITable) => void,
  onError: (message: string) => void
) => {
  let name = spreadsheetData.tableDetails.name
  let logo = spreadsheetData.tableDetails.logo
  let type = spreadsheetData.tableDetails.type
  let variables = spreadsheetData.tableDetails.variables
  let keepValidationsInSync = spreadsheetData.tableDetails.keepValidationsInSync
  let keepColoursInSync = spreadsheetData.tableDetails.keepColoursInSync
  let allowDuplication = spreadsheetData.tableDetails.allowDuplication
  let isDeleted = spreadsheetData.tableDetails.isDeleted
  let deletedAt = spreadsheetData.tableDetails.deletedAt
  let syncHourlyFrequency = spreadsheetData.tableDetails.syncHourlyFrequency

  if (field === 'name' && typeof value === 'string') name = value
  else if (field === 'logo' && typeof value === 'string') logo = value
  else if (field === 'type' && typeof value === 'string') type = value
  else if (field === 'variables' && typeof value !== 'string') variables = value as string[]
  else if (field === 'keepValidationsInSync' && typeof value === 'boolean') keepValidationsInSync = value
  else if (field === 'keepColoursInSync' && typeof value === 'boolean') keepColoursInSync = value
  else if (field === 'allowDuplication' && typeof value === 'boolean') allowDuplication = value
  else if (field === 'isDeleted' && typeof value === 'boolean') {
    if (isDeleted === true && value === false) {
      deletedAt = null
      isDeleted = false
    } else if (isDeleted === false && value === true) {
      deletedAt = new Date().toISOString()
      isDeleted = true
    }
  } else if (field === 'syncHourlyFrequency' && typeof value === 'number') syncHourlyFrequency = value

  put(`/table/${spreadsheetData.tableDetails.publicId}`, {
    name,
    type,
    logo,
    variables,
    keepValidationsInSync,
    keepColoursInSync,
    allowDuplication,
    syncHourlyFrequency,
    context: { projectId: projectId }
  })
    .then((response) => {
      const tableUpdated = response.data as ITable
      if (field === 'isDeleted') {
        setSpreadsheetData({ type: 'UPDATE_TABLE', field: 'deletedAt', value: deletedAt })
        setSpreadsheetData({ type: 'UPDATE_TABLE', field: 'isDeleted', value: isDeleted })
      } else {
        setSpreadsheetData({ type: 'UPDATE_TABLE', field, value })
      }

      onSuccess(tableUpdated)
    })
    .catch((error) => {
      processErrorMessage(error, onError)
    })
}

export interface ICreateTableJoin {
  joinTableId?: string
  joinViewId: string
  joinColumns: IMapJoinColumn[]
  dataColumns: string[]
  isOneToMany: boolean
}

export const createTableJoin = (
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  tableJoin: ICreateTableJoin,
  projectId: string,
  onSuccess: () => void,
  onError: (message: string) => void
) => {
  post(`/table/${spreadsheetData.tableDetails.publicId}/join`, {
    ...tableJoin,
    context: { projectId: projectId }
  })
    .then((response) => {
      setSpreadsheetData({ type: 'UPDATE_TABLE_JOINS', table: response.data as ITable })
      onSuccess()
    })
    .catch((error) => {
      processErrorMessage(error, onError)
    })
}

export const updateTableJoin = (
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  tableJoinId: string,
  tableJoin: ICreateTableJoin,
  projectId: string,
  onSuccess: () => void,
  onError: (message: string) => void
) => {
  put(`/table/${spreadsheetData.tableDetails.publicId}/join/${tableJoinId}`, {
    ...tableJoin,
    context: { projectId: projectId }
  })
    .then((response) => {
      setSpreadsheetData({ type: 'UPDATE_TABLE_JOINS', table: response.data as ITable })
      onSuccess()
    })
    .catch((error) => {
      processErrorMessage(error, onError)
    })
}

export const deleteTableJoin = (
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  tableJoinId: string,
  projectId: string,
  onSuccess: () => void,
  onError: (message: string) => void
) => {
  del(`/table/${spreadsheetData.tableDetails.publicId}/join/${tableJoinId}`, {
    context: { projectId: projectId }
  })
    .then((response) => {
      setSpreadsheetData({ type: 'UPDATE_TABLE_JOINS', table: response.data as ITable })
      onSuccess()
    })
    .catch((error) => {
      processErrorMessage(error, onError)
    })
}

export const getTableJoins = (tableId: string) => {
  return get(`/table/${tableId}/joins`)
}

export const deleteTable = (
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  onSuccess: () => void,
  onFailure: (error: string, tableId?: string, tableViewId?: string) => void
) => {
  del(`/table/${spreadsheetData.tableDetails.publicId}`, { context: { projectId } })
    .then(() => {
      onSuccess()
    })
    .catch((error) => {
      try {
        error.json().then((errorBody: ResponseError) => {
          let message = ''
          let tableId = undefined
          let tableViewId = undefined
          if (errorBody.detail.errors) {
            message = errorBody.detail.errors[0].messages[0]
            const attributes = errorBody.detail.errors[0].attribute
            if (typeof attributes !== 'string' && attributes && attributes.tableId && attributes.tableViewId) {
              tableId = attributes.tableId
              tableViewId = attributes.tableViewId
            }
          } else {
            message = errorBody.detail.message
          }
          onFailure(message, tableId, tableViewId)
        })
      } catch {
        onFailure('Something went wrong.')
      }
    })
}

export const updateView = (
  field:
    | 'name'
    | 'description'
    | 'type'
    | 'disableNewRow'
    | 'allowContributorDelete'
    | 'displayValidationErrorRows'
    | 'displayCommentRows'
    | 'collapsedGroupView'
    | 'unpackMultiselectGroupView',
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  viewId: string,
  value: string | EditorContent | null | number | boolean,
  projectId: string,
  onSuccess: () => void,
  onFailure: (error: string) => void
) => {
  put(`/table/views/${viewId}`, { [field]: value, context: { projectId: projectId } })
    .then(() => {
      setSpreadsheetData({ type: 'UPDATE_VIEW', field, viewId, value })
      onSuccess()
    })
    .catch((error) => {
      processErrorMessage(error, onFailure)
    })
}

export const deleteView = (
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  projectId: string,
  viewId: string,
  onSuccess: () => void,
  onFailure: (error: string) => void
) => {
  del(`/table/views/${viewId}`, { context: { projectId } })
    .then(() => {
      setSpreadsheetData({ type: 'DELETE_VIEW', viewId })
      onSuccess()
    })
    .catch((error) => {
      processErrorMessage(error, onFailure)
    })
}

export const updateColumn = (
  projectId: string,
  field:
    | 'name'
    | 'description'
    | 'kind'
    | 'kindOptions'
    | 'script'
    | 'scriptEnabled'
    | 'aggregate'
    | 'stringValidation'
    | 'hardValidation'
    | 'validationMessage'
    | 'validationNoBlanks'
    | 'validationNoDuplicates'
    | 'width'
    | 'thousandSeparator'
    | 'decimalPlaces'
    | 'dateFormat',

  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  columnId: string,
  value: string | number | EditorContent | null | IColumnTypes | SelectKindOptions | boolean,
  onSuccess: () => void,
  onError: (error?: string) => void,
  alterOptions?: IColumnAlterOptions
) => {
  setSpreadsheetData({ type: 'REFRESH', refreshing: true })
  let column: Partial<ITableViewColumn> & IContext & Partial<{ alterOptions: IColumnAlterOptions }> = {
    publicId: columnId,
    [field]: value,
    context: { projectId: projectId }
  }

  if (field === 'kind' && (value === 'select' || value === 'multiselect')) {
    const foundColumn = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
    const prevKind = foundColumn?.kind
    if (foundColumn && (prevKind === 'select' || prevKind === 'multiselect')) {
      if (foundColumn.kindOptions?.tableOptions.cachedOptions) {
        delete foundColumn.kindOptions?.tableOptions['cachedOptions']
      }
      column = { ...column, kindOptions: foundColumn.kindOptions }
    }
  }
  if (field === 'kindOptions') {
    const foundColumn = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
    column = { ...column, kind: foundColumn?.kind }
  }
  if (alterOptions) {
    column = {
      ...column,
      alterOptions
    }
  }

  put(`/table/views/${spreadsheetData.activeTableView}/columns/${columnId}`, column)
    .then(async (response) => {
      setSpreadsheetData({ type: 'UPDATE_COLUMN', column: response.data as ITableViewColumn })
      onSuccess()
      if (field === 'kind' || field === 'script' || field === 'scriptEnabled') {
        let firstBatch = true
        for await (const rowsBatched of getSpreadsheetRowsStream(
          spreadsheetData.isAdmin,
          spreadsheetData.tableDetails.publicId,
          spreadsheetData.activeTableView,
          spreadsheetData.viewDetails.columns,
          spreadsheetData.processId
        )) {
          // On the first batch, we set the spreadsheet data, so that everything starts to render
          // and we take the spreadsheet out of the loading state
          if (firstBatch) {
            firstBatch = false
            const firstBatchInitialSize = 100

            // We break up the first batch into a set of 100 records, and the rest, when the initial
            // set is sent using SET_SPREADSHEET_DATA, the component will render the rows, we then
            // send the rest of the first batch
            const firstSet = rowsBatched.batchRows.slice(0, firstBatchInitialSize)

            setSpreadsheetData({ type: 'REFRESH_SPREADSHEET_ROWS', rows: firstSet, totalRows: firstSet.length })
            // The second half of the first batch
            if (rowsBatched.batchRows.length > firstBatchInitialSize) {
              const secondSet = rowsBatched.batchRows.slice(firstBatchInitialSize)
              setSpreadsheetData({ type: 'APPEND_SPREADSHEET_ROWS', rows: secondSet })
            }

            // for subsequent batches we just append the incoming rows
          } else {
            setSpreadsheetData({
              type: 'APPEND_SPREADSHEET_ROWS',
              rows: rowsBatched.batchRows
            })
          }
        }

        setSpreadsheetData({ type: 'STREAMING', streaming: false })
      } else {
        onSuccess()
      }
    })
    .catch((error) => {
      try {
        error.json().then((errorBody: ResponseError) => {
          if (errorBody.detail.errors) {
            onError(errorBody.detail.errors[0].messages[0])
          } else {
            onError(errorBody.detail.message)
          }
          setSpreadsheetData({ type: 'REFRESH', refreshing: false })
        })
      } catch {
        onError('Something went wrong.')
        setSpreadsheetData({ type: 'REFRESH', refreshing: false })
      }
    })
}

export const updateRowPosition = (
  projectId: string,
  rowId: string,
  newIndex: number,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: () => void
) => {
  const row = spreadsheetData.rows.find((row: ITableRow) => row.publicId === rowId)
  if (row && !spreadsheetData.streaming) {
    const rows = spreadsheetData.rows.filter((r) => r.publicId !== row.publicId)
    const newSortOrder = getSortIndexForPosition(rows, newIndex)
    put(`/table/views/${spreadsheetData.viewDetails.publicId}/rows`, {
      rows: [
        {
          publicId: rowId,
          sortOrder: newSortOrder,
          // XXX: do we need to send this?
          rowData: Object.fromEntries(
            Object.entries(row.rowData).filter(([k]) => spreadsheetData.originalViewColumns.includes(k))
          )
        }
      ],
      context: {
        projectId: projectId,
        processId: spreadsheetData.processId,
        processSectionId: spreadsheetData.processSectionId,
        processResponseId: spreadsheetData.processResponseId
      }
    })
      .then(() => {
        setSpreadsheetData({ type: 'CHANGE_ROW_ORDER', rowId, newIndex, newSortOrder })
        onSuccess()
      })
      .catch(() => {
        onFailure()
      })
  } else {
    onFailure()
  }
}

export const getTableViews = (
  tableId: string,
  onSuccess: (views: ITableViewWithColumns[]) => void,
  onFailure: () => void
) => {
  get(`/table/${tableId}/views`)
    .then((response) => onSuccess(response.data as ITableViewWithColumns[]))
    .catch(() => onFailure())
}

export const searchTable = (setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>, searchTerm: string) => {
  setSpreadsheetData({ type: 'SEARCH_TABLE', searchTerm })
}
