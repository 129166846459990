import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Csv } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { MortaSyncTypes } from 'types'
import { updateTable } from 'components/spreadsheet/contexts/data/actions'
import Select from 'components/select'
import useApplicationStore from 'hooks/application'
import useProject from 'hooks/project'
import Beta from 'components/beta'

interface ImportMenuProps extends MenuProps {
  setAppendCSVModal: (value: boolean) => void
  setViewpointModal: (value: boolean) => void
  setViewpointRfisModal: (value: boolean) => void
  setBIM360Modal: (value: boolean) => void
  setProcoreModal: (value: boolean) => void
  setAconexModal: (value: boolean) => void
  setAconexWorkflowsModal: (value: boolean) => void
  setMortaModal: (value: boolean, type: MortaSyncTypes) => void
  setReviztoModal: (value: boolean) => void
  setAsiteDocumentsModal: (value: boolean) => void
}

const ImportMenu: React.FC<ImportMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  setAppendCSVModal,
  setViewpointModal,
  setViewpointRfisModal,
  setBIM360Modal,
  setProcoreModal,
  setAconexModal,
  setAconexWorkflowsModal,
  setMortaModal,
  setReviztoModal,
  setAsiteDocumentsModal
}) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div className="overflow-y-auto" style={{ height: '500px', listStyle: 'none', padding: '0px' }}>
        {!spreadsheetData.viewDetails.disableNewRow && (
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                margin: '10px',
                height: '32px',
                lineHeight: '32px',
                cursor: spreadsheetData.tableDetails.isSynced ? 'not-allowed' : 'pointer'
              }}
              onClick={() => {
                if (spreadsheetData.tableDetails.isSynced) {
                  return
                }
                setAppendCSVModal(true)
                setMenuState(INITIAL_CONTEXT_MENU_STATE)
              }}
            >
              <Csv />
              <span className="ml-10px">CSV File</span>
            </div>
          </li>
        )}

        {spreadsheetData.isAdmin && (
          <>
            <li
              className="font-bold bg-light-grey"
              style={{
                padding: '10px 20px'
              }}
            >
              Sync Settings
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none italic"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
              >
                Last sync:{' '}
                {spreadsheetData.tableDetails.isSynced && spreadsheetData.tableDetails.lastSync
                  ? new Date(spreadsheetData.tableDetails.lastSync).toLocaleString()
                  : 'No Sync Configured'}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
              >
                <span style={{ marginRight: '5px', width: '125px' }}>Sync Frequency:</span>
                <div style={{ flex: 1 }}>
                  <Select
                    options={[
                      { label: 'Every Hour', value: '1' },
                      { label: 'Every 24 hours', value: '24' }
                    ]}
                    onOptionClick={(option) => {
                      updateTable(
                        'syncHourlyFrequency',
                        spreadsheetData,
                        setSpreadsheetData,
                        Number.parseInt(option),
                        project.publicId,
                        () => setSnackbarMessage({ message: 'Sync frequency updated successfully', status: 'success' }),
                        (error) => setSnackbarMessage({ message: error, status: 'error' })
                      )
                    }}
                    optionsSelected={[spreadsheetData.tableDetails.syncHourlyFrequency.toString()]}
                  />
                </div>
              </div>
            </li>

            <li
              className="font-bold bg-light-grey"
              style={{
                padding: '10px 20px'
              }}
            >
              Sync Morta Data
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaProjectsSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaProjectsSynced) {
                    return
                  }
                  setMortaModal(true, 'Projects')
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/morta-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Morta small icon"
                />
                <span className="ml-10px">Morta Workspace Data</span>
                {spreadsheetData.tableDetails.isMortaProjectsSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaUsersSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaUsersSynced) {
                    return
                  }
                  setMortaModal(true, 'Users')
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/morta-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Morta small icon"
                />
                <span className="ml-10px">Morta Workspace Members</span>
                {spreadsheetData.tableDetails.isMortaUsersSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaResourcesSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaResourcesSynced) {
                    return
                  }
                  setMortaModal(true, 'Resources')
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/morta-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Morta small icon"
                />
                <span className="ml-10px">Morta Workspace Resources</span>
                {spreadsheetData.tableDetails.isMortaResourcesSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaCommentsSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isMortaCommentsSynced) {
                    return
                  }
                  setMortaModal(true, 'Comments')
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/morta-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Morta small icon"
                />
                <span className="ml-10px">Morta Workspace Comments</span>
                {spreadsheetData.tableDetails.isMortaCommentsSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li
              className="font-bold bg-light-grey"
              style={{
                padding: '10px 20px'
              }}
            >
              Sync Documents
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isViewpointSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isViewpointSynced) {
                    return
                  }
                  setViewpointModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/viewpoint-icon.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Viewpoint For Projects icon"
                />
                <span className="ml-10px">Viewpoint For Projects</span>
                {spreadsheetData.tableDetails.isViewpointSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAutodeskBim360Synced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAutodeskBim360Synced) {
                    return
                  }
                  setBIM360Modal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/autodesk-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Autodesk icon"
                />
                <span className="ml-10px">ACC/BIM 360</span>
                {spreadsheetData.tableDetails.isAutodeskBim360Synced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAsiteDocumentsSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAsiteDocumentsSynced) {
                    return
                  }
                  setAsiteDocumentsModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img src="/assets/images/asite-small.png" style={{ width: '16px', height: '16px' }} alt="Asite icon" />
                <span className="ml-10px">Asite</span>
                <Beta />
                {spreadsheetData.tableDetails.isAsiteDocumentsSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isProcoreSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isProcoreSynced) {
                    return
                  }
                  setProcoreModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/procore-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Procore icon"
                />
                <span className="ml-10px">Procore</span>
                {spreadsheetData.tableDetails.isProcoreSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAconexSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAconexSynced) {
                    return
                  }
                  setAconexModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/aconex-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Aconex icon"
                />
                <span className="ml-10px">Aconex</span>
                {spreadsheetData.tableDetails.isAconexSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li
              className="font-bold bg-light-grey"
              style={{
                padding: '10px 20px'
              }}
            >
              Sync Issues
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isReviztoIssuesSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isReviztoIssuesSynced) {
                    return
                  }
                  setReviztoModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/revizto-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Viewpoint For Projects icon"
                />
                <span className="ml-10px">Revizto</span>
                {spreadsheetData.tableDetails.isReviztoIssuesSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li
              className="font-bold bg-light-grey"
              style={{
                padding: '10px 20px'
              }}
            >
              Sync Workflows
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAconexWorkflowsSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isAconexWorkflowsSynced) {
                    return
                  }
                  setAconexWorkflowsModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/aconex-small.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Aconex icon"
                />
                <span className="ml-10px">Aconex</span>
                {spreadsheetData.tableDetails.isAconexWorkflowsSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>

            <li
              className="font-bold bg-light-grey"
              style={{
                padding: '10px 20px'
              }}
            >
              Sync Tasks
            </li>

            <li style={{ marginTop: '5px' }}>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  margin: '10px',
                  height: '32px',
                  lineHeight: '32px',
                  cursor:
                    spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isViewpointRfisSynced
                      ? 'not-allowed'
                      : 'pointer'
                }}
                onClick={() => {
                  if (spreadsheetData.tableDetails.isSynced && !spreadsheetData.tableDetails.isViewpointRfisSynced) {
                    return
                  }
                  setViewpointRfisModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <img
                  src="/assets/images/viewpoint-icon.png"
                  style={{ width: '16px', height: '16px' }}
                  alt="Viewpoint For Projects icon"
                />
                <span className="ml-10px">Viewpoint For Projects</span>
                {spreadsheetData.tableDetails.isViewpointRfisSynced ? (
                  <span className="bg-green rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Active
                  </span>
                ) : (
                  <span className="bg-red rounded font-bold text-sm ml-auto" style={{ padding: '5px' }}>
                    Inactive
                  </span>
                )}
              </div>
            </li>
          </>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(ImportMenu)
