import React, { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react'
import { ErrorOutline } from 'components/icons'
import constants from 'style/constants.module.scss'
import {
  createCellId,
  getRowHeightVariable,
  getRowHeightClassName,
  findRowAndIndex,
  validateField,
  isSameValueStringValue
} from 'components/spreadsheet/helpers/functions'
import { checkValuesSame } from 'components/spreadsheet/helpers/paste'
import { TextCell } from 'components/spreadsheet/components/cell/components/text'
import { NumberCell } from 'components/spreadsheet/components/cell/components/number'
import { DateCell } from 'components/spreadsheet/components/cell/components/date'
import { UserCell } from 'components/spreadsheet/components/cell/components/user'
import { LinkCell } from 'components/spreadsheet/components/cell/components/link'
import { MultiLinkCell } from 'components/spreadsheet/components/cell/components/multilink'
import { VoteCell } from 'components/spreadsheet/components/cell/components/vote'
import { CheckboxCell } from 'components/spreadsheet/components/cell/components/checkbox'
import { SelectCell } from 'components/spreadsheet/components/cell/components/select'
import { AttachmentCell } from 'components/spreadsheet/components/cell/components/attachment'
import { ISummaryUser, IFile, ICellValue, IVote, IContextMenuState } from 'types'
import { IUpdateTableViewCell } from 'components/spreadsheet/contexts/data/actions'
import { getCellColour } from 'components/spreadsheet/helpers/colouring'
import {
  TEXT_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  USER_COLUMN_TYPES,
  ATTACHMENT_COLUMN_TYPES,
  SELECT_COLUMN_TYPES,
  NON_EDITABLE_COLUMN_OPTION_TYPES,
  INPUT_COLUMN_OPTION_TYPES,
  VOTE_COLUMN_TYPES,
  INITIAL_SELECTED_CELL_RANGE,
  INITIAL_SELECTED_CELL
} from 'components/spreadsheet/constants/const'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import {
  ISpreadsheetData,
  IColumnTypes,
  ISelectedCell,
  IResultFieldValidation,
  rowHeights,
  ISelectedCellState,
  IEditCompleteOptions,
  ISelectedCellRange
} from 'components/spreadsheet/types'
import { isValidValue } from 'components/spreadsheet/helpers/validation'

interface CellProps {
  isContributor: boolean
  isAdmin: boolean
  kind: IColumnTypes
  scriptEnabled: boolean
  isJoined: boolean
  value: ICellValue
  frozen: boolean
  uniqueNumber: number
  rowHeight: number
  width?: number
  cumulativeWidth?: number
  locked?: boolean
  readOnly?: boolean
  loading?: boolean
  setSelectedCell?: (selectedCell: ISelectedCellState) => void
  rowNumber?: number
  rowId?: string
  nextRowId?: string
  prevRowId?: string
  columnNumber?: number
  columnName?: string
  columnId?: string
  nextColumnId?: string
  prevColumnId?: string
  align?: 'center' | 'left' | 'right'
  color?: string
  contextClick?: (menuState: IContextMenuState) => void
  canDrag?: boolean
  isDragOver?: boolean
  handleKeyDown?: (event: React.KeyboardEvent, selectedCell: ISelectedCell) => void
  setCellValue?: (cells: IUpdateTableViewCell, onSuccess: () => void, onError: (message?: string) => void) => void
  searched?: boolean
  isLastFrozenCell?: boolean
  selected?: boolean
  inSelectedCellRange?: boolean
  setSelectedCellRange?: (selectedCellRange: ISelectedCellRange) => void
  isExpanded?: boolean
  spreadsheetData?: ISpreadsheetData
  unresolvedComments?: number
  noComments?: number
  isStartOfGroup?: boolean
  useGreyBackground?: boolean
  formValues?: Record<string, ICellValue>
  editing: boolean
  tempValue?: ICellValue
  setExpandModal?: (expandModal: boolean, focusInput: boolean, rowId: string, rowNumber: number) => void
  setIsFocused?: (isFocused: boolean) => void
}

const Cell: React.FC<CellProps> = ({
  isContributor,
  isAdmin,
  width,
  cumulativeWidth,
  rowHeight,
  rowNumber,
  rowId,
  nextRowId,
  prevRowId,
  setSelectedCell,
  setIsFocused,
  columnNumber,
  columnName,
  columnId,
  prevColumnId,
  nextColumnId,
  uniqueNumber,
  kind,
  scriptEnabled,
  isJoined,
  value,
  locked,
  readOnly,
  loading,
  align,
  color,
  contextClick,
  frozen,
  canDrag,
  isDragOver,
  handleKeyDown,
  setCellValue,
  searched,
  isLastFrozenCell,
  selected,
  inSelectedCellRange,
  isExpanded,
  spreadsheetData,
  setSelectedCellRange,
  unresolvedComments,
  noComments,
  isStartOfGroup,
  useGreyBackground,
  formValues,
  editing,
  tempValue,
  setExpandModal
}) => {
  const cellRef = useRef<HTMLDivElement>(null)
  const savingCellRef = useRef<any>(null)
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  const [validation, setValidation] = useState<{ error: boolean; warn: boolean; errorMessage: string }>({
    error: false,
    warn: false,
    errorMessage: ''
  })

  const [currentValue, updateCurrentValue] = useState<ICellValue>(value)
  const updateCurrentValueCallback = useCallback(
    (newValue: ICellValue) => {
      updateCurrentValue(newValue)
    },
    [value, setCellValue]
  )

  // Check if the status of the selected cell when mounting the component
  useEffect(() => {
    if (editing && tempValue !== undefined) {
      updateCurrentValueCallback(tempValue)
    }
  }, [])

  // Update the state of the selected cell if the value of the cell changes
  useLayoutEffect(() => {
    if (
      rowId &&
      columnId &&
      rowNumber !== undefined &&
      columnNumber !== undefined &&
      selected &&
      editing &&
      setSelectedCell &&
      tempValue !== undefined &&
      tempValue !== currentValue
    ) {
      setSelectedCell({ rowId, columnId, rowNumber, columnNumber, editing, value: currentValue })
    }
  }, [currentValue])

  // when updating a column we need to update the current value of a cell if value changes.
  useLayoutEffect(() => {
    updateCurrentValueCallback(editing ? currentValue : value)
    if (selected && setSelectedCell && rowId && columnId && rowNumber !== undefined && columnNumber !== undefined) {
      setSelectedCell({ editing, rowId, columnId, rowNumber, columnNumber, value: editing ? currentValue : value })
    }
  }, [value])

  useLayoutEffect(() => {
    if (selected) {
      cellRef.current?.focus()
    }
  }, [selected])

  useEffect(() => {
    if (!selected && !scriptEnabled && !isJoined) {
      handleSaveValue()
    }
  }, [selected])

  const column =
    columnId !== undefined ? spreadsheetData?.viewDetails.columns.find((c) => c.publicId === columnId) : null

  const setCurrentValue = useCallback(
    (newValue: ICellValue, onSuccess?: () => void, onError?: (message?: string) => void) => {
      // Give back focus to cell to select column types
      if (SELECT_COLUMN_TYPES.includes(kind)) {
        cellRef.current?.focus()
      }
      // Run Validation Checks

      if (column && rowId && spreadsheetData && spreadsheetData.rows) {
        const found = findRowAndIndex(rowId, spreadsheetData.rows)
        const validValue = isValidValue(
          newValue,
          found?.row,
          spreadsheetData.columnValuesCount,
          column,
          formValues,
          spreadsheetData?.viewDetails.type,
          true
        )
        if (validValue.error) {
          if (validValue.warn) setSnackbarMessage({ status: 'warning', message: validValue.errorMessage })
          else {
            updateCurrentValueCallback(value)
            setSnackbarMessage({ status: 'error', message: validValue.errorMessage })
            if (onError) onError()
            return
          }
        }
      }

      if (
        kind !== 'multilink' &&
        kind !== 'attachment' &&
        kind !== 'select' &&
        kind !== 'multiselect' &&
        kind !== 'vote'
      ) {
        const result = validateField(kind, newValue, columnName!)
        if (!(result as IResultFieldValidation).pass) {
          if (onError) onError()
          updateCurrentValueCallback(value)
          setSnackbarMessage({ status: 'error', message: (result as IResultFieldValidation).error })
          return
        }
      }

      if (checkValuesSame(value, newValue, kind)) return

      if (setCellValue) {
        const cell = {
          cells: [
            {
              columnId: columnId!,
              rowId: rowId!,
              value: newValue,
              oldValue: value
            }
          ],
          context: { projectId: project.publicId }
        }

        setCellValue(
          cell,
          () => {
            if (onSuccess) onSuccess()
            if (['multilink', 'attachment', 'vote'].includes(kind)) updateCurrentValueCallback(newValue)
          },
          (message?: string) => {
            if (onError) onError()
            updateCurrentValueCallback(value)
            setSnackbarMessage({
              status: 'error',
              message:
                message ||
                'Something went wrong updating the cell. Please make sure the current view is saved, refresh and try again.'
            })
          }
        )
      }
    },
    [
      project,
      value,
      columnId,
      rowId,
      setCellValue,
      spreadsheetData &&
        spreadsheetData.columnValuesCount &&
        columnId &&
        spreadsheetData.columnValuesCount[columnId] &&
        value &&
        spreadsheetData.columnValuesCount[columnId][value.toString()]
    ]
  )

  useEffect(() => {
    const column =
      columnId !== undefined ? spreadsheetData?.viewDetails.columns.find((c) => c.publicId === columnId) : null
    if (column && rowId && spreadsheetData && spreadsheetData.rows) {
      const found = findRowAndIndex(rowId, spreadsheetData.rows)
      const validValue = isValidValue(
        currentValue,
        found?.row,
        spreadsheetData.columnValuesCount,
        column,
        formValues,
        spreadsheetData?.viewDetails.type
      )
      setValidation(validValue)
    }
  }, [
    spreadsheetData &&
      spreadsheetData.columnValuesCount &&
      columnId &&
      spreadsheetData.columnValuesCount[columnId] &&
      currentValue?.toString() &&
      spreadsheetData.columnValuesCount[columnId][currentValue?.toString()],
    columnId !== undefined && spreadsheetData?.viewDetails.columns.find((c) => c.publicId === columnId),
    columnId !== undefined && spreadsheetData?.viewDetails.columns.find((c) => c.publicId === columnId)?.kindOptions,
    currentValue,
    kind
  ])

  const textAlign = align ? align : 'left'
  const textColor = color ? color : 'inherit'
  const isReadOnly =
    (locked !== undefined && locked) ||
    (readOnly !== undefined && readOnly) ||
    !isContributor ||
    scriptEnabled ||
    isJoined ||
    NON_EDITABLE_COLUMN_OPTION_TYPES.includes(kind)

  const handleOnContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    if (!editing) {
      if (
        contextClick &&
        setSelectedCell &&
        setSelectedCellRange &&
        rowNumber !== undefined &&
        columnNumber !== undefined &&
        rowId !== undefined &&
        columnId !== undefined
      ) {
        if (!inSelectedCellRange) {
          setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
          setSelectedCell({ rowId, columnId, rowNumber, columnNumber, editing, value })
        }

        contextClick({
          open: true,
          top: `${event.clientY + 5}px`,
          left: `${event.clientX - 120}px`,
          right: 'auto',
          bottom: 'auto',
          columnNumber: columnNumber,
          columnId: columnId,
          rowNumber: rowNumber,
          rowId: rowId
        })
      }
    }
  }

  const handleOnMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.button === 0) {
      if (setSelectedCellRange && rowNumber !== undefined && columnNumber !== undefined) {
        setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
      }
      event.stopPropagation()
      if (!editing) {
        if (
          setSelectedCell &&
          setIsFocused &&
          rowId !== undefined &&
          columnId !== undefined &&
          rowNumber !== undefined &&
          columnNumber !== undefined
        ) {
          cellRef.current?.focus()
          setIsFocused(true)
          setSelectedCell({ rowId, columnId, rowNumber, columnNumber, editing, value })
        }
        if (isExpanded && !NON_EDITABLE_COLUMN_OPTION_TYPES.includes(kind)) {
          handleEditChange(true)
        }
      }
    }
  }
  const handleOnMouseOver = (event: React.MouseEvent) => {
    if (
      setIsFocused &&
      event.buttons > 0 &&
      setSelectedCellRange &&
      rowNumber !== undefined &&
      columnNumber !== undefined
    ) {
      setSelectedCellRange({ endRowIndex: rowNumber, endColumnIndex: columnNumber })
    }
  }

  const handleOnDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (canDrag && rowId !== undefined) {
      event.dataTransfer.setData('rowId', rowId)
      event.dataTransfer.effectAllowed = 'move'
    }
  }

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    event.persist()

    if (
      setSelectedCellRange &&
      rowNumber !== undefined &&
      columnNumber !== undefined &&
      event.shiftKey &&
      event.key === 'Shift' &&
      !editing
    ) {
      setSelectedCellRange({ endRowIndex: rowNumber, endColumnIndex: columnNumber })
    }

    if (editing) {
      if (
        INPUT_COLUMN_OPTION_TYPES.includes(kind) ||
        SELECT_COLUMN_TYPES.includes(kind) ||
        ATTACHMENT_COLUMN_TYPES.includes(kind) ||
        VOTE_COLUMN_TYPES.includes(kind) ||
        kind === 'multilink'
      ) {
        if (event.key === 'Enter' && !(event.ctrlKey || event.altKey || event.metaKey)) {
          handleEditChange(false)
        } else if (event.key === 'Tab') {
          handleEditChange(false)
          fireKeyDownEvent(event.shiftKey ? 'ArrowLeft' : 'ArrowRight')
        }
      }
      return false
    }

    if (handleKeyDown) {
      const startRowId = spreadsheetData?.rows[0]?.publicId
      const finalRowId = spreadsheetData?.rows[spreadsheetData?.rows.length - 1]?.publicId
      const finalRowNumber = spreadsheetData?.rows.length
      const startColumnId = spreadsheetData?.viewDetails.columns[0]?.publicId
      const finalColumnId =
        spreadsheetData?.viewDetails.columns[spreadsheetData?.viewDetails.columns.length - 1].publicId
      const finalColumnNumber = spreadsheetData?.viewDetails.columns.length

      handleKeyDown(event, {
        currentRowId: rowId ? rowId : '',
        currentColumnId: columnId ? columnId : '',
        nextRowId: nextRowId ? nextRowId : '',
        nextColumnId: nextColumnId ? nextColumnId : '',
        prevRowId: prevRowId ? prevRowId : '',
        prevColumnId: prevColumnId ? prevColumnId : '',
        startRowId: startRowId ? startRowId : '',
        finalRowId: finalRowId ? finalRowId : '',
        finalRowNumber: finalRowNumber !== undefined ? finalRowNumber - 1 : -1,
        startColumnId: startColumnId ? startColumnId : '',
        finalColumnId: finalColumnId ? finalColumnId : '',
        finalColumnNumber: finalColumnNumber !== undefined ? finalColumnNumber - 1 : -1
      })
    }

    if (event.key === 'Enter') {
      // if key pressed is enter and cell is a select column then the menu options should open
      // similar to when a double clicking
      if (SELECT_COLUMN_TYPES.includes(kind)) {
        fireClickEvent(formValues ? 'click' : 'dblclick')
      } else if (!INPUT_COLUMN_OPTION_TYPES.includes(kind) || isReadOnly) {
        // if key pressed is enter and cell is a non editable column then the selected cell should move down
        fireKeyDownEvent('ArrowDown')
      }
    } else if (event.key === 'Tab') {
      // the selected cell should move right
      fireKeyDownEvent(event.shiftKey ? 'ArrowLeft' : 'ArrowRight')
    }

    // if key pressed is enter or is an alpha character typed on a editable column then the status of the cell should change to editing
    const charStr = String.fromCharCode(event.keyCode)
    // Check if the META/CTRL key is not being pressed along with a character in order to avoid changing the status of the cell to editing when
    // the user only wanted to do for example CTRL+C/CTRL+V/CTRL+X
    const isChar = !event.metaKey && !event.ctrlKey && /[a-zA-z0-9]/i.test(charStr)
    if ((event.key === 'Enter' || isChar) && INPUT_COLUMN_OPTION_TYPES.includes(kind) && !isReadOnly) {
      handleEditChange(true)

      // Prevent the editor from inserting a newline in the textarea when switching to edit mode for text cells
      if (event.key === 'Enter' && kind === 'text') {
        event.preventDefault()
      }
    }
  }

  const fireKeyDownEvent = (key: 'ArrowDown' | 'ArrowRight' | 'ArrowLeft') => {
    const keyDown = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: key,
      shiftKey: false
    })
    // Wrapped within timeout, because we want any pending state updates in React to
    // be commited before the event is fired - as the handleKeyDown function relies
    // on local state being up to date
    setTimeout(() => cellRef.current?.dispatchEvent(keyDown), 0)
  }

  const fireClickEvent = (event: 'click' | 'dblclick') => {
    const click = new MouseEvent(event, {
      bubbles: true,
      cancelable: true,
      view: window
    })
    // Wrapped within timeout, because we want any pending state updates in React to
    // be commited before the event is fired - as the handleKeyDown function relies
    // on local state being up to date
    if (cellRef.current && cellRef.current.childNodes && cellRef.current.childNodes.length > 0) {
      // fires a click event on the 'cell-value' div
      setTimeout(() => cellRef!.current!.childNodes[1]?.dispatchEvent(click), 0)
    }
  }

  const handleChangeEditMode = (editing: boolean, options?: IEditCompleteOptions) => {
    handleEditChange(editing)

    // When we switch from edit mode to "normal" mode, we need to set focus on this
    // cell component otherwise onKeyDown events are not caught by this component
    if (editing === false) {
      setTimeout(() => cellRef.current?.focus(), 0)
      if (options && options.saveValue === false) {
        updateCurrentValueCallback(value)
      } else handleSaveValue()
      if (options && options.moveToNextCell) {
        fireKeyDownEvent('ArrowDown')
      }
    }
  }

  const hasUnresolvedComments = unresolvedComments !== undefined && unresolvedComments > 0
  const isRowNumberCell = columnId === undefined
  const noDigits = isRowNumberCell && value ? value.toString().length : -1

  const cellId = isRowNumberCell
    ? `numbercell/${rowId}`
    : createCellId(
        rowId !== undefined ? rowId : 'undefined',
        columnId !== undefined ? columnId : 'undefined',
        uniqueNumber
      )
  const isEditingText = editing && kind === 'text'
  const isExpandedTextColumn = (kind === 'text' || kind === 'multiselect') && isExpanded && value

  const getBorderBottomStyle = () => {
    if (isDragOver) {
      return `1px solid ${constants.pink}`
    }
    if (isExpanded) {
      return '0'
    }
    if (editing) {
      return `0px solid ${constants.grey}`
    }

    return `1px solid ${constants.grey}`
  }

  const getBorderStyleTop = () => {
    if (isExpanded) {
      return `1px solid ${constants.grey}`
    }
    if (isStartOfGroup) {
      return `1px solid ${constants.darkerGrey}`
    }
    return 'none'
  }

  const handleOnCommentsClick = () => {
    if (setExpandModal && rowId && rowNumber !== undefined && setSelectedCellRange && setSelectedCell) {
      setSelectedCell(INITIAL_SELECTED_CELL)
      setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
      setExpandModal!(true, true, rowId, rowNumber)
    }
  }

  const handleKeyDownCell = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleChangeEditMode(false, {
        moveToNextCell: event.key === 'Enter',
        saveValue: true
      })
    } else if (event.key === 'Escape') {
      handleChangeEditMode(false, { moveToNextCell: false, saveValue: false })
    }
  }

  const handleSaveValue = () => {
    if (savingCellRef.current !== null) {
      clearTimeout(savingCellRef.current)
    }

    savingCellRef.current = setTimeout(() => {
      savingCellRef.current = null
      const isValueEmpty = value === null || value === ''

      if (
        (TEXT_COLUMN_TYPES.includes(kind) || kind === 'link' || DATE_COLUMN_TYPES.includes(kind)) &&
        !isSameValueStringValue(currentValue as string, isValueEmpty ? null : (value as string))
      ) {
        setCurrentValue(currentValue)
      } else if (
        NUMBER_COLUMN_TYPES.includes(kind) &&
        !isSameValueStringValue(currentValue as string, isValueEmpty ? null : (value as number))
      ) {
        setCurrentValue(currentValue === undefined || currentValue === null ? null : Number(currentValue))
      }
    }, 150)
  }

  const handleEditChange = (newEditing: boolean) => {
    if (setSelectedCell && rowId && columnId && rowNumber !== undefined && columnNumber !== undefined) {
      setSelectedCell({ rowId, columnId, rowNumber, columnNumber, editing: newEditing, value })
    }
  }

  const handleOnBlur = () => {
    if (selected && editing) {
      handleSaveValue()
    }
  }

  const colourSettings = spreadsheetData && spreadsheetData.userConfiguration.colourSettings

  return (
    <div
      ref={cellRef}
      id={cellId}
      className={`${getRowHeightClassName(rowHeight)} ${hasUnresolvedComments ? 'cell-comments' : 'table box-border'} ${
        searched ? 'bg-really-light-yellow' : ''
      } ${
        locked || scriptEnabled || isJoined || NON_EDITABLE_COLUMN_OPTION_TYPES.includes(kind) ? 'bg-light-grey' : ''
      } ${isExpanded ? 'bg-white' : ''} ${inSelectedCellRange ? 'bg-really-light-blue' : ''}`}
      style={{
        width: isExpanded && kind === 'attachment' ? 'unset' : width !== undefined ? `${width}px` : '100%',
        borderTop: getBorderStyleTop(),
        borderBottom: getBorderBottomStyle(),
        borderLeft: 'none',
        borderRight: `1px solid ${constants.lightGrey}`,
        position: frozen ? 'sticky' : isExpanded ? 'unset' : 'absolute',
        left: `${cumulativeWidth}px`,
        zIndex: frozen ? (isEditingText ? 9 : 3) : isEditingText ? 9 : 'auto',
        cursor: canDrag ? 'grab' : 'default',
        height: isExpandedTextColumn ? 'auto' : `${getRowHeightVariable(rowHeight)}px`,
        maxHeight: isExpandedTextColumn ? 'auto' : `${getRowHeightVariable(rowHeight)}px`,
        minHeight: isExpandedTextColumn ? `${getRowHeightVariable(rowHeights.Small)}px` : 'unset',
        boxShadow: isLastFrozenCell
          ? `${Number.parseInt(constants.frozenShadowWidth)}px 0 1px ${constants.grey}`
          : 'none',
        backgroundColor: column
          ? getCellColour(
              column,
              SELECT_COLUMN_TYPES.includes(column.kind) ? undefined : value,
              colourSettings,
              locked ||
                scriptEnabled ||
                isJoined ||
                NON_EDITABLE_COLUMN_OPTION_TYPES.includes(kind) ||
                useGreyBackground
            )
          : isRowNumberCell
          ? constants.lightGrey
          : '',
        fontSize: isRowNumberCell && noDigits !== -1 ? `calc(100% - ${noDigits * 7}%` : '100%'
      }}
      onMouseDown={handleOnMouseDown}
      onMouseOver={handleOnMouseOver}
      tabIndex={rowNumber !== undefined && columnNumber !== undefined ? rowNumber * columnNumber : undefined}
      draggable={canDrag}
      onDragStart={handleOnDragStart}
      onKeyDown={handleOnKeyDown}
      onContextMenu={handleOnContextMenu}
      onDoubleClick={() => {
        if (isExpanded || isReadOnly) {
          return
        } else {
          if (rowId && columnId && rowNumber !== undefined && columnNumber !== undefined) {
            setSelectedCell!({ rowId, columnId, rowNumber, columnNumber, value, editing: true })
          }
        }
      }}
      onBlur={handleOnBlur}
    >
      {!loading && (
        <React.Fragment>
          {TEXT_COLUMN_TYPES.includes(kind) && (typeof value === 'string' || !value || value.toString()) && (
            <TextCell
              value={currentValue as string}
              align={textAlign}
              color={textColor}
              kind={kind}
              readOnly={isReadOnly}
              setCellValue={updateCurrentValueCallback}
              editing={editing}
              handleKeyDown={handleKeyDownCell}
              width={width!}
              rowHeight={rowHeight!}
              selected={selected!}
              isExpanded={isExpanded}
              validation={validation.error}
            />
          )}
          {NUMBER_COLUMN_TYPES.includes(kind) && (typeof value === 'number' || !value) && (
            <NumberCell
              value={currentValue as number}
              color={textColor}
              kind={kind}
              readOnly={isReadOnly}
              setCellValue={updateCurrentValueCallback}
              editing={editing}
              handleKeyDown={handleKeyDownCell}
              width={width!}
              validation={validation.error}
              rowHeight={rowHeight!}
              selected={selected!}
              thousandSeparator={column ? column.thousandSeparator : true}
              decimalPlaces={column ? column.decimalPlaces : 2}
            />
          )}
          {USER_COLUMN_TYPES.includes(kind) && (
            <UserCell value={currentValue as ISummaryUser} selected={selected!} rowHeight={rowHeight!} width={width!} />
          )}
          {DATE_COLUMN_TYPES.includes(kind) && (typeof value === 'string' || !value) && (
            <DateCell
              value={currentValue as string}
              align={textAlign}
              color={textColor}
              kind={kind}
              readOnly={isReadOnly}
              isExpanded={isExpanded}
              setCellValue={updateCurrentValueCallback}
              handleKeyDown={handleKeyDownCell}
              editing={editing}
              setEditing={handleChangeEditMode}
              width={width!}
              rowHeight={rowHeight!}
              selected={selected!}
              validation={validation.error}
              dateFormat={column ? column.dateFormat : null}
            />
          )}
          {kind === 'link' && (typeof value === 'string' || !value) && (
            <LinkCell
              value={currentValue as string}
              align={textAlign}
              color={textColor}
              kind={kind}
              readOnly={isReadOnly}
              setCellValue={updateCurrentValueCallback}
              editing={editing}
              handleKeyDown={handleKeyDownCell}
              width={width!}
              rowHeight={rowHeight!}
              selected={selected!}
              spreadsheetData={spreadsheetData}
              isExpanded={isExpanded}
              validation={validation.error}
            />
          )}
          {kind === 'multilink' && (
            <MultiLinkCell
              value={currentValue as string[]}
              align={textAlign}
              color={textColor}
              kind={kind}
              readOnly={isReadOnly}
              isAdmin={isAdmin}
              isContributor={isContributor}
              setCellValue={setCurrentValue}
              editing={editing}
              width={width!}
              rowHeight={rowHeight!}
              selected={selected!}
              isExpanded={isExpanded}
              validation={validation.error}
            />
          )}
          {kind === 'vote' && (
            <VoteCell
              value={currentValue as IVote[]}
              align={textAlign}
              color={textColor}
              readOnly={isReadOnly}
              isAdmin={isAdmin}
              isContributor={isContributor}
              setCellValue={setCurrentValue}
              width={width!}
              rowHeight={rowHeight!}
              selected={selected!}
              rowNumber={rowNumber}
              isExpanded={isExpanded}
              validation={validation.error}
            />
          )}
          {kind === 'checkbox' && (
            <CheckboxCell
              value={currentValue as boolean}
              align={textAlign}
              color={textColor}
              readOnly={isReadOnly}
              setCellValue={setCurrentValue}
              handleKeyDown={handleKeyDownCell}
              width={width!}
              rowHeight={rowHeight!}
              selected={selected!}
            />
          )}

          {ATTACHMENT_COLUMN_TYPES.includes(kind) && (
            <AttachmentCell
              value={currentValue as IFile[]}
              align={textAlign}
              color={textColor}
              readOnly={isReadOnly}
              isAdmin={isAdmin}
              isContributor={isContributor}
              setCellValue={setCurrentValue}
              rowHeight={rowHeight!}
              selected={selected!}
              width={width!}
              columnId={columnId}
              isExpanded={isExpanded}
            />
          )}
          {SELECT_COLUMN_TYPES.includes(kind) && (
            <SelectCell
              value={currentValue as string}
              align={textAlign}
              color={textColor}
              kind={kind}
              readOnly={isReadOnly}
              isAdmin={isAdmin}
              isContributor={isContributor}
              setCellValue={setCurrentValue}
              editing={editing}
              setEditing={handleChangeEditMode}
              width={width!}
              rowHeight={rowHeight!}
              selected={selected!}
              columnId={columnId}
              spreadsheetData={spreadsheetData!}
              isExpanded={isExpanded}
              rowId={rowId!}
              formValues={formValues}
              validation={validation.error}
              isJoined={isJoined}
            />
          )}

          {/* Validation Display */}
          {validation.error && !loading && !editing && (
            <div style={{ position: 'relative', display: 'table-caption' }}>
              <div
                title={validation.errorMessage}
                style={{ position: 'absolute', right: 5, top: getRowHeightVariable(rowHeight) / 2 - 12 }}
              >
                <ErrorOutline
                  style={{ height: '24px', width: '24px', color: validation.warn ? 'orange' : constants.red }}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {loading && width && (
        <div>
          <span
            className="skeleton-box"
            style={{ width: `${width}px`, height: `${getRowHeightVariable(rowHeight) - 6}px` }}
          />
        </div>
      )}

      {noComments !== undefined && unresolvedComments !== undefined && unresolvedComments < 1 && (
        <div
          className="flex items-center justify-center text-sm rounded cursor-pointer border-box"
          style={{
            minWidth: '19px',
            backgroundColor: noComments > 0 ? constants.lightBlue : 'transparent',
            padding: '2px'
          }}
          onClick={() => handleOnCommentsClick()}
        >
          <span>&nbsp;</span>
        </div>
      )}

      {noComments !== undefined && unresolvedComments !== undefined && unresolvedComments > 0 && (
        <div
          className="flex items-center justify-center text-sm rounded cursor-pointer border-box"
          style={{
            minWidth: '19px',
            backgroundColor: unresolvedComments > 0 ? constants.lightPink : 'transparent',
            padding: '2px'
          }}
          onClick={() => handleOnCommentsClick()}
        >
          {unresolvedComments > 0 ? unresolvedComments.toString() : <span>&nbsp;</span>}
        </div>
      )}
    </div>
  )
}

export default React.memo(Cell)
