import {
  SelectionState,
  DraftHandleValue,
  AtomicBlockUtils,
  EditorState,
  RawDraftEntity,
  DraftEntityMutability
} from 'draft-js'
import { EditorPlugin, PluginFunctions } from '@draft-js-plugins/editor'
import { useApplicationStore } from 'hooks/application'
import api from 'helpers/api'
import { IFileParentResource } from 'types'

export const createDnDUploadPlugin = (resources?: IFileParentResource[]): EditorPlugin => {
  const { setSnackbarMessage } = useApplicationStore()
  /**
   * Value given in Mb
   */
  const MAX_FILE_SIZE = 35

  const addFilesToEditorState = (editorState: EditorState, entities: RawDraftEntity[]): EditorState => {
    let nextEditorState: EditorState = editorState
    entities.forEach((entity: RawDraftEntity) => {
      const contentState = nextEditorState.getCurrentContent()
      const contentStateWithEntity = contentState.createEntity(entity.type, entity.mutability, entity.data)
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
      nextEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
    })

    nextEditorState = EditorState.moveFocusToEnd(EditorState.moveSelectionToEnd(nextEditorState))
    return nextEditorState
  }

  const uploadFiles = (files: File[]) => {
    return Promise.all(
      files.map(async (file) => {
        try {
          const response = await api.uploadFile(file, resources)
          const fileType = file.type.split('/')[0]
          const data = {
            src: response.data.url
          }

          switch (fileType) {
            case 'video':
              return {
                type: 'draft-js-video-plugin-video',
                mutability: 'IMMUTABLE',
                data: { ...data, filename: response.data.filename }
              }
            case 'image':
              return {
                type: 'IMAGE',
                mutability: 'IMMUTABLE',
                data: { ...data, filename: response.data.filename }
              }
            case 'text':
            case 'application':
              return {
                type: 'FILE',
                mutability: 'IMMUTABLE',
                data: { ...data, filename: response.data.filename }
              }
          }
        } catch (e) {
          setSnackbarMessage({ message: 'Upload file failed for ' + file.name, status: 'error' })
        }
      })
    )
  }

  const isValidFile = (file: File) => {
    return file.size / 1000000 <= MAX_FILE_SIZE
  }

  const processMediaFile = async (
    editorState: EditorState,
    files: File[],
    onChange: (nexEditorState: EditorState) => void,
    disableEditor: (blockEditor: boolean) => void
  ) => {
    // Remove invalid files
    disableEditor(true)
    const validFiles = files.filter((file) => {
      const validFile = isValidFile(file)
      if (!validFile) {
        setSnackbarMessage({ message: 'File ' + file.name + ' is invalid.', status: 'error' })
      }
      return validFile
    })
    if (validFiles.length > 0) {
      // upload files
      const entities = await uploadFiles(files)
      const cleanEntities = entities.map((entity) => {
        return { type: entity!.type, mutability: entity!.mutability as DraftEntityMutability, data: entity!.data }
      })
      // add files to state
      const nextEditorStateWithFiles = addFilesToEditorState(editorState, cleanEntities)

      // update editor state
      if (nextEditorStateWithFiles) {
        onChange(nextEditorStateWithFiles)
      }
    }
    disableEditor(false)
  }

  return {
    handleDroppedFiles: (
      selection: SelectionState,
      files: Array<File>,
      properties: PluginFunctions
    ): DraftHandleValue => {
      if (!resources) {
        return 'not-handled'
      }
      const props: any = properties.getProps()
      const editorState = properties.getEditorState()
      processMediaFile(
        editorState,
        files,
        (nextEditorState) => props.onChange(nextEditorState),
        (blockEditor) => properties.setReadOnly(blockEditor)
      )
      props.onFocus()
      return 'handled'
    }
  }
}

export default createDnDUploadPlugin
