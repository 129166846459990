import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { history } from 'helpers/history'
import constants from 'style/constants.module.scss'
import Integration from 'components/integrations'
import { IntegrationProviders } from 'app-constants'

const CreateProjectModal: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const [newProject, setNewProject] = useState<any>({
    name: 'New Workspace',
    template: 'None',
    loading: false
  })

  const { setSnackbarMessage } = useApplicationStore()

  const handleClick = async () => {
    setNewProject({ ...newProject, loading: true })
    api({
      method: 'POST',
      endpoint: '/project',
      data: {
        name: newProject.name
      }
    })
      .then((response) => {
        setOpen(false)
        setNewProject({
          name: 'New Workspace',
          loading: false
        })
        const projectId = response.data.publicId
        history.push(`/project/${projectId}`)
      })
      .catch((error) => {
        console.error(error)
        setSnackbarMessage({
          status: 'error',
          message: 'Something went wrong creating your workspace. Please refresh and try again.'
        })
        setNewProject({ ...newProject, loading: false })
      })
  }

  const templateClass =
    'flex flex-column items-center justify-center rounded cursor-pointer transition-all border-1px border-solid border-black box-border hover-heavy-shadow bg-white'

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Create New Workspace">
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Workspace Name</div>
        <input
          required
          data-cy="project-name-input"
          placeholder="The name of your new workspace"
          defaultValue={newProject.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewProject({ ...newProject, name: event.target.value })
          }
        />
      </div>

      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Workspace Templates</div>
        <div className="grid grid-cols-3 sm-grid-cols-1 gap-16px">
          <div
            className={templateClass}
            style={{ backgroundColor: newProject.template === 'None' ? constants.lightBlue : 'white', height: '150px' }}
          >
            <b>No Template</b>
          </div>
          {/* <div
            className={templateClass}
            style={{
              backgroundColor: newProject.template === 'Trimble' ? constants.lightBlue : 'white',
              height: '150px'
            }}
            onClick={() => setNewProject({ ...newProject, template: 'Trimble' })}
          >
            <img src="/assets/images/trimble.png" alt="Trimble Logo" style={{ width: '50%', marginBottom: '20px' }} />
            <p>Create your new workspace powered by Trimble</p>
          </div> */}
        </div>
      </div>

      {newProject.template === 'Trimble' && (
        <>
          <Integration
            provider={IntegrationProviders.VIEWPOINT}
            type={'Documents'}
            buttonLabel={'Create Workspace'}
            buttonFunction={() => alert('Using Template')}
            buttonLoading={false}
          />
        </>
      )}

      {newProject.template === 'None' && (
        <div className="flex justify-end mt-20px">
          <Button data-cy="create-project-button" onClick={() => handleClick()} isLoading={newProject.loading}>
            Create Workspace
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default CreateProjectModal
