import React, { useEffect } from 'react'
import Menu, { MenuProps } from 'components/menu'
import {
  Expand,
  Delete,
  Arrow,
  Copy,
  Comment,
  Paste,
  Sort,
  Group,
  Visible,
  Plus,
  CopyLink,
  Audit
} from 'components/icons'
import { INITIAL_SELECTED_CELL, INITIAL_SELECTED_CELL_RANGE } from 'components/spreadsheet/constants/const'
import { ISelectedCellRange, sortDirections } from 'components/spreadsheet/types'
import { findRowAndIndex, canRearrangeRows, createCellId } from 'components/spreadsheet/helpers/functions'
import { useApplicationStore } from 'hooks/application'
import { ICellValue, ITableRow, ITableViewColumn, ITableViewGroup, ITableViewSort } from 'types'
import { history } from 'helpers/history'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

interface CellMenuProps extends MenuProps {
  onCreateRow: (values: Record<string, ICellValue>, position: number) => void
  onCreateBlankRows: (noRows: number, values?: Record<string, ICellValue>) => void
  uniqueNumber: number
  setExpandModal: (expandModal: boolean, focusInput: boolean, rowId: string, rowNumber: number) => void
  setRowAuditModal: (open: boolean, rowId: string) => void
  setDeleteRowModal: (deleteRowModal: boolean) => void
  setDeleteRowsModal: (deleteRowsModal: boolean) => void
  selectedCellRange: ISelectedCellRange
}

const CellMenu: React.FC<CellMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  onCreateRow,
  onCreateBlankRows,
  uniqueNumber,
  setExpandModal,
  setRowAuditModal,
  setDeleteRowModal,
  setDeleteRowsModal,
  selectedCellRange
}) => {
  const { spreadsheetData, setSpreadsheetData, setSelectedCell, setSelectedCellRange } = useDataContext()

  useEffect(() => {
    if (!spreadsheetData.loading && !spreadsheetData.streaming) {
      const currentUrlParams = new URLSearchParams(window.location.search)
      const expandedRowId = currentUrlParams.get('row')
      if (expandedRowId) {
        const foundRowIndex = spreadsheetData.rows.findIndex((row: ITableRow) => row.publicId === expandedRowId)
        if (foundRowIndex !== -1) {
          setExpandModal!(true, false, expandedRowId, foundRowIndex)
        } else {
          const currentUrlParams = new URLSearchParams(window.location.search)
          currentUrlParams.delete('row')
          const newUrl = window.location.pathname + '?' + currentUrlParams.toString()
          history.replace(newUrl)
        }
      }
    }
  }, [spreadsheetData.loading, spreadsheetData.streaming])

  const selectedCellRangeActive = selectedCellRange.endRowIndex !== -1 && selectedCellRange.endColumnIndex !== -1

  const { setSnackbarMessage } = useApplicationStore()

  // If we're in grouped mode, the rowNumber doesn't correspond to the row's position
  // in the spreadsheetData.rows[] array, so we manually search the rows array for
  // the row's position.
  // If we're inserting a new row in a group we also need to know the values for the
  // columns that are being grouped, so when we create the row it's placed in the
  // correct group.
  const getRowIndexAndValues = (useColumnName?: boolean) => {
    if (spreadsheetData.userConfiguration.groupSettings.length > 0 && menuState.rowId !== undefined) {
      const { row, index } = findRowAndIndex(menuState.rowId, spreadsheetData.rows)!
      const values: Record<string, ICellValue> = {}
      for (const group of spreadsheetData.userConfiguration.groupSettings) {
        values[useColumnName ? group.columnName : group.columnId] = row.rowData[group.columnId]
      }

      return { values, index }
    } else {
      return { values: {}, index: menuState.rowNumber }
    }
  }

  const handleInsertRowAbove = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.preventDefault()
    const { values, index } = getRowIndexAndValues()
    if (index !== undefined) {
      onCreateRow(values, index)
    }
  }

  const handleInsertRowBelow = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.preventDefault()
    const { values, index } = getRowIndexAndValues()
    if (index !== undefined) {
      onCreateRow(values, index + 1)
    }
  }

  const handleBulkInsertRows = (evt: React.MouseEvent<HTMLDivElement>, numberRows: number) => {
    evt.preventDefault()
    const groupSettings: ITableViewGroup[] =
      spreadsheetData.userConfiguration.groupSettings.length > 0
        ? spreadsheetData.userConfiguration.groupSettings
        : spreadsheetData.viewDetails.groupSettings
    if (groupSettings.length > 0 && menuState.rowId !== undefined) {
      const { values, index } = getRowIndexAndValues(true)
      onCreateBlankRows(numberRows, values)
    } else {
      onCreateBlankRows(numberRows)
    }
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
  }

  const groupHasReadOnlyColumns = () => {
    const groupSettings: ITableViewGroup[] =
      spreadsheetData.userConfiguration.groupSettings.length > 0
        ? spreadsheetData.userConfiguration.groupSettings
        : spreadsheetData.viewDetails.groupSettings

    if (groupSettings) return false

    const goupColumnIds = (groupSettings as ITableViewGroup[]).map((group) => group.columnId)

    return spreadsheetData.viewDetails.columns
      .map((column) => goupColumnIds.includes(column.publicId) && (column.isJoined || column.scriptEnabled))
      .some((readOnly) => readOnly === true)
  }

  const cellId =
    menuState.columnId === undefined
      ? `numbercell/${menuState.rowId}`
      : createCellId(
          menuState.rowId !== undefined ? menuState.rowId : 'undefined',
          menuState.columnId !== undefined ? menuState.columnId : 'undefined',
          uniqueNumber
        )

  const column = spreadsheetData.viewDetails.columns.find(
    (column: ITableViewColumn) => column.publicId === menuState.columnId
  )

  const columnSorted =
    column &&
    spreadsheetData.userConfiguration.sortSettings.find(
      (sortSetting: ITableViewSort) => sortSetting.columnId === column.publicId
    )

  const allowInsert = spreadsheetData.isContributor && canRearrangeRows(spreadsheetData) && !selectedCellRangeActive
  const allowBulkInsert = spreadsheetData.isContributor && !selectedCellRangeActive && !groupHasReadOnlyColumns()

  const isGroupingEnabled = spreadsheetData.userConfiguration.groupSettings.length > 0

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div
        style={{ listStyle: 'none', padding: '0px', margin: '10px' }}
        onMouseDown={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        {!selectedCellRangeActive && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              onClick={() => {
                if (menuState.rowId !== undefined && menuState.rowId !== '' && menuState.rowNumber !== undefined) {
                  const currentUrlParams = new URLSearchParams(window.location.search)
                  currentUrlParams.set('row', menuState.rowId)
                  const newUrl = window.location.pathname + '?' + currentUrlParams.toString()
                  window.history.replaceState({}, '', newUrl)
                  setExpandModal!(true, false, menuState.rowId, menuState.rowNumber)
                }
                setMenuState(INITIAL_CONTEXT_MENU_STATE)
              }}
            >
              <Expand />
              <span className="ml-10px">Expand Row</span>
            </div>
          </div>
        )}
        {!selectedCellRangeActive && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              onClick={() => {
                if (menuState.rowId !== undefined) {
                  setRowAuditModal(true, menuState.rowId)
                }
              }}
            >
              <Audit />
              <span className="ml-10px">View Row Audit Trail</span>
            </div>
          </div>
        )}
        {!selectedCellRangeActive && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              onClick={() => {
                const params = new URLSearchParams(window.location.search)
                if (menuState.rowId !== undefined) {
                  params.set('row', menuState.rowId)
                }

                if (spreadsheetData.processSectionId) {
                  params.set('section', spreadsheetData.processSectionId)
                }

                const newUrl = window.location.origin + window.location.pathname + '?' + params.toString()
                navigator.clipboard.writeText(newUrl)
                setSnackbarMessage({ status: 'success', message: 'Row link succesfully copied.' })
                setMenuState(INITIAL_CONTEXT_MENU_STATE)
              }}
            >
              <CopyLink />
              <span className="ml-10px">Copy Row URL</span>
            </div>
          </div>
        )}
        {!selectedCellRangeActive && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              onClick={() => {
                if (menuState.rowId !== undefined && menuState.rowId !== '' && menuState.rowNumber !== undefined) {
                  const currentUrlParams = new URLSearchParams(window.location.search)
                  currentUrlParams.set('row', menuState.rowId)
                  const newUrl = window.location.pathname + '?' + currentUrlParams.toString()
                  window.history.replaceState({}, '', newUrl)
                  setExpandModal(true, true, menuState.rowId, menuState.rowNumber)
                }
                setSelectedCell(INITIAL_SELECTED_CELL)
                setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
                setMenuState(INITIAL_CONTEXT_MENU_STATE)
              }}
            >
              <Comment />
              <span className="ml-10px">Add Comment</span>
            </div>
          </div>
        )}

        <div
          style={{ cursor: !allowInsert ? 'not-allowed' : 'default' }}
          title={!allowInsert ? 'Make sure view is saved with no sorts or filters to enable insert.' : ''}
        >
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px',
              pointerEvents: !allowInsert ? 'none' : 'all'
            }}
            onClick={(event) => {
              if (!allowInsert) return
              handleInsertRowAbove(event)
            }}
          >
            <Arrow type="up" />
            <span className="ml-10px">Insert Row Above</span>
          </div>
        </div>

        <div
          style={{ cursor: !allowInsert ? 'not-allowed' : 'default' }}
          title={!allowInsert ? 'Make sure view is saved with no sorts or filters to enable insert.' : ''}
        >
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px',
              pointerEvents: !allowInsert ? 'none' : 'all'
            }}
            onClick={(event) => {
              if (!allowInsert) return
              handleInsertRowBelow(event)
            }}
          >
            <Arrow type="down" />
            <span className="ml-10px">Insert Row Below</span>
          </div>
        </div>

        {[10, 25, 100].map((value: number) => {
          return (
            <div
              key={value}
              style={{ cursor: !allowBulkInsert ? 'not-allowed' : 'default' }}
              title={
                !allowBulkInsert
                  ? 'Adding a rows in a group is disabled when groups contain read only colums e.g. script or joined columns'
                  : ''
              }
            >
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px',
                  pointerEvents: !allowBulkInsert ? 'none' : 'all'
                }}
                onClick={(event) => {
                  if (!allowBulkInsert) return
                  handleBulkInsertRows(event, value)
                }}
              >
                <Plus />
                <span className="ml-10px">Add {value} Rows To End</span>
              </div>
            </div>
          )
        })}

        {(spreadsheetData.isAdmin ||
          (spreadsheetData.isContributor && spreadsheetData.viewDetails.allowContributorDelete)) && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onMouseDown={(event) => event.stopPropagation()}
              onClick={() => {
                if (!selectedCellRangeActive) {
                  setDeleteRowModal(true)
                } else {
                  if (isGroupingEnabled) {
                    setSnackbarMessage({
                      status: 'error',
                      message: 'Cannot delete multiple rows in grouped mode.'
                    })
                  } else {
                    setDeleteRowsModal(true)
                  }
                }
              }}
            >
              <Delete />
              <span className="ml-10px">Delete Row{selectedCellRangeActive ? 's' : ''}</span>
            </div>
          </div>
        )}

        {!selectedCellRangeActive && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `table/${spreadsheetData.tableDetails.publicId}/${menuState.columnId}/${menuState.rowId}`
                )
                setSnackbarMessage({ status: 'success', message: 'Cell ID succesfully copied.' })
                setMenuState({ ...menuState, open: false })
              }}
            >
              <Copy />
              <span className="ml-10px">Copy Cell ID</span>
            </div>
          </div>
        )}
        <div>
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px'
            }}
            onClick={() => {
              document.execCommand('copy')
              setMenuState({ ...menuState, open: false })
            }}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <Copy />
            <span className="ml-10px">Copy Values</span>
          </div>
        </div>
        <div>
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px'
            }}
            onClick={() => {
              // Fire manually a Clipboard event paste
              const pasteEvent = new ClipboardEvent('paste', {
                bubbles: true,
                cancelable: true
              })
              document.getElementById(`${cellId}`)?.dispatchEvent(pasteEvent)
              setMenuState({ ...menuState, open: false })
            }}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <Paste />
            <span className="ml-10px">Paste Values</span>
          </div>
        </div>

        {columnSorted && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                if (column) {
                  setSpreadsheetData({ type: 'DELETE_SORT', columnId: column.publicId })
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }
              }}
            >
              <Delete />
              <span className="ml-10px">Remove Sort</span>
            </div>
          </div>
        )}

        {!selectedCellRangeActive &&
          (!columnSorted || (columnSorted && columnSorted.direction === sortDirections.desc)) && (
            <div>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (column) {
                    if (columnSorted) {
                      setSpreadsheetData({ type: 'DELETE_SORT', columnId: column.publicId })
                    }
                    setSpreadsheetData({ type: 'ADD_SORT', columnId: column.publicId, columnName: column.name })
                    setMenuState(INITIAL_CONTEXT_MENU_STATE)
                  }
                }}
              >
                <Sort />
                <span className="ml-10px">Sort Ascending</span>
              </div>
            </div>
          )}

        {!selectedCellRangeActive &&
          (!columnSorted || (columnSorted && columnSorted.direction === sortDirections.asc)) && (
            <div>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (column) {
                    if (columnSorted) {
                      setSpreadsheetData({ type: 'DELETE_SORT', columnId: column.publicId })
                    }
                    setSpreadsheetData({ type: 'ADD_SORT', columnId: column.publicId, columnName: column.name })
                    setSpreadsheetData({
                      type: 'CHANGE_SORT_DIRECTION',
                      columnId: column.publicId,
                      newDirection: sortDirections.desc
                    })
                    setMenuState(INITIAL_CONTEXT_MENU_STATE)
                  }
                }}
              >
                <Sort />
                <span className="ml-10px">Sort Descending</span>
              </div>
            </div>
          )}

        {!selectedCellRangeActive && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                if (column) {
                  setSpreadsheetData({ type: 'ADD_GROUP', columnId: column.publicId, columnName: column.name })
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }
              }}
            >
              <Group />
              <span className="ml-10px">Group Column</span>
            </div>
          </div>
        )}

        {!selectedCellRangeActive && (
          <div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px'
              }}
              onClick={() => {
                if (column) {
                  setSpreadsheetData({ type: 'HIDE_COLUMN', columnId: column.publicId })
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }
              }}
            >
              <Visible />
              <span className="ml-10px">Hide Column</span>
            </div>
          </div>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(CellMenu)
