import React from 'react'
import { ITableColumn, IColourItem } from 'types'
import { Cross, Move } from 'components/icons'
import { isValidFilterTypeForColType } from 'components/spreadsheet/helpers/filtering'
import { IFilterType } from 'components/spreadsheet/types'
import { FilterTypesToDescription } from 'components/spreadsheet/constants/const'
import FilterInput from 'components/spreadsheet/components/menu/views/filter/FilterInput'
import { CompactPicker } from 'react-color'
import Select from 'components/select'

interface ColourItemProps {
  colours: IColourItem[]
  columns: ITableColumn[]
  onDelete: (indexes: number[]) => void
  onChange: (colours: IColourItem[]) => void
  handleOrderChange: (position1: number, position2: number) => void
}

const ColourItem: React.FC<ColourItemProps> = ({ colours, columns, onDelete, onChange, handleOrderChange }) => {
  const colour = colours[0].colour
  const lowestIndex = colours.map((colour) => colour.index).sort((a, b) => a! - b!)[0]

  const column = columns.find((c) => c.publicId === colour.columnId)!

  const columnOptions = columns.map((column) => ({
    label: column.name,
    value: column.publicId
  }))

  const typesOptions = React.useMemo(() => {
    return Object.values(IFilterType)
      .reduce((acc, type) => {
        acc.push({
          value: type,
          label: FilterTypesToDescription[type]
        })
        return acc
      }, [] as { label: string; value: IFilterType }[])
      .filter((option) => {
        return column && isValidFilterTypeForColType(column.kind, option.value)
      })
  }, [])

  const handleDelete = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.preventDefault()
    const indexes = colours.map((colour) => colour.index).filter((index) => index !== undefined) as number[]
    indexes.sort((a, b) => a! - b!)
    indexes.reverse()
    onDelete(indexes)
  }

  const handleColumnChange = (columnId: string) => {
    const newColumn = columns.find((c) => c.publicId === columnId)!

    const found = colours.find((colour) => colour.colour.columnId === columnId)
    if (found) {
      if (found.index !== undefined) onDelete([found.index])
      return
    }

    const newColour = {
      columnId: columnId,
      columnName: newColumn.name,
      filterType: colour.filterType,
      value: colour.value,
      multipleValues: colour.multipleValues,
      backgroundColour: colour.backgroundColour,
      fontColour: colour.fontColour
    }

    // if the newly choosen column kind is not supported by the existing operator
    // then change it
    if (!isValidFilterTypeForColType(newColumn.kind, colour.filterType)) {
      colour.filterType = IFilterType.eq
    }

    onChange([{ colour: newColour }])
  }

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const id = event.currentTarget.id

    event.dataTransfer.setData('lowestIndex', id.split('/')[1])
    event.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.dropEffect = 'move'
  }

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const draggedColourNumber = Number(event.dataTransfer.getData('lowestIndex'))
    const currentIndexOfDroppedColour = Number(event.currentTarget.id.split('/')[1])

    if (draggedColourNumber !== undefined && currentIndexOfDroppedColour !== undefined) {
      handleOrderChange(draggedColourNumber, currentIndexOfDroppedColour)
    }
  }

  return (
    <div
      className="flex items-center"
      style={{ padding: '10px 10px 0 20px' }}
      id={`colouritem/${lowestIndex}`}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleOnDrop}
      onDragEnter={handleDragEnter}
      draggable={true}
    >
      <div className="colour-controls">
        <div
          className="flex items-center cursor-pointer text-secondary"
          style={{ marginRight: '10px' }}
          onClick={handleDelete}
        >
          <Cross />
        </div>
      </div>
      <div style={{ minWidth: '200px', marginRight: '5px' }}>
        <Select
          options={columnOptions}
          optionsSelected={colours.map((colour) => colour.colour.columnId)}
          onOptionClick={(option) => handleColumnChange(option)}
          multiselect={true}
        />
      </div>
      <div style={{ minWidth: '200px', marginRight: '5px' }}>
        <Select
          options={typesOptions}
          optionsSelected={colour.filterType ? [colour.filterType] : []}
          onOptionClick={(option) =>
            onChange(
              colours.map((colourItem) => {
                return { ...colourItem, colour: { ...colourItem.colour, filterType: option as IFilterType } }
              })
            )
          }
        />
      </div>
      <div style={{ width: '300px' }}>
        <FilterInput
          columns={columns}
          columnId={colour.columnId}
          value={colour.value}
          filterType={colour.filterType}
          multipleValues={
            colour.multipleValues
              ? colour.multipleValues.map((value) => (value !== undefined && value !== null ? value?.toString() : ''))
              : []
          }
          onChange={(value: string) =>
            onChange(
              colours.map((colourItem) => {
                return { ...colourItem, colour: { ...colourItem.colour, value: value } }
              })
            )
          }
          onChangeMultipleValues={(options: string[]) =>
            onChange(
              colours.map((colourItem) => {
                return { ...colourItem, colour: { ...colourItem.colour, multipleValues: options } }
              })
            )
          }
        />
      </div>
      <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
        <CompactPicker
          color={colour.backgroundColour}
          onChange={(value: any) =>
            onChange(
              colours.map((colourItem) => {
                return { colour: { ...colourItem.colour, backgroundColour: value.hex }, index: colourItem.index }
              })
            )
          }
        />
      </div>

      <div className="flex items-center cursor-grab text-secondary" style={{ marginRight: '10px' }}>
        <Move />
      </div>
    </div>
  )
}

export default React.memo(ColourItem)
