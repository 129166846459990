import {
  ITableRow,
  ITableViewWithColumns,
  ITableViewFilter,
  ITableViewColour,
  ITableViewGroup,
  ITableViewSort,
  ITableColumn,
  ITableViewColumn,
  ICommentThreadStats,
  ITag,
  EditorContent,
  ICellValue,
  ITableJoin,
  ITable,
  IContextProperties,
  IContextMenuState,
  ITableViewChart
} from 'types'
import { ITableViewHistory } from './contexts/data/actions'

export interface ITableDetails {
  publicId: string
  name: string
  type: string | null
  permissionLevel: number
  logo: string | null
  permissions: string[][]
  variables: Array<string>
  columns: ITableColumn[]
  defaultViewId: string
  joins: ITableJoin[]
  keepValidationsInSync: boolean
  keepColoursInSync: boolean
  allowDuplication: boolean
  isDeleted: boolean
  deletedAt: string | null
  isSynced: boolean
  lastSync?: string
  syncHourlyFrequency: number
  isViewpointSynced: boolean
  isViewpointRfisSynced: boolean
  isAutodeskBim360Synced: boolean
  isProcoreSynced: boolean
  isAconexSynced: boolean
  isAconexWorkflowsSynced: boolean
  isAsiteDocumentsSynced: boolean
  isMortaProjectsSynced: boolean
  isMortaUsersSynced: boolean
  isMortaResourcesSynced: boolean
  isMortaCommentsSynced: boolean
  isReviztoIssuesSynced: boolean
}

export interface IUserConfiguration {
  filterSettings: ITableViewFilter[]
  groupSettings: ITableViewGroup[]
  colourSettings: ITableViewColour[]
  hiddenColumns: string[]
  sortSettings: ITableViewSort[]
  chartSettings: ITableViewChart | null
  unsavedChanges: boolean
}

export interface ISpreadsheetData {
  tableDetails: ITableDetails
  viewDetails: ITableViewWithColumns
  userConfiguration: IUserConfiguration
  processId?: string
  processSectionId?: string
  processResponseId?: string
  tableView: ITableViewWithColumns[]
  activeTableView: string
  comments: ICommentThreadStats[]
  rows: ITableRow[]
  filteredRows: ITableRow[] // rows which have been filtered out
  totalRows: number // the total rows we've downloaded so far, this starts incrementing when we stream in rows
  totalRowsOnServer: number // fetched when we get the table/view
  loading: boolean
  refreshing: boolean
  error: boolean
  errorMessage: string
  columnWidths: {
    frozenWidth: number
    unfrozenWidth: number
  }
  isAdmin: boolean
  isContributor: boolean
  collapsedGroupIds: Set<string>
  collapsedColumnIds: Set<string>
  streaming: boolean
  originalViewColumns: string[]
  processTags: ITag[]
  processVariables: string[]
  processVariableValues: string[]
  searching: boolean
  searchTerm: string
  searchFound: number
  selectedSearchCell: { rowId: string | null; columnId: string | null; rowNumber: number; columnNumber: number }
  columnValuesCount: IColumnValuesCount
  isPollingEnabled: boolean
  history: ITableViewHistory[]
  historyPoint: number
  errorStatusCode?: number
}

export enum IFilterType {
  contains = 'contains',
  not_contains = 'not_contains',
  starts_with = 'starts_with',
  ends_with = 'ends_with',
  eq = 'eq',
  neq = 'neq',
  in = 'in',
  gte = 'gte',
  lte = 'lte',
  gt = 'gt',
  lt = 'lt',
  one_of = 'one_of',
  not_one_of = 'not_one_of',
  is_null = 'is_null',
  is_not_null = 'is_not_null'
}

export enum IAggregateTypes {
  'None' = 0,
  '# Rows' = 1,
  'Empty' = 2,
  'Filled' = 3,
  'Unique' = 4,
  '% Empty' = 5,
  '% Filled' = 6,
  '% Unique' = 7,
  'Sum' = 8,
  'Average' = 9,
  'Min' = 10,
  'Max' = 11,
  'Range' = 12,
  'Earliest Date' = 13,
  'Latest Date' = 14,
  'Date Range (Days)' = 15
}

export enum sortDirections {
  asc = 'asc',
  desc = 'desc'
}

export enum rowHeights {
  'Small' = 0,
  'Medium' = 1,
  'Tall' = 2,
  'Extra Tall' = 3
}

export type IColumnTypes =
  | 'text'
  | 'integer'
  | 'float'
  | 'percentage'
  | 'date'
  | 'datetime'
  | 'created'
  | 'modified'
  | 'createdby'
  | 'modifiedby'
  | 'select'
  | 'multiselect'
  | 'link'
  | 'multilink'
  | 'tag'
  | 'variable'
  | 'attachment'
  | 'phone'
  | 'email'
  | 'vote'
  | 'checkbox'
  | 'duration'
  | 'autonumber'

export interface TableSortObj {
  columnId: string
  columnName: string
  direction: sortDirections
}

export type TableSort = TableSortObj[]

export interface TableGroupObj {
  columnId: string
  columnName: string
  direction: sortDirections
}

export type TableGroup = TableGroupObj[]

export type TableHidden = string[]

export type SpreadsheetReducerActions =
  | { type: 'CHANGE_COLUMN_WIDTH'; columnId: string; columnWidth: number }
  | { type: 'CHANGE_COLUMN_AGGREGATE'; columnId: string; aggregate: number }
  | { type: 'SET_SPREADSHEET_DATA'; spreadsheetData: ISpreadsheetData }
  | { type: 'SET_SPREADSHEET_ROWS'; rows: ITableRow[]; totalRows: number; filteredRows?: ITableRow[] }
  | { type: 'ADD_COLUMN'; column: ITableViewColumn }
  | { type: 'UPDATE_COLUMN'; column: ITableViewColumn }
  | { type: 'DELETE_COLUMN'; columnId: string }
  | { type: 'HIDE_COLUMN'; columnId: string }
  | { type: 'SHOW_COLUMN'; columnId: string }
  | { type: 'REQUIRE_COLUMN'; columnId: string }
  | { type: 'UNREQUIRE_COLUMN'; columnId: string }
  | { type: 'LOCK_COLUMN'; columnId: string }
  | { type: 'UNLOCK_COLUMN'; columnId: string }
  | { type: 'HIDE_ALL_COLUMNS' }
  | { type: 'SHOW_ALL_COLUMNS' }
  | { type: 'UNLOCK_ALL_COLUMNS' }
  | { type: 'LOCK_ALL_COLUMNS' }
  | { type: 'REQUIRE_ALL_COLUMNS' }
  | { type: 'UNREQUIRE_ALL_COLUMNS' }
  | { type: 'REFRESH'; refreshing: boolean }
  | { type: 'LOADING'; loading: boolean }
  | { type: 'CHANGE_COLUMN_ORDER'; columnId: string; newIndex: number }
  | { type: 'CHANGE_ROW_ORDER'; rowId: string; newIndex: number; newSortOrder: number }
  | {
      type: 'EDIT_CELL'
      columnId: string
      rowId: string
      value: { [key: string]: ICellValue }
      oldValue: ICellValue
    }
  | { type: 'CHANGE_ROW_HEIGHT'; newRowHeight: number }
  | { type: 'ADD_SORT'; columnId: string; columnName: string }
  | { type: 'DELETE_SORT'; columnId: string }
  | { type: 'CHANGE_SORT_DIRECTION'; columnId: string; newDirection: sortDirections }
  | { type: 'CHANGE_SORT_ORDER'; existingIndex: number; newIndex: number }
  | { type: 'CHANGE_FROZEN_INDEX'; frozenIndex: number }
  | { type: 'CHANGE_DISPLAY_VALIDATION_ROWS'; displayValidationErrorRows: number }
  | { type: 'CHANGE_DISPLAY_COMMENT_ROWS'; displayCommentRows: number }
  | { type: 'CHANGE_COLLAPSED_GROUP_VIEW'; collapsedGroupView: boolean }
  | { type: 'CHANGE_UNPACK_MULTISELECT_GROUP_VIEW'; unpackMultiselectGroupView: boolean }
  | { type: 'ADD_VIEW'; view: ITableViewWithColumns }
  | { type: 'SET_VIEW'; viewId: string; viewDetails?: ITableViewWithColumns }
  | { type: 'ADD_HISTORY'; event: ITableViewHistory }
  | { type: 'UPDATE_HISTORY_POINT'; point: number }
  | {
      type: 'UPDATE_VIEW'
      field:
        | 'name'
        | 'description'
        | 'type'
        | 'disableNewRow'
        | 'allowContributorDelete'
        | 'displayValidationErrorRows'
        | 'displayCommentRows'
        | 'collapsedGroupView'
        | 'unpackMultiselectGroupView'
      viewId: string
      value: string | boolean | EditorContent | number | null
    }
  | {
      type: 'UPDATE_TABLE'
      field:
        | 'name'
        | 'type'
        | 'logo'
        | 'variables'
        | 'keepValidationsInSync'
        | 'keepColoursInSync'
        | 'allowDuplication'
        | 'isDeleted'
        | 'deletedAt'
        | 'syncHourlyFrequency'
      value: string | string[] | boolean | number | null
    }
  | { type: 'UPDATE_TABLE_JOINS'; table: ITable }
  | { type: 'DELETE_VIEW'; viewId: string }
  | { type: 'SAVE_CURRENT_VIEW' }
  | { type: 'TOGGLE_GROUP_COLLAPSE'; groupId: string }
  | { type: 'ADD_GROUP'; columnId: string; columnName: string }
  | { type: 'ADD_CHART'; chart: ITableViewChart }
  | { type: 'DELETE_GROUP'; columnId: string }
  | { type: 'CHANGE_GROUP_DIRECTION'; columnId: string; newDirection: sortDirections }
  | { type: 'CHANGE_GROUP_ORDER'; existingIndex: number; newIndex: number }
  | { type: 'EXPAND_ALL_GROUPS' }
  | { type: 'COLLAPSE_ALL_GROUPS'; groupIds: string[] }
  | { type: 'UPDATE_FILTERS'; filters: ITableViewFilter[] }
  | { type: 'UPDATE_COLOURS'; colours: ITableViewColour[] }
  | { type: 'APPEND_SPREADSHEET_ROWS'; rows: ITableRow[] }
  | { type: 'STREAMING'; streaming: boolean }
  | { type: 'REFRESH_SPREADSHEET_ROWS'; rows: ITableRow[]; totalRows: number }
  | { type: 'INSERT_ROW'; row: ITableRow; position: number }
  | { type: 'APPEND_ROWS'; rows: Array<ITableRow> }
  | { type: 'DELETE_ROWS'; rowIds: string[] }
  | { type: 'COMMENT_THREADS_CHANGE'; rowId: string; noOpenCommentThreads: number; noResolvedCommentThreads: number }
  | { type: 'TOGGLE_COLUMN_COLLAPSE'; columnId: string; rows: ITableRow[] }
  | { type: 'SEARCH_TABLE'; searchTerm: string }
  | { type: 'SEARCHING' }
  | { type: 'CLEAR_SEARCH' }
  | { type: 'SET_SELECTED_SEARCH_CELL'; rowId: string; columnId: string; columnNumber: number; rowNumber: number }
  | { type: 'SET_COLUMN_VALUES_COUNT'; columnValuesCount: IColumnValuesCount }
  | { type: 'BULK_EDIT_CELL'; cells: ICellUpdateAction[] }

export interface IColumnValuesCount {
  [columnId: string]: { [rowValue: string]: number }
}

export interface ISpreadsheetContextMenuState extends IContextMenuState {
  columnNumber: number
  columnId: string
  rowNumber: number
  rowId: string
}

export type GroupByDictionary = Record<
  string,
  {
    childRows: ITableRow[]
    childGroups: GroupByDictionary | ITableRow[]
    startRowIndex: number
    groupByColumn: ITableColumn
  }
>

// Wrapper when row is part of a group
export interface ITableRowInGroup extends ITableRow {
  start: boolean
  isGrouped: boolean
  groupsToRender: string[]
  groupKeys: string[]
}

export interface IGroupCollapsed {
  isCollapsedRow: boolean
  row: ITableRow
  groupKey: string
  groupKeys: string[]
  groupsToRender: string[]
}

// For addrow within a group
export interface IGroupRowCreate {
  isAddRow: boolean
  lastGroupRow: ITableRow
}

export interface IViewPortColumn {
  column: ITableViewColumn
  position: number
  isGrouped: boolean
  isFrozen: boolean
  isLastFrozenColumn: boolean
  left: number
  originalPosition: number
  isLastGroupedColumn: boolean
}

export interface ISelectedCell {
  currentRowId: string
  currentColumnId: string
  nextRowId: string
  nextColumnId: string
  prevRowId: string
  prevColumnId: string
  startRowId: string
  finalRowId: string
  finalRowNumber: number
  startColumnId: string
  finalColumnId: string
  finalColumnNumber: number
}

export interface IUIFilter {
  cid: string
  filter: ITableViewFilter
}

export interface IResultFieldValidation {
  pass: boolean
  error?: string
}

export interface ISelectOption {
  value: string
  label: string
}

export interface SpreadsheetInProcessContext {
  processId: string
  processSectionId: string
  processResponseId: string
}

export interface IEditCompleteOptions {
  moveToNextCell?: boolean
  saveValue?: boolean
}

export interface ISearchCell {
  columnId: string
  rowId: string
}

export interface ICellUpdateAction {
  columnId: string
  rowId: string
  value: Record<string, ICellValue>
}

export interface ISelectedCellState {
  rowId: string
  columnId: string
  editing: boolean
  value: ICellValue
  rowNumber: number
  columnNumber: number
}

export interface ISelectedCellRange {
  endRowIndex: number
  endColumnIndex: number
}

export interface ISelectedSearchCell {
  rowId: string | null
  columnId: string | null
  rowNumber: number
  columnNumber: number
}

export interface ITableViewCell extends Omit<IContextProperties, 'projectId'> {
  columnId: string
  rowId: string
  value: ICellValue
  oldValue?: ICellValue
}

export interface ITableViewHistoryCell extends Omit<IContextProperties, 'projectId'> {
  columnId: string
  rowId: string
  oldValue: ICellValue
  newValue: ICellValue
}

export interface IEditColumn {
  columnId: string | undefined
  columnNumber: number | undefined
}
