import React, { useState, useMemo } from 'react'
import Chart from 'components/chart'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { getChartData } from 'components/spreadsheet/helpers/api'
import { useApplicationStore } from 'hooks/application'
const SpreadsheetChart: React.FC = () => {
  const { spreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()
  const [data, setData] = useState<{ label: string; value: number; color: string }[]>([])
  const [loading, setLoading] = useState(false)
  // The chart settings are stored in the user configuration if the user has changed them, otherwise they are stored in the view details
  const chartSettings = useMemo(() => {
    const chartSettings =
      spreadsheetData.userConfiguration.chartSettings === null
        ? spreadsheetData.viewDetails.chartSettings === null
          ? { chartType: null, showLegend: true, showValues: false, columnLabelId: '', columnValueId: '' }
          : spreadsheetData.viewDetails.chartSettings
        : spreadsheetData.userConfiguration.chartSettings

    if (chartSettings.chartType !== null && chartSettings.columnLabelId !== '' && chartSettings.columnValueId !== '') {
      setLoading(true)
      getChartData(spreadsheetData.viewDetails.publicId, chartSettings)
        .then((response) => {
          processData(response.data)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error' })
          setLoading(false)
          setData([])
        })
    } else if (data.length > 0) {
      setData([])
    }
    return chartSettings
  }, [spreadsheetData.viewDetails.chartSettings, spreadsheetData.userConfiguration.chartSettings])

  const pallette = [
    '#008ac9',
    '#4189d0',
    '#6288d4',
    '#7f85d6',
    '#9982d7',
    '#b17ed4',
    '#c978d0',
    '#df72c9',
    '#ff7872',
    '#ff815e',
    '#ff8a4e',
    '#ff9140',
    '#ff9831',
    '#ff9f1f',
    '#ffa600'
  ]

  const processData = (data: Record<string, number>) => {
    const palleteSegments = Math.ceil(Object.keys(data).length / pallette.length)
    const newPallete: string[] = [...pallette]

    // if there are more segments than colours, generate more colours
    for (let i = 1; i < palleteSegments; i++) {
      const newPallet = pallette.map((color) => {
        const r = parseInt(color.slice(-6), 16) - Math.pow(16, 6)
        const newColour = '#' + ('0'.repeat(6) + (Math.pow(16, 6) + r + 25 * i).toString(16)).slice(-6)
        return newColour
      })
      newPallete.push(...newPallet)
    }
    const labels = Object.keys(data)

    const newData = labels.map((label, index) => {
      return {
        label,
        value: data[label],
        color: newPallete[index]
      }
    })
    setData(newData)
    setLoading(false)
  }

  if (!chartSettings) {
    return null
  }
  const xAxisName =
    spreadsheetData.viewDetails.columns.find((column) => column.publicId === chartSettings.columnLabelId)?.name || ''
  const yAxisName =
    spreadsheetData.viewDetails.columns.find((column) => column.publicId === chartSettings.columnValueId)?.name || ''

  return (
    <div
      className="relative bg-white rounded shadow border-box"
      style={{ width: '90%', height: 'calc(100% - 100px)', margin: '40px auto', padding: '40px' }}
    >
      <Chart
        loading={loading}
        type={chartSettings.chartType}
        data={data}
        showLegend={chartSettings.showLegend}
        showValues={chartSettings.showValues}
        axisNames={{ x: xAxisName, y: yAxisName }}
      />
    </div>
  )
}

export default SpreadsheetChart
