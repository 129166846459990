import React, { useRef, useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { ICommentThreadObject, ITableViewColumn } from 'types'
import Cell from 'components/spreadsheet/components/cell'
import Header from 'components/spreadsheet/components/header'
import constants from 'style/constants.module.scss'
import { IUpdateTableViewCell } from 'components/spreadsheet/contexts/data/actions'
import Editor from 'components/editor'
import CommentThreads from 'components/comments/commentThreads'
import { PERMISSIONS } from 'helpers/auth'
import { rowHeights, ISelectedCellState } from 'components/spreadsheet/types'
import { useDataContext } from 'components/spreadsheet/contexts/data'

interface ExpandedRowProps extends ModalProps {
  focusInput: boolean
  selectedCell: ISelectedCellState
  setSelectedCell: (selectedCell: ISelectedCellState) => void
  setCellValue: (cells: IUpdateTableViewCell, onSuccess: () => void, onError: () => void) => void
  initialRowId: string | undefined
  initialRowNumber: number | undefined
}

const ExpandedRow: React.FC<ExpandedRowProps> = ({
  id,
  open,
  setOpen,
  setCellValue,
  focusInput,
  selectedCell,
  setSelectedCell,
  onClose,
  initialRowId,
  initialRowNumber
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const [rowId, setRowId] = useState<string>()
  const [rowNumber, setRowNumber] = useState<number>()

  useEffect(() => {
    if (focusInput === true) inputRef.current?.focus()
  }, [])

  useEffect(() => {
    setRowId(initialRowId)
    setRowNumber(initialRowNumber)
  }, [])

  const handleCommentThreadsChange = (value: ICommentThreadObject[]) => {
    if (rowId) {
      const noResolvedCommentThreads = value.filter((comment: ICommentThreadObject) => comment.resolvedAt !== null)
        .length
      const noOpenCommentThreads =
        value.filter((comment: ICommentThreadObject) => comment.resolvedAt === null).length + noResolvedCommentThreads

      setSpreadsheetData({
        type: 'COMMENT_THREADS_CHANGE',
        rowId: rowId,
        noOpenCommentThreads,
        noResolvedCommentThreads
      })
    }
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      title={rowNumber !== undefined ? `Row #${rowNumber + 1}` : ''}
    >
      <div className="flex overflow-hidden">
        <div className="flex flex-column overflow-auto" style={{ flex: '3 1' }}>
          {spreadsheetData.viewDetails.columns
            .filter(
              (column) => !spreadsheetData.userConfiguration.hiddenColumns.find((hidden) => hidden === column.publicId)
            )
            .map((column: ITableViewColumn, columnNumber: number) => {
              const row = spreadsheetData.rows.find((row) => row.publicId === rowId)
              const value = row ? row.rowData[column.publicId] : null
              const selected = selectedCell.columnId === column.publicId && selectedCell.rowId === rowId
              return (
                <div
                  key={columnNumber}
                  className="flex flex-column bg-really-light-grey border-1px border-solid border-grey"
                  style={{ marginBottom: '20px', padding: '10px' }}
                >
                  <Header
                    columnNumber={columnNumber}
                    columnId={column.publicId}
                    value={column.name}
                    kind={column.kind}
                    backgroundColor={constants.reallyLightGrey}
                    border={`0px`}
                    frozen={false}
                    isLastFrozenColumn={false}
                    width={column.width}
                  />
                  {column.description && (
                    <div
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        lineHeight: 1.5
                      }}
                    >
                      <Editor
                        databaseDoc={column.description}
                        readOnly={true}
                        editorId={spreadsheetData.tableDetails.publicId}
                        border={false}
                      />
                    </div>
                  )}
                  <Cell
                    kind={column.kind}
                    scriptEnabled={column.scriptEnabled}
                    isJoined={column.isJoined}
                    value={value}
                    frozen={false}
                    rowHeight={rowHeights.Small}
                    isContributor={spreadsheetData.isContributor}
                    isAdmin={spreadsheetData.isAdmin}
                    uniqueNumber={Math.random()}
                    isExpanded={true}
                    spreadsheetData={spreadsheetData}
                    setCellValue={(cells, onSuccess, onError) => {
                      if (setCellValue && setSpreadsheetData) {
                        cells.cells.forEach((cell) => {
                          if (cell.oldValue !== undefined) {
                            setSpreadsheetData({
                              type: 'EDIT_CELL',
                              columnId: cell.columnId,
                              rowId: cell.rowId,
                              value: { [cell.columnId]: cell.value as string },
                              oldValue: cell.oldValue
                            })
                          }
                        })

                        setCellValue(cells, onSuccess, onError)
                      }
                    }}
                    columnName={column.name}
                    columnNumber={columnNumber}
                    columnId={column.publicId}
                    rowId={rowId}
                    rowNumber={rowNumber}
                    setSelectedCell={setSelectedCell}
                    selected={selected}
                    editing={selected && selectedCell.editing}
                    tempValue={selected ? selectedCell.value : undefined}
                    locked={column.locked || column.isJoined}
                  />
                </div>
              )
            })}
        </div>

        <div id="row-comments" style={{ flex: '2 1' }}>
          {rowId && (
            <CommentThreads
              referenceType={'table'}
              referenceId={rowId}
              selected={focusInput}
              mainReferenceId={spreadsheetData.tableDetails.publicId}
              permissionLevel={spreadsheetData.isContributor ? PERMISSIONS.contributor : PERMISSIONS.viewer}
              onOpenCommentTreadsChange={() => {
                return null
              }}
              onCommentThreadsChange={(value) => handleCommentThreadsChange(value)}
              spreadsheetData={spreadsheetData}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ExpandedRow
