import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { IContextMenuState } from 'types'
import constants from 'style/constants.module.scss'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { KEY_PRESS } from 'components/spreadsheet/constants/const'

export interface MenuProps {
  id: string
  menuState: IContextMenuState
  setMenuState: (menuState: IContextMenuState) => void
  width?: number
  children?: React.ReactNode
  zIndex?: number
  onClose?: () => void
  rootComponent?: HTMLElement | null
}

const Menu: React.FC<MenuProps> = ({
  id,
  menuState,
  setMenuState,
  children,
  width,
  zIndex,
  onClose,
  rootComponent
}) => {
  const appRootElement = document.getElementById('root')
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const componentWidth = width !== undefined ? width : Number(constants.defaultMenuWidth)
  const [componentHeight, setComponentHeight] = useState<number>()

  const handleMenuClose = () => {
    if (onClose) onClose()
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
  }

  const handleMouseClick = (event: MouseEvent) => {
    if (menuState.open && contextMenuRef.current && !event.defaultPrevented) {
      const found = event.composedPath().find((item: EventTarget) => {
        const element = item as HTMLElement
        return element === contextMenuRef.current || (element.id && element.id.includes('select-options'))
      })

      const yClick = event.clientY
      const xClick = event.clientX
      const menuBox = contextMenuRef.current.getBoundingClientRect()
      if (
        !found &&
        (yClick > menuBox.bottom || yClick < menuBox.top || xClick > menuBox.right || xClick < menuBox.left)
      ) {
        handleMenuClose()
      }
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (menuState.open && event.key === KEY_PRESS.esc) {
      handleMenuClose()
    }
  }

  const handleScroll = (event: Event) => {
    const found = event.composedPath().find((item: EventTarget) => {
      const element = item as HTMLElement

      let isFound = true
      if (element && contextMenuRef.current) {
        isFound =
          element === contextMenuRef.current ||
          (element.id && element.id.startsWith(contextMenuRef.current.id)) ||
          (element.id && element.id.includes('select-options')) ||
          contextMenuRef.current.id.includes('search-context-menu')
      }
      return isFound
    })
    // if the element that triggered the scroll is not the current context menu (contextMenuRef)
    // the we have to close the menu
    if (!found) {
      handleMenuClose()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleMouseClick)
    document.addEventListener('keydown', handleKeyDown)
    if (menuState.open) document.addEventListener('scroll', handleScroll, true)

    return () => {
      document.removeEventListener('click', handleMouseClick)
      document.removeEventListener('keydown', handleKeyDown)
      if (menuState.open) document.removeEventListener('scroll', handleScroll, true)
    }
  }, [menuState.open])

  useEffect(() => {
    if (menuState.open && contextMenuRef.current) {
      setComponentHeight(contextMenuRef.current.getBoundingClientRect().height)
    }
  }, [menuState.open])

  const validatedLeft = menuState.left.includes('px')
    ? `${Math.min(window.innerWidth - componentWidth - 10, Math.max(0, Number(menuState.left.replace(/px$/, ''))))}px`
    : menuState.left
  const validatedRight = menuState.right.includes('px')
    ? `${Math.max(componentWidth + 10, Math.min(window.innerWidth, Number(menuState.right.replace(/px$/, ''))))}px`
    : menuState.right
  const validatedTop = menuState.top.includes('px')
    ? `${Math.max(
        0,
        Math.min(
          window.innerHeight - 10 - (componentHeight !== undefined ? componentHeight : 0),
          Number(menuState.top.replace(/px$/, ''))
        )
      )}px`
    : menuState.top

  const validatedBottom = menuState.bottom.includes('px')
    ? `${Math.min(
        window.innerHeight,
        Math.max(componentHeight !== undefined ? componentHeight : 0, Number(menuState.bottom.replace(/px$/, '')))
      )}`
    : menuState.bottom

  if ((rootComponent || appRootElement) && menuState.open) {
    return ReactDOM.createPortal(
      <div
        id={id}
        ref={contextMenuRef}
        className="absolute bg-white rounded border-1 border-solid border-grey select-none h-fit whitespace-nowrap text-sm"
        style={{
          top: validatedTop,
          right: validatedRight,
          bottom: validatedBottom,
          left: validatedLeft,
          width: `${componentWidth}px`,
          zIndex: zIndex !== undefined ? zIndex : 5
        }}
      >
        {children}
      </div>,
      rootComponent || appRootElement!
    )
  } else {
    return null
  }
}

export default React.memo(Menu)
