import React, { useEffect, useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface ReviztoIntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedLicense: string
  selectedProject: string
  setSelectedLicense: (enterprise: string) => void
  setSelectedProject: (project: string) => void
}

const ReviztoIntegration: React.FC<ReviztoIntegrationProps> = ({
  selectedLicense,
  selectedProject,
  setSelectedLicense,
  setSelectedProject
}) => {
  const { user } = useAuth()
  const { setSnackbarMessage } = useApplicationStore()

  const [licenses, setLicenses] = useState<ISyncResource[]>()
  const [loadingLicenses, setLoadingLicenses] = useState<boolean>(false)
  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)

  useEffect(() => {
    if (user && user.reviztoConnected) {
      setLoadingLicenses(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: 'https://api.$region$.revizto.com/v5/user/licenses',
          headers: {
            Authorization: `Bearer $token$`
          },
          sourceSystem: IntegrationProviders.REVIZTO
        })
        .then((response) => {
          let licenses: ISyncResource[] = []
          if (response.data.status === '200') {
            const entities = response['data']['body']['data']['entities']
            licenses = entities.map((license: any) => {
              return {
                id: license['uuid'],
                name: license['name']
              }
            })
          }

          setLicenses(licenses)
          setLoadingLicenses(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting Revizto licenses.' })
          setLoadingLicenses(false)
        })
    }
  }, [])

  useEffect(() => {
    if (user && user.reviztoConnected && selectedLicense && selectedLicense !== '') {
      setLoadingProjects(true)
      api
        .integrationPassthrough({
          method: 'GET',
          sourceSystem: IntegrationProviders.REVIZTO,
          path: `https://api.$region$.revizto.com/v5/project/list/${selectedLicense}/paged`,
          headers: {
            Authorization: `Bearer $token$`
          }
        })
        .then((response) => {
          let projects: ISyncResource[] = []
          if (response.data.status === '200') {
            const entities = response['data']['body']['data']['data']
            projects = entities.map((project: any) => {
              return {
                id: project['uuid'],
                name: project['title']
              }
            })
          }

          setProjects(projects)
          setLoadingProjects(false)
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting Revizto projects.' })
          setLoadingProjects(false)
        })
    }
  }, [selectedLicense])

  return (
    <div>
      {user && user.reviztoConnected && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select License<span className="text-red">*</span>
          </div>

          <Select
            options={
              licenses
                ? licenses.map((license) => {
                    return {
                      label: license.name,
                      value: license.id
                    }
                  })
                : []
            }
            onOptionClick={(option) => {
              setSelectedLicense(option)
              setSelectedProject('')
              setProjects(undefined)
            }}
            optionsSelected={[selectedLicense]}
            loading={loadingLicenses}
          />
        </div>
      )}

      {user && user.reviztoConnected && selectedLicense !== '' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Project<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects.map((project) => {
                    return {
                      label: project.name,
                      value: project.id
                    }
                  })
                : []
            }
            onOptionClick={(option) => {
              setSelectedProject(option)
            }}
            optionsSelected={[selectedProject]}
            loading={loadingProjects}
          />
        </div>
      )}
    </div>
  )
}

export default ReviztoIntegration
