import { ITable } from 'types'
import api from 'helpers/api'
import { APIError } from 'helpers/api'
declare global {
  interface Window {
    opera: any
    find: any
  }
}

export function getQueryParameter(name: string) {
  name = name.replace(/[\]]/g, '\\$&')
  const url = window.location.href
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function isValidEmail(email: string) {
  return /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  )
}

export function capitalize(string: string) {
  return string.replace(/\b\w/g, (l) => l.toUpperCase())
}

export function debounce<F extends (...params: any[]) => any>(fn: F, delay: number) {
  let timeoutID: number
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID)
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay)
  } as F
}

export const isMortaUploadedUrl = (url: string): boolean => {
  const re = /^http(s)?:\/\/.*?\/v1\/files\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/gi
  return url.match(re) ? true : false
}

export const capitaliseFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getTable = (tables: ITable[], uid: string) => {
  try {
    for (let i = 0; i < tables.length; i++) {
      const publicId = tables[i].publicId
      if (publicId === uid) return tables[i]
    }
    return undefined
  } catch {
    return undefined
  }
}

export const getProjectTables = async (projectId: string): Promise<ITable[]> => {
  const projectTables = []
  try {
    const projectResources = await api.getProjectResources(projectId, { excludeProcesses: true })

    for (let i = 0; i < projectResources.data.length; i++) {
      const projectResource = projectResources.data[i]
      if (projectResource.table) {
        projectTables.push(projectResource.table)
      }
    }
  } catch (e) {
    let message = 'There was an error getting the project tables.'

    if (e instanceof APIError) {
      const error = e as APIError
      if (error.message) {
        message = error.message
      } else {
        const errorEntry = error.errors![0]
        message = errorEntry.messages[0]
      }
    } else if (typeof e === 'string') {
      message = e
    }

    return Promise.reject(message)
  }

  return projectTables
}

export const uuidv4 = () => {
  let d = new Date().getTime() //Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
