import React, { useEffect, useState } from 'react'
import Button from 'components/button'
import Modal from 'components/modal'
import { Table } from 'components/table'
import api, { APIError } from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import useProject from 'hooks/project'
import { IMemberInvite, ITableWithCells } from 'types'
import InviteUserForm from '../inviteUserForm'
import { mapInvitedMembersToTableData } from './utils'

const InvitedBox: React.FC = () => {
  const [loaded, setLoaded] = useState(false)
  const [invitedMembers, setInvitedMembers] = useState<IMemberInvite[]>([])
  const { setSnackbarMessage } = useApplicationStore()
  const [removeInviteDialog, setRemoveInviteDialog] = useState<{ open: boolean; invite?: IMemberInvite }>({
    open: false
  })
  const { project } = useProject()
  const [tagTablesWithCells, setTagTablesWithCells] = useState<ITableWithCells[]>([])
  const [inviteToUpdate, setInviteToUpdate] = useState<boolean>(false)
  const [selectedInvite, setSelectedInvite] = useState<IMemberInvite | undefined>()

  const fetchInvitedUsers = async () => {
    try {
      const response = await api.getInvitedMembers(project.publicId)
      setInvitedMembers(response.data)
      setLoaded(true)
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({ status: 'error', message: e.message })
      }
      console.error(e)
    }
  }

  const fetchTagTables = async () => {
    try {
      const response = await api.getProjectTags(project.publicId)
      setTagTablesWithCells(response.data)
    } catch (e) {
      setSnackbarMessage({ status: 'error', message: 'There was a problem fetching tags for this project' })
      console.error(e)
    }
  }

  const handleRemoveInvite = (invite: IMemberInvite) => {
    setRemoveInviteDialog({
      open: true,
      invite
    })
  }

  const handleDeleteInvite = async (invite: IMemberInvite) => {
    try {
      await api.deleteProjectInvite(project.publicId, invite.publicId)
      setRemoveInviteDialog({ open: false })
      fetchInvitedUsers()
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem deleting that invite from the project.' })
    }
  }

  const handleUpdateInvite = () => {
    setInviteToUpdate(false)
    setSelectedInvite(undefined)
    fetchInvitedUsers()
    setSnackbarMessage({ status: 'success', message: 'Invite has been updated' })
  }

  const resendInvite = async (invite: IMemberInvite) => {
    try {
      await api.resendProjectInvite(project.publicId, invite.publicId)
      setSnackbarMessage({ status: 'success', message: 'Invite has been resent' })
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem resending that invite.' })
    }
  }

  useEffect(() => {
    fetchInvitedUsers()
    fetchTagTables()
  }, [])

  const tableData = mapInvitedMembersToTableData(
    invitedMembers,
    tagTablesWithCells,
    (invite) => resendInvite(invite),
    handleRemoveInvite,
    (invite) => {
      setInviteToUpdate(true)
      setSelectedInvite(invite)
    }
  )

  return (
    <div className="w-full overflow-y-scroll" style={{ padding: '30px' }}>
      <Table
        data={tableData}
        include={[
          { header: 'User Email', id: 'email' },
          { header: 'Invited By', id: 'invitedBy' },
          { header: 'Invited Date', id: 'date' },
          { header: 'Workspace Role', id: 'projectRole' },
          { header: 'Tags', id: 'tags' },
          { header: '', id: 'controls' }
        ]}
        defaultSort={'email'}
        defaultSortAscending={false}
        sort={true}
        loading={!loaded}
      />

      {removeInviteDialog.open && (
        <Modal
          id="invite-delete-modal"
          open={removeInviteDialog.open}
          setOpen={() => setRemoveInviteDialog({ open: false })}
          title="Confirm Invite Delete"
        >
          Are you sure you want to delete this invite?
          <div className="flex items-center justify-end mt-20px">
            <Button onClick={() => setRemoveInviteDialog({ open: false })}>Keep The Invite</Button>
            <Button
              style={{ marginLeft: '10px' }}
              internalType="danger"
              onClick={() => handleDeleteInvite(removeInviteDialog.invite!)}
              autoFocus
            >
              Delete The Invite
            </Button>
          </div>
        </Modal>
      )}

      {inviteToUpdate && (
        <Modal
          id={'update-invite-modal'}
          open={inviteToUpdate}
          setOpen={setInviteToUpdate}
          title={'Update Invite'}
          onClose={() => setSelectedInvite(undefined)}
        >
          <InviteUserForm handleOnSuccess={handleUpdateInvite} invite={selectedInvite} />
        </Modal>
      )}
    </div>
  )
}

export default InvitedBox
