import React from 'react'
import { IUserObject } from 'types'
import Button from 'components/button'
import { dateTime } from 'helpers/date'
import { USER_SUBSCRIPTION_LEVELS } from 'app-constants'

export const mapUsersToTableData = (
  users: IUserObject[],
  onLogin: (user: IUserObject) => void,
  verifyEmail: (user: IUserObject) => void,
  handleEditUser: (user: IUserObject) => void,
  remove2fa: (user: IUserObject) => void,
  openAuditModal: (user: IUserObject) => void
) => {
  return users.map((user: IUserObject) => {
    return {
      user: {
        label: (
          <div className="flex items-center">
            <img
              src={user && user.profilePicture ? user.profilePicture : '/assets/images/user.png'}
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                objectFit: 'cover',
                marginRight: '10px'
              }}
              alt={`${user && user.name ? user.name : 'Anonymous'} profile picture`}
            />
            <div>{user && user.name ? user.name : 'Anonymous'}</div>
          </div>
        ),
        value: user && user.name ? user.name : 'Anonymous'
      },
      email: {
        label: (
          <div className="text-blue cursor-pointer" onClick={() => openAuditModal(user)}>
            {user.email}
          </div>
        ),
        value: user.email
      },
      createdAt: {
        label: user.createdAt ? user.createdAt.split('T')[0] : '',
        value: user.createdAt ? user.createdAt : ''
      },
      lastLoginAt: {
        label: user.lastLoginAt
          ? dateTime(new Date(Date.parse(user.lastLoginAt))).toLocaleString()
          : user.createdAt
          ? dateTime(new Date(Date.parse(user.createdAt))).toLocaleString()
          : '',
        value: user.lastLoginAt ? user.lastLoginAt : user.createdAt ? user.createdAt : ''
      },
      subscriptionLevel: {
        label: USER_SUBSCRIPTION_LEVELS[user.subscriptionLevel],
        value: user.subscriptionLevel ? user.subscriptionLevel : ''
      },
      invitedBy: { label: user.invitedBy, value: user.invitedBy ? user.invitedBy : '' },
      invitesSent: { label: user.invitesSent, value: user.invitesSent ? user.invitesSent : 0 },
      projectsOn: { label: user.projectsOn, value: user.projectsOn ? user.projectsOn : 0 },
      verified: {
        label: user.isEmailVerified ? (
          'Yes'
        ) : (
          <Button internalType="danger" onClick={() => verifyEmail(user)}>
            Verify
          </Button>
        ),
        value: user.isEmailVerified ? 'Yes' : 'No'
      },
      isSuperAdmin: { label: user.isSuperAdmin ? 'Yes' : 'No', value: user.isSuperAdmin ? 'Yes' : 'No' },
      is2faEnabled: {
        label: user.is2FaEnabled ? (
          <Button internalType="danger" onClick={() => remove2fa(user)}>
            Remove
          </Button>
        ) : (
          'No'
        ),
        value: user.is2FaEnabled ? 'Yes' : 'No'
      },
      edit: {
        label: (
          <Button internalType="danger" onClick={() => handleEditUser(user)}>
            Edit
          </Button>
        ),
        value: ''
      },
      login: {
        label: user.isEmailVerified ? (
          <Button internalType="secondary" onClick={() => onLogin(user)}>
            Login
          </Button>
        ) : (
          <></>
        ),
        value: ''
      }
    }
  })
}
