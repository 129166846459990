import React, { useState, useEffect } from 'react'
import { ITableWithCells, OptionProps } from 'types'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import VariablesSelect from 'components/variables/components/variablesSelect'

interface VariablesProps {
  initialVariables: string[]
  onChange: (arg0: string[]) => any
  hideTags?: boolean
  hideVariables?: string[]
}

const Variables: React.FC<VariablesProps> = ({ initialVariables, onChange, hideTags, hideVariables }) => {
  const [tablesWithVariables, setTableWithVariables] = useState<ITableWithCells[]>()
  const [tablesWithTags, setTablesWithTags] = useState<ITableWithCells[]>()
  const [selectedVariables, setSelectedVariables] = useState<Record<string, OptionProps[]>>({})
  const { project } = useProject()
  const { setSnackbarMessage } = useApplicationStore()

  const tablesToHide = hideVariables ? hideVariables : []

  useEffect(() => {
    if (project.publicId !== '') {
      fetchVariables()
      fetchTags()
    }
  }, [project])

  useEffect(() => {
    if (tablesWithVariables !== undefined && tablesWithTags !== undefined) {
      populateSelectedVariables()
    }
  }, [tablesWithVariables, tablesWithTags])

  const populateSelectedVariables = () => {
    const matchingVariables: Record<string, OptionProps[]> = {}
    if (!tablesWithVariables || !tablesWithTags) {
      return
    }
    for (const table of tablesWithVariables) {
      const tableMatchingVariables: OptionProps[] = matchingVariables[table.publicId]
        ? matchingVariables[table.publicId]
        : []
      for (const cell of table.cells) {
        if (initialVariables && initialVariables.includes(cell.id)) {
          tableMatchingVariables.push({
            value: cell.id,
            label: cell.value,
            group: cell.column.name
          })
        }
        matchingVariables[table.publicId] = tableMatchingVariables
      }
    }
    for (const table of tablesWithTags) {
      const tableMatchingTags: OptionProps[] = matchingVariables[table.publicId]
        ? matchingVariables[table.publicId]
        : []
      for (const cell of table.cells) {
        if (initialVariables && initialVariables.includes(cell.id)) {
          tableMatchingTags.push({
            value: cell.id,
            label: cell.value,
            group: cell.column.name
          })
        }
        matchingVariables[table.publicId] = tableMatchingTags
      }
    }
    setSelectedVariables(matchingVariables)
  }

  const fetchVariables = async () => {
    try {
      const response = await api.getProjectVariables(project.publicId)
      setTableWithVariables(response.data)
    } catch (e) {
      setSnackbarMessage({
        message: 'There was an error fetching variables for this project',
        status: 'error'
      })
      console.error(e)
    }
  }

  const fetchTags = async () => {
    try {
      const response = await api.getProjectTags(project.publicId)
      setTablesWithTags(response.data)
    } catch (e) {
      setSnackbarMessage({
        message: 'There was an error fetching tags for this project',
        status: 'error'
      })
      console.error(e)
    }
  }

  const handleOptionChange = (tableId: string, options: OptionProps[]) => {
    const newSelectedVariables = {
      ...selectedVariables,
      [tableId]: options
    }
    const variableIds: string[] = []
    setSelectedVariables(newSelectedVariables)

    for (const tableId in newSelectedVariables) {
      for (const variable of newSelectedVariables[tableId]) {
        if (variable.value) variableIds.push(variable.value)
      }
    }

    try {
      onChange(variableIds)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      <div className="flex flex-wrap items-center mb-20px">
        {tablesWithVariables &&
          tablesWithVariables.map((table) => {
            const variableOptions: OptionProps[] = table.cells.map((cell) => ({
              label: cell.value,
              value: cell.id,
              group: cell.column.name
            }))
            const selectedVariablesForTable: OptionProps[] = selectedVariables[table.publicId]
              ? selectedVariables[table.publicId]
              : []
            if (tablesToHide.findIndex((variable) => variable.includes(table.publicId)) === -1)
              return (
                <VariablesSelect
                  key={table.publicId}
                  name={table.name}
                  tags={variableOptions}
                  onChange={(option) => {
                    const index = selectedVariablesForTable.findIndex((variable) => variable.value === option)
                    const value = variableOptions.find((variable) => variable.value === option)
                    if (value) {
                      const newOptions = [...selectedVariablesForTable]
                      if (index === -1) {
                        newOptions.push(value)
                      } else {
                        newOptions.splice(index, 1)
                      }
                      handleOptionChange(table.publicId, newOptions)
                    }
                  }}
                  onMultipleChange={(options) => {
                    const newOptions = variableOptions.filter(
                      (variable) => variable.value && options.includes(variable.value)
                    )
                    handleOptionChange(table.publicId, newOptions)
                  }}
                  selectedTags={selectedVariablesForTable.map((option) => (option.value ? option.value : ''))}
                  keyword={'Variables'}
                />
              )
          })}
      </div>
      {!hideTags && (
        <div className="flex flex-wrap items-center mb-20px">
          {tablesWithTags &&
            tablesWithTags.map((table) => {
              const variableOptions: OptionProps[] = table.cells.map((cell) => ({
                label: cell.value,
                value: cell.id,
                group: cell.column.name
              }))
              const selectedVariablesForTable: OptionProps[] = selectedVariables[table.publicId]
                ? selectedVariables[table.publicId]
                : []

              if (tablesToHide.findIndex((variable) => variable.includes(table.publicId)) === -1)
                return (
                  <VariablesSelect
                    key={table.publicId}
                    name={table.name}
                    tags={variableOptions}
                    onChange={(option) => {
                      const index = selectedVariablesForTable.findIndex((variable) => variable.value === option)
                      const value = variableOptions.find((variable) => variable.value === option)
                      if (value) {
                        const newOptions = [...selectedVariablesForTable]
                        if (index === -1) {
                          newOptions.push(value)
                        } else {
                          newOptions.splice(index, 1)
                        }
                        handleOptionChange(table.publicId, newOptions)
                      }
                    }}
                    onMultipleChange={(options) => {
                      const newOptions = variableOptions.filter(
                        (variable) => variable.value && options.includes(variable.value)
                      )
                      handleOptionChange(table.publicId, newOptions)
                    }}
                    selectedTags={selectedVariablesForTable.map((option) => (option.value ? option.value : ''))}
                    keyword={'Tags'}
                  />
                )
            })}
        </div>
      )}
    </div>
  )
}

export default Variables
