import React, { useState } from 'react'
import Button from 'components/button'

export interface Disable2FaValues {
  code: string
}

interface Props {
  onSubmit: (code: string) => void | Promise<void>
}

const TwoFactorDisableForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const [code, setCode] = useState<string>('')
  const codeError = code === ''

  return (
    <>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">2FA Code</div>
        <p style={{ marginBottom: '20px' }}>
          Type in the code from your 2FA device to deactivate 2FA protection for your account.
        </p>
        <input type="text" placeholder="Enter your 2fa code" onChange={(event) => setCode(event.target.value)} />
        {codeError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            The 2FA code cannot be blank
          </p>
        )}
      </div>
      <div className="flex items-center justify-end">
        <Button onClick={() => onSubmit(code)} disabled={codeError}>
          Remove 2FA
        </Button>
      </div>
    </>
  )
}

export default TwoFactorDisableForm
