import React, { useState } from 'react'
import Button from 'components/button'

export interface IFormValues {
  password1: string
  password2: string
}

interface Props {
  onSubmit: (values: IFormValues) => void
}

const PasswordResetForm: React.FC<Props> = ({ onSubmit }) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [values, setValues] = useState<IFormValues>({
    password1: '',
    password2: ''
  })

  const password8CharactersError = !values.password1.match(/.{8,}/)
  const passwordLowercaseError = !values.password1.match(/^(?=.*[a-z])/)
  const passwordUppercaseError = !values.password1.match(/^(?=.*[A-Z])/)
  const passwordNumericError = !values.password1.match(/^(?=.*[0-9])/)
  const passwordSpecialError = !values.password1.match(/^(?=.*[!@#$%^&*])/)
  const confirmPasswordError = values.password1 !== values.password2
  const passwordError =
    password8CharactersError ||
    passwordLowercaseError ||
    passwordUppercaseError ||
    passwordNumericError ||
    passwordSpecialError ||
    confirmPasswordError

  const handleSubmit = async () => {
    setSubmitting(true)
    await onSubmit(values)
    setSubmitting(false)
  }

  return (
    <>
      <input
        placeholder="Your new password"
        type="password"
        onChange={(event) => setValues({ ...values, password1: event.target.value })}
        autoComplete="new-password"
        autoFocus
      />
      <p className={`${password8CharactersError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
        {`${password8CharactersError ? '⤫' : '✓'} Password must be at least 8 characters long`}
      </p>
      <p className={`${passwordLowercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
        {`${passwordLowercaseError ? '⤫' : '✓'} Password must contain at least one lowercase letter`}
      </p>
      <p className={`${passwordUppercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
        {`${passwordUppercaseError ? '⤫' : '✓'} Password must contain at least one uppercase letter`}
      </p>
      <p className={`${passwordNumericError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
        {`${passwordNumericError ? '⤫' : '✓'} Password must contain at least one numeric character`}
      </p>
      <p className={`${passwordSpecialError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
        {`${passwordSpecialError ? '⤫' : '✓'} Password must contain at least one special character: !@#$%^&*`}
      </p>
      <input
        placeholder="Confirm your new password"
        type="password"
        autoComplete="new-password"
        style={{ marginTop: '20px' }}
        onChange={(event) => setValues({ ...values, password2: event.target.value })}
      />
      {confirmPasswordError && (
        <p className="text-red" style={{ marginTop: '10px' }}>
          Your passwords do not match
        </p>
      )}
      <Button
        style={{ marginTop: '20px' }}
        onClick={() => handleSubmit()}
        disabled={passwordError}
        isLoading={submitting}
      >
        Reset Password
      </Button>
    </>
  )
}

export default PasswordResetForm
