import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { useApplicationStore } from 'hooks/application'
import { useDataContext } from 'components/spreadsheet/contexts/data'

const FreezeMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()

  const minFrozenIndex = 0
  const maxFrozenIndex = 6
  const currentFrozenIndex = spreadsheetData.viewDetails.frozenIndex

  const handleFrozenChange = (value: string) => {
    const noFrozenColumns = parseInt(value)
    if (!isNaN(noFrozenColumns)) {
      const frozenIndex = noFrozenColumns
      if (frozenIndex >= minFrozenIndex && noFrozenColumns < maxFrozenIndex) {
        setSpreadsheetData({ type: 'CHANGE_FROZEN_INDEX', frozenIndex })
      } else {
        setSnackbarMessage({
          status: 'error',
          message: `The number of frozen columns should be above ${minFrozenIndex} and less than ${maxFrozenIndex}`
        })
      }
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        <input
          className="bg-light-grey rounded"
          style={{ height: '30px', outline: 'none', border: 'none', padding: '5px', width: '50px' }}
          type="number"
          min={minFrozenIndex}
          max={maxFrozenIndex - 1}
          defaultValue={currentFrozenIndex}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFrozenChange(event.target.value)}
        />
        <span className="truncate" style={{ padding: '5px 10px' }}>
          {(currentFrozenIndex === 0 || currentFrozenIndex > 1) && `frozen columns`}
          {currentFrozenIndex === 1 && `frozen column`}
        </span>
      </div>
    </Menu>
  )
}

export default React.memo(FreezeMenu)
