import React, { useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Cross } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

const SearchMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const [search, setSearch] = useState<string>('')

  const handleSearch = () => {
    let strFound
    if (window.find) {
      strFound = self.find(search, false, false, true, false, false, false)
      if (strFound && self.getSelection && !self.getSelection()?.anchorNode) {
        strFound = self.find(search)
      }
      if (!strFound) {
        strFound = self.find(search, 0, 1)
        while (self.find(search, 0, 1)) continue
      }
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            style={{ width: '200px', marginRight: 'auto' }}
            autoFocus
            placeholder="Search Process"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
          />

          <div
            className="flex items-center justify-center bg-light-grey hover-bg-grey cursor-pointer rounded transition-all"
            style={{
              marginLeft: '5px',
              padding: '5px'
            }}
            onClick={() => handleSearch()}
          >
            Find
          </div>

          <div
            className="flex items-center justify-center bg-light-grey hover-bg-grey cursor-pointer rounded transition-all"
            style={{ marginLeft: '5px', padding: '5px' }}
            onClick={() => {
              setSearch('')
              setMenuState(INITIAL_CONTEXT_MENU_STATE)
            }}
          >
            <Cross />
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(SearchMenu)
