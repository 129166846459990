import { IContextMenuState } from 'types'

export enum ProjectRoles {
  MEMBER = 'member',
  OWNER = 'owner',
  ADMIN = 'admin'
}

export enum IntegrationProviders {
  VIEWPOINT = 'viewpoint',
  AUTODESK_BIM360 = 'autodesk-bim360',
  PROCORE = 'procore',
  ACONEX = 'aconex',
  MORTA = 'morta',
  REVIZTO = 'revizto',
  ASITE = 'asite'
}

export enum UserRoles {
  NO_ACCEESS = -1,
  VIEWER = 0,
  NOTIFY = 1,
  CONTRIBUTER = 2,
  REVIEWER = 3,
  OWNER = 4
}

export enum AccessResourceKinds {
  TABLE = 'table',
  PROCESS = 'process',
  VIEW = 'table_view'
}

export enum AccessAttributeKinds {
  USER = 'user',
  TAG = 'tag',
  PROJECT = 'project',
  ALL_TABLE_TAGS = 'all_table_tags'
}

export const INITIAL_PROJECT_INFORMATION = {
  createdAt: '2020-06-25T15:04:17.642721',
  deletedAt: null,
  isDeleted: false,
  name: '',
  primaryColour: '#2B3389',
  projectList: [],
  publicId: '',
  updatedAt: '2021-06-07T14:16:00.517533',
  loading: true,
  role: ProjectRoles.MEMBER,
  defaultProcessId: '',
  folders: [],
  logo: null,
  processTitleFontSize: 1.4,
  processTitleBold: true,
  processTitleItalic: false,
  processTitleUnderline: false,
  processTitleColour: '#000',
  headingStyles: [],
  public: false,
  mfaRequired: false,
  hideProcessCreated: false,
  aiSearchEnabled: false
}

export const INITIAL_CONTEXT_MENU_STATE: IContextMenuState = {
  open: false,
  top: '0px',
  right: '0px',
  left: '0px',
  bottom: '0px',
  columnNumber: -1,
  columnId: '',
  rowNumber: -1,
  rowId: ''
}

export const DEFAULT_SIDEBAR_WIDTH = 240

export const USER_SUBSCRIPTION_LEVELS = ['Basic', 'Advanced', 'Enterprise']

export const AUDIT_RED_KEYWORDS = ['error', 'deleted', 'truncated']
export const AUDIT_GREEN_KEYWORDS = [
  'success',
  'created',
  'updated',
  'restored',
  'duplicated',
  'uploaded',
  'reopened',
  'completed'
]
