import React, { useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { FixedSizeList } from 'react-window'
import Button from 'components/button'
import { SelectProps } from 'components/select'
import { OptionProps } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

interface SelectMenuProps extends SelectProps, MenuProps {}

const SelectMenu: React.FC<SelectMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  options,
  onOptionClick,
  loading,
  multiselect,
  optionsSelected,
  setOptionsSelected,
  groupBy,
  customAddText,
  customRemoveText,
  switchColours
}) => {
  const [search, setSearch] = useState<string>('')

  const groupSelected = (list: Array<OptionProps>, keyGetter: (input: OptionProps) => string) => {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  const getGroupArray = (groups: Map<string, OptionProps[]>) => {
    const array: Array<OptionProps> = []

    groups.forEach((object, key: string) => {
      array.push({ label: key })
      object.forEach((option) => array.push(option))
    })

    return array
  }

  const filteredOptions = options
    .filter(
      (option: OptionProps) =>
        (option.label && option.label.toLowerCase().includes(search.toLowerCase())) ||
        (option.group && option.group.toLowerCase().includes(search.toLowerCase()))
    )
    .sort((a, b) => {
      // Sort options by which ones are selected
      if (multiselect && optionsSelected && optionsSelected.length > 0 && a.value && b.value) {
        if (optionsSelected.indexOf(a.value) !== -1 && optionsSelected.indexOf(b.value) === -1) {
          return -1
        } else if (optionsSelected.indexOf(a.value) === -1 && optionsSelected.indexOf(b.value) !== -1) {
          return 1
        } else {
          return 0
        }
      } else {
        return 0
      }
    })
  const groups = groupBy
    ? groupSelected(filteredOptions, (filteredOption) => (filteredOption.group ? filteredOption.group : ''))
    : undefined

  const finalArray = groups ? getGroupArray(groups) : filteredOptions

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div className={`flex flex-column border-2px border-solid border-grey`}>
        <input
          className="border-b-2px border-solid border-grey"
          placeholder="Find an option"
          onChange={(event) => setSearch(event.target.value)}
          readOnly={loading}
        />

        <div className="overflow-hidden bg-light-grey" style={{ maxHeight: '200px' }}>
          {finalArray.length === 0 || loading ? (
            <div className="flex items-center" style={{ padding: '11px 16px', height: '50px' }}>
              {loading ? (
                <div className="flex w-full items-center">
                  <div className="spin" style={{ height: '20px', width: '20px' }} />
                  <span className="ml-4">Loading Options</span>
                </div>
              ) : (
                'No Options'
              )}
            </div>
          ) : (
            <FixedSizeList height={200} itemCount={finalArray.length} itemSize={50} width={'100%'}>
              {({ index, style }) => {
                const value = finalArray[index].value
                const isGroupLabel = value === undefined
                const selected = value !== undefined && optionsSelected && optionsSelected.indexOf(value) !== -1
                return (
                  <div
                    key={index}
                    className={`flex items-center ${isGroupLabel && 'text-secondary'} ${
                      !isGroupLabel && 'cursor-pointer hover-bg-light-grey transition-all'
                    }`}
                    style={{ ...style, padding: '11px 16px' }}
                    onClick={(event) => {
                      event.stopPropagation()
                      if (value) {
                        onOptionClick(value)
                        if (!multiselect) setMenuState(INITIAL_CONTEXT_MENU_STATE)
                      }
                    }}
                    onMouseDown={(event) => event.stopPropagation()}
                    onBlur={(event) => event.stopPropagation()}
                  >
                    <span title={`${finalArray[index].label}`} className="truncate" style={{ flex: 1 }}>
                      {finalArray[index].label}
                    </span>
                    {multiselect && !isGroupLabel && !selected && (
                      <Button
                        internalType={switchColours ? 'danger' : 'accept'}
                        className={`ml-auto ${customAddText ? 'text-sm' : 'text-lg'}`}
                        style={{ width: customAddText ? '60px' : '26px', padding: customAddText ? '5px' : '0px' }}
                      >
                        {customAddText ? customAddText : '+'}
                      </Button>
                    )}
                    {multiselect && !isGroupLabel && selected && (
                      <Button
                        internalType={switchColours ? 'accept' : 'danger'}
                        className={`ml-auto ${customRemoveText ? 'text-sm' : 'text-lg'}`}
                        style={{ width: customRemoveText ? '60px' : '26px', padding: customRemoveText ? '5px' : '0px' }}
                      >
                        {customRemoveText ? customRemoveText : '−'}
                      </Button>
                    )}
                  </div>
                )
              }}
            </FixedSizeList>
          )}
        </div>

        <div
          className="flex items-center border-t-2px border-solid border-grey w-full"
          style={{ padding: '11px 16px' }}
        >
          {!loading && <div className="text-secondary">{filteredOptions.length} Options</div>}
          {finalArray.length > 0 && setOptionsSelected && (
            <>
              <Button
                className="ml-auto"
                internalType={switchColours ? 'accept' : 'danger'}
                disabled={loading}
                onClick={() =>
                  setOptionsSelected(
                    options
                      .filter(
                        (option) =>
                          !filteredOptions.map((filteredOption) => filteredOption.value).includes(option.value)
                      )
                      .map((option) => (option.value !== undefined ? option.value : ''))
                  )
                }
              >
                {customRemoveText ? customRemoveText : 'Remove'} All
              </Button>
              <Button
                className="ml-4"
                internalType={switchColours ? 'danger' : 'accept'}
                disabled={loading}
                onClick={() =>
                  setOptionsSelected(filteredOptions.map((option) => (option.value !== undefined ? option.value : '')))
                }
              >
                {customAddText ? customAddText : 'Add'} All
              </Button>
            </>
          )}
        </div>
      </div>
    </Menu>
  )
}

export default SelectMenu
