import React from 'react'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import ResponseEdit from 'components/process/components/responseEdit'
import { IProcessSectionObject, IProcessResponseObject } from 'types'
import Button from 'components/button'
import Modal, { ModalProps } from 'components/modal'
import { useDataContext } from 'components/process/contexts/data'

export interface AddSectionModalProps {
  anchorEl: any
  fromArchive: boolean
  isLoading: boolean
  parentSection: null | string
}

interface SectionEditProps extends ModalProps {
  section: IProcessSectionObject
}

const SectionEdit: React.FC<SectionEditProps> = ({ id, open, setOpen, section }: SectionEditProps) => {
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const { process, setProcess } = useDataContext()

  const setSection = (section: IProcessSectionObject) => {
    if (process)
      setProcess({
        ...process,
        children: updateProcessSection(section.publicId, process.children, section)
      })
  }

  const updateProcessSection = (
    sectionId: string,
    children: IProcessSectionObject[],
    sectionUpdated: IProcessSectionObject
  ): IProcessSectionObject[] => {
    children.forEach((child, index) => {
      if (child.publicId === sectionUpdated?.publicId) {
        children[index] = sectionUpdated
        return children
      } else {
        return updateProcessSection(sectionId, child.children, sectionUpdated)
      }
    })
    return children
  }

  const addResponse = (): void => {
    if (process && section) {
      api
        .createNewProcessResponse({
          type: 'Flexible',
          processId: process.publicId,
          processSectionId: section.publicId,
          context: { projectId: project.publicId }
        })
        .then((response) => {
          setProcess({
            ...process,
            children: updateProcessSection(section.publicId, process.children, response.data)
          })
          setSnackbarMessage({ status: 'success' })
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({ status: 'error' })
        })
    }
  }

  const deleteResponse = (response: IProcessResponseObject): void => {
    if (process && section) {
      api
        .deleteProcessResponse(project.publicId, process.publicId, section.publicId, response.publicId)
        .then((response) => {
          setProcess({
            ...process,
            children: updateProcessSection(section.publicId, process.children, response.data)
          })
          setSnackbarMessage({ status: 'success' })
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({ status: 'error' })
        })
    }
  }

  const resetResponse = (response: IProcessResponseObject): void => {
    if (process && section) {
      api
        .resetProcessResponse(project.publicId, process.publicId, section.publicId, response.publicId)
        .then((response) => {
          setProcess({
            ...process,
            children: updateProcessSection(section.publicId, process.children, response.data)
          })
          setSnackbarMessage({ status: 'success' })
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({ status: 'error' })
        })
    }
  }

  if (!section)
    return (
      <div
        className="rounded heavy-shadow select-none overflow-y-auto h-full"
        style={{ flex: 3, scrollBehavior: 'smooth', minWidth: '300px', padding: '30px' }}
      />
    )

  return (
    <Modal id={id} open={open} setOpen={setOpen} title={`${section.name} Responses`}>
      <div
        className="flex w-full border-b-1px border-solid border-grey"
        style={{ paddingBottom: '10px', paddingTop: '10px', marginTop: '15px', marginBottom: '25px' }}
      >
        <div className="text-lg font-semibold" style={{ marginBottom: '10px' }}>
          Responses
        </div>
        <div className="flex ml-auto">
          <Button internalType="accept" onClick={() => addResponse()} data-cy="add-process-section-response">
            Add Response
          </Button>
        </div>
      </div>

      {section.responses.map((response: IProcessResponseObject, responseNumber: number) => (
        <ResponseEdit
          key={responseNumber}
          responseNumber={responseNumber}
          response={response}
          deleteResponse={deleteResponse}
          resetResponse={resetResponse}
          processId={process ? process.publicId : ''}
          processSectionId={section.publicId}
          setSection={setSection}
        />
      ))}
    </Modal>
  )
}

export default SectionEdit
