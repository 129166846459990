import React, { useState } from 'react'
import Button from 'components/button'
import api, { APIError } from 'helpers/api'
import useApplicationStore from 'hooks/application'
import useAuth from 'hooks/auth'

export interface UserAccountValues {
  oldPassword: string
  password: string
  passwordConfirm: string
  twoFactorCode: string
}

interface UserAccountFormProps {
  setShowChangeLogin: (open: boolean) => void
}

const UserAccountForm: React.FC<UserAccountFormProps> = ({ setShowChangeLogin }) => {
  const { setSnackbarMessage } = useApplicationStore()
  const { user } = useAuth()

  const [submitting, setSubmitting] = useState<boolean>(false)

  const [values, setValues] = useState<UserAccountValues>({
    oldPassword: '',
    password: '',
    passwordConfirm: '',
    twoFactorCode: ''
  })

  const currentPasswordError = values.oldPassword === ''
  const password8CharactersError = !values.password.match(/.{8,}/)
  const passwordLowercaseError = !values.password.match(/^(?=.*[a-z])/)
  const passwordUppercaseError = !values.password.match(/^(?=.*[A-Z])/)
  const passwordNumericError = !values.password.match(/^(?=.*[0-9])/)
  const passwordSpecialError = !values.password.match(/^(?=.*[!@#$%^&*])/)
  const confirmPasswordError = values.password !== values.passwordConfirm
  const twoFactorCodeError = user && user.is2FaEnabled && values.twoFactorCode === ''

  const passwordError =
    currentPasswordError ||
    password8CharactersError ||
    passwordLowercaseError ||
    passwordUppercaseError ||
    passwordNumericError ||
    passwordSpecialError ||
    confirmPasswordError

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await api.updateUserAccount(
        values.oldPassword,
        values.password,
        values.passwordConfirm,
        user && user.is2FaEnabled ? values.twoFactorCode : undefined
      )
      setSubmitting(false)
      setShowChangeLogin(false)
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({
          status: 'error',
          message: e.message
        })
      } else {
        setSnackbarMessage({
          status: 'error',
          message: 'There was a problem changing your password'
        })
      }
      setSubmitting(false)
      console.error(e)
    }
  }

  return (
    <>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Your Email</div>
        <input id="username" autoComplete="username" type="email" value={user ? user.email : ''} disabled={true} />
      </div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Current Password</div>
        <input
          id="current-password"
          placeholder="Please enter your old password"
          type="password"
          onChange={(event) => setValues({ ...values, oldPassword: event.target.value })}
          autoComplete="current-password"
          data-cy="current-password-input"
        />
        {currentPasswordError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            Your current password cannot be blank
          </p>
        )}
      </div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">New Password</div>
        <input
          id="new-password"
          placeholder="Please enter your new password"
          type="password"
          onChange={(event) => setValues({ ...values, password: event.target.value })}
          autoComplete="new-password"
          data-cy="new-password-input"
        />
        <p className={`${password8CharactersError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
          {`${password8CharactersError ? '⤫' : '✓'} Password must be at least 8 characters long`}
        </p>
        <p className={`${passwordLowercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
          {`${passwordLowercaseError ? '⤫' : '✓'} Password must contain at least one lowercase letter`}
        </p>
        <p className={`${passwordUppercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
          {`${passwordUppercaseError ? '⤫' : '✓'} Password must contain at least one uppercase letter`}
        </p>
        <p className={`${passwordNumericError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
          {`${passwordNumericError ? '⤫' : '✓'} Password must contain at least one numeric character`}
        </p>
        <p className={`${passwordSpecialError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
          {`${passwordSpecialError ? '⤫' : '✓'} Password must contain at least one special character: !@#$%^&*`}
        </p>
      </div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Confirm New Password</div>
        <input
          placeholder="Please repeat your new password"
          type="password"
          onChange={(event) => setValues({ ...values, passwordConfirm: event.target.value })}
          data-cy="confirm-password-input"
          autoComplete="new-password"
        />
        {confirmPasswordError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            Your passwords do not match
          </p>
        )}
      </div>

      {user && user.is2FaEnabled && (
        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">2FA Code</div>
          <input
            placeholder="Please enter your 2fa code from your device"
            type="text"
            onChange={(event) => setValues({ ...values, twoFactorCode: event.target.value })}
          />
          {twoFactorCodeError && (
            <p className="text-red" style={{ marginTop: '10px' }}>
              Your 2FA code cannot be blank
            </p>
          )}
        </div>
      )}

      <div className="flex items-center justify-end">
        <Button
          style={{ marginTop: '20px' }}
          onClick={() => handleSubmit()}
          disabled={passwordError}
          isLoading={submitting}
          data-cy="update-password-button"
        >
          Update Password
        </Button>
      </div>
    </>
  )
}

export default UserAccountForm
