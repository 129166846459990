import React, { useState, useEffect } from 'react'
import Button from 'components/button'
import CreateProjectModal from 'views/home/components/createProjectModal'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { IProject } from 'types'
import useAuth from 'hooks/auth'
import { MFA, Plus, FilledStar, NonFilledStar } from 'components/icons'

const Home: React.FC = () => {
  const [filter, setFilter] = useState<any>({
    active: false,
    search: '',
    ascending: true
  })

  const [projectsLoading, setProjectsLoading] = useState<boolean>(false)
  const [projects, setProjects] = useState<IProject[]>([])
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false)
  const { setSnackbarMessage } = useApplicationStore()
  const { user, updateUser } = useAuth()

  useEffect(() => {
    const getFile = async (url: string, name: string) => {
      const signedFileTokenResponse = await api.signUrl(url)
      const downloadUrl = signedFileTokenResponse.data.url

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = downloadUrl
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      const newUrl = `${window.location.origin}${window.location.pathname}`
      window.history.replaceState({ path: newUrl }, '', newUrl)
    }

    const getTableFile = async (tableId: string, columnId: string, filename: string) => {
      api
        .getFile({
          tableId: tableId,
          columnId: columnId,
          filename: filename
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const params = new URLSearchParams(window.location.search)
    const fileUrl = params.get('fileUrl')
    const fileName = params.get('fileName')
    const tableId = params.get('tableId')
    const columnId = params.get('columnId')
    if (fileUrl && fileName) {
      try {
        getFile(fileUrl, fileName)
      } catch (e) {
        console.error(e)
      }
    } else if (fileName && tableId && columnId) {
      try {
        getTableFile(tableId, columnId, fileName)
      } catch (e) {
        console.error(e)
      }
    }
  }, [window.location.search])

  useEffect(() => {
    document.title = 'Morta | Home'
    if (user) {
      setProjectsLoading(true)
      api
        .getProjects()
        .then((response) => {
          if (response && response.data) {
            setProjects(response.data)
          }
          setProjectsLoading(false)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [user])

  const [modalOpen, setModalOpen] = React.useState(false)

  function favouriteProject(projectId: string) {
    api({
      method: 'PUT',
      endpoint: '/user/projects/' + projectId + '/favourite'
    })
      .then((response) => {
        if (user && response && response.data) {
          updateUser({ ...user, favourites: response.data })
        }
      })
      .catch((error) => {
        console.error(error)
        setSnackbarMessage({ status: 'error' })
      })
  }

  function sortProjects(a: IProject, b: IProject) {
    const aFavourite =
      user && user.favourites && user.favourites.findIndex((favourite) => favourite.publicId === a.publicId) !== -1
        ? 1
        : 0
    const bFavourite =
      user && user.favourites && user.favourites.findIndex((favourite) => favourite.publicId === b.publicId) !== -1
        ? 1
        : 0

    return (
      bFavourite - aFavourite ||
      (a.name > b.name ? (filter.ascending ? 1 : -1) : b.name > a.name ? (filter.ascending ? -1 : 1) : 0)
    )
  }

  const projectIds = projects.map((project: IProject) => project.publicId)

  return (
    <div className="flex flex-column w-full">
      <div
        className="sticky box-border bg-white flex items-center bottom-shadow overflow-y-hidden overflow-x-auto"
        style={{ padding: '10px 30px 3px 50px', top: 0, minHeight: '70px', maxHeight: '70px' }}
      >
        <input
          className="text-base ml-auto"
          placeholder="Search for a workspace..."
          style={{ width: '300px', marginRight: '7px', height: '36px' }}
          onChange={(event) =>
            setFilter({
              ...filter,
              search: event.target.value
            })
          }
        />

        <Button
          internalType="secondary"
          style={{ minWidth: '120px', marginRight: '7px' }}
          onClick={() =>
            setFilter({
              ...filter,
              sort: 'name',
              ascending: !filter.ascending
            })
          }
        >
          Sort {filter.ascending ? 'A → Z' : 'Z → A'}
        </Button>

        <Button
          internalType="secondary"
          style={{ minWidth: '120px' }}
          onClick={() => setIncludeDeleted(!includeDeleted)}
        >
          {includeDeleted ? 'Hide Archived' : 'Show Archived'}
        </Button>
      </div>

      <div className="grid grid-cols-3 sm-grid-cols-1 p-8 gap-16px">
        <div
          onClick={() => {
            if (user) {
              setModalOpen(true)
            } else {
              setSnackbarMessage({ status: 'error', message: 'You have to be signed it to create a new workspace.' })
            }
          }}
          data-cy="create-project"
          className={`flex items-center p-4 rounded ${
            user ? 'cursor-pointer' : 'cursor-not-allowed'
          } transition-all border-1px border-solid border-black box-border hover-heavy-shadow`}
        >
          <div
            className="text-primary flex items-center justify-center rounded m-10px text-xl bg-grey"
            style={{ width: '40px', height: '40px' }}
          >
            <Plus />
          </div>

          <div className="text-primary font-bold px-10px">Create New Workspace</div>
        </div>

        {!projectsLoading &&
          projectIds.length > 0 &&
          projects
            .sort((a: IProject, b: IProject) => sortProjects(a, b))
            .filter((project: IProject) => project.name.toLowerCase().includes(filter.search.toLowerCase()))
            .map(function (project: IProject, projectNumber: number) {
              if (!project.isDeleted || (includeDeleted && project.isDeleted))
                return (
                  <a
                    key={projectNumber}
                    className={`flex items-center p-4 rounded cursor-pointer transition-all border-1px border-solid border-black box-border hover-heavy-shadow ${
                      project.isDeleted ? 'bg-grey' : 'bg-white'
                    }`}
                    href={`/project/${project.publicId}`}
                    data-cy={project.name}
                  >
                    <div
                      className="text-primary flex items-center justify-center rounded m-10px text-xl text-white"
                      style={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: project.primaryColour
                      }}
                    >
                      {project.name.substring(0, 2)}
                    </div>
                    <div className="text-primary font-normal px-10px truncate">{`${project.name}${
                      project.isDeleted ? ' (Archived)' : ''
                    }`}</div>
                    {project.mfaRequired && (
                      <div className="ml-auto flex items-center" style={{ marginRight: '15px' }}>
                        <MFA style={{ width: '25px', height: '25px' }} />
                        <span className="text-primary text-sm">MFA Required</span>
                      </div>
                    )}

                    {user && (
                      <div
                        className={`${!project.mfaRequired && 'ml-auto'} transition-all`}
                        onClick={(event) => {
                          event.preventDefault()
                          favouriteProject(project.publicId)
                        }}
                        title={
                          user &&
                          user.favourites &&
                          user.favourites
                            .map((project: Partial<IProject>) => project.publicId)
                            .includes(project.publicId)
                            ? 'Remove from favourites'
                            : 'Add to favourites'
                        }
                      >
                        {user &&
                        user.favourites &&
                        user.favourites
                          .map((project: Partial<IProject>) => project.publicId)
                          .includes(project.publicId) ? (
                          <FilledStar
                            style={{
                              height: '25px',
                              width: '25px'
                            }}
                          />
                        ) : (
                          <NonFilledStar
                            style={{
                              height: '25px',
                              width: '25px'
                            }}
                          />
                        )}
                      </div>
                    )}
                  </a>
                )
            })}
      </div>

      {modalOpen && <CreateProjectModal id="create-project-modal" open={modalOpen} setOpen={setModalOpen} />}
    </div>
  )
}

export default Home
