import React from 'react'
import { userRoleOptions } from 'components/permissions/utils'
import { useProject } from 'hooks/project'
import { IAccessPolicy } from 'types'
import { UserRoles, AccessAttributeKinds } from 'app-constants'

interface Props {
  policy?: IAccessPolicy
  onUpdatePolicy: (policy: IAccessPolicy, role: UserRoles) => void
  onCreatePolicy: (role: UserRoles, attributeKind: AccessAttributeKinds, attributeId?: string) => void
  excludedPermissionCategories?: UserRoles[]
}

const ProjectPermissions: React.FC<Props> = ({
  onUpdatePolicy,
  policy,
  onCreatePolicy,
  excludedPermissionCategories
}) => {
  const { project } = useProject()

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = parseInt(event.target.value as string)
    if (policy) {
      onUpdatePolicy(policy, value)
    } else {
      onCreatePolicy(value, AccessAttributeKinds.PROJECT, project.publicId)
    }
  }

  return (
    <div
      className="flex items-center cursor-pointer rounded border-box select-none border-1px border-solid border-black transition-all"
      style={{ padding: '1.5rem', minHeight: '90px', margin: '0px 5px 10px 5px' }}
    >
      <div
        className="text-primary flex items-center justify-center rounded text-xl text-white"
        style={{
          minWidth: '40px',
          width: '50px',
          height: '50px',
          marginRight: '10px',
          backgroundColor: project.primaryColour
        }}
      >
        {project.name.substring(0, 2)}
      </div>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ fontWeight: 700, marginBottom: '5px' }}>This Project</div>
        <div>Set permissions for all users in &apos;{project.name}&apos;</div>
      </div>
      <div className="ml-auto" style={{ width: '130px' }}>
        <select
          value={
            policy
              ? excludedPermissionCategories && excludedPermissionCategories.includes(policy.role)
                ? ''
                : policy.role
              : -1
          }
          onChange={handleChange}
        >
          {userRoleOptions
            .filter(
              (userRole) => !excludedPermissionCategories || !excludedPermissionCategories.includes(userRole.value)
            )
            .map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      </div>
    </div>
  )
}

export default ProjectPermissions
