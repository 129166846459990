import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import { history } from 'helpers/history'
import { PROCESS_BASE, PROJECT_BASE } from 'routes'
import { useApplicationStore } from 'hooks/application'
import ResourceType from 'components/resourceType'
import Button from 'components/button'

interface INewProcess {
  name: string
  type: string
  loading: boolean
}

const INITIAL_PROCESS_STATE = {
  name: 'My New Document',
  type: '',
  loading: false
}

const NewProcess: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { project } = useProject()
  const [newProcess, setNewProcess] = useState<INewProcess>(INITIAL_PROCESS_STATE)
  const { setSnackbarMessage } = useApplicationStore()

  function createProcess(): void {
    if (newProcess.name !== '') {
      setNewProcess({ ...newProcess, loading: true })
      api
        .createNewProcess({
          projectId: project.publicId,
          name: newProcess.name,
          type: newProcess.type,
          context: { projectId: project.publicId }
        })
        .then((response) => {
          setNewProcess({ ...newProcess, loading: false })
          history.push(PROJECT_BASE + project.publicId + PROCESS_BASE + response.data.publicId)
          setOpen(false)
        })
        .catch((error) => {
          console.error(error)
          setNewProcess({ ...newProcess, loading: false })
          setSnackbarMessage({
            status: 'error',
            message: 'Something went wrong creating the process! Please try again.'
          })
        })
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Create New Document">
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Document Name</div>
        <input
          value={newProcess.name}
          onChange={(event) => setNewProcess({ ...newProcess, name: event.target.value })}
        />
      </div>

      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Document Type</div>
        <ResourceType
          handleChange={(value: string | null) =>
            value ? setNewProcess({ ...newProcess, type: value }) : setNewProcess({ ...newProcess, type: '' })
          }
          defaultValue={newProcess.type}
          helperText="The type can be selected from your workspace variables (created by going into a table and changing the column type to 'variable') or custom text."
        />
      </div>

      <div className="flex mt-20px">
        <Button className="ml-auto" onClick={() => createProcess()} data-cy="create-table-button">
          Create Document
        </Button>
      </div>
    </Modal>
  )
}

export default NewProcess
