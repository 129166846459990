import { IAggregateTypes, IColumnTypes } from 'components/spreadsheet/types'
import { numberWithCommas } from 'components/spreadsheet/helpers/format'
import { DATE_COLUMN_TYPES, NUMBER_COLUMN_TYPES } from 'components/spreadsheet/constants/const'
import { ICellValue } from 'types'
import { calculatePercentage, formatPercentage, neatRound } from './format'

const EMPTY_RETURN = 'n/a'

export const calculateAggregate = (rows: ICellValue[], aggregate: number) => {
  switch (aggregate) {
    case IAggregateTypes['# Rows']:
      return numberWithCommas(rows.length)
    case IAggregateTypes.Empty:
      return numberWithCommas(calculateEmptyAggregate(rows))
    case IAggregateTypes.Filled:
      return numberWithCommas(calculateFilledAggregate(rows))
    case IAggregateTypes.Unique:
      return numberWithCommas(calculateUniqueAggregate(rows))
    case IAggregateTypes['% Empty']:
      const pctEmpty = calculatePctEmptyAggregate(rows)
      if (isNaN(pctEmpty)) return EMPTY_RETURN
      else return formatPercentage(pctEmpty)
    case IAggregateTypes['% Filled']:
      const pctFilled = calculatePctFilledAggregate(rows)
      if (isNaN(pctFilled)) return EMPTY_RETURN
      else return formatPercentage(pctFilled)
    case IAggregateTypes['% Unique']:
      const pctUnique = calculatePctUniqueAggregate(rows)
      if (isNaN(pctUnique)) return EMPTY_RETURN
      else return formatPercentage(pctUnique)
    case IAggregateTypes.Sum:
      return numberWithCommas(calculateSumAggregate(rows as number[]))
    case IAggregateTypes.Average:
      const avg = calculateAverageAggregate(rows)
      if (isNaN(avg)) return EMPTY_RETURN
      else return numberWithCommas(avg)
    case IAggregateTypes.Min:
      const min = calculateMinAggregate(rows as number[])
      if (min !== undefined && isFinite(min)) return numberWithCommas(min)
      else return EMPTY_RETURN
    case IAggregateTypes.Max:
      const max = calculateMaxAggregate(rows as number[])
      if (max !== undefined && isFinite(max)) return numberWithCommas(max)
      else return EMPTY_RETURN
    case IAggregateTypes.Range:
      const range = calculateRangeAggregate(rows as number[])
      if (range !== undefined && isFinite(range)) return numberWithCommas(range)
      else return EMPTY_RETURN
    case IAggregateTypes['Earliest Date']:
      const minDate = calculateEarliestDateAggregate(rows)
      if (minDate !== undefined) return minDate
      else return EMPTY_RETURN
    case IAggregateTypes['Latest Date']:
      const maxDate = calculateLatestDateAggregate(rows)
      if (maxDate !== undefined) return maxDate
      else return EMPTY_RETURN
    case IAggregateTypes['Date Range (Days)']:
      const dateRange = calculateDateRangeAggregate(rows)
      if (dateRange !== undefined) return numberWithCommas(Math.round(dateRange / (1000 * 60 * 60 * 24)))
      else return EMPTY_RETURN
    default:
      return ''
  }
}

export const isValidAggregateTypeForColType = (colType: IColumnTypes, aggregateType: number): boolean => {
  const allAggregateTypes = [
    IAggregateTypes.None,
    IAggregateTypes['# Rows'],
    IAggregateTypes.Empty,
    IAggregateTypes.Filled,
    IAggregateTypes['% Empty'],
    IAggregateTypes['% Filled'],
    IAggregateTypes.Unique,
    IAggregateTypes['% Unique']
  ]

  const numberAggregateTypes = [
    IAggregateTypes.Sum,
    IAggregateTypes.Average,
    IAggregateTypes.Min,
    IAggregateTypes.Max,
    IAggregateTypes.Range
  ].concat(allAggregateTypes)

  const dateAggregateTypes = [
    IAggregateTypes['Earliest Date'],
    IAggregateTypes['Latest Date'],
    IAggregateTypes['Date Range (Days)']
  ].concat(allAggregateTypes)

  if (aggregateType in allAggregateTypes) return true
  else if (numberAggregateTypes.indexOf(aggregateType) !== -1) return NUMBER_COLUMN_TYPES.includes(colType)
  else if (dateAggregateTypes.indexOf(aggregateType) !== -1) return DATE_COLUMN_TYPES.includes(colType)
  else return false
}

export const calculateEmptyAggregate = (rows: ICellValue[]) => {
  return rows.filter((cell: ICellValue) => cell === null || (Array.isArray(cell) && cell.length === 0)).length
}

export const calculateFilledAggregate = (rows: ICellValue[]) => {
  return rows.filter(
    (cell: ICellValue) => cell !== null && (!Array.isArray(cell) || (Array.isArray(cell) && cell.length > 0))
  ).length
}

export const calculateUniqueAggregate = (rows: ICellValue[]) => {
  return new Set(
    rows.filter(
      (cell: ICellValue) => cell !== null && (!Array.isArray(cell) || (Array.isArray(cell) && cell.length > 0))
    )
  ).size
}

export const calculatePctEmptyAggregate = (rows: ICellValue[]) => {
  const empty = calculateEmptyAggregate(rows)
  return calculatePercentage(empty / rows.length)
}

export const calculatePctFilledAggregate = (rows: ICellValue[]) => {
  const filled = calculateFilledAggregate(rows)
  return calculatePercentage(filled / rows.length)
}

export const calculatePctUniqueAggregate = (rows: ICellValue[]) => {
  const unique = calculateUniqueAggregate(rows)
  return calculatePercentage(unique / rows.length)
}

export const calculateSumAggregate = (rows: number[]) => {
  return neatRound(rows.reduce((a: number, b: number) => a + b, 0))
}

export const calculateAverageAggregate = (rows: ICellValue[]) => {
  const sum = calculateSumAggregate(rows as number[])
  const total = rows.filter((cell: ICellValue) => cell !== null).length
  const average = sum / total
  return neatRound(average)
}

export const calculateMinAggregate = (rows: number[]) => {
  let len = rows.length
  let min = Infinity

  while (len--) {
    min = rows[len] < min ? rows[len] : min
  }
  return min
}

export const calculateMaxAggregate = (rows: number[]) => {
  let len = rows.length
  let max = -Infinity

  while (len--) {
    max = rows[len] > max ? rows[len] : max
  }
  return max
}

export const calculateRangeAggregate = (rows: number[]) => {
  const max = calculateMaxAggregate(rows)
  const min = calculateMinAggregate(rows)
  const range = max - min
  return neatRound(range)
}

export const calculateEarliestDateAggregate = (rows: ICellValue[]) => {
  let minValue

  for (let i = 0; i < rows.length; i++) {
    const columnValue = rows[i]
    if (
      columnValue !== null &&
      typeof columnValue === 'string' &&
      Date.parse(columnValue) &&
      (minValue === undefined || columnValue < minValue)
    )
      minValue = columnValue
  }

  return minValue
}

export const calculateLatestDateAggregate = (rows: ICellValue[]) => {
  let maxValue

  for (let i = 0; i < rows.length; i++) {
    const columnValue = rows[i]
    if (
      columnValue !== null &&
      typeof columnValue === 'string' &&
      Date.parse(columnValue) &&
      (maxValue === undefined || columnValue > maxValue)
    )
      maxValue = columnValue
  }

  return maxValue
}

export const calculateDateRangeAggregate = (rows: ICellValue[]) => {
  let minValue
  let maxValue

  for (let i = 0; i < rows.length; i++) {
    const columnValue = rows[i]
    if (
      columnValue !== null &&
      typeof columnValue === 'string' &&
      Date.parse(columnValue) &&
      (maxValue === undefined || Date.parse(columnValue) > maxValue)
    )
      maxValue = Date.parse(columnValue)
    if (
      columnValue !== null &&
      typeof columnValue === 'string' &&
      Date.parse(columnValue) &&
      (minValue === undefined || Date.parse(columnValue) < minValue)
    )
      minValue = Date.parse(columnValue)
  }
  if (minValue !== undefined && maxValue !== undefined) return maxValue - minValue
  else return undefined
}
