import { IntegrationProviders } from 'app-constants'
import { IUserObject, IntegrationButtonProps, IIntegrationData, IIntegrationSyncResourceTypes } from 'types'
import api from 'helpers/api'
import { ITableDetails } from 'components/spreadsheet/types'

export const syncIntegration = (
  user: IUserObject | undefined,
  provider: IntegrationProviders,
  props: IntegrationButtonProps,
  dataType: IIntegrationSyncResourceTypes,
  syncingIntegration: boolean,
  setSyncingIntegration: (syncing: boolean) => void,
  tablePublicId: string,
  onSuccess: () => void,
  onFailure: (error: any) => void
) => {
  if (user && !syncingIntegration) {
    setSyncingIntegration(true)
    const data: IIntegrationData = getDataForProvider(provider, props, dataType)
    api
      .syncIntegration(tablePublicId, provider, data)
      .then(() => {
        onSuccess()
      })
      .catch((e) => {
        onFailure(e)
      })
  }
}

const getDataForProvider = (
  provider: IntegrationProviders,
  props: IntegrationButtonProps,
  dataType: IIntegrationSyncResourceTypes
) => {
  if (provider === IntegrationProviders.MORTA) {
    return {
      projectIds: props.projects,
      type: dataType
    }
  } else if (provider === IntegrationProviders.ACONEX) {
    return {
      projectId: props.project,
      type: dataType,
      docTypes: props.docTypes
    }
  } else if (provider == IntegrationProviders.ASITE) {
    return {
      projectId: props.project,
      type: dataType
    }
  } else if (provider === IntegrationProviders.AUTODESK_BIM360) {
    return {
      hubId: props.hub,
      projectId: props.project,
      topFolderId: props.topFolder,
      folderId: !props.folder || props.folder === '' ? undefined : props.folder
    }
  } else if (provider === IntegrationProviders.PROCORE) {
    return {
      companyId: props.company,
      projectId: props.project
    }
  } else if (provider === IntegrationProviders.REVIZTO) {
    return {
      licenseId: props.license,
      projectId: props.project
    }
  } else if (provider === IntegrationProviders.VIEWPOINT) {
    return {
      enterpriseId: props.enterprise,
      projectId: props.project,
      folderId: !props.folder || props.folder === '' ? undefined : props.folder,
      type: dataType
    }
  } else {
    return {}
  }
}

export const getProviderLogo = (provider: IntegrationProviders) => {
  if (provider === IntegrationProviders.MORTA) return '/assets/images/logo.png'
  else if (provider === IntegrationProviders.ACONEX) return '/assets/images/aconex.png'
  else if (provider === IntegrationProviders.AUTODESK_BIM360) return '/assets/images/autodesk.png'
  else if (provider === IntegrationProviders.PROCORE) return '/assets/images/procore.png'
  else if (provider === IntegrationProviders.REVIZTO) return '/assets/images/revizto.png'
  else if (provider === IntegrationProviders.VIEWPOINT) return '/assets/images/viewpoint.png'
  else if (provider === IntegrationProviders.ASITE) return '/assets/images/asite.png'
  else return ''
}

export const getIsProviderSynced = (provider: IntegrationProviders, tableDetails: ITableDetails) => {
  if (provider === IntegrationProviders.MORTA) {
    return (
      tableDetails.isMortaProjectsSynced ||
      tableDetails.isMortaResourcesSynced ||
      tableDetails.isMortaUsersSynced ||
      tableDetails.isMortaCommentsSynced
    )
  } else if (provider === IntegrationProviders.ACONEX) {
    return tableDetails.isAconexSynced || tableDetails.isAconexWorkflowsSynced
  } else if (provider === IntegrationProviders.AUTODESK_BIM360) {
    return tableDetails.isAutodeskBim360Synced
  } else if (provider === IntegrationProviders.PROCORE) {
    return tableDetails.isProcoreSynced
  } else if (provider === IntegrationProviders.REVIZTO) {
    return tableDetails.isReviztoIssuesSynced
  } else if (provider === IntegrationProviders.VIEWPOINT) {
    return tableDetails.isViewpointSynced || tableDetails.isViewpointRfisSynced
  } else if (provider == IntegrationProviders.ASITE) {
    return tableDetails.isAsiteDocumentsSynced
  } else return false
}
