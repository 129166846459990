import React, { useState, useContext, createContext, useEffect, useRef } from 'react'
import { IProcessObject, IProcessSectionObject } from 'types'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import { NO_PERMISSION } from 'routes'
import { history } from 'helpers/history'
import { PERMISSIONS } from 'helpers/auth'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { useApplicationStore } from 'hooks/application'

export interface IExpanded {
  time: string
  expanded: boolean
}

export interface DataContextProps {
  process: IProcessObject | null
  setProcess: (newProcess: IProcessObject) => void
  updateProcess: (newProcess: IProcessObject, blockApiCall?: boolean) => void
  setSection: (newProcessSection: IProcessSectionObject, blockApiCall?: boolean) => void
  isAdmin: boolean
  isContributor: boolean
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  navigationMenu: boolean
  setNavigationMenu: (navigationMenu: boolean) => void
  expandAllSections: (parent: IProcessObject | IProcessSectionObject, expanded: boolean) => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  errorStatusCode: number | null
}

export const DataContext = createContext<DataContextProps | undefined>(undefined)

interface DataContextProviderProps {
  processPublicId: string
  permissionCap?: number
  children?: React.ReactNode
}

export const DataContextProvider = (props: DataContextProviderProps) => {
  const { project } = useProject()
  const { setSnackbarMessage } = useApplicationStore()
  const [processObject, setProcessObject] = useState<IProcessObject | null>(null)
  const [errorStatusCode, setErrorStatusCode] = useState<number | null>(null)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [navigationMenu, setNavigationMenu] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const updateTimeout = useRef<TimeoutID | null>(null)

  const isAdmin =
    (props.permissionCap === undefined && processObject && processObject.permissionLevel >= PERMISSIONS.owner) ||
    props.permissionCap === PERMISSIONS.owner
  const isContributor =
    (props.permissionCap === undefined && processObject && processObject.permissionLevel >= PERMISSIONS.contributor) ||
    props.permissionCap === PERMISSIONS.contributor

  useEffect(() => {
    const processId = props.processPublicId
    if (processId && (!processObject || processObject.publicId !== processId)) {
      window.scrollTo(0, 0)
      setProcessObject(null)
      setNavigationMenu(false)

      api
        .getProcess(processId, {})
        .then((response) => {
          return response.data
        })
        .then((processData) => {
          api.getResourcePermissions('process', processId).then((response) => {
            if (processData && response.data) {
              processData['permissions'] = response.data
              document.title = `${processData.name}`
              setProcessObject(processData)
            }
          })
        })
        .catch((error) => {
          console.error(error)

          if (error.rawCode && error.rawCode === 404) {
            setErrorStatusCode(error.rawCode)
          } else {
            history.push(NO_PERMISSION + `?project=${project.publicId}&type=process&id=${processId}`)
          }
        })
    }
  }, [props.processPublicId])

  const expandAllSections = async (parent: IProcessObject | IProcessSectionObject, expanded: boolean) => {
    for (let i = 0; i < parent.children.length; i++) {
      const section = parent.children[i]
      setSection({ ...section, expanded: expanded }, true)
      expandAllSections(section, expanded)
    }
  }

  const updateProcess = (newProcess: IProcessObject, blockApiCall?: boolean) => {
    setProcessObject(newProcess)
    if (updateTimeout.current !== null) cancelTimeout(updateTimeout.current)
    updateTimeout.current = requestTimeout(() => {
      if (!blockApiCall) {
        api
          .updateProcess({
            variables: newProcess.variables,
            name: newProcess.name,
            description: newProcess.description,
            type: newProcess.type,
            processId: newProcess.publicId,
            logo: newProcess.logo,
            context: { projectId: project.publicId },
            allowDuplication: newProcess.allowDuplication,
            expandByDefault: newProcess.expandByDefault,
            allowComments: newProcess.allowComments,
            isTemplate: newProcess.isTemplate,
            templateCategory: newProcess.templateCategory
          })
          .catch((error) => {
            console.error(error)
            setSnackbarMessage({ status: 'error', message: 'Something went wrong: ' + error.toString() })
          })
      }
    }, 1000)
  }

  const updateProcessSection = (newProcessSection: IProcessSectionObject, children: IProcessSectionObject[]) => {
    children.forEach((child, index) => {
      if (child.publicId === newProcessSection?.publicId) {
        children[index] = newProcessSection
        return children
      } else {
        return updateProcessSection(newProcessSection, child.children)
      }
    })
    return children
  }

  const setSection = (newProcessSection: IProcessSectionObject, blockApiCall?: boolean) => {
    if (processObject) {
      setProcessObject({
        ...processObject,
        children: updateProcessSection(newProcessSection, processObject.children)
      })
      if (!blockApiCall) {
        if (updateTimeout.current !== null) cancelTimeout(updateTimeout.current)
        updateTimeout.current = requestTimeout(() => {
          api
            .updateProcessSection({
              name: newProcessSection.name,
              description: newProcessSection.description,
              processId: processObject.publicId,
              processSectionId: newProcessSection.publicId,
              pdfIncludeDescription: newProcessSection.pdfIncludeDescription,
              pdfIncludeSection: newProcessSection.pdfIncludeSection,
              pageBreakBefore: newProcessSection.pageBreakBefore,
              context: { projectId: project.publicId }
            })
            .catch((error) => {
              console.error(error)
              setSnackbarMessage({ status: 'error', message: 'Something went wrong: ' + error.toString() })
            })
        }, 1000)
      }
    }
  }

  return (
    <DataContext.Provider
      value={{
        process: processObject,
        setProcess: setProcessObject,
        updateProcess,
        setSection,
        isAdmin,
        isContributor,
        editMode,
        setEditMode,
        navigationMenu,
        setNavigationMenu,
        expandAllSections,
        searchTerm,
        setSearchTerm,
        errorStatusCode
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export const useDataContext = () => {
  const context = useContext(DataContext)

  if (context === undefined) {
    throw new Error('useDataContext must be used witin DataContextProvider')
  }

  return context
}
