import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Pencil, Arrow, Delete, Visible, Lock, Asterisk, Filter, Group } from 'components/icons'
import { createTableColumnViewAction } from 'components/spreadsheet/contexts/data/actions'
import { IntegrationSyncTypes, ViewTypes } from 'components/spreadsheet/constants/const'
import { useProject } from 'hooks/project'
import { buildNewColumn } from 'components/spreadsheet/helpers/functions'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { ITableViewColumn, ITableViewFilter, ITableViewGroup } from 'types'
import { getDefaultFilterTypeForColType } from 'components/spreadsheet/helpers/filtering'

interface ColumnMenuProps extends MenuProps {
  setEditColumnModal: (open: boolean) => void
  setDeleteColumnModal: (open: boolean) => void
}

const ColumnMenu: React.FC<ColumnMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  setEditColumnModal,
  setDeleteColumnModal
}) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData, setFilterMenu } = useDataContext()

  const column = spreadsheetData.viewDetails.columns.find(
    (column: ITableViewColumn) => column.publicId === menuState.columnId
  )

  const handleInsertColumn = async (sortOrder: number) => {
    const column = buildNewColumn(spreadsheetData, sortOrder, project.publicId)
    createTableColumnViewAction(spreadsheetData.viewDetails.publicId, column, setSpreadsheetData, handleOnSuccess)
  }

  const handleOnSuccess = () => {
    setMenuState({ ...menuState, open: false })
  }

  const handleOnClickFilter = () => {
    const filterToolbarButton = document.getElementById('filter-toolbar-button')

    if (filterToolbarButton && column) {
      const isFiltered =
        spreadsheetData.userConfiguration.filterSettings.findIndex(
          (setting: ITableViewFilter) => setting.columnId === menuState.columnId
        ) !== -1

      if (!isFiltered) {
        setFilterMenu({
          open: true,
          top: `${filterToolbarButton.getBoundingClientRect().bottom + 10}px`,
          left: `${filterToolbarButton.getBoundingClientRect().left - 900 + 80}px`,
          right: 'auto',
          bottom: 'auto',
          defaultFilter: {
            columnId: column.publicId,
            columnName: column.name,
            filterType: getDefaultFilterTypeForColType(column.kind),
            value: '',
            multipleValues: [],
            orGroup: 'main'
          }
        })
      } else {
        setSpreadsheetData({
          type: 'UPDATE_FILTERS',
          filters: spreadsheetData.userConfiguration.filterSettings.filter(
            (setting: ITableViewFilter) => setting.columnId !== menuState.columnId
          )
        })
      }

      setMenuState({ ...menuState, open: false })
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        <li>
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px'
            }}
            onClick={() => handleOnClickFilter()}
          >
            <Filter />
            <span className="ml-10px">
              {`${
                spreadsheetData.userConfiguration.filterSettings.findIndex(
                  (setting: ITableViewFilter) => setting.columnId === menuState.columnId
                ) === -1
                  ? 'Add Filter'
                  : 'Remove Filters'
              }`}
            </span>
          </div>
        </li>
        <li>
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px'
            }}
            onClick={() => {
              if (column) {
                const isGrouped =
                  spreadsheetData.userConfiguration.groupSettings.findIndex(
                    (setting: ITableViewGroup) => setting.columnId === menuState.columnId
                  ) !== -1

                if (isGrouped) {
                  setSpreadsheetData({
                    type: 'DELETE_GROUP',
                    columnId: column.publicId
                  })
                } else {
                  setSpreadsheetData({
                    type: 'ADD_GROUP',
                    columnId: column.publicId,
                    columnName: column.name
                  })
                }

                handleOnSuccess()
              }
            }}
          >
            <Group />
            <span className="ml-10px">
              {`${
                spreadsheetData.userConfiguration.groupSettings.find(
                  (setting: ITableViewGroup) => setting.columnId === menuState.columnId
                )
                  ? 'Ungroup'
                  : 'Group'
              }`}{' '}
              Column
            </span>
          </div>
        </li>
        {spreadsheetData.isAdmin && (
          <>
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (menuState.columnId !== undefined) {
                    setSpreadsheetData({ type: 'HIDE_COLUMN', columnId: menuState.columnId })
                  }

                  handleOnSuccess()
                }}
              >
                <Visible />
                <span className="ml-10px">Hide Column</span>
              </div>
            </li>
            {(!column ||
              (column.viewpointSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.viewpointRfisSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.autodeskBim360Synced === IntegrationSyncTypes.NOT_SYNCED &&
                column.procoreSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.mortaSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.reviztoIssuesSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.asiteDocumentsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexWorkflowsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexSynced === IntegrationSyncTypes.NOT_SYNCED)) && (
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{
                    padding: '0 10px',
                    height: '32px',
                    lineHeight: '32px'
                  }}
                  onClick={() => {
                    if (column) {
                      if (column.locked) {
                        setSpreadsheetData({ type: 'UNLOCK_COLUMN', columnId: column.publicId })
                      } else {
                        setSpreadsheetData({ type: 'LOCK_COLUMN', columnId: column.publicId })
                      }
                    }

                    handleOnSuccess()
                  }}
                >
                  <Lock />
                  <span className="ml-10px">{`${column && column.locked ? 'Unlock' : 'Lock'} Column`}</span>
                </div>
              </li>
            )}

            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => setEditColumnModal(true)}
              >
                <Pencil />
                <span className="ml-10px">Edit Column</span>
              </div>
            </li>

            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (menuState.columnNumber !== undefined) handleInsertColumn(menuState.columnNumber)
                }}
              >
                <Arrow type="left" />
                <span className="ml-10px">Insert Before</span>
              </div>
            </li>
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (menuState.columnNumber !== undefined) handleInsertColumn(menuState.columnNumber + 1)
                }}
              >
                <Arrow type="right" />
                <span className="ml-10px">Insert After</span>
              </div>
            </li>
            {(!column ||
              (column.viewpointSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.viewpointRfisSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.autodeskBim360Synced === IntegrationSyncTypes.NOT_SYNCED &&
                column.procoreSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.mortaSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.reviztoIssuesSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.asiteDocumentsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexWorkflowsSynced === IntegrationSyncTypes.NOT_SYNCED &&
                column.aconexSynced === IntegrationSyncTypes.NOT_SYNCED)) && (
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{
                    padding: '0 10px',
                    height: '32px',
                    lineHeight: '32px'
                  }}
                  onClick={() => setDeleteColumnModal(true)}
                >
                  <Delete />
                  <span className="ml-10px">Delete Column</span>
                </div>
              </li>
            )}
          </>
        )}

        {spreadsheetData.viewDetails.type === ViewTypes.FORM && (
          <>
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (menuState.columnId !== undefined) {
                    setSpreadsheetData({ type: 'REQUIRE_COLUMN', columnId: menuState.columnId })
                  }

                  handleOnSuccess()
                }}
              >
                <Asterisk />
                <span className="ml-10px">Set Required</span>
              </div>
            </li>
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{
                  padding: '0 10px',
                  height: '32px',
                  lineHeight: '32px'
                }}
                onClick={() => {
                  if (menuState.columnId !== undefined) {
                    setSpreadsheetData({ type: 'UNREQUIRE_COLUMN', columnId: menuState.columnId })
                  }

                  handleOnSuccess()
                }}
              >
                <Asterisk />
                <span className="ml-10px">Set Unrequired</span>
              </div>
            </li>
          </>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(ColumnMenu)
