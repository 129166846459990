import React, { useEffect, useState, useRef } from 'react'
import Modal from 'components/modal'
import Button from 'components/button'
import InviteUserForm from 'views/settings/components/inviteUserForm'
import { Table } from 'components/table'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ITableWithCells, IProjectMember, ITag } from 'types'
import { getProjectMembershipData } from './utils'
import useProject from 'hooks/project'
import { useAuth } from 'hooks/auth'

export interface IFilter {
  active: boolean
  search: string
  ascending: boolean
  sort: string
  role: string
}

const PermissionsBox: React.FC = () => {
  const { user } = useAuth()

  const [inviteUserModalOpen, setInviteUserModalOpen] = useState<boolean>(false)
  const [members, setMembers] = useState<IProjectMember[]>([])
  const [tagTablesWithCells, setTagTablesWithCells] = useState<ITableWithCells[]>([])
  const [loaded, setLoaded] = useState(false)
  const { project, projectRole } = useProject()
  const [filter, setFilter] = useState<IFilter>({
    active: false,
    search: '',
    ascending: true,
    sort: 'name',
    role: 'all'
  })
  const searchTimeout: any = useRef(null)
  const filterTimeout: any = useRef(null)

  const [removeUserDialog, setRemoveUserDialog] = useState<{ open: boolean; member?: IProjectMember }>({
    open: false
  })
  const [adminToMemberDialog, setAdminToMemberDialog] = useState<{ open: boolean; member?: IProjectMember }>({
    open: false
  })
  const [ownerToAdminDialog, setOwnerToAdminDialog] = useState<{ open: boolean; member?: IProjectMember }>({
    open: false
  })
  const [userAdminDialog, setUserAdminDialog] = useState<{ open: boolean; member?: IProjectMember }>({
    open: false
  })
  const [userOwnerDialog, setUserOwnerDialog] = useState<{ open: boolean; member?: IProjectMember }>({
    open: false
  })
  const { setSnackbarMessage } = useApplicationStore()

  const fetchProjectUsers = async () => {
    try {
      const response = await api.getProjectMembers(project.publicId)
      setMembers(response.data)
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem fetching users for this project' })
    }
  }

  const fetchTagTables = async () => {
    try {
      const response = await api.getProjectTags(project.publicId)
      setTagTablesWithCells(response.data)
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem fetching tags for this project' })
    }
  }

  useEffect(() => {
    const results = Promise.all([fetchProjectUsers(), fetchTagTables()])
    results.then(() => setLoaded(true))
  }, [])

  const triggerRemoveUser = (member: IProjectMember) => {
    setRemoveUserDialog({
      open: true,
      member
    })
  }

  const triggerAdminToMember = (member: IProjectMember) => {
    setAdminToMemberDialog({
      open: true,
      member
    })
  }

  const triggerOwnerToAdmin = (member: IProjectMember) => {
    setOwnerToAdminDialog({
      open: true,
      member
    })
  }

  const triggerUserAdmin = (member: IProjectMember) => {
    setUserAdminDialog({
      open: true,
      member
    })
  }

  const triggerUserOwner = (member: IProjectMember) => {
    setUserOwnerDialog({
      open: true,
      member
    })
  }

  const getTableHeaders = () => {
    let headers = [
      { header: 'User', id: 'user' },
      { header: 'Email', id: 'email' },
      { header: 'Role', id: 'role' },
      { header: 'Joined', id: 'joined' }
    ]
    const tagHeaders = []

    for (const table of tagTablesWithCells) {
      tagHeaders.push({
        header: `${table.name} Tags`,
        id: table.publicId
      })
    }

    headers = headers.concat(tagHeaders)
    headers.push({ header: 'Remove', id: 'remove' })

    return headers
  }

  const handlePromoteUserToAdmin = async (member: IProjectMember) => {
    try {
      await api.updateProjectMemberRole(member.user, project, 'admin')
      setUserAdminDialog({ open: false })
      fetchProjectUsers()
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem promoting user to builder' })
    }
  }

  const handlePromoteUserToOwner = async (member: IProjectMember) => {
    try {
      await api.updateProjectMemberRole(member.user, project, 'owner')
      setUserOwnerDialog({ open: false })
      fetchProjectUsers()
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem promoting user to owner' })
    }
  }

  const handleDeleteProjectMember = async (member: IProjectMember) => {
    try {
      await api.deleteProjectMember(member.user, project)
      setRemoveUserDialog({ open: false })
      fetchProjectUsers()
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem removing the user from the project' })
    }
  }

  const handleAdminToMember = async (member: IProjectMember) => {
    try {
      await api.updateProjectMemberRole(member.user, project, 'member')
      setAdminToMemberDialog({ open: false })
      fetchProjectUsers()
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem turning this builder into a member' })
    }
  }

  const handleOwnerToAdmin = async (member: IProjectMember) => {
    try {
      await api.updateProjectMemberRole(member.user, project, 'admin')
      setOwnerToAdminDialog({ open: false })
      fetchProjectUsers()
    } catch (e) {
      console.error(e)
      setSnackbarMessage({ status: 'error', message: 'There was a problem turning this owner into a member' })
    }
  }

  const handlePermissionsChange = async (
    member: IProjectMember,
    selectedTagIds: string[],
    tableSelectedTags: ITag[]
  ) => {
    const currentUserTagReferenceIds = member.user.tags!.map((tag) => tag.referencePublicId)
    const newTags: ITag[] = []
    const deletedTagIds: string[] = []

    // Add new tag
    for (const selectedTag of selectedTagIds) {
      if (!currentUserTagReferenceIds?.includes(selectedTag)) {
        const response = await api.addUserTag(member.user, selectedTag)
        newTags.push(response.data)
      }
    }

    // Remove tag
    for (const currentTag of tableSelectedTags) {
      if (!selectedTagIds.includes(currentTag.referencePublicId)) {
        await api.deleteUserTag(member.user, currentTag)
        deletedTagIds.push(currentTag.referencePublicId)
      }
    }

    // Update the member's tags in the state
    let allMemberTags = member.user.tags!.filter((t) => !deletedTagIds.includes(t.referencePublicId))
    allMemberTags = allMemberTags.concat(newTags)

    const newMembershipData = members.map((m) => {
      if (m.user.publicId === member.user.publicId) {
        return {
          ...m,
          user: {
            ...m.user,
            tags: allMemberTags
          }
        }
      }
      return m
    })

    setMembers(newMembershipData)
  }

  const onSearchChange = (searchTerm: string) => {
    setLoaded(false)
    if (searchTimeout.current !== null) {
      clearTimeout(searchTimeout.current)
    }

    searchTimeout.current = setTimeout(() => {
      setFilter({
        ...filter,
        search: searchTerm
      })
      setLoaded(true)
    }, 1000)
  }

  const handleFilterChange = (filter: IFilter) => {
    setLoaded(false)
    if (filterTimeout.current !== null) {
      clearTimeout(filterTimeout.current)
    }

    filterTimeout.current = setTimeout(() => {
      setFilter(filter)
      setLoaded(true)
    }, 500)
  }

  const handleCreateInvite = () => {
    fetchProjectUsers()
    setInviteUserModalOpen(false)
    setSnackbarMessage({ status: 'success', message: 'Users were invited to project' })
  }

  function filterMembers(a: IProjectMember) {
    if (filter.role === 'all' || filter.role === a.projectRole) {
      if (a.user.name.toLowerCase().includes(filter.search.toLowerCase())) return 1
      else if (a.user.email.toLowerCase().includes(filter.search.toLowerCase())) return 1
      else if (a.createdAt.split('T')[0].toString().includes(filter.search)) return 1
      else if (a.projectRole.toLowerCase().includes(filter.search.toLowerCase())) return 1
      else return 0
    } else {
      return 0
    }
  }

  const memberList = members.filter(filterMembers)

  const tableData = getProjectMembershipData(
    user,
    memberList,
    projectRole,
    tagTablesWithCells,
    handlePermissionsChange,
    triggerUserAdmin,
    triggerUserOwner,
    triggerRemoveUser,
    triggerAdminToMember,
    triggerOwnerToAdmin
  )

  return (
    <div className="flex flex-column w-full h-full overflow-y-scroll" style={{ padding: '10px 30px' }}>
      <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
        <div>Showing {memberList.length} Workspace Members</div>
        <div style={{ marginLeft: 'auto', height: '100%' }}></div>
        <input
          className="h-full text-base"
          placeholder="Search for a workspace member..."
          onChange={(event) => onSearchChange(event.target.value)}
          style={{ maxWidth: '300px', border: '1px solid white', width: '300px', padding: '7px' }}
        />

        <div className="h-full" style={{ marginLeft: '15px' }}>
          <select
            className="text-white font-semibold text-sm h-full"
            style={{ background: project.primaryColour, padding: '7px 12px', border: '1px solid white' }}
            onChange={(event) =>
              handleFilterChange({
                ...filter,
                role: event.target.value
              })
            }
          >
            <option value={'all'}>Role: All</option>
            <option value={'owner'}>Role: Owner</option>
            <option value={'admin'}>Role: Builder</option>
            <option value={'member'}>Role: Member</option>
          </select>
        </div>
        <div className="h-full" style={{ marginLeft: '15px' }} onClick={() => setInviteUserModalOpen(true)}>
          <Button className="h-full">Invite New Members</Button>
        </div>
      </div>

      <Table
        data={tableData}
        include={getTableHeaders()}
        defaultSort={'joined'}
        defaultSortAscending={false}
        loading={!loaded}
      />

      {inviteUserModalOpen && (
        <Modal
          id={'invite-user-modal'}
          open={inviteUserModalOpen}
          setOpen={setInviteUserModalOpen}
          title={'Invite User To Workspace'}
        >
          <InviteUserForm handleOnSuccess={handleCreateInvite} />
        </Modal>
      )}

      {removeUserDialog.open && (
        <Modal
          id="remove-user-modal"
          title="Confirm Removal Of User"
          open={removeUserDialog.open}
          setOpen={() => setRemoveUserDialog({ open: false })}
        >
          Are you sure you want to remove this user from the project?
          <div className="flex items-center justify-end mt-20px">
            <Button onClick={() => setRemoveUserDialog({ open: false })}>Keep In Project</Button>
            <Button
              internalType="danger"
              style={{ marginLeft: '10px' }}
              onClick={() => handleDeleteProjectMember(removeUserDialog.member!)}
              autoFocus
            >
              Remove From Project
            </Button>
          </div>
        </Modal>
      )}

      {adminToMemberDialog.open && (
        <Modal
          id="admin-to-member-modal"
          open={adminToMemberDialog.open}
          setOpen={() => setAdminToMemberDialog({ open: false })}
          title="Confirm Changing Builder To Member"
        >
          Are you sure you want to convert this builder into a normal member?
          <div className="flex items-center justify-end mt-20px">
            <Button onClick={() => setAdminToMemberDialog({ open: false })}>Keep As Builder</Button>
            <Button
              style={{ marginLeft: '10px' }}
              internalType="danger"
              onClick={() => handleAdminToMember(adminToMemberDialog.member!)}
              autoFocus
            >
              Downgrade To Member
            </Button>
          </div>
        </Modal>
      )}

      {ownerToAdminDialog.open && (
        <Modal
          id="owner-to-admin-modal"
          open={ownerToAdminDialog.open}
          setOpen={() => setOwnerToAdminDialog({ open: false })}
          title="Confirm Changing Owner To Builder"
        >
          Are you sure you want to convert this owner into a project builder?
          <div className="flex items-center justify-end mt-20px">
            <Button onClick={() => setOwnerToAdminDialog({ open: false })}>Keep As Owner</Button>
            <Button
              style={{ marginLeft: '10px' }}
              internalType="danger"
              onClick={() => handleOwnerToAdmin(ownerToAdminDialog.member!)}
              autoFocus
            >
              Downgrade To Builder
            </Button>
          </div>
        </Modal>
      )}

      {userAdminDialog.open && (
        <Modal
          id="user-admin-modal"
          open={userAdminDialog.open}
          setOpen={() => setUserAdminDialog({ open: false })}
          title="Confirm Making User Builder"
        >
          Are you sure you want to make this user a builder?
          <div className="flex items-center justify-end mt-20px">
            <Button onClick={() => setUserAdminDialog({ open: false })}>Do Not Make Builder</Button>
            <Button
              style={{ marginLeft: '10px' }}
              internalType="danger"
              onClick={() => handlePromoteUserToAdmin(userAdminDialog.member!)}
              autoFocus
            >
              Make Builder
            </Button>
          </div>
        </Modal>
      )}

      {userOwnerDialog.open && (
        <Modal
          id="user-owner-modal"
          open={userOwnerDialog.open}
          setOpen={() => setUserOwnerDialog({ open: false })}
          title="Confirm Making User Owner"
        >
          Are you sure you want to add this user as an owner of the project?
          <div className="flex items-center justify-end mt-20px">
            <Button onClick={() => setUserOwnerDialog({ open: false })}>Do Not Make Owner</Button>
            <Button
              style={{ marginLeft: '10px' }}
              internalType="danger"
              onClick={() => handlePromoteUserToOwner(userOwnerDialog.member!)}
              autoFocus
            >
              Make Owner
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default PermissionsBox
