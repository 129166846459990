import React from 'react'
import api from 'helpers/api'
import { Table } from 'components/table'
import { mapStatsToTableData } from 'views/admin/helpers/utils'

const AdminProcessStats: React.FC = () => {
  const [stats, setStats] = React.useState<Record<string, number>>()

  const fetchProcessStats = async () => {
    try {
      const response = await api.getProcessStats()
      setStats(response.data)
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    fetchProcessStats()
    document.title = 'Morta | Process Stats'
  }, [])

  const tableData = mapStatsToTableData(stats)

  return (
    <Table
      data={tableData}
      include={[
        { header: 'Date', id: 'date' },
        { header: '# Processes', id: 'no' },
        { header: 'Day Change (%)', id: 'dayChange' },
        { header: 'Week Change (%)', id: 'weekChange' },
        { header: 'Month Change (%)', id: 'monthChange' },
        { header: 'Year Change (%)', id: 'yearChange' }
      ]}
      defaultSort={'date'}
      defaultSortAscending={true}
      sort={false}
      loading={stats === undefined}
    />
  )
}

export default AdminProcessStats
