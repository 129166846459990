import React, { useEffect, useState } from 'react'
import Modal from 'components/modal'
import Button from 'components/button'
import { Table } from 'components/table'
import NotificationResourceList from './components/NotificationResourceList'
import DeleteResource from 'components/delete'

import NotificationForm, {
  IFormValues as INotificationFormValues
} from 'views/settings/components/notifications/components/NotificationForm'

import NotificationExecutions from 'views/settings/components/notifications/components/NotificationExecutions'
import api, { APIError, ApiErrorCodes } from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { INotification } from 'types'
import useProject from 'hooks/project'

const DevelopersBox: React.FC = () => {
  const [showAddNotificationModal, setShowAddNotificationModal] = useState(false)
  const [showEditNotificationModal, setShowEditNotificationModal] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<INotification[]>([])
  const { setSnackbarMessage } = useApplicationStore()

  const [deleteNotification, setDeleteNotification] = useState<boolean>(false)
  const [showNotificationExecutions, setShowNotificationExecutions] = useState<boolean>(false)
  const [selectedNotification, setSelectedNotification] = useState<INotification | undefined>()

  const { project } = useProject()

  useEffect(() => {
    fetchNotifications()
  }, [])

  const fetchNotifications = async () => {
    try {
      const response = await api.getNotifications(project.publicId)
      setNotifications(response.data)
    } catch (e) {
      setSnackbarMessage({
        status: 'error',
        message: 'There was a problem fetching notifications for this project'
      })
      console.error(e)
    }
  }

  const handleDeleteNotification = async (notification: INotification) => {
    try {
      await api.deleteNotification(notification.publicId)
      fetchNotifications()
      setDeleteNotification(false)
    } catch (e) {
      setSnackbarMessage({
        status: 'error',
        message: 'There was a problem deleting this notification'
      })
      console.error(e)
      setDeleteNotification(false)
    }
  }

  const handleCreateNotification = async (values: INotificationFormValues) => {
    try {
      const valuesReshaped = {
        ...values,
        tables: values.tables.map((t) => t.publicId),
        processes: values.processes.map((p) => p.publicId)
      }
      await api.createNotification(project.publicId, valuesReshaped)
      setShowAddNotificationModal(false)
      fetchNotifications()
    } catch (e) {
      if (e instanceof APIError && e.code === ApiErrorCodes.REQUEST_PARAMS_ERROR) {
        setSnackbarMessage({
          status: 'error',
          message: e.message
        })
      } else {
        setSnackbarMessage({
          status: 'error',
          message: 'There was a problem creating this notification'
        })
      }

      console.error(e)
    }
  }

  const handleUpdateNotification = async (values: INotificationFormValues) => {
    try {
      const valuesReshaped = {
        ...values,
        tables: values.tables.map((t) => t.publicId),
        processes: values.processes.map((p) => p.publicId)
      }
      await api.updateNotification(selectedNotification!, valuesReshaped)
      setShowEditNotificationModal(false)
      setSelectedNotification(undefined)
      fetchNotifications()
    } catch (e) {
      if (e instanceof APIError && e.code === ApiErrorCodes.REQUEST_PARAMS_ERROR) {
        setSnackbarMessage({
          status: 'error',
          message: e.message
        })
      } else {
        setSnackbarMessage({
          status: 'error',
          message: 'There was a problem updating this notification'
        })
      }

      console.error(e)
    }
  }

  const notificationstableHeader = [
    { header: 'Description', id: 'description' },
    { header: 'Webhook URL', id: 'url' },
    { header: 'Events', id: 'events' },
    { header: 'Resources', id: 'resources' },
    { header: '', id: 'controls' }
  ]

  const notificationTableData = notifications.map((notification, notificationNumber) => {
    const events = notification.triggers.reduce<JSX.Element[]>((prev, current) => {
      const name = current.resource + '.' + current.verb
      prev.push(<div key={name}>{name}</div>)
      return prev
    }, [])

    return {
      description: { label: notification.description, value: notification.description },
      url: { label: notification.actions[0].webhookUrl.substring(0, 100), value: notification.actions[0].webhookUrl },
      events: { label: events, value: '' },
      resources: { label: <NotificationResourceList notification={notification} />, value: '' },
      controls: {
        label: [
          <div key={notificationNumber} className="flex text-right justify-right">
            <Button
              internalType="secondary"
              className="ml-auto"
              onClick={() => {
                setShowNotificationExecutions(true)
                setSelectedNotification(notification)
              }}
            >
              Logs
            </Button>
            <Button
              className="ml-10px"
              onClick={() => {
                setShowEditNotificationModal(true)
                setSelectedNotification(notification)
              }}
            >
              Edit
            </Button>
            <Button
              internalType="danger"
              className="ml-10px"
              onClick={() => {
                setSelectedNotification(notification)
                setDeleteNotification(true)
              }}
            >
              Delete
            </Button>
          </div>
        ],
        value: ''
      }
    }
  })

  return (
    <div className="flex flex-column w-full h-full overflow-y-scroll" style={{ padding: '10px 30px' }}>
      <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px">
        <div className="w-full font-medium text-base my-20px">
          Webhooks are sent when certain events happen within the Morta platform. For example, Morta will send event
          data to the endpoint of your choice when a table cell is updated.
        </div>
        <div className="ml-auto" onClick={() => setShowAddNotificationModal(true)} style={{ width: '150px' }}>
          <Button>+ Add Webhook</Button>
        </div>
      </div>

      <Table
        data={notificationTableData}
        include={notificationstableHeader}
        defaultSort={'url'}
        defaultSortAscending={true}
        sort={true}
      />

      {showAddNotificationModal && (
        <Modal
          id={'add-notification-modal'}
          open={showAddNotificationModal}
          setOpen={setShowAddNotificationModal}
          title="Create New Webhook"
        >
          <NotificationForm project={project} onSubmit={handleCreateNotification} />
        </Modal>
      )}

      {deleteNotification && (
        <DeleteResource
          id="delete-notification-modal"
          open={deleteNotification}
          setOpen={setDeleteNotification}
          resourceName={selectedNotification ? selectedNotification.description : ''}
          deleteResource={() => {
            if (selectedNotification) handleDeleteNotification(selectedNotification)
          }}
        />
      )}

      {showEditNotificationModal && (
        <Modal
          id={'edit-notification-modal'}
          open={showEditNotificationModal}
          setOpen={setShowEditNotificationModal}
          title="Edit Webhook"
        >
          <NotificationForm
            project={project}
            onSubmit={handleUpdateNotification}
            notification={selectedNotification!}
          />
        </Modal>
      )}

      {showNotificationExecutions && (
        <Modal
          id={'show-notification-executions-modal'}
          open={showNotificationExecutions}
          setOpen={setShowNotificationExecutions}
          title="Webhooks Sent"
        >
          <NotificationExecutions project={project} notification={selectedNotification!} />
        </Modal>
      )}
    </div>
  )
}

export default DevelopersBox
