import React, { useEffect, useState } from 'react'
import Button from 'components/button'
import api from 'helpers/api'

const Redis = () => {
  const [redisStats, setRedisStats] = useState<any>()

  const flushRedis = async () => {
    await api.flushRedis()
  }

  const fetchRedisStats = async () => {
    const { data } = await api.redisStats()
    setRedisStats(data)
  }

  useEffect(() => {
    fetchRedisStats()
    const intervalId = setInterval(fetchRedisStats, 10000)
    document.title = 'Morta | Redis Stats'

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <>
      <div className="mb-8">
        {redisStats &&
          redisStats.memory_stats &&
          Object.keys(redisStats.memory_stats).map((stat: string, statNumber: number) => {
            return (
              <div className="mb-2" key={statNumber}>
                <div>{`${stat}: ${JSON.stringify(redisStats.memory_stats[stat])}`}</div>
              </div>
            )
          })}
      </div>
      <div className="mb-8">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Redis Keys
        </div>
        {redisStats &&
          redisStats.keys &&
          redisStats.keys.map((key: any, keyNumber: number) => {
            return (
              <div className="mb-2" key={keyNumber}>
                <div>
                  Key #{keyNumber + 1}: {key.name} (expires {key.expire_time})
                </div>
              </div>
            )
          })}
      </div>
      <div className="mb-8">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Redis Management
        </div>
        <Button internalType="danger" onClick={() => flushRedis()}>
          Flush Redis
        </Button>
      </div>
    </>
  )
}

export default Redis
