import React, { useState } from 'react'
import { useProject } from 'hooks/project'
import Button from 'components/button'
import ProjectPermissions from 'components/permissions/views/user/components/project'
import IndividualUserPermissions from 'components/permissions/views/user/components/user'
import { IAccessPolicy, IContextMenuState, IProjectUser } from 'types'
import { UserRoles, AccessAttributeKinds, INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import SelectMenu from 'components/select/components/menu'

interface Props {
  display: boolean
  policies: IAccessPolicy[]
  onCreatePolicy: (role: UserRoles, attributeKind: AccessAttributeKinds, attributeId?: string) => void
  onUpdatePolicy: (policy: IAccessPolicy, role: UserRoles) => void
  excludedPermissionCategories?: UserRoles[]
}

const UserPermissions: React.FC<Props> = ({
  display,
  policies,
  onCreatePolicy,
  onUpdatePolicy,
  excludedPermissionCategories
}) => {
  const { project } = useProject()

  const [addUserMenu, setAddUserMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  const handleGrantUserPermission = (userPublicId: string) => {
    onCreatePolicy(UserRoles.VIEWER, AccessAttributeKinds.USER, userPublicId)
    handleCancelUserPermission()
  }

  // Policy for the current project (can be undefined)
  const projectPolicy = policies.find(
    (policy) =>
      policy.accessAttribute.kind === 'project' && policy.accessAttribute.project!.publicId === project.publicId
  )

  // User policies
  const userPolicies = policies.filter((policy) => policy.accessAttribute.kind === 'user')

  const handleCancelUserPermission = () => {
    setAddUserMenu(INITIAL_CONTEXT_MENU_STATE)
  }

  const userMenuWidth = 300

  return (
    <div style={{ display: display ? 'block' : 'none', height: display ? '95%' : '0%', overflowY: 'auto' }}>
      <div style={{ display: 'flex', margin: '0px 5px 10px 0px' }}>
        <Button
          style={{ marginLeft: 'auto' }}
          onClick={(event) =>
            setAddUserMenu({
              open: true,
              top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
              left: `${event.currentTarget.getBoundingClientRect().left - userMenuWidth + 160}px`,
              right: 'auto',
              bottom: 'auto'
            })
          }
        >
          Grant User Permission
        </Button>
      </div>

      <ProjectPermissions
        policy={projectPolicy}
        onUpdatePolicy={onUpdatePolicy}
        onCreatePolicy={onCreatePolicy}
        excludedPermissionCategories={excludedPermissionCategories}
      />

      {userPolicies.map((policy) => (
        <IndividualUserPermissions
          key={policy.publicId}
          policy={policy}
          onUpdatePolicy={onUpdatePolicy}
          excludedPermissionCategories={excludedPermissionCategories}
        />
      ))}

      {addUserMenu.open && (
        <SelectMenu
          options={
            project
              ? project.projectList
                  .sort((a: IProjectUser, b: IProjectUser) =>
                    a.user.name && b.user.name && a.user.name.toLowerCase() > b.user.name?.toLowerCase()
                      ? 1
                      : a.user.name?.toLowerCase() &&
                        b.user.name?.toLowerCase() &&
                        a.user.name?.toLowerCase() < b.user.name?.toLowerCase()
                      ? -1
                      : 0
                  )
                  .filter((element: IProjectUser) => {
                    return (
                      userPolicies.findIndex(
                        (policy: IAccessPolicy) => policy.accessAttribute.user?.publicId === element.user.publicId
                      ) === -1
                    )
                  })
                  .map((element: IProjectUser) => {
                    const user = element.user
                    if (user && user.publicId && user.name) {
                      return {
                        value: user.publicId,
                        label: `${user.name} (${user.email})`
                      }
                    } else {
                      return { value: '', label: '' }
                    }
                  })
              : []
          }
          onOptionClick={(option: string) => handleGrantUserPermission(option)}
          id="select-options"
          menuState={addUserMenu}
          setMenuState={setAddUserMenu}
          width={userMenuWidth}
        />
      )}
    </div>
  )
}

export default UserPermissions
