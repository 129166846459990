import React, { useEffect, useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface ProcoreIntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedCompany: string
  selectedProject: string
  setSelectedCompany: (company: string) => void
  setSelectedProject: (project: string) => void
}

const Procore: React.FC<ProcoreIntegrationProps> = ({
  selectedCompany,
  selectedProject,
  setSelectedCompany,
  setSelectedProject
}) => {
  const { user } = useAuth()
  const { setSnackbarMessage } = useApplicationStore()

  const [companies, setCompanies] = useState<ISyncResource[]>()
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false)
  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)

  useEffect(() => {
    if (user && user.procoreConnected) {
      setLoadingCompanies(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: `https://api.procore.com/rest/v1.0/companies?page=1&per_page=100&include_free_companies=true`,
          sourceSystem: IntegrationProviders.PROCORE,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let companies: ISyncResource[] = []
          if (response.data.status === '200') {
            companies = response.data.body.map((company: any) => {
              return {
                id: company['id'].toString(),
                name: company['name']
              }
            })
          }
          setCompanies(companies)
          setLoadingCompanies(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting Procore companies.' })
          setLoadingCompanies(false)
        })
    }
  }, [])

  useEffect(() => {
    if (user && user.procoreConnected && selectedCompany && selectedCompany !== '') {
      setLoadingProjects(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: `https://api.procore.com/rest/v1.0/companies/${selectedCompany}/projects?page=1&per_page=100`,
          sourceSystem: IntegrationProviders.PROCORE,
          headers: {
            Authorization: 'Bearer $token',
            'Procore-Company-Id': selectedCompany
          }
        })
        .then((response) => {
          let projects: ISyncResource[] = []
          if (response.data.status === '200') {
            projects = response.data.body.map((project: any) => {
              return {
                id: project['id'].toString(),
                name: project['name']
              }
            })
          }
          setProjects(projects)
          setLoadingProjects(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting Procore projects.' })
          setLoadingProjects(false)
        })
    }
  }, [selectedCompany])

  return (
    <div>
      {user && user.procoreConnected && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Company<span className="text-red">*</span>
          </div>

          <Select
            options={
              companies
                ? companies.map((company) => {
                    return {
                      label: company.name,
                      value: company.id
                    }
                  })
                : []
            }
            onOptionClick={(option) => {
              setSelectedCompany(option)
              setSelectedProject('')
              setProjects(undefined)
            }}
            optionsSelected={[selectedCompany]}
            loading={loadingCompanies}
          />
        </div>
      )}

      {user && user.procoreConnected && selectedCompany !== '' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Project<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects.map((project) => {
                    return {
                      label: project.name,
                      value: project.id
                    }
                  })
                : []
            }
            onOptionClick={(option) => {
              setSelectedProject(option)
            }}
            optionsSelected={[selectedProject]}
            loading={loadingProjects}
          />
        </div>
      )}
    </div>
  )
}

export default Procore
