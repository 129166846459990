import React, { useRef, useState, useEffect } from 'react'
import { uuidv4 } from 'helpers/utils'
import Menu, { MenuProps } from 'components/menu'
import FilterItem from './FilterItem'
import { ITableViewFilter, ITableColumn, ITableViewColumn } from 'types'
import { IFilterType, IUIFilter } from 'components/spreadsheet/types'
import { isUIFilterActive, getColumnsPerUserRole } from 'components/spreadsheet/helpers/functions'
import { BLOCK_FILTER_BY, CommentDisplayTypes, ValidationDisplayTypes } from 'components/spreadsheet/constants/const'
import { useApplicationStore } from 'hooks/application'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import Select from 'components/select'

const FilterMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()

  const globalFilters = spreadsheetData.userConfiguration.filterSettings.map((filter) => ({
    filter,
    cid: uuidv4()
  }))

  const [localFilters, setLocalFilters] = useState<IUIFilter[]>(globalFilters)

  const timeoutRef = useRef<TimeoutID | null>(null)
  const { setSnackbarMessage } = useApplicationStore()

  // When there are changes to filters, propagate changes to main state
  useEffect(() => {
    const filterChange = localFilters !== globalFilters
    if (spreadsheetData.loading || spreadsheetData.streaming || !filterChange) {
      return
    }
    if (timeoutRef.current) {
      cancelTimeout(timeoutRef.current)
    }
    timeoutRef.current = requestTimeout(() => {
      // Convert UI filters to data filters, and remove any data filters that are empty
      const filters = localFilters.map((ui) => ui.filter).filter(isUIFilterActive)

      setSpreadsheetData({ type: 'UPDATE_FILTERS', filters })
    }, 1000)
  }, [localFilters])

  useEffect(() => {
    if (menuState.defaultFilter) {
      setLocalFilters([...localFilters, { filter: menuState.defaultFilter, cid: uuidv4() }])
    }
  }, [])

  const handleAddFilter = (columnId: string) => {
    const column = spreadsheetData.viewDetails.columns.find((column: ITableViewColumn) => column.publicId === columnId)
    if (column) {
      if (BLOCK_FILTER_BY.includes(column.kind)) {
        setSnackbarMessage({ status: 'error', message: `Filtering columns of type ${column.kind} is not allowed ` })
        return
      }
      const newColumn = spreadsheetData.viewDetails.columns.find((c) => c.publicId === columnId)!
      const newFilter: ITableViewFilter = {
        columnId: newColumn.publicId,
        columnName: newColumn.name,
        filterType: IFilterType.contains,
        value: '',
        multipleValues: [],
        orGroup: 'main'
      }

      setLocalFilters([...localFilters, { cid: uuidv4(), filter: newFilter }])
    }
  }

  const handleFilterChange = (newFilter: IUIFilter) => {
    const newFilters = localFilters.map((filter) => {
      if (filter.cid == newFilter.cid) {
        return newFilter
      } else {
        return filter
      }
    })

    setLocalFilters(newFilters)
  }

  const handleDelete = (uiFilter: IUIFilter) => {
    const newFilters = localFilters.filter((f) => f.cid !== uiFilter.cid)
    setLocalFilters(newFilters)
  }

  const handleChangeGrouping = (changedUIFilter: IUIFilter, grouping: string) => {
    const newFilters = localFilters.map((uiFilter) => {
      if (changedUIFilter.cid === uiFilter.cid) {
        const groupId = grouping === 'and' ? 'main' : uuidv4()
        return {
          ...uiFilter,
          filter: {
            ...uiFilter.filter,
            orGroup: groupId
          }
        }
      } else {
        return uiFilter
      }
    })

    setLocalFilters(newFilters)
  }

  const handleValidationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value)
    setSpreadsheetData({ type: 'CHANGE_DISPLAY_VALIDATION_ROWS', displayValidationErrorRows: newValue })
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
  }

  const handleCommentRowsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = parseInt(event.target.value)
    setSpreadsheetData({ type: 'CHANGE_DISPLAY_COMMENT_ROWS', displayCommentRows: newValue })
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
  }

  const columnOptions = spreadsheetData.viewDetails.columns
    .filter((column: ITableColumn) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId))
    .map((column) => ({
      label: column.name,
      value: column.publicId
    }))

  const allColumns = getColumnsPerUserRole(spreadsheetData)

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {localFilters.length === 0 && (
          <div>
            <div className="font-bold truncate" style={{ padding: '3px 10px', marginBottom: '10px' }}>
              You have no active filters in this view.
            </div>
            <div className="truncate" style={{ padding: '5px 10px', marginBottom: '20px', marginTop: '20px' }}>
              Filters allow you to show rows that match your set conditions.
            </div>
          </div>
        )}

        {localFilters.length > 0 && (
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {localFilters.map((uiFilter, idx) => (
              <FilterItem
                key={uiFilter.cid}
                position={idx}
                uiFilter={uiFilter}
                columns={allColumns}
                onChange={handleFilterChange}
                onDelete={handleDelete}
                onChangeGrouping={handleChangeGrouping}
                optionsOpen={menuState.defaultFilter !== undefined}
              />
            ))}
          </div>
        )}

        <div className="flex items-center rounded" style={{ padding: '10px', height: '50px', marginTop: '20px' }}>
          <span style={{ minWidth: '150px', marginRight: '10px' }}>Column Filters:</span>
          <div className="w-full">
            <Select options={columnOptions} onOptionClick={(option) => handleAddFilter(option)} />
          </div>
        </div>

        <div className="flex items-center rounded" style={{ padding: '10px', height: '50px' }}>
          <span style={{ minWidth: '150px', marginRight: '10px' }}>Validation Filters:</span>
          <div className="w-full">
            <select value={spreadsheetData.viewDetails.displayValidationErrorRows} onChange={handleValidationChange}>
              <option value={ValidationDisplayTypes.SHOW_VALIDATION}>Show all</option>
              <option value={ValidationDisplayTypes.HIDE_VALIDATION}>Exclude validation errors</option>
              <option value={ValidationDisplayTypes.ONLY_VALIDATION}>Only show validation errors</option>
            </select>
          </div>
        </div>
        <div className="flex items-center rounded" style={{ padding: '10px', height: '50px' }}>
          <span style={{ minWidth: '150px', marginRight: '10px' }}>Comment Filters:</span>
          <div className="w-full">
            <select value={spreadsheetData.viewDetails.displayCommentRows} onChange={handleCommentRowsChange}>
              <option value={CommentDisplayTypes.SHOW_ALL}>Show all</option>
              <option value={CommentDisplayTypes.SHOW_WITH_COMMENTS}>Show rows with comments</option>
              <option value={CommentDisplayTypes.SHOW_RESOLVED}>Show rows with fully resolved comments</option>
              <option value={CommentDisplayTypes.SHOW_UNRESOLVED}>Show rows with unresolved comments</option>
            </select>
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(FilterMenu)
