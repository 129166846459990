import React, { useEffect, useState } from 'react'
import api from 'helpers/api'
import { ILogObject, IProjectUser, OptionProps, IUserObject } from 'types'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { Table } from 'components/table'
import { Copy } from 'components/icons'
import { dateTime } from 'helpers/date'
import Button from 'components/button'
import Select from 'components/select'
import { AUDIT_RED_KEYWORDS, AUDIT_GREEN_KEYWORDS } from 'app-constants'
import constants from 'style/constants.module.scss'

interface AuditProps {
  type?: string
  resourceId?: string
  searchTerm?: string
  verbs?: string[]
}

const Audit: React.FC<AuditProps> = ({ type, resourceId, searchTerm, verbs }: AuditProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const [config, setConfig] = useState<{
    search: string
    page: number
    user: string
    verbs: string[]
    startDate: string
    endDate: string
  }>({
    search: searchTerm ? searchTerm : '',
    page: 1,
    user: '',
    verbs: verbs ? verbs : [],
    startDate: '',
    endDate: ''
  })

  const [logs, setLogs] = useState<ILogObject[]>([])
  const [availableEventNames, setAvailableEventNames] = useState<string[]>([])
  const [users, setUsers] = useState<OptionProps[]>([])
  const [noMoreLogs, setNoMoreLogs] = useState<boolean>(false)
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()

  useEffect(() => {
    fetchAvailabileEventTypes()
    if (resourceId && type) {
      setUsers(
        project.loading
          ? []
          : project.projectList
              .sort((a: IProjectUser, b: IProjectUser) => {
                const aUser = a?.user
                const bUser = b?.user

                if (aUser && aUser.name && bUser && bUser.name) {
                  if (aUser.name.toLowerCase() > bUser.name.toLowerCase()) return 1
                  else if (aUser.name.toLowerCase() < bUser.name.toLowerCase()) return -1
                  else return 0
                } else {
                  return 0
                }
              })
              .map((user: IProjectUser) => {
                return { label: `${user.user.name} (${user.user.email})`, value: user.user.publicId }
              })
      )
    } else {
      api
        .getAllUsers()
        .then((response) => {
          setUsers(
            response.data
              .sort((aUser: IUserObject, bUser: IUserObject) => {
                if (aUser.name.toLowerCase() > bUser.name.toLowerCase()) return 1
                else if (aUser.name.toLowerCase() < bUser.name.toLowerCase()) return -1
                else return 0
              })
              .map((user: IUserObject) => {
                return { label: `${user.name} (${user.email})`, value: user.publicId }
              })
          )
        })
        .catch((error) => {
          console.error(error)
          setUsers([])
        })
    }
  }, [])

  const fetchAvailabileEventTypes = async () => {
    try {
      const response = await api.getNotificationEventTypes()
      setAvailableEventNames(response.data)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setLoading(true)
    let newLogs: ILogObject[] = logs
    if (config.page === 1) newLogs = []

    const user = config.user === '' ? null : config.user
    const verbs = config.verbs
    const startDate = config.startDate === '' ? null : new Date(config.startDate).toISOString()
    const endDate = config.endDate === '' ? null : new Date(config.endDate).toISOString()
    const search = config.search === '' ? null : config.search

    if (resourceId && type) {
      api
        .getEvents(resourceId, type, config.page, user, verbs, startDate, endDate, search)
        .then((response) => {
          const additionalLogs: ILogObject[] = response.data
          setLogs(newLogs.concat(additionalLogs))
          setLoading(false)
          if (additionalLogs.length < 50) setNoMoreLogs(true)
          else if (additionalLogs.length === 50) setNoMoreLogs(false)
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({
            status: 'error'
          })
          setLoading(false)
          setNoMoreLogs(true)
        })
    } else {
      api
        .getAdminEvents(config.page, user, verbs, startDate, endDate, search)
        .then((response) => {
          const additionalLogs: ILogObject[] = response.data
          setLogs(newLogs.concat(additionalLogs))
          setLoading(false)
          if (additionalLogs.length < 50) setNoMoreLogs(true)
          else if (additionalLogs.length === 50) setNoMoreLogs(false)
        })
        .catch((error) => {
          console.error(error)
          setSnackbarMessage({
            status: 'error'
          })
          setLoading(false)
          setNoMoreLogs(true)
        })
    }
  }, [config])

  const handleUserChange = (user: string) => {
    setConfig({
      ...config,
      page: 1,
      user
    })
  }

  const handleVerbChange = (verb: string) => {
    let new_verbs: string[] = []
    if (verb === '') {
      new_verbs = []
    } else if (config.verbs.includes(verb)) {
      new_verbs = config.verbs.filter((v) => v !== verb)
    } else {
      new_verbs = [...config.verbs, verb]
    }

    setConfig({
      ...config,
      page: 1,
      verbs: new_verbs
    })
  }

  const handleStartDateChange = (startDate: string) => {
    setConfig({
      ...config,
      page: 1,
      startDate
    })
  }

  const handleEndDateChange = (endDate: string) => {
    setConfig({
      ...config,
      page: 1,
      endDate
    })
  }

  const handleChangeCopy = (change: string) => {
    try {
      navigator.clipboard.writeText(change)
      setSnackbarMessage({
        status: 'success',
        message: `Copied change value to clipboard`
      })
    } catch (error) {
      console.error(error)
      setSnackbarMessage({ status: 'error' })
    }
  }

  return (
    <div className="flex flex-column overflow-hidden" style={{ maxHeight: '100%' }}>
      <div className="flex items-center text-sm mb-2" style={{ height: '70px' }}>
        <div className="mr-2 w-250px">
          <Select
            options={users}
            onOptionClick={(option) => handleUserChange(option)}
            optionsSelected={config.user === '' ? [] : [config.user]}
            placeholder="All users"
          />
        </div>
        <div style={{ marginLeft: '5px', cursor: 'pointer' }} className="mr-2" onClick={() => handleUserChange('')}>
          ❌
        </div>
        <div className="mr-2 w-250px">
          <Select
            options={availableEventNames.map((eventTypeName) => {
              return { label: eventTypeName, value: eventTypeName }
            })}
            onOptionClick={(option) => handleVerbChange(option)}
            optionsSelected={config.verbs}
            multiselect={true}
            placeholder="All event types"
          />
        </div>
        <div style={{ marginLeft: '5px', cursor: 'pointer' }} className="mr-2" onClick={() => handleVerbChange('')}>
          ❌
        </div>
        <label style={{ display: 'block' }} htmlFor="start-date">
          From:
        </label>
        <input
          type="datetime-local"
          id="start-date"
          name="start-date"
          style={{ marginLeft: '5px', userSelect: 'none', cursor: 'pointer' }}
          value={config.startDate}
          max={config.endDate}
          onChange={(event) => handleStartDateChange(event.target.value)}
          onClick={(event) => event.stopPropagation()}
        />
        <div
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          className="mr-2"
          onClick={() => handleStartDateChange('')}
        >
          ❌
        </div>
        <label style={{ display: 'block' }} htmlFor="end-date">
          To:
        </label>
        <input
          type="datetime-local"
          id="end-date"
          name="end-date"
          style={{ marginLeft: '5px', userSelect: 'none', cursor: 'pointer' }}
          min={config.startDate}
          value={config.endDate}
          onChange={(event) => handleEndDateChange(event.target.value)}
          onClick={(event) => event.stopPropagation()}
        />
        <div style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleEndDateChange('')}>
          ❌
        </div>
      </div>

      <Table
        data={logs.map((log) => {
          return {
            name: {
              label: (
                <div className="flex items-center">
                  <img
                    src={log.user && log.user.profilePicture ? log.user.profilePicture : '/assets/images/user.png'}
                    style={{
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                      objectFit: 'cover',
                      marginRight: '10px'
                    }}
                    alt={`${log.user && log.user.name ? log.user.name : 'Anonymous'} profile picture`}
                  />
                  {log.user && log.user.name && log.user.firebaseUserId ? (
                    <a
                      href={`/profile/${log.user.firebaseUserId}/information`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {log.user.name}
                    </a>
                  ) : (
                    <div>Anonymous</div>
                  )}
                </div>
              ),
              value: log.user && log.user.name ? log.user.name : 'Anonymous'
            },
            project: {
              label: log.contextProject ? (
                <a href={`/project/${log.contextProject.publicId}`} target="_blank" rel="noopener noreferrer">
                  {log.contextProject.name}
                </a>
              ) : (
                ''
              ),
              value: log.contextProject ? log.contextProject.name : ''
            },
            createdAt: { label: dateTime(new Date(Date.parse(log.createdAt))).toLocaleString(), value: log.createdAt },
            action: {
              label: (
                <span
                  className="font-bold rounded"
                  style={{
                    backgroundColor:
                      AUDIT_RED_KEYWORDS.findIndex((keyword) => log.verb.includes(keyword)) !== -1
                        ? constants.lightRed
                        : AUDIT_GREEN_KEYWORDS.findIndex((keyword) => log.verb.includes(keyword)) !== -1
                        ? constants.green
                        : constants.lightBlue,
                    padding: '5px'
                  }}
                >
                  {log.resource}.{log.verb}
                </span>
              ),
              value: `${log.resource}.${log.verb}`
            },
            process: {
              label:
                log.contextProject && log.contextProcess ? (
                  <a
                    href={
                      log.contextProcessSection && log.contextProcessSection.publicId
                        ? `/project/${log.contextProject.publicId}/process/${log.contextProcess.publicId}?section=${log.contextProcessSection.publicId}`
                        : `/project/${log.contextProject.publicId}/process/${log.contextProcess.publicId}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {log.contextProcess.name}
                  </a>
                ) : (
                  'n/a'
                ),
              value: log.contextProcess ? log.contextProcess.name : ''
            },
            processSection: {
              label: log.contextProcessSection ? log.contextProcessSection.name : 'n/a',
              value: log.contextProcessSection ? log.contextProcessSection.name : ''
            },
            table: {
              label: log.contextTable?.publicId ? (
                <a
                  href={`/project/${log.contextProject?.publicId}/table/${log.contextTable?.publicId}/view/${
                    log.contextTableView?.publicId ? log.contextTableView?.publicId : log.contextTable?.defaultViewId
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {log.contextTable?.name}
                </a>
              ) : (
                'n/a'
              ),
              value: log.contextTable ? log.contextTable.name : ''
            },
            column: {
              label: log.contextTableColumn ? log.contextTableColumn.name : 'n/a',
              value: log.contextTableColumn ? log.contextTableColumn.name : ''
            },
            change: {
              label:
                log.change && log.change !== 'null' && log.change !== '{}' ? (
                  <div
                    className="cursor-pointer hover-bg-light-grey transition-all rounded"
                    style={{ padding: '5px', maxWidth: 'fit-content' }}
                    title={log.change}
                    onClick={() => handleChangeCopy(log.change ? log.change : '')}
                  >
                    <Copy />
                  </div>
                ) : (
                  ''
                ),
              value: ''
            }
          }
        })}
        include={[
          { header: 'User', id: 'name' },
          { header: 'Project', id: 'project' },
          { header: 'Date', id: 'createdAt' },
          { header: 'Action', id: 'action' },
          { header: 'Process', id: 'process' },
          { header: 'Process Section', id: 'processSection' },
          { header: 'Table', id: 'table' },
          { header: 'Column', id: 'column' },
          { header: 'Change', id: 'change' }
        ]}
        defaultSort={'createdAt'}
        defaultSortAscending={true}
        sort={false}
        loading={false}
      />
      {!noMoreLogs && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            style={{ width: '150px' }}
            onClick={() => setConfig({ ...config, page: config.page + 1 })}
            isLoading={loading}
          >
            Get More Logs
          </Button>
        </div>
      )}
    </div>
  )
}

export default Audit
