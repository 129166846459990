import React, { useState } from 'react'
import Modal from 'components/modal'
import Button from 'components/button'
import { Table } from 'components/table'
import { IProjectSecret } from 'types'
import ProjectSecretForm, {
  ProjectSecretFormValues
} from 'views/settings/components/secrets/components/ProjectSecretForm'

interface Props {
  secrets: IProjectSecret[]
  onDelete: (secret: IProjectSecret) => void
  onEdit: (secret: IProjectSecret, values: ProjectSecretFormValues, closeModal: () => void) => Promise<void>
}

const ProjectSecretsList: React.FC<Props> = ({ secrets, onDelete, onEdit }) => {
  const [currentlyEditingSecret, setCurrentlyEditingSecret] = useState<boolean>(false)
  const [selectedSecret, setSelectedSecret] = useState<IProjectSecret>()

  const handleEdit = async (values: ProjectSecretFormValues) => {
    try {
      await onEdit(selectedSecret!, values, () => {
        setCurrentlyEditingSecret(false)
        setSelectedSecret(undefined)
      })
    } catch (e) {
      console.error(e)
    }
  }

  const header = [
    { header: 'Name', id: 'name' },
    { header: 'Value', id: 'value' },
    { header: 'Created At', id: 'createdAt' },
    { header: 'Updated At', id: 'updatedAt' },
    { header: '', id: 'controls' }
  ]

  const hideValue = (value: string) => {
    return new Array(value.length + 1).join('*')
  }

  const tableData = secrets.map((secret) => {
    return {
      name: { label: secret.name, value: secret.name },
      value: {
        label: secret.isReadOnly ? secret.value : hideValue(secret.value),
        value: secret.isReadOnly ? secret.value : hideValue(secret.value)
      },
      createdAt: {
        label: secret.createdAt ? new Date(secret.createdAt).toLocaleDateString() : '',
        value: secret.createdAt
      },
      updatedAt: {
        label: secret.updatedAt ? new Date(secret.updatedAt).toLocaleDateString() : '',
        value: secret.updatedAt
      },
      controls: {
        label: !secret.isReadOnly ? (
          [
            <div key={secret.publicId} className="flex text-right justify-right">
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  setCurrentlyEditingSecret(true)
                  setSelectedSecret(secret)
                }}
              >
                Edit
              </Button>
              <Button internalType="danger" style={{ marginLeft: '10px' }} onClick={() => onDelete(secret)}>
                Delete
              </Button>
            </div>
          ]
        ) : (
          <div key={secret.publicId} className="flex text-right justify-right">
            <div style={{ marginLeft: 'auto' }}>This secret cannot be edited</div>
          </div>
        ),
        value: ''
      }
    }
  })

  return (
    <>
      <Table data={tableData} include={header} defaultSort={'name'} defaultSortAscending={true} sort={false} />
      <Modal
        id="editing-secret-modal"
        open={currentlyEditingSecret}
        setOpen={setCurrentlyEditingSecret}
        onClose={() => setSelectedSecret(undefined)}
        title="Edit Project Secret"
      >
        <ProjectSecretForm secret={selectedSecret} onSubmit={handleEdit} />
      </Modal>
    </>
  )
}

export default ProjectSecretsList
