import React, { useEffect, useState } from 'react'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface MortaIntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedProjects: string[]
  setSelectedProjects: (projects: string[]) => void
}

const Morta: React.FC<MortaIntegrationProps> = ({ selectedProjects, setSelectedProjects }) => {
  const { user } = useAuth()
  const { setSnackbarMessage } = useApplicationStore()

  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      api
        .getOwnerProjects()
        .then((response) => {
          const projects = response.data.map((project) => {
            return {
              id: project.publicId,
              name: project.name
            }
          })
          setProjects(projects)
          setLoadingProjects(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'There was an error fetching projects' })
          setLoadingProjects(false)
        })
    }
  }, [])

  return (
    <div>
      {user && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Morta Workspaces<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((project) => {
                      return {
                        label: project.name,
                        value: project.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              const newOptions = [...selectedProjects]
              const indexOf = newOptions.findIndex((value) => value === option)
              if (indexOf === -1) {
                newOptions.push(option)
              } else {
                newOptions.splice(indexOf, 1)
              }
              setSelectedProjects(newOptions)
            }}
            optionsSelected={selectedProjects}
            setOptionsSelected={(options) => {
              setSelectedProjects(options)
            }}
            loading={loadingProjects}
            multiselect={true}
          />
        </div>
      )}
    </div>
  )
}

export default Morta
