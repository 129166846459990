import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Copy, Delete, EditBy, ExpandAll, Export, Plus, Restore, Template, Comment } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { useDataContext } from 'components/process/contexts/data'
import ResourceType from 'components/resourceType'
import Toggle from 'components/toggle'
import api from 'helpers/api'
import useProject from 'hooks/project'

interface SettingsMenuProps extends MenuProps {
  setExportModal: (open: boolean) => void
  setRespondersModal: (open: boolean) => void
  addSection: (parentId: string | null) => void
  setRestoreSectionModal: (open: boolean) => void
  setDuplicateModal: (open: boolean) => void
  setArchiveModal: (open: boolean) => void
}

const Settings: React.FC<SettingsMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  setExportModal,
  setArchiveModal,
  setRespondersModal,
  addSection,
  setRestoreSectionModal,
  setDuplicateModal
}) => {
  const { project } = useProject()
  const { process, updateProcess, isAdmin } = useDataContext()

  const restoreProcess = () => {
    if (project && process) {
      api
        .restoreProcess(process.publicId, {
          projectId: project.publicId,
          context: { projectId: project.publicId }
        })
        .then(() => {
          updateProcess({ ...process, isDeleted: false })
        })
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
        {isAdmin && process && !process.isDeleted && (
          <div className="p-10px border-b-1px border-solid border-grey">
            <li>
              <ResourceType
                handleChange={(value: string | null) => {
                  if (process) {
                    if (value) {
                      updateProcess({ ...process, type: value })
                    } else {
                      updateProcess({ ...process, type: '' })
                    }
                  }
                }}
                defaultValue={process && process.type ? process.type : ''}
                helperText="Set the document type"
              />
            </li>
          </div>
        )}

        {isAdmin && process && !process.isDeleted && (
          <div className="p-10px border-b-1px border-solid border-grey">
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => addSection(null)}
              >
                <Plus />
                <span className="ml-10px">Add Section</span>
              </div>
            </li>
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => {
                  setRestoreSectionModal(true)
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <Restore />
                <span className="ml-10px">Restore Section</span>
              </div>
            </li>
          </div>
        )}

        <div className="p-10px border-b-1px border-solid border-grey">
          {isAdmin && process && !process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => setRespondersModal(true)}
              >
                <EditBy />
                <span className="ml-10px">Set All Responders</span>
              </div>
            </li>
          )}

          {isAdmin && process && !process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                title="This will allow any user with access to duplicate the entire process, including all linked tables."
              >
                <Copy />
                <span className="ml-10px">Allow Duplication</span>
                <Toggle
                  toggled={process ? process.allowDuplication : false}
                  onToggle={(toggle) => {
                    if (process) {
                      updateProcess({ ...process, allowDuplication: toggle })
                    }
                  }}
                />
              </div>
            </li>
          )}

          {isAdmin && process && !process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                title="This will allow any user with access to use this process as a template."
              >
                <Template />
                <span className="ml-10px">Set As Template</span>
                <Toggle
                  toggled={process ? process.isTemplate : false}
                  onToggle={(toggle) => {
                    if (process) {
                      updateProcess({ ...process, isTemplate: toggle })
                    }
                  }}
                />
              </div>
            </li>
          )}

          {isAdmin && process && !process.isDeleted && process.isTemplate && (
            <li>
              <div
                className="w-full flex items-center"
                style={{ padding: '0 10px', height: '32px' }}
                title="This will allow any user with access to use this process as a template."
              >
                <span style={{ marginRight: '5px' }}>Template Category:</span>
                <input
                  value={process.templateCategory ? process.templateCategory : ''}
                  style={{ height: '25px' }}
                  onChange={(event) => {
                    if (process) {
                      updateProcess({ ...process, templateCategory: event.target.value })
                    }
                  }}
                />
              </div>
            </li>
          )}

          {isAdmin && process && !process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                title="This will expand all sections of the process by default when the process loads."
              >
                <ExpandAll />
                <span className="ml-10px">Expand Sections By Default</span>
                <Toggle
                  toggled={process ? process.expandByDefault : false}
                  onToggle={(toggle) => {
                    if (process) {
                      updateProcess({ ...process, expandByDefault: toggle })
                    }
                  }}
                />
              </div>
            </li>
          )}

          {isAdmin && process && !process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                title="This will allow users to comment on sections within this process."
              >
                <Comment />
                <span className="ml-10px">Enable Comments</span>
                <Toggle
                  toggled={process ? process.allowComments : false}
                  onToggle={(toggle) => {
                    if (process) {
                      updateProcess({ ...process, allowComments: toggle })
                    }
                  }}
                />
              </div>
            </li>
          )}

          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              onClick={() => setExportModal(true)}
              data-cy="download-pdf"
            >
              <Export />
              <span className="ml-10px">Export</span>
            </div>
          </li>

          {process && process.allowDuplication && !process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => setDuplicateModal(true)}
              >
                <Copy />
                <span className="ml-10px">Duplicate</span>
              </div>
            </li>
          )}
          {isAdmin && process && !process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => setArchiveModal(true)}
              >
                <Delete />
                <span className="ml-10px">Archive</span>
              </div>
            </li>
          )}
          {isAdmin && process && process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                onClick={() => restoreProcess()}
              >
                <Restore />
                <span className="ml-10px">Restore</span>
              </div>
            </li>
          )}
        </div>

        <div className="p-10px border-b-1px border-solid border-grey">
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
            >
              <span className="ml-10px italic text-secondary">
                Created on {process && new Date(process.createdAt).toLocaleString()}
              </span>
            </div>
          </li>
          {process && process.isDeleted && (
            <li>
              <div
                className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              >
                <span className="ml-10px italic text-secondary">
                  Archived on {process && process && new Date(process.deletedAt).toLocaleString()}
                </span>
              </div>
            </li>
          )}
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
            >
              <span className="ml-10px italic text-secondary">
                Contains {process && process.children.length} Sections
              </span>
            </div>
          </li>
        </div>
      </div>
    </Menu>
  )
}

export default Settings
