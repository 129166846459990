import React, { useState } from 'react'
import { EditorState } from 'draft-js'
import Button from 'components/button'
import Menu from 'components/menu'
import { IContextMenuState } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

interface IframeInsertProps {
  editorState: EditorState
  onChange: (nextEditorState: EditorState) => void
  modifier: (editorState: EditorState, url: string, height: number) => EditorState
}

interface AddIframeFormProps extends IframeInsertProps {
  onClose: () => void
}

const AddIframeForm: React.FC<AddIframeFormProps> = ({
  editorState,
  onChange,
  modifier,
  onClose
}: AddIframeFormProps) => {
  const defaultHeight = 500
  const [url, setUrl] = useState<string>('')
  const [height, setHeight] = useState<number>(defaultHeight)

  const handleSubmit = () => {
    onChange(modifier(editorState, url, height))
    handleClose()
  }

  const handleClose = () => {
    setUrl('')
    setHeight(defaultHeight)
    onClose()
  }

  const handleKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    } else if (event.key === 'Escape') {
      handleClose()
    }
  }

  return (
    <div className="flex" style={{ padding: '12px 20px' }}>
      <input
        id="iframe-url"
        placeholder="IFrame URL"
        value={url}
        style={{ marginRight: '10px' }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUrl(event.target.value)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPressed(event)}
      />
      <input
        id="iframe-height"
        type="number"
        value={height}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHeight(parseInt(event.target.value))}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPressed(event)}
      />
      <Button style={{ marginLeft: '7px' }} onClick={() => handleSubmit()}>
        Add
      </Button>
    </div>
  )
}

interface AddIconProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

const AddIframeIcon: React.FC<AddIconProps> = ({ onClick }) => {
  return <div className="flex items-center h-full cursor-pointer italic" onClick={onClick}>{`<iframe>`}</div>
}

const IframeInsert: React.FC<IframeInsertProps> = ({ editorState, onChange, modifier }) => {
  const [iframeMenu, setIframeMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  const iframeMenuWidth = 500
  const offset = 500

  return (
    <>
      <AddIframeIcon
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          setIframeMenu({
            open: true,
            top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
            left: `${event.currentTarget.getBoundingClientRect().left - iframeMenuWidth + offset}px`,
            right: 'auto',
            bottom: 'auto'
          })
        }}
      />
      {iframeMenu.open && (
        <Menu
          id="iframe-insert-menu"
          menuState={iframeMenu}
          setMenuState={setIframeMenu}
          width={iframeMenuWidth}
          zIndex={1000}
        >
          <AddIframeForm
            editorState={editorState}
            onChange={onChange}
            onClose={() => setIframeMenu(INITIAL_CONTEXT_MENU_STATE)}
            modifier={modifier}
          />
        </Menu>
      )}
    </>
  )
}

export default IframeInsert
