import React, { useState, useEffect } from 'react'
import { INotification, INotificationExecution, IProject } from 'types'
import api from 'helpers/api'
import { Table } from 'components/table'

interface Props {
  notification: INotification
  project: IProject
}

const NotificationExecutions: React.FC<Props> = ({ project, notification }) => {
  const [notificationExecutions, setNotificationExecutions] = useState<INotificationExecution[]>([])
  const [loaded, setLoaded] = useState(false)
  const size = 10

  useEffect(() => {
    fetchSentNotifications()
  }, [notification])

  const fetchSentNotifications = async () => {
    try {
      const response = await api.getSentProjectNotifications(project.publicId, notification.publicId, 1, size)
      setNotificationExecutions(response.data)
      setLoaded(true)
    } catch (e) {}
  }

  return (
    <>
      <p>Showing the last {size} webhook notifications sent</p>
      <br />

      <Table
        include={[
          {
            header: 'Sent',
            id: 'createdAt'
          },
          {
            header: 'Successful',
            id: 'isSuccess'
          }
        ]}
        data={notificationExecutions.map((notificationExecution) => {
          return {
            createdAt: { label: notificationExecution.createdAt, value: notificationExecution.createdAt },
            isSuccess: {
              label: notificationExecution.isSuccess ? 'Success' : 'Failed',
              value: notificationExecution.isSuccess ? 'Success' : 'Failed'
            }
          }
        })}
        defaultSort={'createdAt'}
        defaultSortAscending={true}
        sort={true}
        loading={!loaded}
      />
    </>
  )
}

export default NotificationExecutions
