import React, { useState, createRef, useEffect } from 'react'
import { useDataContext } from 'components/process/contexts/data'
import useApplicationStore from 'hooks/application'
import api from 'helpers/api'
import useProject from 'hooks/project'

const Image: React.FC = () => {
  const { project } = useProject()
  const { process, updateProcess, isAdmin, editMode } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()
  const inputFile = createRef<HTMLInputElement>()

  const [src, setSrc] = useState<string>()
  const [ready, setReady] = useState<boolean>(false)

  const onImageClick = () => {
    if (inputFile.current) {
      inputFile.current.click()
    }
  }

  const onImageChange = async (e: any) => {
    if (process) {
      const file = e.target.files[0]
      e.target.value = ''

      try {
        const response = await api.uploadFile(file)
        updateProcess({ ...process, logo: response.data.url })
      } catch (e) {
        setSnackbarMessage({ status: 'error', message: 'Error updating table logo, file type might not be supported' })
      }
    }
  }

  const signUrl = async () => {
    if (process && process.logo) {
      const signedFileTokenResponse = await api.signUrl(process.logo)
      setSrc(signedFileTokenResponse.data.url)
      setReady(true)
    }
  }

  useEffect(() => {
    if (process && process.logo) {
      signUrl()
    }
  }, [process && process.logo])

  return (
    <div className="relative w-full hover-opacity-1" style={{ height: '30vh' }}>
      {isAdmin && editMode && (
        <div
          className="absolute bg-white text-sm flex rounded overflow-hidden transition-all"
          style={{
            whiteSpace: 'nowrap',
            color: 'rgba(55, 53, 47, 0.6)',
            fill: 'rgba(55, 53, 47, 0.6)',
            maxWidth: `calc(100% - 16px)`,
            minHeight: '24px',
            right: '8px',
            bottom: '20px',
            zIndex: 2,
            marginRight: '150px'
          }}
        >
          <div
            className="text-primary cursor-pointer flex items-center transition-all bg-white hover-bg-light-grey font-semibold"
            style={{ padding: '4px 6px' }}
            onClick={() => {
              onImageClick()
            }}
          >
            Change Image
          </div>
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={onImageChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </div>
      )}
      {ready && src ? (
        <img src={src} className="h-full w-full" style={{ objectFit: 'cover' }} />
      ) : (
        <div className="h-full w-full" style={{ backgroundColor: project.primaryColour }} />
      )}
    </div>
  )
}

export default Image
