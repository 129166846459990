import React from 'react'
import { getQueryParameter } from 'helpers/utils'
import api from 'helpers/api'
import { useAuth } from 'hooks/auth'
import { HOME } from 'routes'
import Button from 'components/button'
import { APIError } from 'helpers/api'

const ConfirmEmailVerificationCode: React.FC = () => {
  const code = getQueryParameter('token')
  const [verified, setVerified] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const { updateUser } = useAuth()
  document.title = 'Morta | Verify Email'

  React.useEffect(() => {
    if (!code) {
      return
    }

    tryToConfirmUserAccount(code)
  }, [code])

  const tryToConfirmUserAccount = async (code: string) => {
    try {
      const response = await api.verifyAccount(code)
      updateUser(response.data)
      setVerified(true)
    } catch (e) {
      if (e instanceof APIError) {
        console.error(e.message)
      }

      setVerified(false)
    }
    setLoaded(true)
  }

  if (!code) {
    return <p>Please check you have typed in the url correctly. We could not find your verification code</p>
  }

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        {loaded && (
          <>
            {verified ? (
              <>
                <p>Success! Your account has been confirmed</p>
                <br />
                <Button to={HOME}>Go to your dashboard</Button>
              </>
            ) : (
              <p>
                There was a problem confirming your account. Verification emails are valid for 24 hours. You can try
                sending another verification email.
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ConfirmEmailVerificationCode
