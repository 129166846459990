import React, { useEffect, useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Grid, Form, Chart } from 'components/icons'
import { ITableViewWithColumns } from 'types'
import { useParams } from 'react-router-dom'
import constants from 'style/constants.module.scss'
import { ViewTypes } from 'components/spreadsheet/constants/const'
import { addNewView } from 'components/spreadsheet/contexts/data/actions'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { history } from 'helpers/history'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import Beta from 'components/beta'

interface RouteParams {
  projectId: string
  tableId: string
  viewId: string
}

const ViewMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()

  const [search, setSearch] = useState<string>('')
  const params = useParams<RouteParams>()
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const handleScroll = (event: MouseEvent) => {
    event.stopPropagation()
  }

  useEffect(() => {
    if (menuState.open) document.addEventListener('wheel', handleScroll)

    return () => {
      if (menuState.open) document.removeEventListener('wheel', handleScroll)
    }
  }, [menuState.open])

  const createNewViewSuccess = () => {
    setSnackbarMessage({ status: 'success', message: 'View created successfully!' })
    setMenuState({
      ...INITIAL_CONTEXT_MENU_STATE
    })
  }

  const createNewViewFailure = () => {
    setSnackbarMessage({ status: 'error', message: 'Unable to create new view. Please refresh and try again.' })
  }

  const createNewView = (type: number) => {
    addNewView(project.publicId, spreadsheetData, setSpreadsheetData, type, createNewViewSuccess, createNewViewFailure)
  }

  const searchedViews = spreadsheetData.tableView
    .filter((a: ITableViewWithColumns) => a.name.toLowerCase().includes(search))
    .sort((a: ITableViewWithColumns, b: ITableViewWithColumns) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
        <div
          className="relative border-b-1px border-solid border-grey flex items-center"
          style={{ padding: '15px 10px 15px 15px' }}
        >
          <input
            type="text"
            placeholder="Search Views"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value.toLowerCase())}
          />
        </div>

        <ul
          className="overflow-y-auto overflow-x-hidden"
          style={{ listStyle: 'none', padding: 0, margin: 0, maxHeight: '200px' }}
        >
          {searchedViews.map((tableView: ITableViewWithColumns, tableViewNumber: number) => {
            const viewSelected = tableView.publicId === spreadsheetData.viewDetails.publicId
            const colour =
              tableView.type === ViewTypes.SPREADSHEET
                ? constants.blue
                : tableView.type === ViewTypes.FORM
                ? constants.pink
                : constants.darkGreen
            return (
              <div
                key={tableViewNumber}
                className={`relative m-10px rounded select-none flex items-center cursor-pointer ${
                  viewSelected ? 'bg-light-blue' : ''
                }`}
                style={{ height: '33px', padding: '5px', color: colour }}
                onClick={() => {
                  setMenuState({
                    ...INITIAL_CONTEXT_MENU_STATE
                  })
                  const newUrl = `/project/${params.projectId}/table/${spreadsheetData.tableDetails.publicId}/view/${tableView.publicId}`
                  history.push(newUrl)
                }}
              >
                {tableView.type === ViewTypes.SPREADSHEET && <Grid />}
                {tableView.type === ViewTypes.FORM && <Form />}
                {tableView.type === ViewTypes.CHART && <Chart />}
                <div
                  className="relative m-10px rounded select-none cursor-pointer text-primary truncate  box-border"
                  style={{ lineHeight: '33px', height: '33px', flex: 1 }}
                >
                  {tableView.name}
                </div>
              </div>
            )
          })}

          {searchedViews.length === 0 && (
            <div
              className="relative m-10px rounded select-none flex items-center cursor-pointer"
              style={{ height: '33px', padding: '5px', color: constants.darkerGrey }}
            >
              No results
            </div>
          )}
        </ul>

        <div className="flex border-t-1px border-solid border-grey" style={{ padding: '15px 5px' }}>
          <li style={{ width: '100%' }}>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px',
                color: constants.blue
              }}
              onClick={() => createNewView(ViewTypes.SPREADSHEET)}
            >
              <Grid />
              <span style={{ color: constants.textPrimary }} className="ml-10px">
                New Spreadsheet View
              </span>
            </div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px',
                color: constants.pink
              }}
              onClick={() => createNewView(ViewTypes.FORM)}
            >
              <Form />
              <span className="ml-10px" style={{ color: constants.textPrimary }}>
                New Form View
              </span>
            </div>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{
                padding: '0 10px',
                height: '32px',
                lineHeight: '32px',
                color: constants.darkGreen
              }}
              onClick={() => createNewView(ViewTypes.CHART)}
            >
              <Chart />
              <span className="ml-10px" style={{ color: constants.textPrimary }}>
                New Chart View
              </span>
              <Beta />
            </div>
          </li>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(ViewMenu)
