import React, { useState, useEffect } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { deleteTableColumnAction } from 'components/spreadsheet/contexts/data/actions'
import { useProject } from 'hooks/project'
import { useApplicationStore } from 'hooks/application'
import { ITableViewWithColumns } from 'types'
import { getColumnUsedViews } from 'components/spreadsheet/helpers/api'
import { ResponsePayload } from 'types'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'

export interface DeleteColumnModalProps extends ModalProps {
  initialColumnId: string | undefined
}

const DeleteColumnModal: React.FC<DeleteColumnModalProps> = ({ id, open, setOpen, initialColumnId }) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()
  const [columnId, setColumnId] = useState<string>()

  const [processing, setProcessing] = useState<boolean>(false)
  const [usedViews, setUsedViews] = useState<ITableViewWithColumns[]>([])

  useEffect(() => {
    setColumnId(initialColumnId)
  }, [])

  const handleClose = () => {
    setOpen(false)
    setProcessing(false)
  }

  const handleFailure = (error: string, tableId?: string, tableViewId?: string) => {
    setSnackbarMessage({
      status: 'error',
      message: error,
      action:
        tableId && tableViewId ? (
          <a
            style={{ color: 'inherit !important', border: '1px solid white', padding: '5px', borderRadius: '2px' }}
            href={`/project/${project.publicId}/table/${tableId}/view/${tableViewId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Table
          </a>
        ) : (
          <></>
        )
    })
    setProcessing(false)
  }

  const handleDeleteColumn = () => {
    if (columnId) {
      setProcessing(true)
      deleteTableColumnAction(
        spreadsheetData.tableDetails.publicId,
        columnId,
        project.publicId,
        setSpreadsheetData,
        handleClose,
        handleFailure
      )
    }
  }

  useEffect(() => {
    const getUsedTableViews = async () => {
      if (columnId) {
        const tableViews: ResponsePayload<ITableViewWithColumns[]> = await getColumnUsedViews(
          spreadsheetData.tableDetails.publicId,
          columnId
        )
        setUsedViews(tableViews.data)
      }
    }

    if (open && columnId) {
      getUsedTableViews()
    }
  }, [open])

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Confirm Deletion">
      {`Are you sure you want to delete this column? ${
        usedViews.length > 0
          ? 'All settings related to this column (filters, sorts, groupings etc.), as well as the column itself, will be removed from the following views in this table:'
          : ''
      }`}
      <ul style={{ marginTop: '20px', padding: '20px' }}>
        {usedViews.map((view: ITableViewWithColumns, viewNumber: number) => {
          return (
            <li style={{ marginBottom: '5px' }} key={viewNumber}>
              <a
                href={`/project/${project.publicId}/table/${spreadsheetData.tableDetails.publicId}/view/${view.publicId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {view.name}
              </a>
            </li>
          )
        })}
      </ul>
      <div style={{ display: 'flex', marginTop: '30px', justifyContent: 'flex-end' }}>
        <Button
          internalType="secondary"
          style={{ maxWidth: '130px', marginRight: '15px' }}
          onClick={() => {
            if (!processing) setOpen(false)
          }}
        >
          Keep Column
        </Button>
        <Button
          internalType="danger"
          style={{ maxWidth: '130px' }}
          onClick={() => {
            if (!processing) handleDeleteColumn()
          }}
        >
          Delete Column
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteColumnModal
