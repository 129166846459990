import React, { useState } from 'react'
import { Triangle, Bold, Italic, Underline } from 'components/icons'
import Button from 'components/button'
import { CompactPicker } from 'react-color'
import { IHeadingStyle } from 'types'

interface FormattingBoxProps {
  name: string
  formatting: IHeadingStyle
  onUpdate: (newFormatting: IHeadingStyle) => void
}

const FormattingBox: React.FC<FormattingBoxProps> = ({ name, formatting, onUpdate }) => {
  const { colour, bold, italic, underline, numberingStyle, startAt0, fontSize } = formatting
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <div className="shadow rounded select-none mb-20px" style={{ padding: '20px' }}>
      <div
        className={`flex items-center cursor-pointer ${bold ? 'font-bold' : ''} ${italic ? 'italic' : ''} ${
          underline ? 'underline' : ''
        }`}
        style={{ minHeight: '50px', color: colour, fontSize: `${fontSize * 100}%` }}
        onClick={() => setExpanded(!expanded)}
      >
        <div>{name}</div>
        <div className="ml-auto">
          <Triangle type={expanded ? 'down' : 'right'} />
        </div>
      </div>
      {expanded && (
        <div className="border-t-2px border-solid border-grey" style={{ paddingTop: '20px' }}>
          <div className="flex items-center mb-20px">
            <div style={{ width: '200px' }}>Formatting:</div>
            <Button
              internalType={bold ? 'primary' : 'outline'}
              onClick={() => onUpdate({ ...formatting, bold: !bold })}
            >
              <Bold />
            </Button>
            <Button
              internalType={italic ? 'primary' : 'outline'}
              style={{ marginLeft: '10px' }}
              onClick={() => onUpdate({ ...formatting, italic: !italic })}
            >
              <Italic />
            </Button>
            <Button
              internalType={underline ? 'primary' : 'outline'}
              style={{ marginLeft: '10px' }}
              onClick={() => onUpdate({ ...formatting, underline: !underline })}
            >
              <Underline />
            </Button>
          </div>
          <div className="flex items-center mb-20px">
            <div style={{ width: '200px' }}>Font Size:</div>
            <input
              type="number"
              style={{ width: '200px' }}
              min={10}
              max={300}
              step={10}
              defaultValue={fontSize * 100}
              onChange={(event) => onUpdate({ ...formatting, fontSize: Number.parseFloat(event.target.value) / 100 })}
            />
            <span style={{ marginLeft: '10px' }}>%</span>
          </div>
          <div className="flex items-center mb-20px">
            <div style={{ width: '200px' }}>Colour:</div>
            <CompactPicker
              color={colour}
              onChangeComplete={(value: any) => {
                onUpdate({ ...formatting, colour: value.hex })
              }}
            />
          </div>
          {numberingStyle !== undefined && startAt0 !== undefined && (
            <div className="flex items-center mb-20px">
              <div style={{ width: '200px' }}>Numbering Style:</div>
              <select
                style={{ width: '200px' }}
                value={numberingStyle}
                onChange={(event) => onUpdate({ ...formatting, numberingStyle: Number.parseInt(event.target.value) })}
              >
                <option value={0}>{startAt0 ? '0. 1. 2.' : '1. 2. 3.'}</option>
                <option value={1}>A. B. C.</option>
                <option value={2}>I. II. III.</option>
                <option value={3}>No Numbering</option>
              </select>
            </div>
          )}
          {numberingStyle === 0 && startAt0 !== undefined && (
            <div className="flex items-center mb-20px">
              <div style={{ width: '200px' }}>Start At 0:</div>
              <input
                type="checkbox"
                style={{ width: '20px' }}
                onChange={(event) => onUpdate({ ...formatting, startAt0: event.target.checked })}
                checked={startAt0}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default FormattingBox
