import React, { useState } from 'react'
import Button from 'components/button'

export interface IFormValues {
  email?: string
  password?: string
}

interface Props {
  onSubmit: (values: IFormValues) => void
}

const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [values, setValues] = useState<IFormValues>({
    email: undefined,
    password: undefined
  })

  const emailError =
    values.email === undefined
      ? false
      : !values.email.match(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
  const passwordError = values.password === undefined ? false : values.password === ''

  const handleSubmit = async () => {
    if (values.email && values.password) {
      setSubmitting(true)
      await onSubmit(values)
      setSubmitting(false)
    }
  }

  return (
    <>
      <input
        name="email"
        type="text"
        placeholder="Your email address"
        data-cy="email-input"
        onChange={(event) => setValues({ ...values, email: event.target.value })}
        autoComplete="email"
      />
      {emailError && (
        <p className="text-red" style={{ marginTop: '10px' }}>
          This must be a valid email address
        </p>
      )}
      <input
        name="password"
        type="password"
        placeholder="Your password"
        data-cy="password-input"
        onChange={(event) => setValues({ ...values, password: event.target.value })}
        style={{ marginTop: '20px' }}
        autoComplete="password"
      />
      {passwordError && (
        <p className="text-red" style={{ marginTop: '10px' }}>
          The password cannot be blank
        </p>
      )}
      <Button
        style={{ marginTop: '20px' }}
        onClick={() => handleSubmit()}
        disabled={emailError || passwordError}
        isLoading={submitting}
        data-cy="signin-button"
      >
        Sign In
      </Button>
    </>
  )
}

export default LoginForm
