import React, { useState } from 'react'
import Button from 'components/button'

export interface IFormValues {
  email: string
}

interface Props {
  onSubmit: (values: IFormValues) => void
}

const SendPasswordResetForm: React.FC<Props> = ({ onSubmit }) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [values, setValues] = useState<IFormValues>({
    email: ''
  })

  const emailError = !values.email.match(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  )

  const handleSubmit = async () => {
    setSubmitting(true)
    await onSubmit(values)
    setSubmitting(false)
  }

  return (
    <>
      <input
        name="email"
        type="text"
        placeholder="Your email address"
        onChange={(event) => setValues({ ...values, email: event.target.value })}
      />
      {emailError && (
        <p className="text-red" style={{ marginTop: '10px' }}>
          This must be a valid email address
        </p>
      )}
      <Button style={{ marginTop: '20px' }} onClick={() => handleSubmit()} disabled={emailError} isLoading={submitting}>
        Reset Password
      </Button>
    </>
  )
}

export default SendPasswordResetForm
