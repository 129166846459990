import React, { useEffect, useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface BIM360IntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedHub: string
  selectedProject: string
  selectedTopFolder: string
  selectedFolder: string
  setSelectedHub: (hub: string) => void
  setSelectedProject: (project: string) => void
  setSelectedTopFolder: (folder: string) => void
  setSelectedFolder: (folder: string) => void
}

const BIM360: React.FC<BIM360IntegrationProps> = ({
  selectedHub,
  selectedProject,
  selectedTopFolder,
  selectedFolder,
  setSelectedHub,
  setSelectedProject,
  setSelectedTopFolder,
  setSelectedFolder
}) => {
  const { user } = useAuth()
  const { setSnackbarMessage } = useApplicationStore()

  const [hubs, setHubs] = useState<ISyncResource[]>()
  const [loadingHubs, setLoadingHubs] = useState<boolean>(false)

  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)

  const [topFolders, setTopFolders] = useState<ISyncResource[]>()
  const [loadingTopFolders, setLoadingTopFolders] = useState<boolean>(false)

  const [folders, setFolders] = useState<ISyncResource[][]>([])
  const [selectedFolders, setSelectedFolders] = useState<string[]>([])
  const [loadingFolders, setLoadingFolders] = useState<boolean>(false)

  const resetFolders = () => {
    setSelectedFolder('')
    setFolders([])
    setSelectedFolders([])
  }

  useEffect(() => {
    if (user && user.autodeskConnected) {
      setLoadingHubs(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: 'https://developer.api.autodesk.com/project/v1/hubs',
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let hubs: ISyncResource[] = []
          if (response.data.status === '200') {
            hubs = response.data['body']['data'].map((hub: any) => {
              return {
                id: hub['id'],
                name: hub['attributes']['name']
              }
            })
          }

          setHubs(hubs)
          setLoadingHubs(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting ACC/BIM 360 Hubs.' })
          setLoadingHubs(false)
        })
    }
  }, [])

  useEffect(() => {
    if (user && user.autodeskConnected && selectedHub && selectedHub !== '') {
      setLoadingProjects(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: `https://developer.api.autodesk.com/project/v1/hubs/${selectedHub}/projects`,
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let projects: ISyncResource[] = []
          if (response.data.status === '200') {
            projects = response.data['body']['data'].map((project: any) => {
              return {
                id: project['id'],
                name: project['attributes']['name']
              }
            })
          }
          setProjects(projects)
          setLoadingProjects(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting ACC/BIM 360 Projects.' })
          setLoadingProjects(false)
        })
    }
  }, [selectedHub])

  useEffect(() => {
    if (user && user.autodeskConnected && selectedProject && selectedProject !== '') {
      setLoadingTopFolders(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path: `https://developer.api.autodesk.com/project/v1/hubs/${selectedHub}/projects/${selectedProject}/topFolders`,
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let topFolders: ISyncResource[] = []
          if (response.data.status === '200') {
            topFolders = response.data['body']['data'].map((topFolder: any) => {
              return {
                id: topFolder['id'],
                name: topFolder['attributes']['name']
              }
            })
          }
          setTopFolders(topFolders)
          setLoadingTopFolders(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting ACC/BIM 360 top folders.' })
          setLoadingTopFolders(false)
        })
    }
  }, [selectedProject])

  useEffect(() => {
    if (user && user.autodeskConnected && selectedTopFolder && selectedTopFolder !== '') {
      setLoadingFolders(true)
      const foldersSet = selectedFolder && selectedFolder !== ''
      const folderId = foldersSet ? selectedFolder : selectedTopFolder

      api
        .integrationPassthrough({
          method: 'GET',
          path: `https://developer.api.autodesk.com/data/v1/projects/${selectedProject}/folders/${folderId}/contents`,
          sourceSystem: IntegrationProviders.AUTODESK_BIM360,
          headers: {
            Authorization: 'Bearer $token$'
          }
        })
        .then((response) => {
          let folders: ISyncResource[] = []
          if (response.data.status === '200') {
            folders = response.data['body']['data']
              .filter((folder: any) => folder.type === 'folders')
              .map((folder: any) => {
                return {
                  id: folder['id'],
                  name: folder['attributes']['name']
                }
              })
          }
          if (foldersSet) {
            setFolders((existingFolders) => [...existingFolders, folders])
          } else {
            setFolders([folders])
          }

          setLoadingFolders(false)
        })
        .catch(() => {
          setSnackbarMessage({ status: 'error', message: 'Something went wrong getting ACC/BIM 360 folders.' })
          setLoadingFolders(false)
        })
    }
  }, [selectedTopFolder, selectedFolder])

  return (
    <div>
      {user && user.autodeskConnected && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Hub<span className="text-red">*</span>
          </div>

          <Select
            options={
              hubs
                ? hubs
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((hub) => {
                      return {
                        label: hub.name,
                        value: hub.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              resetFolders()
              setSelectedHub(option)
              setSelectedProject('')
              setProjects(undefined)
              setSelectedTopFolder('')
              setTopFolders(undefined)
            }}
            optionsSelected={[selectedHub]}
            loading={loadingHubs}
          />
        </div>
      )}

      {user && user.autodeskConnected && selectedHub !== '' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Project<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((project) => {
                      return {
                        label: project.name,
                        value: project.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              resetFolders()
              setSelectedProject(option)
              setSelectedTopFolder('')
              setTopFolders(undefined)
            }}
            optionsSelected={[selectedProject]}
            loading={loadingProjects}
          />
        </div>
      )}

      {user && user.autodeskConnected && selectedProject !== '' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Top Level Document Folder<span className="text-red">*</span>
          </div>
          <Select
            options={
              topFolders
                ? topFolders
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((topFolder) => {
                      return {
                        label: topFolder.name,
                        value: topFolder.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              resetFolders()
              setSelectedTopFolder(option)
            }}
            optionsSelected={[selectedTopFolder]}
            loading={loadingTopFolders}
          />
        </div>
      )}

      {folders.map((folder, index) => {
        return (
          <div key={index} style={{ marginTop: '30px' }}>
            <div className="font-bold flex" style={{ marginBottom: '10px' }}>
              Select Specific Document Folder (Level {index + 1})
            </div>
            <Select
              options={folder
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((folder) => {
                  return {
                    label: folder.name,
                    value: folder.id
                  }
                })}
              onOptionClick={(option) => {
                setSelectedFolder(option)

                const newFolders = [...folders]
                newFolders[index] = folder
                newFolders.splice(index + 1, newFolders.length - index - 1)
                setFolders(newFolders)

                const newSelectedFolders = [...selectedFolders]
                newSelectedFolders[index] = option
                newSelectedFolders.splice(index + 1, newSelectedFolders.length - index - 1)
                setSelectedFolders(newSelectedFolders)
              }}
              optionsSelected={[selectedFolders[index]]}
              loading={index === folders.length - 1 ? loadingFolders : false}
            />
          </div>
        )
      })}
    </div>
  )
}

export default BIM360
