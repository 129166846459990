import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'components/modal'
import api, { APIError } from 'helpers/api'
import { useProject } from 'hooks/project'
import useApplicationStore from 'hooks/application'
import { Process, Spreadsheet } from 'components/icons'
import Button from 'components/button'
import { AI } from 'components/icons'
import constants from 'style/constants.module.scss'

interface IResourceMatch {
  text: string
  link: string
  type: string
}

interface AIAnswer {
  answer: string
  context: string
  source: string
  link?: string
}

const Search: React.FC = () => {
  const { project, search, openSearch, closeSearch } = useProject()
  const { setSnackbarMessage } = useApplicationStore()

  const appRootElement = document.getElementById('root')
  const [searchText, setSearchText] = useState<string>('')
  const [results, setResults] = useState<IResourceMatch[] | undefined>()
  const [loadingResults, setLoadingResults] = useState<boolean>(false)

  const [aiAnswer, setAiAnswer] = useState<AIAnswer | undefined>()
  const [loadingAiAnswer, setLoadingAiAnswer] = useState<boolean>(false)

  const isLoading = loadingResults || loadingAiAnswer

  const [selectedType, setSelectedType] = useState<string>('')

  const handleResourcesSearch = () => {
    setLoadingResults(true)
    api
      .searchProjectResources({
        projectId: project.publicId,
        search: searchText,
        processPublicId: search.processPublicId
      })
      .then((response) => {
        setResults(response.data)
        setLoadingResults(false)
      })
      .catch(() => {
        setSnackbarMessage({ status: 'error' })
        setLoadingResults(false)
        setResults(undefined)
      })
  }

  const handleAiSearch = () => {
    setLoadingAiAnswer(true)
    api
      .searchProjectAI({ projectId: project.publicId, search: searchText, processPublicId: search.processPublicId })
      .then((response) => {
        setAiAnswer(response.data)
        setLoadingAiAnswer(false)
      })
      .catch((e) => {
        if (e instanceof APIError) {
          setSnackbarMessage({
            status: 'error',
            message: e.message
          })
        } else if (typeof e === 'string') {
          setSnackbarMessage({
            status: 'error',
            message: e
          })
        } else {
          setSnackbarMessage({
            status: 'error'
          })
        }

        setLoadingAiAnswer(false)
        setAiAnswer(undefined)
      })
  }

  const types = [
    {
      name: 'All',
      id: ''
    },
    { name: 'Tables', id: 'table' },
    { name: 'Table Views', id: 'view' },
    { name: 'Documents (Name + Description)', id: 'process' },
    { name: 'Document Sections (Name + Description)', id: 'section' }
  ]

  const filteredResults = results
    ? results.filter((result) => {
        if (selectedType === '') return true
        else return result.type === selectedType
      })
    : undefined

  const closeAndResetModal = () => {
    closeSearch()
    setSearchText('')
    setResults(undefined)
    setSelectedType('')
  }

  if (appRootElement && search.open) {
    return ReactDOM.createPortal(
      <Modal
        id="search-modal"
        open={search.open}
        setOpen={(open: boolean) => {
          if (open) {
            openSearch()
          } else {
            closeAndResetModal()
          }
        }}
      >
        <div className="text-lg" style={{ flexGrow: 0, flexShrink: 0 }}>
          <div style={{ marginBottom: '20px' }}>
            {search.processName
              ? "We will limit the search to the '" + search.processName + "' document."
              : 'We will search across the entire workspace.'}
          </div>

          <input
            type="text"
            placeholder={'Ask a question or enter a search term...'}
            autoFocus
            value={searchText}
            onChange={(event) => {
              setResults(undefined)
              setAiAnswer(undefined)
              setSearchText(event.target.value)
            }}
            style={{ marginBottom: '10px' }}
          />

          <div className="flex justify-end">
            <Button
              internalType="grey"
              className="text-base button-shadow-hover"
              style={{ width: '200px', marginRight: '10px', padding: '10px' }}
              onClick={() => handleResourcesSearch()}
              disabled={isLoading || searchText === ''}
            >
              Search For Resources
            </Button>

            <Button
              internalType="gradient"
              className="text-base"
              style={{ width: '200px', padding: '10px' }}
              onClick={() => handleAiSearch()}
              disabled={isLoading || searchText === ''}
              isLoading={isLoading}
            >
              <AI style={{ marginRight: '5px' }} />
              Ask Morta AI
            </Button>
          </div>
        </div>

        {!loadingAiAnswer && aiAnswer && (
          <div
            className="flex flex-column rounded text-lg"
            style={{
              marginBottom: '20px',
              marginTop: '40px',
              backgroundColor: constants.aiBackground,
              padding: '20px 10px'
            }}
          >
            <div className="font-bold" style={{ marginBottom: '20px' }}>
              <AI style={{ marginRight: '5px' }} /> Morta AI Powered Answer
            </div>
            <div style={{ lineHeight: 1.5, whiteSpace: 'pre-wrap', padding: '0px 5px' }}>{aiAnswer.answer}</div>

            {aiAnswer.context && aiAnswer.context !== '' && (
              <div
                className="flex flex-column text-secondary"
                style={{ padding: '10px', lineHeight: 1.5, whiteSpace: 'pre-wrap', marginTop: '30px' }}
              >
                <span>
                  <b>Source: {aiAnswer.source}</b>
                  <br />
                  {aiAnswer.context}
                  {aiAnswer.link && (
                    <a href={aiAnswer.link} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                      🔗 Go To Relevant Context
                    </a>
                  )}
                </span>
              </div>
            )}
          </div>
        )}

        {!search.processPublicId && filteredResults && !loadingResults && (
          <div>
            <div className="font-bold" style={{ marginTop: '20px', marginBottom: '20px' }}>
              Relevant Workspace Resources
            </div>
            <div className="flex items-center" style={{ paddingBottom: '10px' }}>
              {types.map((type, index) => {
                const selected = type.id === selectedType
                return (
                  <div
                    key={index}
                    className={`${selected ? 'bg-grey' : 'hover-bg-light-grey'} cursor-pointer rounded`}
                    style={{ padding: '2px 10px' }}
                    onClick={() => setSelectedType(type.id)}
                  >
                    {type.name}
                  </div>
                )
              })}
            </div>
            <ul
              className="w-full overflow-auto-hidden overflow-y-auto border-t-1px border-solid border-grey"
              style={{ padding: '8px', zIndex: 2, height: '400px' }}
            >
              {filteredResults.length === 0 && <span>No Results</span>}
              {filteredResults.length > 0 &&
                filteredResults.map((result, resultNumber) => {
                  return (
                    <a
                      key={resultNumber}
                      href={result.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center space-between relative hover-bg-light-grey"
                      style={{ padding: '10px' }}
                    >
                      {result.link.includes('/process') && <Process />}
                      {result.link.includes('/table') && <Spreadsheet />}

                      <div
                        className="w-full flex items-center overflow-hidden truncate"
                        style={{ height: '60px', marginLeft: '10px', marginRight: '10px' }}
                      >
                        {result.text}
                      </div>
                      <div>
                        <svg
                          className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--external-svg SVG--color-svg SVG--color--gray50-svg"
                          style={{ width: '12px', height: '12px' }}
                          height="16"
                          viewBox="0 0 16 16"
                          width="16"
                          fill="#d0d0cf"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 4v10h10v-3a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2zm5.707 5.707a1 1 0 1 1-1.414-1.414l6.3-6.298H9.017a.998.998 0 1 1 0-1.995h5.986A.995.995 0 0 1 16 .998v5.986a.998.998 0 1 1-1.995 0V3.406z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  )
                })}
            </ul>
          </div>
        )}
      </Modal>,
      appRootElement
    )
  } else {
    return <div></div>
  }
}

export default Search
