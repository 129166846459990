import { formatPercentDisplayValue } from 'components/spreadsheet/helpers/format'

export const secondsToHms = (d: number) => {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : ''
  const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : ''
  const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

export const mapStatsToTableData = (stats: Record<string, number> | undefined) => {
  const newStats: Record<string, any>[] = []
  if (stats) {
    const keys = Object.keys(stats)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const dayBeforeKey = keys[i - 1]
      const weekBeforeKey = keys[i - 7]
      const monthBeforeKey = keys[i - 30]
      const yearBeforeKey = keys[i - 365]

      const no = stats[key]
      const noDayAgo = dayBeforeKey ? stats[dayBeforeKey] : undefined
      const noWeekAgo = weekBeforeKey ? stats[weekBeforeKey] : undefined
      const noMonthAgo = monthBeforeKey ? stats[monthBeforeKey] : undefined
      const noYearAgo = yearBeforeKey ? stats[yearBeforeKey] : undefined

      const dayChange = noDayAgo !== undefined ? no / noDayAgo - 1 : undefined
      const weekChange = noWeekAgo !== undefined ? no / noWeekAgo - 1 : undefined
      const monthChange = noMonthAgo !== undefined ? no / noMonthAgo - 1 : undefined
      const yearChange = noYearAgo !== undefined ? no / noYearAgo - 1 : undefined

      const dayChangePct =
        dayChange !== undefined && !Number.isNaN(dayChange) ? formatPercentDisplayValue(dayChange) : 'n/a'

      const weekChangePct =
        weekChange !== undefined && !Number.isNaN(weekChange) ? formatPercentDisplayValue(weekChange) : 'n/a'

      const monthChangePct =
        monthChange !== undefined && !Number.isNaN(monthChange) ? formatPercentDisplayValue(monthChange) : 'n/a'

      const yearChangePct =
        yearChange !== undefined && !Number.isNaN(yearChange) ? formatPercentDisplayValue(yearChange) : 'n/a'

      newStats.push({
        date: { label: key, value: key },
        no: { label: no, value: no },
        dayChange: { label: dayChangePct, value: dayChangePct },
        weekChange: { label: weekChangePct, value: weekChangePct },
        monthChange: { label: monthChangePct, value: monthChangePct },
        yearChange: { label: yearChangePct, value: yearChangePct }
      })
    }
  }
  return newStats
}
