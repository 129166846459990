import React, { useEffect, useState } from 'react'
import Button from 'components/button'
import api from 'helpers/api'
import { INotification, ITrigger, IProject, ITable, IProcess, IProcessObject } from 'types'
import {
  isTableTriggerSelected,
  isProcessTriggerSelected,
  ITriggerNoId
} from 'views/settings/components/notifications/helpers/utils'
import Select from 'components/select'

// Does the list of triggers contain the event name
const isEventNameInTriggerList = (triggers: ITriggerNoId[], eventName: string) => {
  return triggers?.map((trigger) => `${trigger.resource}.${trigger.verb}`).includes(eventName) ? true : false
}

// Convert trigger to event name
const toEventName = (trigger: ITriggerNoId) => {
  return `${trigger.resource}.${trigger.verb}`
}

// Convert event name to trigger object
const toTrigger = (eventName: string) => {
  const [resource, verb] = eventName.split('.')
  return {
    resource,
    verb
  }
}

export interface IFormValues {
  webhookUrl: string
  description: string
  triggers: ITrigger[]
  processes: Pick<IProcess, 'publicId' | 'name'>[]
  tables: Pick<ITable, 'publicId' | 'name'>[]
}

interface Props {
  project: IProject
  onSubmit: (values: IFormValues) => void
  notification?: INotification
}

const NotificationForm: React.FC<Props> = ({ notification, project, onSubmit }) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [availableEvents, setAvailableEvents] = React.useState<string[]>()
  const [availableProcesses, setAvailableProcesses] = React.useState<IProcessObject[]>()
  const [availableTables, setAvailableTables] = React.useState<ITable[]>()

  const [values, setValues] = useState<IFormValues>({
    webhookUrl: notification ? notification.actions[0].webhookUrl : '',
    description: notification ? notification.description : '',
    triggers: notification ? notification.triggers : [],
    tables: notification ? notification.tables : [],
    processes: notification ? notification.processes : []
  })

  const webhookUrlError = !values.webhookUrl.match(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  )
  const descriptionError = values.description === ''
  const triggersError = values.triggers.length < 1

  useEffect(() => {
    fetchAvailabileEventTypes()
    fetchAvailableProcesses()
  }, [])

  const fetchAvailableProcesses = async () => {
    try {
      const response = await api.getProjectResources(project.publicId, {})
      const newAvailableProcesses = []
      const newAvailableTables = []
      for (let i = 0; i < response.data.length; i++) {
        const folderContents = response.data[i]
        if (folderContents.process) {
          newAvailableProcesses.push(folderContents.process)
        }
        if (folderContents.table) {
          newAvailableTables.push(folderContents.table)
        }
      }
      setAvailableProcesses(newAvailableProcesses)
      setAvailableTables(newAvailableTables)
    } catch (e) {
      console.error(e)
    }
  }

  const fetchAvailabileEventTypes = async () => {
    try {
      const response = await api.getNotificationEventTypes()
      setAvailableEvents(response.data)
    } catch (e) {
      console.error(e)
    }
  }

  const handleTriggerSelect = (name: string) => {
    if (isEventNameInTriggerList(values.triggers, name)) {
      setValues({ ...values, triggers: values.triggers.filter((trigger) => toEventName(trigger) !== name) })
    } else {
      setValues({ ...values, triggers: [...values.triggers, toTrigger(name)] })
    }
  }

  const handleTableSelect = (table: ITable) => {
    if (values.tables.find((t) => t.publicId === table.publicId)) {
      setValues({ ...values, tables: values.tables.filter((t) => t.publicId !== table.publicId) })
    } else {
      setValues({ ...values, tables: [...values.tables, table] })
    }
  }

  const handleProcessSelect = (process: IProcessObject) => {
    if (values.processes.find((p) => p.publicId === process.publicId)) {
      setValues({ ...values, processes: values.processes.filter((p) => p.publicId !== process.publicId) })
    } else {
      setValues({ ...values, processes: [...values.processes, process] })
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    await onSubmit(values)
    setSubmitting(false)
  }

  return (
    <>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">URL Endpoint</div>
        <p style={{ marginBottom: '10px' }}>This is the endpoint where Morta will send the event data</p>
        <input
          type="text"
          name="webhookUrl"
          placeholder="Please enter a URL endpoint"
          value={values.webhookUrl}
          onChange={(event) => setValues({ ...values, webhookUrl: event.target.value })}
        />
        {webhookUrlError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            The URL cannot be blank and needs to be in a URL format (e.g. https://...)
          </p>
        )}
      </div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Description</div>
        <input
          placeholder="Please enter a description"
          value={values.description}
          onChange={(event) => setValues({ ...values, description: event.target.value })}
        />
        {descriptionError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            The webhook description cannot be blank
          </p>
        )}
      </div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Pick Events</div>
        <p style={{ marginBottom: '20px' }}>
          These are the events that occur within Morta that will trigger your notification
        </p>
        <Select
          options={
            availableEvents
              ? availableEvents.map((event) => {
                  return { value: event, label: event }
                })
              : []
          }
          optionsSelected={values.triggers.map((trigger) => toEventName(trigger))}
          onOptionClick={(option) => handleTriggerSelect(option)}
          multiselect={true}
          loading={availableEvents === undefined}
          setOptionsSelected={(options) => {
            const newTriggers: ITrigger[] = []
            options.forEach((option) => {
              newTriggers.push(toTrigger(option))
            })
            setValues({ ...values, triggers: newTriggers })
          }}
        />
        {triggersError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            At least one event must be selected
          </p>
        )}
      </div>

      {isTableTriggerSelected(values.triggers) && (
        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Pick Tables</div>
          <p style={{ marginBottom: '20px' }}>
            Only send notifications relevant to to the tables below. If you do not select any tables then a notification
            will be sent for all tables.
          </p>

          <Select
            options={
              availableTables
                ? availableTables.map((table) => {
                    return { value: table.publicId, label: table.name }
                  })
                : []
            }
            loading={!availableTables}
            optionsSelected={values.tables.map((table) => table.publicId)}
            onOptionClick={(option) => {
              if (availableTables) {
                const table = availableTables.find((table) => table.publicId === option)
                if (table) {
                  handleTableSelect(table)
                }
              }
            }}
            multiselect={true}
            setOptionsSelected={(options) => {
              if (availableTables) {
                const newTables: ITable[] = []
                options.forEach((option) => {
                  const table = availableTables.find((table) => table.publicId === option)
                  if (table) {
                    newTables.push(table)
                  }
                })
                setValues({ ...values, tables: newTables })
              }
            }}
          />
        </div>
      )}

      {isProcessTriggerSelected(values.triggers) && (
        <div className="mb-8">
          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Pick Processes</div>
          <p style={{ marginBottom: '10px' }}>
            Only send notifications relevant to to the processes below. If you do not select any processes then a
            notification will be sent for all processes.
          </p>

          <Select
            options={
              availableProcesses
                ? availableProcesses.map((process) => {
                    return { value: process.publicId, label: process.name }
                  })
                : []
            }
            loading={!availableProcesses}
            optionsSelected={values.processes.map((process) => process.publicId)}
            onOptionClick={(option) => {
              if (availableProcesses) {
                const process = availableProcesses.find((process) => process.publicId === option)
                if (process) {
                  handleProcessSelect(process)
                }
              }
            }}
            multiselect={true}
            setOptionsSelected={(options) => {
              if (availableProcesses) {
                const newProcesses: IProcess[] = []
                options.forEach((option) => {
                  const process = availableProcesses.find((process) => process.publicId === option)
                  if (process) {
                    newProcesses.push(process)
                  }
                })
                setValues({ ...values, processes: newProcesses })
              }
            }}
          />
        </div>
      )}

      <div className="flex items-center justify-end">
        <Button
          disabled={webhookUrlError || descriptionError || triggersError}
          isLoading={submitting}
          style={{ marginTop: '20px' }}
          onClick={() => handleSubmit()}
        >
          {notification ? 'Update Webhook' : 'Create Webhook'}
        </Button>
      </div>
    </>
  )
}

export default NotificationForm
