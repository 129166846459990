import React, { useEffect, useState } from 'react'
import { useProject } from 'hooks/project'
import { IContextMenuState, IFolder, IFolderContents, IProcessObject, ITable } from 'types'
import useApplicationStore from 'hooks/application'
import { Link } from 'react-router-dom'
import ResourceMenu from 'components/sidebar/components/resourcemenu'
import NewProcess from 'components/process/components/modal/new'
import NewTable from 'components/spreadsheet/components/newTable'
import { Plus, Process, Spreadsheet, Triangle } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { useLocation } from 'react-router-dom'
interface ResourceProps {
  layer: number
  process?: IProcessObject
  table?: ITable
}

const rowHeight = '40px'

const Resource: React.FC<ResourceProps> = ({ layer, process, table }) => {
  const { project } = useProject()

  const location = useLocation()

  if (process) {
    return (
      <div
        className="flex items-center w-full text-white cursor-pointer font-medium transition-all"
        style={{ minHeight: rowHeight, padding: '0px 20px 0px 14px' }}
      >
        <Link
          style={{
            display: 'flex',
            width: '100%',
            color: '#fff',
            height: '100%',
            alignItems: 'center',
            pointerEvents: project.loading ? 'none' : 'all',
            fontWeight: 400,
            border: location.pathname.includes(process.publicId) ? '1px solid #fff' : 'none'
          }}
          to={`/project/${project.publicId}/process/${process.publicId}`}
        >
          <div style={{ minWidth: `${10 * layer}px` }} />
          <div
            className="flex justify-center items-center rounded cursor-pointer transition-all select-none"
            style={{ minWidth: '20px', height: '20px', marginRight: '5px' }}
          >
            <Process />
          </div>
          <div className="flex items-center truncate" style={{ minWidth: '0px', flex: '1 1 auto' }}>
            <div className="truncate">{process.name}</div>
          </div>
        </Link>
      </div>
    )
  } else if (table) {
    return (
      <div
        className="flex items-center w-full text-white cursor-pointer font-medium transition-all"
        style={{ minHeight: rowHeight, padding: '0px 20px 0px 14px' }}
      >
        <Link
          style={{
            display: 'flex',
            width: '100%',
            color: '#fff',
            height: '100%',
            alignItems: 'center',
            pointerEvents: project.loading ? 'none' : 'all',
            fontWeight: 400,
            border: location.pathname.includes(table.publicId) ? '1px solid #fff' : 'none'
          }}
          to={`/project/${project.publicId}/table/${table.publicId}/view/${table.defaultViewId}`}
        >
          <div style={{ minWidth: `${10 * layer}px` }} />
          <div
            className="flex justify-center items-center rounded cursor-pointer transition-all select-none"
            style={{ minWidth: '20px', height: '20px', marginRight: '5px' }}
          >
            <Spreadsheet />
          </div>
          <div className="flex items-center truncate" style={{ minWidth: '0px', flex: '1 1 auto' }}>
            <div className="truncate">{table.name}</div>
          </div>
        </Link>
      </div>
    )
  } else {
    return (
      <div
        className="flex items-center w-full text-white cursor-pointer font-medium transition-all"
        style={{ minHeight: rowHeight, padding: '0px 20px 0px 14px' }}
      >
        <div style={{ minWidth: `${10 * layer}px` }} />
        <div
          className="flex justify-center items-center rounded cursor-pointer transition-all select-none"
          style={{ minWidth: '20px', height: '20px', marginRight: '5px' }}
        />
        <div className="flex items-center truncate" style={{ minWidth: '0px', flex: '1 1 auto' }}>
          <div className="truncate">Empty</div>
        </div>
      </div>
    )
  }
}

interface FolderProps {
  folder: IFolder
}

const Folder: React.FC<FolderProps> = ({ folder }) => {
  const { project, getFolderResources } = useProject()
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (project.publicId !== '' && expanded && folder.resources === undefined) {
      if (folder.childFolders === null) {
        getFolderResources(folder.id)
      }
    }
  }, [expanded])

  return (
    <>
      <div
        className="flex items-center w-full text-white cursor-pointer font-medium transition-all"
        style={{ minHeight: rowHeight, padding: '0px 20px 0px 14px' }}
        onClick={() => setExpanded(!expanded)}
      >
        <div style={{ minWidth: `${10 * folder.layer}px` }} />
        <div
          className="flex justify-center items-center rounded cursor-pointer transition-all select-none"
          style={{ minWidth: '20px', height: '20px', marginRight: '5px' }}
        >
          <div className="text-white">
            <Triangle style={{ width: '10px', height: '10px' }} type={expanded ? 'down' : 'right'} />
          </div>
        </div>
        <div className="flex items-center truncate" style={{ minWidth: '0px', flex: '1 1 auto' }}>
          <div className="truncate">{folder.name ? folder.name : 'No Folder'}</div>
          {expanded && folder.resources === undefined && (
            <div style={{ marginLeft: 'auto' }}>
              <div className="spin" style={{ height: '20px', width: '20px' }} />
            </div>
          )}
        </div>
      </div>

      {expanded && folder.resources && folder.resources.length === 0 && <Resource layer={folder.layer + 1} />}

      {expanded &&
        folder.resources &&
        folder.resources.map((resource: IFolderContents, resourceNumber: number) => {
          return (
            <Resource key={resourceNumber} process={resource.process} table={resource.table} layer={folder.layer + 1} />
          )
        })}
    </>
  )
}

const Folders: React.FC = () => {
  const { project, projectAdmin } = useProject()
  const { sidebarWidth, minSidebarWidthDisplay } = useApplicationStore()

  const [resourceMenu, setResourceMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [newProcessModal, setNewProcessModal] = useState<boolean>(false)
  const [newTableModal, setNewTableModal] = useState<boolean>(false)

  const resourceMenuWidth = 200
  const offset = 200

  return (
    <>
      {!project.loading && sidebarWidth > minSidebarWidthDisplay && (
        <div
          className="relative flex text-white w-full font-semibold flex-row justify-center transition-all items-center box-border"
          style={{ height: '40px', padding: '10px', opacity: 0.7 }}
        >
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <div>Workspace Resources</div>
            <div
              style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
              onClick={(event) => {
                setResourceMenu({
                  open: true,
                  top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                  left: `${event.currentTarget.getBoundingClientRect().left - resourceMenuWidth + offset}px`,
                  right: 'auto',
                  bottom: 'auto'
                })
              }}
            >
              {!project.loading && projectAdmin && (
                <div className="cursor-pointer flex items-center">
                  <Plus />
                </div>
              )}
              {project.loading && <div className="spin" style={{ height: '20px', width: '20px' }} />}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-column w-full overflow-y-auto" style={{ flex: 8 }}>
        {!project.loading &&
          sidebarWidth > minSidebarWidthDisplay &&
          project.folders &&
          project.folders.map((folder: IFolder, folderNumber: number) => {
            return <Folder key={folderNumber} folder={folder} />
          })}
      </div>
      {resourceMenu.open && (
        <ResourceMenu
          id={'new-resource-menu'}
          menuState={resourceMenu}
          setMenuState={setResourceMenu}
          width={resourceMenuWidth}
          openNewProcessModal={() => setNewProcessModal(true)}
          openNewTableModal={() => setNewTableModal(true)}
        />
      )}

      {newProcessModal && <NewProcess id="new-process-modal" open={newProcessModal} setOpen={setNewProcessModal} />}
      {newTableModal && <NewTable id="new-table-modal" open={newTableModal} setOpen={setNewTableModal} />}
    </>
  )
}

export default Folders
