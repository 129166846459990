import React from 'react'
import { Link } from 'react-router-dom'
import Menu, { MenuProps } from 'components/menu'
import { useAuth } from 'hooks/auth'
import { INTEGRATIONS, SECURITY, SIGN_IN } from 'routes'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { history } from 'helpers/history'
import { AdminPanel, Integrations, Profile, Security } from 'components/icons'

const UserMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { user, logout } = useAuth()
  const signOut = async () => {
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
    await logout()
    history.push(SIGN_IN)
  }
  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <Link
        className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
        style={{ padding: '10px' }}
        data-cy="profile-button"
        to={`/profile/${user?.firebaseUserId}/information`}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Profile style={{ marginRight: '5px' }} />
        My Profile
      </Link>

      <Link
        className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
        style={{ padding: '10px' }}
        data-cy="profile-button"
        to={INTEGRATIONS}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Integrations style={{ marginRight: '5px' }} />
        Integrations
      </Link>

      <Link
        className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
        style={{ padding: '10px' }}
        data-cy="profile-button"
        to={SECURITY}
        onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
      >
        <Security style={{ marginRight: '5px' }} />
        Account Security
      </Link>

      <hr style={{ marginTop: '5px', marginBottom: '5px' }} />

      {user?.isSuperAdmin && (
        <>
          <Link
            className="w-full flex items-center hover-bg-light-grey text-primary transition-all"
            style={{ padding: '10px' }}
            to={`/admin/today`}
            onClick={() => setMenuState(INITIAL_CONTEXT_MENU_STATE)}
          >
            <AdminPanel style={{ marginRight: '5px' }} />
            Admin Panel
          </Link>
          <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
        </>
      )}

      <div
        className="w-full flex items-center hover-bg-light-grey text-primary cursor-pointer transition-all"
        style={{ padding: '10px', marginBottom: '5px' }}
        data-cy="signout-button"
        onClick={() => signOut()}
      >
        Sign Out
      </div>
    </Menu>
  )
}

export default UserMenu
