import React, { ImgHTMLAttributes, ReactElement } from 'react'
import api from 'helpers/api'
import { isMortaUploadedUrl } from 'helpers/utils'
import { ContentBlock, ContentState } from 'draft-js'

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  block: ContentBlock
  contentState: ContentState
}

export default React.forwardRef<HTMLImageElement, ImageProps>(function EmbeddedImage(props, ref): ReactElement {
  const { block, ...otherProps } = props
  const { contentState, ...elementProps } = otherProps
  const [src, setSrc] = React.useState<string>(contentState.getEntity(block.getEntityAt(0)).getData().src)
  const [ready, setReady] = React.useState(false)

  const signUrl = async () => {
    const signedFileTokenResponse = await api.signUrl(src)
    setSrc(signedFileTokenResponse.data.url)
    setReady(true)
  }

  React.useEffect(() => {
    if (isMortaUploadedUrl(src)) {
      signUrl()
    } else {
      setReady(true)
    }
  }, [])

  if (!ready) {
    return <span>Loading image...</span>
  }

  return <img {...elementProps} ref={ref} src={src} role="presentation" />
})
