import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Modal, { ModalProps } from 'components/modal'
import { IProcessObject } from 'types'
import api from 'helpers/api'
import Editor from 'components/editor'
import Button from 'components/button'
import ProcessComponent from 'components/process'
import { PERMISSIONS } from 'helpers/auth'
import Duplicate from 'components/duplicate'
import useAuth from 'hooks/auth'

const Templates: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { user } = useAuth()
  const allTemplatesText = 'All Templates'
  const [searchText, setSearchText] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>(allTemplatesText)
  const [templates, setTemplates] = useState<Partial<IProcessObject>[]>()
  const [selectedTemplate, setSelectedTemplate] = useState<{
    templateId: string
    projectId: string
  }>()
  const [duplicateModal, setDuplicateModal] = useState<boolean>(false)

  const categoryWidth = 400
  const templateStyle = {}
  const templateClass =
    'items-center rounded cursor-pointer transition-all border-1px border-solid border-black box-border hover-heavy-shadow bg-white'

  useEffect(() => {
    api.getTemplates().then((response) => {
      setTemplates(response.data)
    })
  }, [])

  const filteredTemplates = templates
    ? templates.filter((template) => {
        return template.name && template.name.toLowerCase().includes(searchText.toLowerCase())
      })
    : undefined

  const categories = filteredTemplates
    ? [
        allTemplatesText,
        ...new Set(
          filteredTemplates.map((template) => (template.templateCategory ? template.templateCategory : 'Other'))
        )
      ]
    : [allTemplatesText]

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={
        !selectedTemplate ? (
          <input
            className="text-base-sm"
            type="text"
            placeholder={'Search for a template'}
            autoFocus
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            style={{ width: '40%', height: '100%' }}
          />
        ) : (
          <Button internalType="outline" onClick={() => setSelectedTemplate(undefined)}>
            ← Back
          </Button>
        )
      }
      removePadding={true}
    >
      {!selectedTemplate ? (
        <>
          <div className="flex text-base-sm">
            <div className="flex flex-column h-full" style={{ width: `${categoryWidth}px`, padding: '30px 40px' }}>
              <div className="text-secondary" style={{ marginBottom: '5px' }}>
                Browse By Category
              </div>
              {filteredTemplates ? (
                <>
                  {categories.sort().map((category, categoryIndex) => {
                    return (
                      <div
                        key={categoryIndex}
                        className={`cursor-pointer truncate ${
                          selectedCategory === category ? 'bg-light-grey' : 'hover-bg-light-grey'
                        }`}
                        style={{ padding: '12px 16px' }}
                        onClick={() => setSelectedCategory(category)}
                      >
                        {category}
                      </div>
                    )
                  })}
                </>
              ) : (
                <>
                  <span className="skeleton-box" style={{ height: '35px', marginBottom: '10px' }} />
                  <span className="skeleton-box" style={{ height: '35px', marginBottom: '10px' }} />
                  <span className="skeleton-box" style={{ height: '35px', marginBottom: '10px' }} />
                </>
              )}
            </div>

            <div className="overflow-y-auto" style={{ width: `calc(100% - ${categoryWidth}px)`, padding: '30px 40px' }}>
              {filteredTemplates ? (
                <>
                  <div className="font-bold text-2xl" style={{ marginBottom: '16px' }}>
                    {selectedCategory}
                  </div>
                  <div className="grid grid-cols-2 sm-grid-cols-1 gap-16px">
                    {filteredTemplates
                      .filter((template) => {
                        const category = template.templateCategory ? template.templateCategory : 'Other'
                        return selectedCategory === allTemplatesText || selectedCategory === category
                      })
                      .map((template, templateIndex) => {
                        return (
                          <div
                            key={templateIndex}
                            className={templateClass}
                            style={templateStyle}
                            tabIndex={0}
                            onClick={() => {
                              if (template && template.publicId && template.projectPublicId) {
                                setSelectedTemplate({
                                  templateId: template.publicId,
                                  projectId: template.projectPublicId
                                })
                              }
                            }}
                          >
                            <div
                              className="flex relative w-full overflow-hidden justify-center items-center"
                              style={{ height: '100px' }}
                            >
                              {template.logo && (
                                <img
                                  src={template.logo}
                                  className="absolute"
                                  style={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    height: '100%'
                                  }}
                                />
                              )}
                            </div>
                            <div>
                              <div
                                className="font-bold text-lg box-border border-t-1px border-solid border-darker-grey"
                                style={{ marginBottom: '10px', padding: '10px' }}
                              >
                                {template.name}
                              </div>
                              <div className="overflow-y-auto b" style={{ height: '200px', padding: '5px' }}>
                                <Editor
                                  databaseDoc={template.description}
                                  readOnly={true}
                                  editorId={templateIndex.toString()}
                                  border={false}
                                />
                              </div>
                              {template && template.createdBy && (
                                <div
                                  className="box-border border-t-1px border-solid border-darker-grey"
                                  style={{ padding: '15px 10px' }}
                                >
                                  <div className="flex items-center">
                                    <img
                                      src={
                                        template.createdBy.profilePicture
                                          ? template.createdBy.profilePicture
                                          : '/assets/images/user.png'
                                      }
                                      style={{ height: '25px', width: '25px', borderRadius: '50%' }}
                                    />
                                    <div className="text-secondary" style={{ marginLeft: '5px' }}>
                                      Created by{' '}
                                      <Link
                                        to={`/profile/${template.createdBy.firebaseUserId}/information`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {template.createdBy.name}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </>
              ) : (
                <>
                  <div className="font-bold text-2xl" style={{ marginBottom: '16px' }}>
                    <span className="skeleton-box" style={{ height: '32px', width: '200px' }} />
                  </div>
                  <div className="grid grid-cols-2 sm-grid-cols-1 gap-16px">
                    <span className="skeleton-box" style={{ height: '100px' }} />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ height: '600px' }}>
            <ProcessComponent
              processPublicId={selectedTemplate.templateId}
              permissionCap={PERMISSIONS.viewer}
              disableControls={true}
            />
          </div>
          <div className="flex justify-end border-t-2px border-solid border-grey" style={{ padding: '20px' }}>
            <Button
              to={`/project/${selectedTemplate.projectId}/process/${selectedTemplate.templateId}`}
              onClick={() => {
                setOpen(false)
                setSelectedTemplate(undefined)
              }}
            >
              View In Workspace
            </Button>
            <Button
              internalType="accept"
              style={{ marginLeft: '10px' }}
              onClick={() => setDuplicateModal(true)}
              disabled={!user}
            >
              {user ? 'Use This Template' : 'Sign In To Use This Template'}
            </Button>
          </div>
        </>
      )}

      {selectedTemplate && duplicateModal && (
        <Duplicate
          type="process"
          open={duplicateModal}
          setOpen={setDuplicateModal}
          publicId={selectedTemplate.templateId}
          id="use-template"
        />
      )}
    </Modal>
  )
}
export default Templates
