import { IFilterType, IColumnTypes, IColumnValuesCount } from 'components/spreadsheet/types'
import {
  ITableViewFilter,
  ITableRow,
  ITableViewColumn,
  IFile,
  IVote,
  ITag,
  ICellValue,
  ISummaryUser,
  ICommentThreadStats
} from 'types'
import {
  DATE_COLUMN_TYPES,
  TEXT_COLUMN_TYPES,
  USER_COLUMN_TYPES,
  FilterVariables,
  NUMBER_COLUMN_TYPES,
  ATTACHMENT_COLUMN_TYPES,
  ValidationDisplayTypes,
  BOOLEAN_COLUMN_TYPES,
  CommentDisplayTypes
} from 'components/spreadsheet/constants/const'
import { isSummaryUser } from 'components/spreadsheet/helpers/functions'
import { isValidValue } from 'components/spreadsheet/helpers/validation'

// Returns a filtered set of rows
export const filterRows = (
  rows: ITableRow[],
  columnValuesCount: IColumnValuesCount,
  alreadyFilterRows: ITableRow[],
  filters: ITableViewFilter[],
  userEmail: string,
  userId: string,
  userTags: ITag[],
  processTags: ITag[],
  processVariables: string[],
  processVariableValues: string[],
  processTempVariables: string[],
  columns: ITableViewColumn[],
  displayValidationErrorRows: number,
  displayCommentRows: number,
  comments?: ICommentThreadStats[]
) => {
  const visibleRows: ITableRow[] = []
  const filteredRows: ITableRow[] = []

  const filtersGrouped = filters.reduce((prev, current) => {
    if (prev[current.orGroup]) {
      prev[current.orGroup].push(current)
    } else {
      prev[current.orGroup] = [current]
    }
    return prev
  }, {} as Record<string, ITableViewFilter[]>)

  // Filter currently visible rows
  const seenVisibleRows = new Set()
  const seenFilteredRows = new Set()
  rows.forEach((row) => {
    const visible = isRowVisible(
      row,
      columnValuesCount,
      filtersGrouped,
      userEmail,
      userId,
      userTags,
      processTags,
      processVariables,
      processVariableValues,
      processTempVariables,
      columns,
      displayValidationErrorRows,
      displayCommentRows,
      comments
    )
    if (visible) {
      if (!seenFilteredRows.has(row.publicId)) {
        visibleRows.push(row)
        seenVisibleRows.add(row.publicId)
      }
    } else {
      if (!seenFilteredRows.has(row.publicId)) {
        filteredRows.push(row)
        seenFilteredRows.add(row.publicId)
      }
    }
  })

  // Filter on existing filtered rows
  alreadyFilterRows.forEach((row) => {
    const visible = isRowVisible(
      row,
      columnValuesCount,
      filtersGrouped,
      userEmail,
      userId,
      userTags,
      processTags,
      processVariables,
      processVariableValues,
      processTempVariables,
      columns,
      displayValidationErrorRows,
      displayCommentRows,
      comments
    )
    if (visible) {
      if (!seenFilteredRows.has(row.publicId)) {
        visibleRows.push(row)
        seenVisibleRows.add(row.publicId)
      }
    } else {
      if (!seenFilteredRows.has(row.publicId)) {
        filteredRows.push(row)
        seenFilteredRows.add(row.publicId)
      }
    }
  })

  return {
    rows: visibleRows,
    filteredRows
  }
}

// Returns true if row matchs a filter
export const isRowVisible = (
  row: ITableRow,
  columnValuesCount: IColumnValuesCount,
  orGroupedFilters: Record<string, ITableViewFilter[]>,
  userEmail: string,
  userId: string,
  userTags: ITag[],
  processTags: ITag[],
  processVariables: string[],
  processVariableValues: string[],
  processTempVariables: string[],
  columns: ITableViewColumn[],
  displayValidationErrorRows: number,
  displayCommentRows: number,
  comments?: ICommentThreadStats[]
) => {
  const result = []

  // Get row comments
  const rowComments = comments && comments.find((comment: ICommentThreadStats) => comment.referenceId === row.publicId)

  for (const key in orGroupedFilters) {
    const group = orGroupedFilters[key]
    let hasMatch = true

    // If a filter fails to match, we mark the entire group as failing to match because
    // all filters inside a group use the AND clause
    for (const filter of group) {
      const value = row.rowData[filter.columnId]
      const column = columns.find((column) => column.publicId === filter.columnId)!
      if (
        !column ||
        !doesFilterMatchValue(
          filter.filterType,
          filter.value,
          filter.multipleValues,
          value,
          userEmail,
          userId,
          userTags,
          processTags,
          processVariables,
          processVariableValues,
          processTempVariables,
          column.kind
        )
      ) {
        hasMatch = false
        break
      }
    }

    result.push(hasMatch)
  }

  // Run comments checks
  let commentsPass = true

  if (displayCommentRows === CommentDisplayTypes.SHOW_RESOLVED) {
    commentsPass =
      rowComments && rowComments.openCommentThreads - rowComments.resolvedCommentThreads === 0 ? true : false
  } else if (displayCommentRows === CommentDisplayTypes.SHOW_UNRESOLVED) {
    commentsPass = rowComments && rowComments.openCommentThreads - rowComments.resolvedCommentThreads > 0 ? true : false
  } else if (displayCommentRows === CommentDisplayTypes.SHOW_WITH_COMMENTS) {
    commentsPass =
      rowComments && (rowComments.openCommentThreads > 0 || rowComments.resolvedCommentThreads > 0) ? true : false
  }

  // Run validation checks
  let validationPass = true

  if (
    displayValidationErrorRows === ValidationDisplayTypes.HIDE_VALIDATION ||
    displayValidationErrorRows === ValidationDisplayTypes.ONLY_VALIDATION
  ) {
    for (const column of columns) {
      const value = row.rowData[column.publicId]
      const validValue = isValidValue(value, row, columnValuesCount, column)

      if (displayValidationErrorRows === ValidationDisplayTypes.HIDE_VALIDATION && validValue.error) {
        validationPass = false
        break
      } else if (displayValidationErrorRows === ValidationDisplayTypes.ONLY_VALIDATION) {
        if (validValue.error) {
          validationPass = true
          break
        } else {
          validationPass = false
        }
      }
    }
  }

  if (
    (result.length === 0 && validationPass && commentsPass) ||
    (result.find((v) => v === true) && validationPass && commentsPass)
  ) {
    return true
  }

  return false
}

export const doesFilterMatchValue = (
  filterType: IFilterType,
  filterValue: string,
  filterMultipleValues: string[],
  value: ICellValue,
  userEmail: string,
  userId: string,
  userTags: ITag[],
  processTags: ITag[],
  processVariables: string[],
  processVariableValues: string[],
  processTempVariables: string[],
  kind: IColumnTypes
) => {
  // equal

  if (filterValue === '{{user.email}}' && typeof value === 'string') {
    value = value.toLowerCase()
  }

  if (kind) {
    if (FilterVariables.includes(filterValue)) {
      filterValue = transformFilterVariable(
        filterValue,
        userEmail,
        userId,
        userTags,
        processTags,
        processVariables,
        processVariableValues,
        processTempVariables
      )
    }

    if (
      filterMultipleValues &&
      (filterMultipleValues.includes('{{user.email}}') ||
        filterMultipleValues.includes('{{user.tags}}') ||
        filterMultipleValues.includes('{{user.tag_values}}') ||
        filterMultipleValues.includes('{{process.variables}}') ||
        filterMultipleValues.includes('{{process.variable_values}}'))
    ) {
      const newFilterMultipleValues: string[] = []
      filterMultipleValues.map((val) => {
        if (val === '{{user.email}}') {
          newFilterMultipleValues.push(userEmail.toLowerCase())
        } else if (val === '{{user.tags}}') {
          userTags.map((userTag: ITag) => newFilterMultipleValues.push(userTag.referencePublicId))
        } else if (val === '{{user.tag_values}}') {
          userTags.map((userTag: ITag) => newFilterMultipleValues.push(userTag.value))
        } else if (val === '{{process.variables}}') {
          processVariables.map((processVariable: string) => newFilterMultipleValues.push(processVariable))
        } else if (val === '{{process.variable_values}}') {
          processVariableValues.map((processVariableValue: string) =>
            newFilterMultipleValues.push(processVariableValue)
          )
        } else {
          newFilterMultipleValues.push(val)
        }
      })
      filterMultipleValues = newFilterMultipleValues
    }

    if (filterType === IFilterType.eq) {
      if (kind === 'checkbox') {
        const checkboxValue = value === null ? false : value
        if (checkboxValue?.toString() === filterValue) {
          return true
        }
      } else if (kind === 'createdby' || kind === 'modifiedby') {
        const userValue = value as ISummaryUser
        if (userValue?.publicId === filterValue) {
          return true
        } else {
          return false
        }
      } else {
        if (value == filterValue) {
          return true
        }
      }
    }

    // not equal to
    else if (filterType === IFilterType.neq) {
      if (kind === 'checkbox') {
        const checkboxValue = value === null ? false : value
        if (checkboxValue?.toString() !== filterValue) {
          return true
        }
      } else if (kind === 'createdby' || kind === 'modifiedby') {
        const userValue = value as ISummaryUser
        if (userValue?.publicId !== filterValue) {
          return true
        } else {
          return false
        }
      } else {
        if (value != filterValue) {
          return true
        }
      }
    }

    // contains
    else if (
      filterType === IFilterType.contains &&
      value !== null &&
      (typeof value === 'string' || typeof value === 'object') &&
      typeof filterValue === 'string'
    ) {
      if (Array.isArray(value)) {
        if (checkArrayValue(value, (v: string) => v.toLowerCase().includes(filterValue.toLowerCase()))) {
          return true
        }
      } else if (
        value &&
        !isSummaryUser(value) &&
        filterValue &&
        typeof filterValue === 'string' &&
        value.toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true
      }
    }

    // does not contain
    else if (
      filterType === IFilterType.not_contains &&
      (value === null || typeof value === 'string' || typeof value === 'object') &&
      typeof filterValue === 'string'
    ) {
      if (value === null) {
        return true
      } else if (Array.isArray(value)) {
        if (checkArrayValue(value, (v: string) => !v.toLowerCase().includes(filterValue.toLowerCase()))) {
          return true
        }
      } else if (value && !isSummaryUser(value) && !value.toLowerCase().includes(filterValue.toLowerCase())) {
        return true
      }
    }

    // starts with
    else if (filterType === IFilterType.starts_with && typeof value === 'string' && typeof filterValue === 'string') {
      if (value.toLowerCase().startsWith(filterValue.toLowerCase())) {
        return true
      }
    }

    // ends with
    else if (filterType === IFilterType.ends_with && typeof value === 'string' && typeof filterValue === 'string') {
      if (value.toLowerCase().endsWith(filterValue.toLowerCase())) {
        return true
      }
    }

    // is empty
    else if (filterType === IFilterType.is_null) {
      if (Array.isArray(value) && value.length === 0) return true
      else if (value == null) return true
    }

    // is not empty
    else if (filterType === IFilterType.is_not_null) {
      if (Array.isArray(value) && value.length > 0) return true
      else if (!Array.isArray(value) && value != null) return true
    }

    // is in: filter value contains row value
    else if (filterType === IFilterType.in && typeof value === 'string') {
      if (filterValue !== undefined && filterValue !== null && filterValue.includes(value)) {
        return true
      }
    }

    // one of: row value is in list of filter values
    else if (filterType === IFilterType.one_of && filterMultipleValues && Array.isArray(filterMultipleValues)) {
      if (['multilink', 'multiselect'].includes(kind) && Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (filterMultipleValues.indexOf(value[i] as string) !== -1) {
            return true
          }
        }

        return false
      } else if (USER_COLUMN_TYPES.includes(kind)) {
        return filterMultipleValues.includes((value as ISummaryUser).firebaseUserId)
      } else {
        if (
          (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value === null) &&
          filterMultipleValues &&
          filterMultipleValues.includes(value !== null && value !== undefined ? value.toString() : '')
        ) {
          return true
        }
      }
    }

    // not one of: row value is in list of filter values
    else if (filterType === IFilterType.not_one_of && filterMultipleValues && Array.isArray(filterMultipleValues)) {
      if (['multilink', 'multiselect'].includes(kind) && Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (filterMultipleValues.indexOf(value[i] as string) !== -1) {
            return false
          }
        }

        return true
      } else if (USER_COLUMN_TYPES.includes(kind)) {
        return !filterMultipleValues.includes((value as ISummaryUser).firebaseUserId)
      } else {
        if (
          (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value === null) &&
          filterMultipleValues &&
          !filterMultipleValues.includes(value !== null && value !== undefined ? value.toString() : '')
        ) {
          return true
        }
      }
    }

    // greater than
    else if (filterType === IFilterType.gt && value !== null) {
      // for date type columns
      if (DATE_COLUMN_TYPES.includes(kind)) {
        const dateValue = new Date(value as string)
        const filterDateValue = new Date(filterValue)
        return dateValue > filterDateValue
      }
      // anything else we treat as a number
      else {
        return value > parseFloat(filterValue)
      }
    }

    // less than
    else if (filterType === IFilterType.lt && value !== null) {
      if (DATE_COLUMN_TYPES.includes(kind)) {
        const dateValue = new Date(value as string)
        const filterDateValue = new Date(filterValue)
        return dateValue < filterDateValue
      } else {
        return value < parseFloat(filterValue)
      }
    }

    // greater than or equal to
    else if (filterType === IFilterType.gte && value !== null) {
      if (DATE_COLUMN_TYPES.includes(kind)) {
        const dateValue = new Date(value as string)
        const filterDateValue = new Date(filterValue)
        return dateValue >= filterDateValue
      } else {
        return value >= parseFloat(filterValue)
      }
    }

    // less than or equal to
    else if (filterType === IFilterType.lte && value !== null) {
      if (DATE_COLUMN_TYPES.includes(kind)) {
        const dateValue = new Date(value as string)
        const filterDateValue = new Date(filterValue)
        return dateValue <= filterDateValue
      } else {
        return value <= parseFloat(filterValue)
      }
    }
  }

  return false
}

const transformFilterVariable = (
  value: string,
  userEmail: string,
  userId: string,
  userTags: ITag[],
  processTags: ITag[],
  processVariables: string[],
  processVariableValues: string[],
  processTempVariables: string[]
) => {
  let transformedValue = value
  if (value === '{{user.email_domain}}') transformedValue = userEmail.split('@')[1]
  else if (value === '{{user.email}}') transformedValue = userEmail.toLowerCase()
  else if (value === '{{user.id}}') transformedValue = userId
  else if (value === '{{user.tags}}')
    transformedValue = userTags.map((userTag: ITag) => userTag.referencePublicId).join(',')
  else if (value === '{{user.tag_values}}') transformedValue = userTags.map((userTag: ITag) => userTag.value).join(',')
  else if (value === '{{process.tags}}')
    transformedValue = processTags.map((processTag: ITag) => processTag.referencePublicId).join(',')
  else if (value === '{{process.variables}}') transformedValue = processVariables.join(',')
  else if (value === '{{process.variable_values}}') transformedValue = processVariableValues.join(',')
  else if (value === '{{process.public_variables}}') transformedValue = processTempVariables.join(',')
  else transformedValue = value

  return transformedValue
}

const checkArrayValue = (value: Array<string | IFile | IVote | null>, predicate: (value: string) => boolean) => {
  // Get the string values from IFile if the value is of that type
  const arrayStringValues =
    value === null
      ? []
      : value
          .filter((v) => v !== null)
          .map((v: string | IFile | IVote | null) => ((v as IFile).filename ? (v as IFile).filename : v) as string)
          .filter((v) => v !== null)

  // check if any of the string includes or not the filter value
  return arrayStringValues.map(predicate).some((v) => v === true)
}

export const isValidFilterTypeForColType = (colType: IColumnTypes, filterType: IFilterType): boolean => {
  const stringTypes = [...TEXT_COLUMN_TYPES, 'select', 'link']
  const numberTypes = NUMBER_COLUMN_TYPES
  const simpleArrayTypes = ['multiselect', 'multilink']
  // these are arrays of objects, rather than arrays of numbers/strings
  const complexArrayTypes = [...ATTACHMENT_COLUMN_TYPES, 'vote']
  const arrayTypes = [...simpleArrayTypes, ...complexArrayTypes]
  const userJoinTypes = USER_COLUMN_TYPES
  const booleanTypes = BOOLEAN_COLUMN_TYPES

  // supported by all types apart from boolean
  if (filterType === IFilterType.is_null || filterType === IFilterType.is_not_null) {
    return !userJoinTypes.includes(colType) && !booleanTypes.includes(colType)
  }

  // supported by all types except array types
  if (filterType === IFilterType.eq || filterType === IFilterType.neq) {
    return !arrayTypes.includes(colType)
  }

  // supported only by numbers, strings, and non-complex array types
  if (filterType === IFilterType.one_of || filterType === IFilterType.not_one_of) {
    return !complexArrayTypes.includes(colType) && !booleanTypes.includes(colType)
  }

  // only supported by string and array types
  if (filterType === IFilterType.contains || filterType === IFilterType.not_contains) {
    return stringTypes.includes(colType) || arrayTypes.includes(colType)
  }

  // only supported by string types
  if (filterType === IFilterType.starts_with || filterType === IFilterType.ends_with || filterType === IFilterType.in) {
    return stringTypes.includes(colType)
  }

  // only supported by number fields and date fields
  if (
    filterType == IFilterType.gt ||
    filterType == IFilterType.gte ||
    filterType == IFilterType.lt ||
    filterType == IFilterType.lte
  ) {
    return numberTypes.includes(colType) || DATE_COLUMN_TYPES.includes(colType)
  }

  return false
}

export const getDefaultFilterTypeForColType = (colType: IColumnTypes): IFilterType => {
  const booleanTypes = BOOLEAN_COLUMN_TYPES
  if (booleanTypes.includes(colType)) {
    return IFilterType.eq
  }

  return IFilterType.one_of
}

export const getFilterValues = (rows: ITableRow[], column: ITableViewColumn, columnId: string) => {
  const values = new Set()
  if (column) {
    const shouldFlatten = ['multilink', 'multiselect'].includes(column.kind)
    const shouldTransformUserObject = USER_COLUMN_TYPES.includes(column.kind)
    for (const row of rows) {
      const cellValue = row.rowData[columnId]
      if (shouldFlatten && cellValue) {
        ;(cellValue as any[]).forEach((value) => values.add(value))
      } else if (shouldTransformUserObject && cellValue) {
        let found = false
        values.forEach((val) => {
          if (!found) found = (val as ISummaryUser).firebaseUserId === (cellValue as ISummaryUser).firebaseUserId
        })
        if (!found) values.add(cellValue)
      } else {
        values.add(row.rowData[columnId])
      }
    }
  }

  return values
}
