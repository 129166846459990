import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IProcessResponseObject, IProcessSectionObject, ITable, ITableColumn, ITableView, ResponsePayload } from 'types'
import AlterResponseDialog from 'components/process/components/responseEdit/components/alterResponseDialog'
import Checkbox from 'components/checkbox'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { RESPONSE_TYPES } from 'components/process/helpers/functions'
import Responders from 'components/process/components/responseEdit/components/responders'
import NewTable from 'components/spreadsheet/components/newTable'
import { getTable as getTableApi } from 'components/spreadsheet/helpers/api'
import { getProjectTables, getTable } from 'helpers/utils'
import Button from 'components/button'
import Select from 'components/select'

interface ResponseEditProps {
  response: IProcessResponseObject
  responseNumber: number
  deleteResponse: (response: IProcessResponseObject) => void
  resetResponse: (response: IProcessResponseObject) => void
  setSection: (section: IProcessSectionObject) => void
  processId: string
  processSectionId: string
}

const defaultDialogState = {
  type: '',
  open: false
}

interface ISimpleView {
  name: string
  publicId: string
}

const ResponseEdit: React.FC<ResponseEditProps> = ({
  response,
  responseNumber,
  deleteResponse,
  resetResponse,
  setSection,
  processId,
  processSectionId
}: ResponseEditProps) => {
  const [alterResponseDialog, setAlterResponseDialog] = useState<{ type: string; open: boolean }>(defaultDialogState)
  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const [newTableModal, setNewTableModal] = useState<boolean>(false)
  const [tableViews, setTableViews] = useState<Array<ISimpleView>>([])
  const [columnOptions, setColumnOptions] = useState<ITableColumn[]>([])
  const [tables, setTables] = useState<ITable[]>()

  const spaceBetween = '20px'
  const labelFlex = 3

  useEffect(() => {
    if (project.publicId !== '' && response && (response.type === 'Table' || response.type === 'Selection')) {
      getProjectTables(project.publicId)
        .then((tables) => {
          setTables(tables)
        })
        .catch((e) => setSnackbarMessage({ status: 'error', message: e }))
    }
  }, [project.publicId, response && response.type])

  useEffect(() => {
    if (response && response.typeOptions && response.typeOptions.table) {
      if (response.type === 'Table') {
        api
          .getTableViews(response.typeOptions.table, { ignoreColumns: true })
          .then((viewResponse) => {
            setTableViews(
              viewResponse.data.map((view: ITableView) => {
                return { name: view.name, publicId: view.publicId }
              })
            )
          })
          .catch(() => setTableViews([]))
      }
      if (response.type === 'Selection') {
        async function getTableColumns() {
          const table: ResponsePayload<ITable> = await getTableApi(response.typeOptions.table)
          const columns = table.data.columns
            .filter((column: ITableColumn) => ['text', 'integer', 'float', 'tag'].includes(column.kind))
            .sort((a: any, b: any) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))

          setColumnOptions(columns)
        }
        getTableColumns()
      }
    }
  }, [response && response.typeOptions && response.typeOptions.table])

  const createNewResponseTable = () => {
    setNewTableModal(true)
  }

  const handleResponseChange = async (field: string, value: any, secondValue?: any) => {
    let selectionOptions: Array<any> = []
    if (response.typeOptions && field === 'typeOptionsSelection') {
      const apiResponse = await api.getDistinct({
        tableId: response.typeOptions.table,
        columnId: value.publicId
      })

      if (apiResponse.data) {
        selectionOptions = apiResponse.data.map(String)
      } else {
        setSnackbarMessage({ status: 'error' })
      }
    }
    api
      .updateProcessResponse({
        responders: field === 'responders' ? value : response.responders,
        type: field === 'type' ? value : response.type,
        dueDate:
          field === 'dueDate'
            ? value === null || value === ''
              ? null
              : new Date(value).toISOString()
            : response.dueDate,
        resetAfterResponse: field === 'resetAfterResponse' ? value : response.resetAfterResponse,
        typeOptions:
          field === 'type'
            ? {}
            : field === 'typeOptionsTable'
            ? {
                tableView: secondValue ? secondValue : null,
                table: value,
                selectionColumn: { name: 'None Selected', publicId: 'none' },
                selectionOptions: null
              }
            : field === 'typeOptionsView'
            ? { ...response.typeOptions, tableView: value }
            : field === 'typeOptionsFileUpload'
            ? { noOfFiles: value }
            : field === 'typeOptionsSelection'
            ? {
                ...response.typeOptions,
                selectionColumn: value,
                selectionOptions: selectionOptions,
                selectionNumber: 'Single'
              }
            : field === 'typeOptionsSelectionNumber'
            ? { ...response.typeOptions, selectionNumber: value }
            : response.typeOptions,
        processId: processId,
        processSectionId: processSectionId,
        processResponseId: response.publicId,
        pdfIncludeResponse: field === 'pdfIncludeResponse' ? value : response.pdfIncludeResponse,
        expireResponse: field === 'expireResponse' ? value : response.expireResponse,
        context: { projectId: project.publicId }
      })
      .then((apiResponse) => {
        setSection(apiResponse.data)
      })
      .catch((error) => {
        console.error(error)
        setSnackbarMessage({ status: 'error' })
      })
  }

  if (!response.approved) {
    return (
      <div data-cy="process-section-response-container" style={{ marginTop: '20px', marginBottom: '40px' }}>
        <div className="flex w-full items-center" style={{ marginBottom: '10px' }}>
          <div data-cy="process-response-number" className="text-lg font-semibold">{`Response #${
            responseNumber + 1
          }`}</div>

          <div style={{ marginLeft: 'auto' }}>
            <Checkbox
              key={response.publicId}
              defaultChecked={response.pdfIncludeResponse}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleResponseChange('pdfIncludeResponse', event.target.checked)
              }
            >
              Include Response in Download?
            </Checkbox>
          </div>

          <Button style={{ marginLeft: '10px' }} internalType="danger" onClick={() => deleteResponse(response)}>
            Delete Response
          </Button>
        </div>
        <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
          <div style={{ flex: 1 }}>Response type:</div>
          <div style={{ flex: labelFlex, overflow: 'hidden', padding: '5px' }}>
            <select
              className="w-full"
              value={response.type}
              onChange={(event: any) => handleResponseChange('type', event.target.value)}
              data-cy="response-type-select"
            >
              {RESPONSE_TYPES.map((responseType: string, responseTypeNumber: number) => {
                return (
                  <option key={responseTypeNumber} value={responseType}>
                    {responseType}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        {response.type === 'File Upload' && (
          <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
            <div style={{ width: '200px' }}># Files to upload:</div>
            <input
              onChange={(event: any) => handleResponseChange('typeOptionsFileUpload', event.target.value)}
              defaultValue={response.typeOptions && response.typeOptions.noOfFiles ? response.typeOptions.noOfFiles : 1}
              type="number"
              data-cy="response-file-upload"
            />
          </div>
        )}

        {(response.type === 'Selection' || response.type === 'Table') && tables && (
          <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
            <div style={{ flex: 1 }}>Table:</div>
            <div style={{ flex: labelFlex, overflow: 'hidden', padding: '5px' }}>
              <Select
                options={[{ label: '+ Create New', value: 'createnew' }].concat(
                  tables
                    .slice()
                    .sort((a: ITable, b: ITable) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((table: ITable) => {
                      return { value: table.publicId, label: table.name }
                    })
                )}
                onOptionClick={(option) => {
                  if (option === 'createnew') {
                    createNewResponseTable()
                  } else {
                    const table = tables.find((table) => table.publicId === option)
                    if (table) {
                      handleResponseChange('typeOptionsTable', table.publicId, table.defaultViewId)
                    }
                  }
                }}
                optionsSelected={response.typeOptions && response.typeOptions.table ? [response.typeOptions.table] : []}
                error={
                  !response.typeOptions || !response.typeOptions.table
                    ? 'You need to select a table for the user to respond to'
                    : undefined
                }
              />
            </div>
          </div>
        )}

        {response.type === 'Selection' &&
          response.typeOptions &&
          response.typeOptions.table &&
          tables &&
          getTable(tables, response.typeOptions.table) && (
            <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
              <div style={{ flex: 1 }}>Column:</div>
              <div style={{ flex: labelFlex, overflow: 'hidden', padding: '5px' }}>
                <Select
                  options={columnOptions.map((column) => {
                    return { value: column.publicId, label: column.name }
                  })}
                  optionsSelected={
                    response.typeOptions.selectionColumn && response.typeOptions.selectionColumn.publicId
                      ? [response.typeOptions.selectionColumn.publicId]
                      : []
                  }
                  onOptionClick={(option) => {
                    const value = columnOptions.find((column) => column.publicId === option)
                    if (value) {
                      handleResponseChange('typeOptionsSelection', value)
                    }
                  }}
                  data-cy="response-selection"
                />
              </div>
            </div>
          )}

        {response.type === 'Selection' && response.typeOptions && response.typeOptions.selectionOptions && (
          <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
            <div style={{ flex: 1 }}>Options to select:</div>
            <div style={{ flex: labelFlex, overflow: 'hidden', padding: '5px' }}>
              <select
                value={response.typeOptions.selectionNumber ? response.typeOptions.selectionNumber : 'Single'}
                onChange={(event) => handleResponseChange('typeOptionsSelectionNumber', event.target.value)}
              >
                <option value={'Single'}>Single</option>
                <option value={'Multiple'}>Multiple</option>
              </select>
            </div>
          </div>
        )}

        {response.type === 'Table' &&
          response.typeOptions &&
          response.typeOptions.table &&
          tables &&
          getTable(tables, response.typeOptions.table) && (
            <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
              <div style={{ flex: 1 }}>Table View:</div>
              <div style={{ flex: labelFlex, overflow: 'hidden', padding: '5px' }}>
                <Select
                  options={tableViews
                    .sort((a: ISimpleView, b: ISimpleView) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((view) => {
                      return { value: view.publicId, label: view.name }
                    })}
                  onOptionClick={(option) => handleResponseChange('typeOptionsView', option)}
                  optionsSelected={
                    response.typeOptions && response.typeOptions.tableView ? [response.typeOptions.tableView] : []
                  }
                  data-cy="response-table"
                />
              </div>
            </div>
          )}
        {response &&
          response.type === 'Table' &&
          response.typeOptions &&
          response.typeOptions.table &&
          response.typeOptions.tableView && (
            <div className="text-sm" style={{ marginTop: '5px' }}>
              <Link
                style={{ marginLeft: 'auto' }}
                to={`/project/${project.publicId}/table/${response.typeOptions.table}/view/${response.typeOptions.tableView}`}
                target="_blank"
              >
                Click To View Selected Table View
              </Link>
            </div>
          )}
        <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
          <div style={{ flex: 1 }}>Response Date:</div>
          <div style={{ flex: labelFlex, overflow: 'hidden', padding: '5px' }}>
            <input
              data-cy="response-date-picker"
              type="date"
              value={response.dueDate ? response.dueDate.split('T')[0] : ''}
              onChange={(event) => handleResponseChange('dueDate', event.target.value)}
              style={{ width: '100%', marginBottom: '0px', padding: '15px' }}
            />
          </div>
        </div>

        <div className="flex items-center" style={{ marginTop: spaceBetween, marginBottom: spaceBetween }}>
          <div style={{ flex: 1 }}>Reset After Response:</div>
          <div style={{ flex: labelFlex, overflow: 'hidden', padding: '5px' }}>
            <select
              className="w-full"
              value={response.resetAfterResponse ? 'true' : 'false'}
              onChange={(event: any) => handleResponseChange('resetAfterResponse', event.target.value === 'true')}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>

        {response.dueDate && (
          <div style={{ width: '100%', display: 'flex' }}>
            <div
              style={{
                marginLeft: 'auto',
                fontSize: '95%',
                marginBottom: '10px',
                position: 'relative',
                top: '-15px',
                display: 'flex',
                width: '100%'
              }}
            >
              <Checkbox
                key={response.publicId}
                defaultChecked={response.expireResponse}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleResponseChange('expireResponse', event.target.checked)
                }
              >
                No Response Allowed After Due Date
              </Checkbox>
            </div>
          </div>
        )}

        <Responders
          setSection={setSection}
          processId={processId}
          processSectionId={processSectionId}
          response={response}
        />

        {alterResponseDialog && (
          <AlterResponseDialog
            open={alterResponseDialog.open}
            onClose={() => setAlterResponseDialog(defaultDialogState)}
            type={alterResponseDialog.type}
            message={'Do not delete this response if it has active data you would like to keep.'}
            onSave={() => {
              setAlterResponseDialog(defaultDialogState)
              deleteResponse(response)
            }}
          />
        )}

        {newTableModal && (
          <NewTable
            id="new-table-modal"
            open={newTableModal}
            setOpen={setNewTableModal}
            noRedirect={true}
            noRedirectFunction={(publicId: string) => handleResponseChange('typeOptionsTable', publicId)}
          />
        )}
      </div>
    )
  } else {
    return (
      <div data-cy="response-container" style={{ marginTop: '20px', marginBottom: '40px' }}>
        <div className="flex w-full items-center" style={{ marginBottom: '10px' }}>
          <div className="text-lg font-semibold">{`Response #${responseNumber + 1} (Locked - Response Received)`}</div>
          <div style={{ marginLeft: 'auto' }}>
            <Checkbox
              key={response.publicId}
              defaultChecked={response.pdfIncludeResponse}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleResponseChange('pdfIncludeResponse', event.target.checked)
              }
              data-cy="include-response-download-file"
            >
              Include Response in Download?
            </Checkbox>
          </div>
          <Button style={{ marginLeft: '10px' }} onClick={() => setAlterResponseDialog({ open: true, type: 'Reset' })}>
            Reset Response
          </Button>
        </div>
        <AlterResponseDialog
          open={alterResponseDialog.open}
          onClose={() => setAlterResponseDialog(defaultDialogState)}
          type={alterResponseDialog.type}
          message={'Do not reset this response if there is information you would like to keep in the response.'}
          onSave={() => {
            setAlterResponseDialog(defaultDialogState)
            resetResponse(response)
          }}
        />

        {newTableModal && (
          <NewTable
            id="new-table-modal"
            open={newTableModal}
            setOpen={setNewTableModal}
            noRedirect={true}
            noRedirectFunction={(publicId: string) => handleResponseChange('typeOptionsTable', publicId)}
          />
        )}
      </div>
    )
  }
}

export default ResponseEdit
