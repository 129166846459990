import React, { useEffect } from 'react'
import api from 'helpers/api'
import { ICeleryStats } from 'types'
import { secondsToHms } from '../../helpers/utils'
import { Table } from 'components/table'
import Button from 'components/button'

const CeleryStats: React.FC = () => {
  const [stats, setStats] = React.useState<ICeleryStats>()
  const [lastUpdate, setLastUpdate] = React.useState<Date>()

  const fetchCeleryStats = async () => {
    const response = await api.getCeleryStats()
    setStats(response.data)
    setLastUpdate(new Date())
  }

  useEffect(() => {
    fetchCeleryStats()
    const intervalId = setInterval(fetchCeleryStats, 10000)
    document.title = 'Morta | Celery Stats'
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const handleCeleryQueueActions = async (action: string, queue?: string) => {
    if (window.confirm(`Are you sure you want to ${action} ${queue ? `the ${queue} queue` : ''}?`)) {
      await api.executeCeleryQueueActions(action, queue)
    }
  }

  return (
    <>
      <p style={{ marginTop: '10px' }}>
        Data last updated: <b>{lastUpdate?.toLocaleString()}</b>, will fetch automatically every 10s
      </p>

      <div style={{ marginTop: '20px' }}>
        <h3 style={{ marginBottom: '10px' }}>Queues</h3>
        <Table
          data={
            stats
              ? stats.queues.map((queue) => {
                  return {
                    name: {
                      label: queue.name,
                      value: queue.name
                    },
                    pending: {
                      label: queue.pending,
                      value: queue.pending
                    },
                    active: {
                      label: queue.active['total'],
                      value: queue.active['total']
                    },
                    active_task_args: {
                      label: queue.active['tasks']
                        .map(
                          (entry: { args: any; name: string }) => `${entry['name'].split('.').pop()}(${entry['args']})`
                        )
                        .join('\n'),
                      value: queue.active['tasks']
                        .map(
                          (entry: { args: any; name: string }) => `${entry['name'].split('.').pop()}(${entry['args']})`
                        )
                        .join('\n')
                    },
                    reserved: {
                      label: queue.reserved,
                      value: queue.reserved
                    },
                    actions: {
                      label: (
                        <Button internalType="danger" onClick={() => handleCeleryQueueActions('purge', queue.name)}>
                          Revoke all tasks
                        </Button>
                      ),
                      value: 'Cool'
                    }
                  }
                })
              : []
          }
          include={[
            { header: 'Queue Name', id: 'name' },
            { header: '# Pending Tasks', id: 'pending' },
            { header: '# Active Tasks', id: 'active' },
            { header: '# Active Tasks Args', id: 'active_task_args' },
            { header: '# Reserved Tasks', id: 'reserved' },
            { header: 'Actions', id: 'actions' }
          ]}
          defaultSort={'name'}
          defaultSortAscending={true}
          sort={true}
          loading={!stats}
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3 style={{ marginBottom: '10px' }}>Workers</h3>

        <Table
          data={
            stats
              ? stats.workers.map((worker) => {
                  return {
                    name: {
                      label: worker.name,
                      value: worker.name
                    },
                    accepted: {
                      label: worker.tasks_executed,
                      value: worker.tasks_executed
                    },
                    active: {
                      label: worker.active_tasks,
                      value: worker.active_tasks
                    },
                    reserved: {
                      label: worker.reserved_tasks,
                      value: worker.reserved_tasks
                    },
                    revoked: {
                      label: worker.revoked_tasks,
                      value: worker.revoked_tasks
                    },
                    scheduled: {
                      label: worker.scheduled_tasks,
                      value: worker.scheduled_tasks
                    },
                    uptime: {
                      label: secondsToHms(worker.uptime),
                      value: worker.uptime
                    },
                    max_concurrency: {
                      label: worker.max_concurrency,
                      value: worker.max_concurrency
                    },
                    max_tasks_per_child: {
                      label: worker.max_tasks_per_child,
                      value: worker.max_tasks_per_child
                    }
                  }
                })
              : []
          }
          include={[
            { header: 'Worker Name', id: 'name' },
            { header: 'Number of Processes/Threads', id: 'max_concurrency' },
            { header: 'Max Tasks Per Process/Thread', id: 'max_tasks_per_child' },
            { header: 'Total # Accepted Tasks', id: 'accepted' },
            { header: '# Active Tasks', id: 'active' },
            { header: '# Reserved Tasks', id: 'reserved' },
            { header: '# Revoked Tasks', id: 'revoked' },
            { header: '# Scheduled Tasks', id: 'scheduled' },
            { header: 'Uptime', id: 'uptime' }
          ]}
          defaultSort={'name'}
          defaultSortAscending={true}
          sort={true}
          loading={!stats}
        />
      </div>
    </>
  )
}

export default CeleryStats
