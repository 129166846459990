import React from 'react'
import { IColumnTypes } from 'components/spreadsheet/types'
import { getRowHeightVariable, validateDateTimeField } from 'components/spreadsheet/helpers/functions'
import { EDITABLE_DATETIME_OPTION_TYPES } from 'components/spreadsheet/constants/const'
import Button from 'components/button'
import { EditDate } from 'components/icons'
import { formatDate, formatDateTime } from 'components/spreadsheet/helpers/format'
import { isDate } from 'helpers/date'

interface DateCellProps {
  value: string
  align: 'center' | 'left' | 'right'
  color: string
  kind: IColumnTypes
  readOnly: boolean
  isExpanded?: boolean
  editing: boolean
  width: number
  rowHeight: number
  selected: boolean
  validation: boolean
  setEditing: (editing: boolean) => void
  handleKeyDown: (event: React.KeyboardEvent) => void
  setCellValue: (value: string) => void
  dateFormat: string | null
}

const DateCellComponent: React.FC<DateCellProps> = ({
  value,
  align,
  color,
  kind,
  readOnly,
  isExpanded,
  editing,
  width,
  rowHeight,
  selected,
  validation,
  setEditing,
  handleKeyDown,
  setCellValue,
  dateFormat
}) => {
  const dateValue =
    value && typeof value === 'string' && value !== '' && isDate(value + 'Z')
      ? kind === 'date'
        ? formatDate(new Date(value + 'Z').toISOString(), dateFormat ? dateFormat : 'YYYY-MM-DD')
        : formatDateTime(new Date(value + 'Z').toISOString(), dateFormat ? dateFormat : 'YYYY-MM-DD HH:mm:ss')
      : ''

  if (EDITABLE_DATETIME_OPTION_TYPES.includes(kind) && editing && !readOnly) {
    return (
      <input
        className="cell-value-editing focus"
        type={kind === 'datetime' ? 'datetime-local' : kind}
        style={{ width: 'inherit', height: `${getRowHeightVariable(rowHeight) - 1}px` }}
        value={value ? value : ''}
        onChange={(event) => setCellValue(event.target.value)}
        onKeyDown={handleKeyDown}
        autoFocus
      ></input>
    )
  } else {
    return (
      <div
        className={`cell-value ${selected ? 'focus' : ''}`}
        tabIndex={-1}
        style={{
          textAlign: align,
          color: color,
          width: width,
          maxWidth: width,
          maxHeight: `${getRowHeightVariable(rowHeight)}px`
        }}
        onDoubleClick={(event) => !EDITABLE_DATETIME_OPTION_TYPES.includes(kind) && event.stopPropagation()}
      >
        <div
          className="truncate flex items-center"
          style={{
            maxHeight: `${getRowHeightVariable(rowHeight) - 3}px`,
            width: validation ? 'calc(100% - 25px)' : '100%'
          }}
        >
          {(selected || isExpanded) && !readOnly && (
            <Button
              id="calendar-today-icon-container"
              internalType="grey"
              style={{ maxWidth: '27px', width: '27px', padding: '2px', marginTop: '1px', height: 'inherit', flex: 1 }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                setEditing(true)
              }}
            >
              <EditDate id="calendar-today-icon" />
            </Button>
          )}
          {validateDateTimeField(kind, value !== null ? value : '', '').pass ? (
            kind === 'date' ? (
              dateValue
            ) : (
              `${dateValue}`
            )
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    )
  }
}

export const DateCell = React.memo(DateCellComponent)
